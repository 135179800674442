import React, {useEffect, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {Splitter, SplitterPanel} from "primereact/splitter";
import CreatorToolFieldsTab from "./tabs/CreatorToolFieldsTab";
import CreatorToolCommonTab from "./tabs/CreatorToolCommonTab";
import CreatorToolPreview from "../CreatorToolPreview";
import {Button, message} from "antd";
import "./CreatorTool.css";
import {useMutation} from "@tanstack/react-query";
import ToolFacade from "../../../services/facade/ToolFacade";
import _ from "lodash";
import {LoadingOutlined} from "@ant-design/icons";
import {useLocation, useNavigate } from "react-router-dom";
import CreatorToolPromptTab from "./tabs/CreatorToolPromptTab";
import MainHeaderTitle from "../../main-header/MainHeaderTitle";
import CreatorToolIntroTab from "./tabs/CreatorToolIntroTab";
import {useCreateTool, useUpdateTool} from "../../../utils/hooks/useTools";

const CreatorTool = ({setHeaderTitle}) => {
    const location = useLocation();

    const [tool, setTool] = useState(location.state?.tool || {});
    const [isEditing, setEditing] = useState(!!location.state?.tool);

    const {createTool, isCreating} = useCreateTool();
    const {updateTool, isUpdating} = useUpdateTool();

    useEffect(() => {
        if (location.state?.tool) {
            setEditing(true)
        }

        setHeaderTitle(
            <MainHeaderTitle
                src={`${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${tool.icon}`}
                title={tool.name}
                renderBack
            />
        );
    }, [location.state?.tool, setHeaderTitle, tool.icon, tool.name]);

    const validateTool = (tool) => {
        return tool.name &&
            tool.description &&
            tool.category &&
            tool.icon &&
            tool.badge &&
            tool.outputs &&
            tool.maxOutputs &&
            tool.artifact &&
            !_.isEmpty(tool.tags) &&
            !_.isEmpty(tool.fields) && tool.fields.every(validateField) &&
            validateIntroduction(tool.introduction) &&
            !_.isEmpty(tool.generationModelPreference) &&
            tool.generationModelPreference.regular &&
            tool.generationModelPreference.advanced;
    }

    const validateIntroduction = (introduction) => {
        return !_.isEmpty(introduction?.text) &&
            introduction?.difficultyLevel &&
            introduction?.estimatedTime && introduction?.estimatedTime > 0;
    }

    const validateField = (field) => {
        const validateExampleValue = _.get(tool, "introduction.hasExample", false) ? !_.isEmpty(field.exampleValue) : true;
        return field.name &&
            field.type &&
            validateExampleValue;
    }

    const handleSaveTool = () => {
        if (isEditing) {
            updateTool(tool);
        } else {
            createTool(tool);
        }
    }

    const handleCommonUpdate = ({name, description, category, icon, badge, outputs, maxOutputs, artifact, tags, specialty, organizationIds, generationModelPreference, availableSubscriptions}) => {
        const copy = {...tool};
        copy.name = name;
        copy.description = description;
        copy.category = category;
        copy.icon = icon;
        copy.badge = badge;
        copy.outputs = outputs;
        copy.maxOutputs = maxOutputs;
        copy.artifact = artifact;
        copy.tags = tags;
        copy.specialty = specialty;
        copy.organizationIds = organizationIds;
        copy.generationModelPreference = generationModelPreference;
        copy.availableSubscriptions = availableSubscriptions;
        setTool(copy);
    }

    const handleFieldsUpdate = (fields) => {
        const copy = {...tool};
        copy.fields = fields;
        setTool(copy);
    }

    const handleIntroUpdate = (intro) => {
        const copy = {...tool};
        copy.introduction = intro;
        setTool(copy);
    }

    const handlePromptUpdate = (prompt) => {
        const copy = {...tool};
        if (!_.isEmpty(prompt)) {
            copy.prompt = prompt;
            setTool(copy);
        }
    }

    const renderCommonTab = () => {
        return (
            <TabPanel header="Informações básicas">
                <CreatorToolCommonTab tool={tool} onUpdate={handleCommonUpdate}/>
            </TabPanel>
        );
    }

    const renderFieldsTab = () => {
        return (
            <TabPanel header="Campos"  style={{height: "100%"}}>
                <CreatorToolFieldsTab tool={tool} onUpdate={handleFieldsUpdate}/>
            </TabPanel>
        );
    }

    const renderIntroTab = () => {
        return (
            <TabPanel header="Introdução" style={{height: "100%"}}>
                <CreatorToolIntroTab tool={tool} onUpdate={handleIntroUpdate}/>
            </TabPanel>
        );
    }

    const renderPromptTab = () => {
        return (
            <TabPanel header="Prompt" style={{height: "100%"}}>
                <CreatorToolPromptTab tool={tool} onUpdate={handlePromptUpdate}/>
            </TabPanel>
        );
    }

    const renderSaveButton = () => {
        const disabled = !validateTool(tool) || isCreating || isUpdating;
        return (
            <div className="CreatorToolSaveButtonContainer">
                <Button
                    size="large"
                    type="primary"
                    className="CreatorToolSaveButton"
                    onClick={handleSaveTool}
                    disabled={disabled}
                >
                    {(isCreating || isUpdating) ? <LoadingOutlined spin/> : 'Salvar'}
                </Button>
            </div>
        );
    }

    return (
        <div className="h-full">
            <Splitter className="h-full">
                <SplitterPanel className="CreatorToolSplitterContainer" size={65} minSize={40}>
                    <TabView className="h-full">
                        {renderCommonTab()}
                        {renderIntroTab()}
                        {renderFieldsTab()}
                        {renderPromptTab()}
                    </TabView>
                    {renderSaveButton()}
                </SplitterPanel>
                <SplitterPanel className="CreatorToolSplitterContainer" size={35} minSize={20}>
                    <CreatorToolPreview tool={tool}/>
                </SplitterPanel>
            </Splitter>
        </div>
    );
}

export default CreatorTool;