import React, {useState} from 'react';
import {Button, Card, Form, Input, message, Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowUp, faLink, faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import BrandVoiceRemote from "../../../services/dao/remote/brandVoice";
import DocsUpload from "./DocsUpload";
import {useCreateBrandVoice} from "../../../utils/hooks/useBrandVoices";
import PremiumButton from "../../../components/buttons/PremiumButton";

const BrandVoiceCreateTypeCard = ({icon, title, description, onClick}) => {
    return (
        <Card
            className="w-full font-primary hover:border-primary hover:bg-primary-bg-hover hover:cursor-pointer transition-all"
            onClick={onClick}
        >
            <div className="flex flex-col gap-1 md:gap-2 text-center">
                <FontAwesomeIcon icon={icon} className="text-primary opacity-75 my-1 md:my-3 text-[24px] md:text-[48px]"/>
                <div className="text-lg font-bold">
                    {title}
                </div>
                <div className="text-sm text-gray-400">
                    {description}
                </div>
            </div>
        </Card>
    );

}

const BrandVoiceCreateModalChoice = ({onSelect}) => {
    return (
        <div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4 w-full mt-2 md:mt-4 mb-2 md:mb-8">
                <BrandVoiceCreateTypeCard
                    icon={faQuoteLeft}
                    title="Texto"
                    description="Escreva ou copie e cole o texto que deseja utilizar"
                    onClick={() => onSelect('text')}
                />

                <BrandVoiceCreateTypeCard
                    icon={faFileArrowUp}
                    title="Arquivo"
                    description="Faça upload de um arquivo de texto (.txt, .pdf ou .docx)"
                    onClick={() => onSelect('file')}
                />

                <BrandVoiceCreateTypeCard
                    icon={faLink}
                    title="URL"
                    description="Insira uma URL e o CMOs.ai irá extrair o texto para você"
                    onClick={() => onSelect('url')}
                />
            </div>

            <div className="text-sm text-gray-400 font-primary">
                Você deve possuir permissões de uso sobre todo e qualquer conteúdo que você adicionar ao CMOs.ai. Usar o
                CMOs.ai para violar os direitos autorais de terceiros é uma violação dos nossos Termos de Serviço.
            </div>
        </div>
    );
};

const BrandVoiceCreateModalText = ({setVoice, setLoading, isCreating}) => {
    const [text, setText] = useState("");
    const [isLoading, setLocalLoading] = useState(false);

    const handleScanText = async () => {
        setLoading(true);
        setLocalLoading(true);
        try {
            const data = await BrandVoiceRemote.scanText({text})
            setVoice(data);
        } catch (err) {
            console.error(err);
            message.error("O texto informado não pôde ser analisado.");
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    }

    return (
        <Form.Item label="Texto">
            <Input.TextArea
                rows={4}
                autoSize={{minRows: 6, maxRows: 12}}
                showCount
                maxLength={7000}
                placeholder="Escreva ou cole o texto que deseja utilizar como voz da sua marca"
                onChange={(e) => setText(e.target.value)}
                value={text}
                disabled={isCreating || isLoading}
            />

            <PremiumButton
                type="primary"
                size="large"
                onClick={handleScanText}
                loading={isLoading}
                disabled={isLoading || !text}
                className="mt-2 flex"
            >
                {!isLoading && "Analizar"}
                {isLoading && "Analizando"}
            </PremiumButton>
        </Form.Item>
    );
}

const BrandVoiceCreateModalURL = ({setVoice, setLoading, isCreating}) => {
    const [url, setUrl] = useState("")
    const [isLoading, setLocalLoading] = useState(false);

    const handleScanUrl = async () => {
        setLoading(true);
        setLocalLoading(true)
        try {
            const data = await BrandVoiceRemote.scanUrl({url})
            setVoice(data);
        } catch (err) {
            console.error(err);
            message.error("A URL informada não pôde ser acessada.");
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    }

    return (
        <Form.Item name="url" label="URL">
            <div className="flex flex-row items-center gap-2">
                <Input
                    prefix={<FontAwesomeIcon icon={faLink} className="mr-1 text-gray-400"/>}
                    placeholder="https://www.cmos.ai"
                    size="large"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    disabled={isCreating || isLoading}
                />

                <PremiumButton
                    type="primary"
                    size="large"
                    onClick={handleScanUrl}
                    loading={isLoading}
                    disabled={isLoading || !url}
                >
                    {!isLoading && "Analizar"}
                    {isLoading && "Analizando"}
                </PremiumButton>
            </div>
        </Form.Item>
    );
}

const BrandVoiceCreateModalUploadFile = ({setVoice, setLoading, isCreating}) => {
    return (
        <DocsUpload
            onUpload={(text) => setVoice(text)}
            onLoading={(loading) => setLoading(loading)}
            disabled={isCreating}
        />
    );
}


const BrandVoiceCreateModal = ({open, onClose}) => {
    const {createBrandVoice, isCreating} = useCreateBrandVoice({
        onSuccess: () => {
            onClose();
            clearForm();
        },
        onError: () => {
            onClose();
            clearForm();
        }
    });

    const [type, setType] = useState(null);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [voice, setVoice] = useState("");

    const [isLoading, setLoading] = useState(false);

    const clearForm = () => {
        setName("");
        setDescription("");
        setVoice("");
        setType(null);
    }

    const handleCreate = () => {
        createBrandVoice({
            name,
            description,
            voice
        });
    }

    const renderContent = () => {
        switch (type) {
            case 'url':
                return (
                    <BrandVoiceCreateModalURL
                        setVoice={(voice) => setVoice(voice)}
                        setLoading={setLoading}
                        isCreating={isCreating}
                    />
                );
            case 'file':
                return (
                    <BrandVoiceCreateModalUploadFile
                        setVoice={(voice) => setVoice(voice)}
                        setLoading={setLoading}
                        isCreating={isCreating}
                    />
                );
            case 'text':
                return (
                    <BrandVoiceCreateModalText
                        setVoice={(voice) => setVoice(voice)}
                        setLoading={setLoading}
                        isCreating={isCreating}
                    />
                );
            default:
                return <BrandVoiceCreateModalChoice onSelect={(type) => setType(type)}/>
        }
    }

    const renderFooter = () => {
        if (!type) return null;

        const disabled = isLoading || !voice || !name || isCreating;

        return (
            <div className="flex flex-row justify-end gap-2">
                <Button
                    type="text"
                    size="large"
                    onClick={() => {
                        setType(null)
                        setVoice("")
                    }}
                    disabled={isLoading || isCreating}
                >
                    Voltar
                </Button>
                <PremiumButton
                    type="primary"
                    size="large"
                    disabled={disabled}
                    onClick={handleCreate}
                    loading={isCreating}
                >
                    {isCreating && "Criando"}
                    {!isCreating && "Criar voz"}
                </PremiumButton>
            </div>
        );
    }

    return (
        <Modal
            title="Criar brand voice"
            centered
            open={open}
            width={800}
            okText="Adicionar"
            cancelText="Cancelar"
            footer={renderFooter()}
            className="font-primary"
            onCancel={onClose}
        >
            <div className="md:p-4">
                <Form layout="vertical" autoComplete="off">
                    {type && (
                        <>
                            <Form.Item label="Nome">
                                <Input
                                    placeholder="Voz da minha marca"
                                    size="large"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={isCreating}
                                />
                            </Form.Item>

                            <Form.Item label="Descrição">
                                <Input
                                    placeholder="Voz da marca para uso em publicações em redes sociais"
                                    size="large"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    disabled={isCreating}
                                />
                            </Form.Item>
                        </>
                    )}
                    {!voice && renderContent()}
                    {voice && (
                        <>
                            <Form.Item label="Voz da marca">
                                <Input.TextArea
                                    rows={4}
                                    autoSize={{minRows: 6, maxRows: 12}}
                                    showCount
                                    maxLength={7000}
                                    placeholder="Escreva ou cole o texto que deseja utilizar como voz da sua marca"
                                    onChange={(e) => setVoice(e.target.value)}
                                    value={voice}
                                    disabled={isCreating}
                                />
                            </Form.Item>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
};

export default BrandVoiceCreateModal;