import React from 'react';
import {Avatar} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";

const WorkspaceUsagePage = () => {
    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faChartSimple}/>}/>
                <div className="font-sans">
                    <div className="text-3xl">
                        Utilização
                    </div>

                    <div className="text-sm text-gray-400">
                        Utilização do workspace
                    </div>
                </div>
            </div>

            {/*Content*/}
        </div>
    );
};

export default WorkspaceUsagePage;