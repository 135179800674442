import {urlBase, params} from "./resources";
const URL = urlBase + "/api/wallet";

const get = async () => {
    return fetch(URL, await params.get())
        .then(res => res.json());
}

const WalletService = {
    get
};

export default WalletService;