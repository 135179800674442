import {params, urlBase, resolve} from "./resources";
const url = urlBase + "/api/specialty";

async function create(specialty) {
    const content = {body: specialty};
    return fetch(url, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function update(specialty) {
    const content = {body: specialty};
    return fetch(url, await params.put(content))
        .then(response => response.json())
        .then(resolve);
}

async function list() {
    return fetch(`${url}`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function remove(idSpecialty) {
    return fetch(`${url}/${idSpecialty}`, await params.delete())
        .then(response => response.json())
        .then(resolve);
}

async function publish(specialty) {
    const content = {body: specialty};
    return fetch(`${url}/publish`, await params.patch(content))
        .then(response => response.json())
        .then(resolve);
}

async function unpublish(specialty) {
    const content = {body: specialty};
    return fetch(`${url}/unpublish`, await params.patch(content))
        .then(response => response.json())
        .then(resolve);
}

async function getPublished() {
    return fetch(`${url}/published`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

const SpecialtyDAORemote = {
    create,
    update,
    list,
    remove,
    publish,
    unpublish,
    getPublished
};

export default SpecialtyDAORemote;