import React from "react";
import Sider from "antd/es/layout/Sider";
import "./MainMenuDesktop.css";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import MainMenu from "./MainMenu";


const MainMenuDesktop = ({user}) => {

    const [isCollapsed, setCollapsed] = React.useState(false);

    const onCollapse = () => {
        setCollapsed(!isCollapsed);
    }

    const renderCollapseButton = () => {
        const chevronRight = () => <FontAwesomeIcon icon={faChevronRight}/>
        const chevronLeft = () => <FontAwesomeIcon icon={faChevronLeft}/>

        return (
            <Button
                shape="circle"
                size="large"
                type="text"
            >
                {isCollapsed ? chevronRight() : chevronLeft()}
            </Button>
        )
    }


    return (
        <div style={{zIndex: 3}}>
            <Sider
                className="MenuSider"
                width={256}
                collapsible={false}
                onCollapse={onCollapse}
                trigger={renderCollapseButton()}
                theme={"light"}
                breakpoint="md"
                style={{position: "relative"}}
            >
                <MainMenu user={user}/>
            </Sider>
        </div>
    );
}

export default MainMenuDesktop;