import {
    BarChartOutlined,
    CrownOutlined,
    LockOutlined,
    LogoutOutlined, TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Avatar, Dropdown} from "antd";
import "./UserDropdown.css";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {useNavigate} from "react-router-dom";

const UserDropdown = ({user, defaultPicture, onSignOut}) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const handleMenuItem = (target) => {
        navigate(`/settings/${target}`);
    }

    const renderMenuProfileItem = () => {
        return (
            <div className="UserDropdownItem" onClick={() => handleMenuItem("profile")}>
                <UserOutlined/>
                <span>Meu perfil</span>
            </div>
        );
    }

    const renderMenuSecurityItem = () => {
        return (
            <div className="UserDropdownItem" onClick={() => handleMenuItem("security")}>
                <LockOutlined/>
                <span>Segurança</span>
            </div>
        );
    }

    const renderMenuSubscriptionItem = () => {
        return (
            <div className="UserDropdownItem" onClick={() => handleMenuItem("subscription")}>
                <CrownOutlined/>
                <span>Assinatura</span>
            </div>
        );
    }

    const renderMenuWorkspaceItem = () => {
        return (
            <div className="UserDropdownItem" onClick={() => navigate('/workspace')}>
                <TeamOutlined/>
                Workspace
            </div>
        );
    }

    const renderMenuUsageItem = () => {
        return (
            <div className="UserDropdownItem" onClick={() => handleMenuItem("usage")}>
                <BarChartOutlined/>
                <span>Créditos</span>
            </div>
        );
    }

    const getProfilePictureUrl = () => {
        if (user.picture) {
            return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/64/${user.picture}`;
        } else if (defaultPicture) {
            return defaultPicture;
        }
        return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/64/default.png`;
    }

    const renderUserAvatar = () => {
        return <Avatar size={36} src={getProfilePictureUrl()}/>;
    }

    const renderUserName = () => {
        if (isMobile)
            return;

        const userName = user.name.split(' ')[0];
        return (
            <div className="text-md text-neutral-800">{userName}</div>
        );
    }

    const renderCurrentWorkspace = () => {
        return (
            <div className="text-sm text-neutral-500">Personal</div>
        );
    }

    const items = [
        {
            label: renderMenuProfileItem(),
            key: 'profile',
        },
        {
            label: renderMenuSecurityItem(),
            key: 'security',
        },
        {
            label: renderMenuSubscriptionItem(),
            key: 'subscription',
        },
        // {
        //     label: renderMenuUsageItem(),
        //     key: 'usage',
        // },
        {
            label: renderMenuWorkspaceItem(),
            key: 'workspace',
        },
        {
            type: 'divider',
        },
        // {
        //     label: (
        //         <div className="UserDropdownItem">
        //             <CommentOutlined/>
        //             <span>Suporte</span>
        //         </div>
        //     ),
        //     key: 'support',
        // },
        {
            label: (
                <div className="UserDropdownItem" onClick={onSignOut}>
                    <LogoutOutlined/>
                    <span>Sair</span>
                </div>
            ),
            key: 'logout',
        }
    ];

    const renderUserDropdown = () => {
        return (
            <div>
                <Dropdown
                    menu={{items}}
                    trigger="click"
                    placement="bottomRight"
                >
                    <div className="flex flex-row items-center leading-none gap-2 py-2 px-4 rounded-xl hover:bg-primary-bg hover:cursor-pointer transition-all ease-in-out duration-200">
                        {renderUserAvatar()}
                        <div className="flex flex-col gap-[2px] font-primary">
                            {renderUserName()}
                        </div>
                    </div>
                </Dropdown>
            </div>
        );
    }

    return (
        <div>
            {renderUserDropdown()}
        </div>
    );
}

export default UserDropdown;