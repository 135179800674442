// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client'; // Importação atualizada
import './index.css';
import 'alertifyjs/build/css/alertify.css';
import "alertifyjs/build/css/themes/default.min.css";
import "alertifyjs/build/css/alertify.min.css";

import App from './App';
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter } from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

// Suprimir avisos de findDOMNode no ambiente de desenvolvimento
if (process.env.NODE_ENV === 'development') {
    const originalWarn = console.warn;
    console.warn = (message, ...args) => {
        if (message.includes('findDOMNode')) {
            // Ignorar avisos sobre findDOMNode
            return;
        }
        originalWarn(message, ...args);
    };
}

// Seleciona o elemento DOM onde a aplicação será renderizada
const container = document.getElementById('root');

// Cria a raiz usando createRoot
const root = createRoot(container);

// Renderiza a aplicação dentro da raiz
root.render(
    <React.StrictMode>
        <AmplifyProvider>
            <Authenticator.Provider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Authenticator.Provider>
        </AmplifyProvider>
    </React.StrictMode>
);
