import React, {useEffect, useState} from "react";
import {Col, Input, Radio, Row, Select, Slider} from "antd";
import "./CreatorToolCommonTab.css";
import _ from "lodash";
import CreatorToolUploadIcon from "./CreatorToolUploadIcon";
import {Specialties} from "../../../../api/specialties";
import {useAnthropicModels, useOpenAIModels} from "../../../../utils/hooks/useGenerationModels";
import {useSubscriptions} from "../../../../utils/hooks/useSubscriptions";

const CreatorToolCommonTab = ({tool, onUpdate}) => {
    const [title, setTitle] = useState(_.get(tool, "name", ""));
    const [description, setDescription] = useState(_.get(tool, "description", ""));
    const [maxOutputs, setMaxOutputs] = useState(_.get(tool, "maxOutputs", 1));
    const [defaultOutputs, setDefaultOutputs] = useState(_.get(tool, "outputs", 1));
    const [icon, setIcon] = useState(_.get(tool, "icon", null));

    const [artifactSingular, setArtifactSingular] = useState(_.get(tool, "artifact[0]", ""));
    const [artifactPlural, setArtifactPlural] = useState(_.get(tool, "artifact[1]", ""));
    const [artifactGender, setArtifactGender] = useState(_.get(tool, "artifact[2]", "F"));

    const [category, setCategory] = useState(_.get(tool, "category", ""));
    const [badge, setBadge] = useState(_.get(tool, "badge", ""));
    const [specialty, setSpecialty] = useState(_.get(tool, "specialty", ""));
    const [regularGenerationModel, setRegularGenerationModel] = useState(_.get(tool, "generationModelPreference[regular]", ""));
    const [advancedGenerationModel, setAdvancedGenerationModel] = useState(_.get(tool, "generationModelPreference[advanced]", ""));
    const [model, setModel] = useState(_.get(tool, "model", ""));
    const [organizationIds, setOrganizationIds] = useState(_.get(tool, "organizationIds", []));

    const [tags, setTags] = useState(_.get(tool, "tags", []));

    const {openAIModels, isLoading: isOpenAIModelsLoading} = useOpenAIModels();
    const {anthropicModels, isLoading: isAnthropicModelsLoading} = useAnthropicModels();
    const subscriptions = useSubscriptions();
    const [availableSubscriptions, setAvailableSubscriptions] = useState(_.get(tool, "availableSubscriptions", []));

    useEffect(() => {
        onUpdate({
            name: title,
            description,
            category,
            icon,
            badge,
            outputs: defaultOutputs,
            maxOutputs,
            artifact: [artifactSingular, artifactPlural, artifactGender],
            tags,
            specialty,
            model,
            organizationIds,
            generationModelPreference: {
                regular: regularGenerationModel,
                advanced: advancedGenerationModel
            },
            availableSubscriptions
        });
    }, [
        title,
        description,
        category,
        icon,
        badge,
        maxOutputs,
        defaultOutputs,
        artifactSingular,
        artifactPlural,
        artifactGender,
        tags,
        specialty,
        model,
        organizationIds,
        regularGenerationModel,
        advancedGenerationModel,
        availableSubscriptions
    ]);

    const getSpecialtyOptions = () => {
        return Specialties.map(specialty => {
            return {
                value: specialty.id,
                label: specialty.name
            }
        });
    }

    const getModelOptions = () => {
        if (isOpenAIModelsLoading || isAnthropicModelsLoading) {
            return [];
        }

        const models = {
            'Anthropic': anthropicModels.map(model => {
                return {
                    name: model.name,
                    model: model.model,
                }
            }),
            'OpenAI': openAIModels.map(model => {
                return {
                    name: model.name,
                    model: model.model,
                }
            })
        }

        return Object.entries(models).map(([key, value]) => {
            return {
                label: key,
                options: value.map(model => {
                    return {
                        value: model.model,
                        label: model.name
                    }
                })
            }
        });
    }

    const renderTitleField = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Título da ferramenta
                </label>
                <Input
                    size={"large"}
                    placeholder={"Título da ferramenta"}
                    maxLength={50}
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                />
            </div>
        );
    }

    const renderDescriptionField = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Descrição
                </label>
                <Input.TextArea
                    size={"large"}
                    placeholder={"Descrição da ferramenta"}
                    maxLength={120}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
            </div>
        );
    }

    const renderSpecialtySelect = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Especialidade
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    dropdownMatchSelectWidth={false}
                    options={getSpecialtyOptions()}
                    onChange={(value) => setSpecialty(value)}
                    value={specialty ? specialty : undefined}
                    placeholder={"Especialidade"}
                    showSearch
                    allowClear
                />
            </div>
        );
    }

    const renderCategorySelect = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Categoria
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    dropdownMatchSelectWidth={false}
                    options={[
                        {value: 'Gestão', label: 'Gestão'},
                        {value: 'Redes sociais', label: 'Redes sociais'},
                        {value: 'Conteúdo', label: 'Conteúdo'},
                        {value: 'Produtividade', label: 'Produtividade'},
                        {value: 'Diversas', label: 'Diversas'},
                        {value: 'Anúncios', label: 'Anúncios'},
                        {value: 'Vendas', label: 'Vendas'},
                    ]}
                    onChange={(value) => setCategory(value)}
                    value={category ? category : undefined}
                    placeholder={"Categoria"}
                />
            </div>
        );
    }

    const renderBadgeSelect = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Badge
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    dropdownMatchSelectWidth={false}
                    options={[
                        {value: 'new', label: 'Novo'},
                        {value: 'popular', label: 'Popular'},
                        {value: 'none', label: 'Nenhum'},
                    ]}
                    onChange={(value) => setBadge(value)}
                    value={badge ? badge : undefined}
                    placeholder={"Badge"}
                />
            </div>
        );
    }

    const renderSubscriptionPlansSelect = () => {
        const options = subscriptions.map(subscription => {
            return {
                value: subscription.idSubscriptionPlan,
                label: subscription.name
            }
        });

        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Assinaturas
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    mode={"multiple"}
                    options={options}
                    onChange={(value) => setAvailableSubscriptions(value)}
                    value={availableSubscriptions}
                    placeholder={"Deixe em branco para selecionar todas as assinaturas"}
                />
            </div>
        );
    }


    const renderIconUpload = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Ícone
                </label>
                <div id="CreatorToolIconUploadWrapper">
                    <CreatorToolUploadIcon
                        icon={icon}
                        onFinishUpload={(url) => setIcon(url)}
                        onRemoveIcon={() => setIcon(null)}
                    />
                </div>
            </div>
        );
    }

    const renderRegularGenerationModelSelector = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Modelo LLM padrão - Regular
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    dropdownMatchSelectWidth={false}
                    options={getModelOptions()}
                    onChange={(value) => setRegularGenerationModel(value)}
                    value={regularGenerationModel ? regularGenerationModel : undefined}
                    placeholder={"Modelo"}
                    showSearch
                />
            </div>
        );
    }

    const renderAdvancedGenerationModelSelector = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Modelo LLM padrão - Avançado
                </label>
                <Select
                    size={"large"}
                    style={{width: "90%"}}
                    dropdownMatchSelectWidth={false}
                    options={getModelOptions()}
                    onChange={(value) => setAdvancedGenerationModel(value)}
                    value={advancedGenerationModel ? advancedGenerationModel : undefined}
                    placeholder={"Modelo"}
                    showSearch
                />
                {model}
            </div>
        );
    }

    const test = (value) => {
        console.log(value);
        setOrganizationIds(value);
    }

    const renderMaxOutputsSlider = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Número máximo de resultados
                </label>
                <div className="CreatorToolOutputsSliderWrapper">
                    <Slider
                        value={maxOutputs}
                        onChange={(value) => setMaxOutputs(value)}
                        min={1}
                        max={10}
                        style={{maxWidth: "250px", width: "100%"}}
                    />
                    <label className="CreatorToolOutputsSliderCounter">
                        {maxOutputs}
                    </label>
                </div>
            </div>
        );
    }

    const renderDefaultOutputsSlider = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Número padrão de resultados
                </label>
                <div className="CreatorToolOutputsSliderWrapper">
                    <Slider
                        value={defaultOutputs}
                        onChange={(value) => setDefaultOutputs(value)}
                        min={1}
                        max={maxOutputs}
                        style={{maxWidth: "250px", width: "100%"}}
                    />
                    <label className="CreatorToolOutputsSliderCounter">
                        {defaultOutputs}
                    </label>
                </div>
            </div>
        );
    }

    const renderArtifactsSection = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Artefato gerado
                </label>
                <Row>
                    <Col span={7}>
                        <div>
                            <label className="CreatorToolFieldLabel">
                                Singular
                            </label>
                            <Input
                                size={"large"}
                                placeholder={"Ex.: Blog post"}
                                maxLength={20}
                                onChange={(e) => setArtifactSingular(e.target.value)}
                                value={artifactSingular}
                            />
                        </div>
                    </Col>
                    <Col span={7} offset={1}>
                        <div>
                            <label className="CreatorToolFieldLabel">
                                Plural
                            </label>
                            <Input
                                size={"large"}
                                placeholder={"Ex.: Blog posts"}
                                maxLength={20}
                                onChange={(e) => setArtifactPlural(e.target.value)}
                                value={artifactPlural}
                            />
                        </div>
                    </Col>

                    <Col span={7} offset={1}>
                        <div>
                            <label className="CreatorToolFieldLabel">
                                Gênero
                            </label>
                            <div className="CreatorToolArtifactGenderCheckboxWrapper">
                                <Radio.Group
                                    onChange={(e) => setArtifactGender(e.target.value)}
                                    value={artifactGender}
                                    size={"large"}
                                >
                                    <Radio value={"F"}>Feminino</Radio>
                                    <Radio value={"M"}>Masculino</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    const renderTagsChips = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Tags
                </label>
                <Select
                    value={tags}
                    onChange={(value) => setTags(value)}
                    mode="tags"
                    size="large"
                    dropdownMatchSelectWidth={false}
                    placeholder={"Digite uma tag e pressione enter"}
                />
            </div>
        );
    }

    return (
        <div style={{padding: "12px 24px"}}>
            <Row gutter={12}>
                <Col span={18}>{renderTitleField()}</Col>
            </Row>

            {renderDescriptionField()}
            <Row>
                <Col span={6}>{renderSpecialtySelect()}</Col>
                <Col span={6}>{renderCategorySelect()}</Col>
                <Col span={6}>{renderIconUpload()}</Col>
                <Col span={6}>{renderRegularGenerationModelSelector()}{renderAdvancedGenerationModelSelector()}</Col>
            </Row>
            <Row>
                <Col span={8}>
                    {renderMaxOutputsSlider()}
                </Col>
                <Col span={8}>
                    {renderDefaultOutputsSlider()}
                </Col>
            </Row>
            {renderArtifactsSection()}
            <Row>
                <Col span={17}>{renderTagsChips()}</Col>
                <Col span={6} offset={1}>{renderBadgeSelect()}</Col>
            </Row>
            <Row>
                <Col span={12}>
                    {renderSubscriptionPlansSelect()}
                </Col>
            </Row>
        </div>
    );
}

export default CreatorToolCommonTab;