import {Flex, Image, useTheme} from "@aws-amplify/ui-react";
import "./Header.css";
import {Badge} from "antd";
import Logo from "../../images/logo.svg";
import useIsMobile from "../../utils/hooks/useIsMobile";

export function Header() {
    const { tokens } = useTheme();

    const isMobile = useIsMobile();

    const renderLoginTitle = () => {
        return (
            <div className="HeaderTitle">
                <div><label>Junte-se à melhor plataforma de AI para líderes de marketing e especialistas</label></div>
            </div>
        )
    }

    const renderLoginSubtitle = () => {
        return (
            <label className="HeaderSubtitle">
                Dê o seu feedback e renove a avaliação gratuita da nossa plataforma a cada 15 dias
            </label>
        )
    }

    const renderLogo = () => {
        if (isMobile) {
            return (
                <div className="HeaderLogoContainer">
                    <Image
                        className="HeaderLogo"
                        src={Logo}
                    />
                </div>
            );
        }
    }

    const renderBadge = () => {
        return (
            <Badge.Ribbon className="HeaderBadge" text="OPEN Beta" color={"#724CF9"}></Badge.Ribbon>
        );
    }

    return (
        <div>
            {renderLogo()}

            <Flex justifyContent="center">
                <div>
                    {renderLoginTitle()}
                    {/*{renderLoginSubtitle()}*/}
                </div>
                {/*{renderBadge()}*/}
            </Flex>
        </div>

    );
}
