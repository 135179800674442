import {useContext} from "react";
import {OrganizationContext} from "../providers/organizationProvider";
import _ from "lodash";
import {UserContext} from "../providers/userProvider";

export const useWalletService = () => {
    const {user} = useContext(UserContext);
    const [organization] = useContext(OrganizationContext);

    const getWallet = () => {
        if (_.isEmpty(user)) {
            return null;
        }

        const wallets = _.get(user, "wallets", []);
        if (_.isEmpty(organization)) {
            // return wallets.find(wallet => wallet.idOrganization === 1);
            return null;
        }
        return wallets.find(wallet => wallet.idOrganization === organization.idOrganization);
    }

    const hasBalance = () => {
        const wallet = getWallet();
        // temporário, enquanto a cobrança não estiver habilitada para os usuários em geral
        if (_.isEmpty(wallet)) {
            return true;
        }
        return wallet && wallet.balance['advanced'] > 0;
    }

    return {
        getWallet,
        hasBalance,
    }
}