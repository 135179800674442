import {urlBase, params} from "./resources";

const url = urlBase + "/api/user";

async function get() {
    return fetch(url, await params.get())
        .then(res => res.json());
}

async function update(data) {
    const content = {body: data};
    return fetch(url, await params.put(content))
        .then(res => res.json());
}

async function updateEmail(email) {
    const content = {body: email};
    return fetch(url + "/email", await params.put(content))
        .then(res => res.json());
}

async function updateSetting(setting) {
    const content = {body: setting};
    return fetch(url + "/settings", await params.put(content));
}

const UserRemote = {
    get,
    update,
    updateEmail,
    updateSetting
};

export default UserRemote;