// src/components/textinput/TextInput.jsx

import React, { Component } from "react";
import "./TextInput.css";
import autoBind from "auto-bind";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import TextArea from "antd/lib/input/TextArea";
import PremiumButton from "../buttons/PremiumButton";
import classNames from "classnames";
import PropTypes from "prop-types";

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            placeholder: "",
        };
        this.inputRef = React.createRef();
        this.userAlreadyTyped = false;
        autoBind(this);
    }

    componentDidMount() {
        this.focus();
        this.typeText();
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    clearTimers() {
        clearInterval(this.typeTimer);
        clearTimeout(this.eraseTimer);
    }

    typeText() {
        const fullText = this.getRandomSentence();
        let i = 0;
        this.typeTimer = setInterval(() => {
            if (i === fullText.length) {
                clearInterval(this.typeTimer);
                this.eraseTimer = setTimeout(() => this.eraseText(), 15000);
                return;
            }

            const currentChar = fullText.charAt(i);
            const newChar = i === 0 ? currentChar.toUpperCase() : currentChar;

            this.setState((prevState) => ({
                placeholder: prevState.placeholder + newChar,
            }));
            i++;
        }, 50);
    }

    eraseText() {
        let i = this.state.placeholder.length;
        this.eraseTimer = setInterval(() => {
            if (i === 0) {
                clearInterval(this.eraseTimer);
                this.typeTimer = setTimeout(() => this.typeText(), 2000);
                return;
            }

            this.setState((prevState) => ({
                placeholder: prevState.placeholder.slice(0, -1)
            }));
            i--;
        }, 50);
    }

    getRandomSentence() {
        const sentences = [
            "Escreva a sua pergunta aqui!",
            "Solicite, receba e interaja!",
            "Me fale sobre o seu negócio...",
            "Como é o seu time de marketing?",
            "Qual o seu maior desafio hoje?"
        ];
        return sentences[Math.floor(Math.random() * sentences.length)];
    }

    clearInput() {
        this.setState({ text: "" });
    }

    handleUserFirstTyping() {
        this.userAlreadyTyped = true;
        this.clearTimers();
    }

    handleTextChange(event) {
        const text = event.target.value;

        if (!this.userAlreadyTyped) {
            this.handleUserFirstTyping();
        }

        this.setState({ text });
    }

    handleSubmit(event) {
        const { text } = this.state;
        const { onSubmit } = this.props;

        if (text.trim() === "") {
            return;
        }

        this.clearInput();

        onSubmit(text.trim());
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.handleSubmit(event);
        }
    }

    renderPromptInput() {
        const { text, placeholder } = this.state;
        const { disabled } = this.props;

        return (
            <TextArea
                id="TextInput"
                ref={this.inputRef}
                className="ImageGeneratorPromptInput"
                size="large"
                placeholder={placeholder || "Faça uma pergunta"}
                onChange={this.handleTextChange}
                autoSize={{ minRows: 1, maxRows: 10 }}
                showCount
                value={text}
                disabled={disabled}
                onKeyDown={this.handleKeyDown}
                aria-label="Campo de texto para enviar mensagens"
                style={{ textTransform: 'none' }} // Estilo inline adicionado
            />
        );
    }

    renderGenerateButton() {
        const { text } = this.state;
        const { disabled, isMobile } = this.props;
        return (
            <PremiumButton
                className={classNames(
                    "ImageGeneratorPromptGenerateButton",
                    { "right-[12px]": isMobile },
                    { "right-[14px]": !isMobile }
                )}
                size={isMobile ? "medium" : "large"}
                disabled={disabled || text.trim() === ""}
                onClick={this.handleSubmit}
                shape="square"
                aria-label="Enviar mensagem"
            >
                <FontAwesomeIcon icon={faPaperPlane} />
            </PremiumButton>
        );
    }

    render() {
        return (
            <div className="relative">
                {this.renderPromptInput()}
                {this.renderGenerateButton()}
            </div>
        );
    }
}

TextInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool,
};

TextInput.defaultProps = {
    disabled: false,
    isMobile: false,
};

export default TextInput;
