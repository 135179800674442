import React, {Component} from "react";
import "./AppFooter.css";

import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faSpotify } from "@fortawesome/free-brands-svg-icons"
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import {Footer} from "antd/es/layout/layout";
import {Divider} from "antd";


class AppFooter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Footer className="Footer">
                <label>
                    Desenvolvido por <a href="https://thecmosmarketers.digital/?utm_source=cmosai&utm_medium=rodape&utm_campaign=conectando&utm_id=001&utm_term=discovery" target="_blank" style={{fontSize: "12px"}}>The CMOs Marketers</a> <label style={{fontSize: "14px"}}>💙</label> A maior comunidade e edtech de líderes e especialistas de marketing do Brasil!
                </label>

                <label className="FooterIconTray">
                    <a href="https://thecmosmarketers.digital/">
                        <FontAwesomeIcon icon={faGlobe} className="FooterIcon"/>
                    </a>
                    <a href="https://comunidade.thecmosmarketers.digital/">
                        <FontAwesomeIcon icon={faUsers} className="FooterIcon"/>
                    </a>
                    <a href="https://www.instagram.com/thecmosmarketers/">
                        <FontAwesomeIcon icon={faInstagram} className="FooterIcon"/>
                    </a>
                    <a href="https://www.linkedin.com/company/the-cmos-marketers/">
                        <FontAwesomeIcon icon={faLinkedin} className="FooterIcon"/>
                    </a>
                    <a href="https://open.spotify.com/show/4oY2ScvaZr61fkiQRHFWQo?si=c98b908eebf140bb&nd=1">
                        <FontAwesomeIcon icon={faSpotify} className="FooterIcon"/>
                    </a>
                </label>
            </Footer>
        );
    }
}

export default AppFooter;