import {urlBase, params} from "./resources";

const url = urlBase + "/api/organization";


async function create(data) {
    const content = {body: data};
    return fetch(`${url}`, await params.post(content))
        .then(response => response.json());
}

async function update(data) {
    const content = {body: data};
    return fetch(url, await params.put(content))
        .then(response => response.json());
}

async function getAll() {
    return fetch(`${url}/list`, await params.get())
        .then(response => response.json());
}

async function remove(idOrganization) {
    return fetch(`${url}/${idOrganization}`, await params.delete())
        .then(response => response.json());
}

async function getSubscriptionPlans() {
    return fetch(`${url}/subscriptions`, await params.get())
        .then(response => response.json());
}

const OrganizationRemote = {
    create,
    update,
    getAll,
    remove,
    getSubscriptionPlans
};

export default OrganizationRemote;