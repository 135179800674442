import {Button, Progress, Tooltip} from "antd";
import React from "react";
import "./ToolExample.css";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWandSparkles} from "@fortawesome/free-solid-svg-icons";
import {Theme} from "../../api/Theme";
import {useWalletService} from "../../utils/hooks/useWalletService";

const ToolExample = ({onStartExample, onFillInputs, onStartGeneration, onFinishExample, artifact, onGenerateExample}) => {
    const messages = ["Preenchendo informações", "Iniciando geração"];

    const [isGenerating, setGenerating] = React.useState(false);
    const [currentLabel, setCurrentLabel] = React.useState(messages[0]);
    const [currentProgress, setCurrentProgress] = React.useState(0);

    const walletService = useWalletService();

    const handleGenerate = () => {
        // onStartExample();
        // setGenerating(true);
        // setCurrentProgress(0);
        // setCurrentLabel(messages[0]);
        //
        // createProgressAnimationTimeout(messages[0], 1500, 50, onFillInputs);
        // createProgressAnimationTimeout(messages[1], 3000, 50);
        // createProgressAnimationTimeout(messages[1], 4500, 100);
        // setTimeout(() => {
        //     onStartGeneration(true);
        //     onFinishExample();
        //     setGenerating(false);
        // }, 6000);
        onGenerateExample();
    }

    const createProgressAnimationTimeout = (label, duration, progress, callback) => {
        return setTimeout(() => {
            setCurrentLabel(label);
            setCurrentProgress(progress);

            if (callback) {
                callback();
            }

        }, duration);
    }

    const renderAnimationScreen = () => {
        return (
            <div style={{display: "flex", flexDirection: "column", marginTop: "24px", textAlign: "center"}}>
                <Progress
                    percent={currentProgress}
                    status={"active"}
                    showInfo={false}
                    strokeColor={"#724CF9"}
                />
                <label className="ToolExampleLabel">{currentLabel}</label>

            </div>
        );
    }

    const renderMainScreen = () => {
        if (_.isEmpty(artifact)) {
            return null;
        }

        const getTooltipMessage = () => {
            if (!walletService.hasBalance()) {
                return "Você não possui saldo suficiente. Por favor, faça um upgrade em seu plano";
            } else {
                return null;
            }
        }

        return (
            <Tooltip title={getTooltipMessage()} color={Theme.PRIMARY}>
                <Button
                    type="primary"
                    size="large"
                    onClick={handleGenerate}
                    loading={isGenerating}
                    icon={<FontAwesomeIcon icon={faWandSparkles} style={{marginRight: 10}}/>}
                    disabled={!walletService.hasBalance()}
                >
                    Gere um exemplo!
                </Button>
            </Tooltip>
        );
    }

    const render = () => {
        return (
            <div>
                {/*{isGenerating && renderAnimationScreen()}*/}
                {/*{!isGenerating && renderMainScreen()}*/}
                {renderMainScreen()}
            </div>
        );
    }

    return render();
}

export default ToolExample;