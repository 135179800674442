import useUser from "./useUser";
import _ from "lodash";

const useOrganizationService = () => {
    const userQuery = useUser();

    const getOrganizations = () => {
        if (userQuery.user) {
            return userQuery.user.organizations;
        }
    }

    const getOrganization = (idOrganization) => {
        if (userQuery.user) {
            return userQuery.user.organizations.find(organization => organization.idOrganization === idOrganization);
        }
    }

    const hasCustomOrganizations = () => {
        if (userQuery.user) {
            return userQuery.user.organizations.filter(organization => organization.idOrganization > 1).length > 0;
        }

        return false;
    }

    const getWelcomeOrganization = () => {
        if (userQuery.user) {
            return _.head(userQuery.user.organizations.filter(organization => {
                return organization.idOrganization >= 1
                    && organization.settings?.showWelcomeScreen;
            }));
        }
    }

    return {
        getOrganization,
        getOrganizations,
        hasCustomOrganizations,
        getWelcomeOrganization,
        isLoading: userQuery.isLoading,
    }
}

export default useOrganizationService;