import React from 'react';
import {DeleteOutlined, EditOutlined, EyeInvisibleOutlined, LinkOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";

const ContentCreatorToolsDropdownOptions = ({tool, onEdit, onDelete, onPublish, onUnpublish}) => {
    const items = [
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onEdit}>
                    <EditOutlined/>
                    Editar
                </div>
            ),
            key: "edit",
        },
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onPublish}>
                    <LinkOutlined />
                    Publicar
                </div>
            ),
            key: "publish",
            disabled: tool?.status === 'PUBLISHED'
        },
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onUnpublish}>
                    <EyeInvisibleOutlined />
                    Ocultar
                </div>
            ),
            key: "unpublish",
            disabled: tool?.status !== 'PUBLISHED'
        },
        {
            type: 'divider',
          },
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onDelete}>
                    <DeleteOutlined/>
                    Excluir
                </div>
            ),
            key: "delete",
            danger: true
        }
    ];

    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
            placement="bottomRight"
        >
            <Button type="text" shape="circle">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </Button>
        </Dropdown>
    );
};

export default ContentCreatorToolsDropdownOptions;