import React from 'react';
import {Modal} from "antd";
import {useWallet} from "../../utils/hooks/useWallet";
import moment from "moment/moment";
import UpgradeButton from "../../components/subscription/UpgradeButton";

const NoMoreTextCreditsModal = ({hide, onClose}) => {
    const {textCredits, nextRecharge, isLoading} = useWallet();

    const renderMessage = () => {
        if (nextRecharge == null) {
            return (
                <>
                    <div>
                        Infelizmente seus créditos chegaram ao fim.
                    </div>
                    <div>
                        Você pode fazer um upgrade de plano para liberar novos créditos.
                    </div>
                </>
            );
        }

        return (
            <>
                <div>
                    Infelizmente seus créditos chegaram ao fim, mas não se preocupe!
                </div>
                <div>
                    Seus créditos serão recarregados em <b>{moment(nextRecharge).format('DD/MM/YYYY')}</b>.
                </div>
                <div>
                    Você também pode fazer um upgrade de plano para liberar novos créditos.
                </div>
            </>
        );
    }

    return (
        <Modal
            open={!hide && (!isLoading && textCredits === 0)}
            title="Seus créditos acabaram..."
            centered
            className="font-primary"
            footer={null}
            onCancel={onClose}
        >
            <div className="flex flex-col gap-1">
                {renderMessage()}
                <div className="mt-4">
                    <UpgradeButton size="large"/>
                </div>
            </div>
        </Modal>
    );
};

export default NoMoreTextCreditsModal;