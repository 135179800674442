// src/pages/image-generator/components/modal/InsufficientImageCreditsModal.jsx

import { Modal } from "antd";
import UpgradeButton from "../../../../components/subscription/UpgradeButton";
import { useSubscriptionService } from "../../../../utils/hooks/useSubscriptionService";
import _ from "lodash";
import { useMemo } from "react";
import "./InsufficientImageCreditsModal.css";

const InsufficientImageCreditsModal = ({ isOpen, onClose }) => {
    const subscriptionService = useSubscriptionService();

    const renderTitle = () => {
        return (
            <label className="PremiumTitle">
                Seus créditos de imagens chegaram ao fim
            </label>
        );
    }

    const getTrialProvisionCredits = (subscriptionPlan) => {
        const imageProvision = _.get(subscriptionPlan, "trialProvisions", []).find((provision) => provision.type === "ImageGenerationCredits");
        return _.get(imageProvision, "balance", 0);
    }

    const getPlanImageProvisionCredits = (subscriptionPlan) => {
        const imageProvision = _.get(subscriptionPlan, "provisions", []).find((provision) => provision.type === "ImageGenerationCredits");
        return _.get(imageProvision, "balance", 0);
    }

    const insufficientCredits = useMemo(() => {
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();

        if (!activeSubscriptionPlan) {
            console.warn("Nenhum plano de assinatura ativo encontrado.");
            return true; // Considera créditos insuficientes se não houver plano ativo
        }

        if (subscriptionService.isUserActiveSubscriptionOnTrial()) {
            const trialCredits = getTrialProvisionCredits(activeSubscriptionPlan);
            console.log("Créditos no período de teste:", trialCredits);
            return trialCredits <= 0;
        } else {
            const planCredits = getPlanImageProvisionCredits(activeSubscriptionPlan);
            console.log("Créditos no plano:", planCredits);
            return planCredits <= 0;
        }
    }, [subscriptionService]);

    const renderContent = () => {
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();

        if (subscriptionService.isUserActiveSubscriptionOnTrial()) {
            return (
                <>
                    <label className="InsufficientImageCreditsModalParagraph">
                        Você está no período de testes do plano <strong>{activeSubscriptionPlan.name}</strong>, o qual oferece apenas <strong>{getTrialProvisionCredits(activeSubscriptionPlan)} créditos</strong> de imagens para testar.
                    </label>
                    <label className="InsufficientImageCreditsModalParagraph">
                        Para continuar utilizando o gerador de imagens, considere ativar agora o plano {activeSubscriptionPlan.name} e encerrar o período de testes grátis. Você receberá <strong>{getPlanImageProvisionCredits(activeSubscriptionPlan)} créditos</strong> de imagens para utilizar.
                    </label>
                </>
            );
        }

        const nextSubscriptionPlan = subscriptionService.getNextUserActiveSubscriptionPlan();
        if (!_.isEmpty(nextSubscriptionPlan)) {
            return (
                <>
                    <label className="InsufficientImageCreditsModalParagraph">
                        Para continuar utilizando o gerador de imagens, considere fazer um upgrade para o plano <strong>{nextSubscriptionPlan.name}</strong> e receba <strong>{getPlanImageProvisionCredits(nextSubscriptionPlan)} novos créditos</strong> de imagens para utilizar.
                    </label>
                </>
            );
        }

        return (
            <>
                <label className="InsufficientImageCreditsModalParagraph">
                    Seus créditos de imagens acabaram e serão renovados de acordo com a sua assinatura atual ao término do ciclo mensal de faturamento.
                </label>
                <label className="InsufficientImageCreditsModalParagraph">
                    Qualquer dúvida, entre em contato com o nosso suporte!
                </label>
            </>
        );
    }

    const renderUpgradeButton = () => {
        return (
            <div style={{ marginTop: 12 }}>
                <UpgradeButton label="Ativar" />
            </div>
        );
    }

    // Renderiza o modal apenas se houver créditos insuficientes
    if (!insufficientCredits) {
        return null;
    }

    return (
        <Modal
            open={isOpen && insufficientCredits} // Modal abre somente se isOpen e créditos insuficientes
            centered
            footer={null}
            onCancel={onClose}
            title={renderTitle()}
            onClose={onClose}
        >
            {renderContent()}
            {renderUpgradeButton()}
        </Modal>
    );
}

export default InsufficientImageCreditsModal;
