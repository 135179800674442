import { slide as Menu } from 'react-burger-menu'
import React from "react";
import "./MainBurgerMenu.css";
import MainMenu from "./MainMenu";

const MainBurgerMenu = ({user}) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <Menu
            isOpen={isOpen}
            onStateChange={(state) => setOpen(state.isOpen)}
        >
            <MainMenu
                user={user}
                onClose={() => setOpen(false)}
            />
        </Menu>
    );
}

export default MainBurgerMenu;