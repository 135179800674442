// resources.js

import _ from "lodash";
import { Auth } from "aws-amplify";

const getHeaders = async () => new Headers({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': await getAuthorization(),
    'Workspace-Id': await getCurrentWorkspaceId()
});

const getCurrentWorkspaceId = async () => {
    const idUser = (await Auth.currentAuthenticatedUser()).username;
    const currentWorkspace = localStorage.getItem(`${idUser}_currentWorkspace`);

    if (!currentWorkspace || currentWorkspace === 'undefined') {
        return '';
    }

    try {
        return JSON.parse(currentWorkspace).idWorkspace;
    } catch (e) {
        return '';
    }
}

const getToken = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    return token;
}

export const getAuthorization = async () => {
    const token = await getToken();
    return `Bearer ${token}`;
}

export const urlBase = getUrlBase();

function getUrlBase() {
    const environment = process.env.REACT_APP_ENV;
    switch (environment) {
        case "prod":
            return process.env.REACT_APP_PROD_BACKEND_URL;
        case "stage":
            return process.env.REACT_APP_STAGE_BACKEND_URL;
        case "dev":
        default:
            return process.env.REACT_APP_DEV_BACKEND_URL;
    }
}

export const getParams = async () => {
    return {
        method: 'GET',
        headers: await getHeaders(),
        mode: 'cors',
        cache: 'default'
    };
};

export const postParams = async ({ body, controller } = {}) => {
    return {
        method: "POST",
        headers: await getHeaders(),
        mode: 'cors',
        cache: 'default',
        signal: controller ? controller.signal : null,
        body: JSON.stringify(body)
    };
};

export const patchParams = async ({ body }) => {
    return {
        method: "PATCH",
        headers: await getHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(body)
    }
};

export const putParams = async ({ body }) => {
    return {
        method: "PUT",
        headers: await getHeaders(),
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(body)
    }
};

export const deleteParams = async () => {
    return {
        method: 'DELETE',
        headers: await getHeaders(),
        mode: 'cors',
        cache: 'default'
    };
};

export const params = {
    get: getParams,
    patch: patchParams,
    put: putParams,
    post: postParams,
    delete: deleteParams
};

export const resolve = (response) => {
    const error = _.get(response, "error", null)
    if (error != null) {
        return Promise.reject(error);
    }
    return Promise.resolve(response);
}