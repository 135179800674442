import {urlBase, params} from "./resources";

const url = urlBase + "/api/subscription";


async function get() {
    return fetch(`${url}/data`, await params.get())
        .then(response => response.json());
}

async function skipSubscriptionTrial(idSubscription) {
    const content = {body: {idSubscription}};
    return fetch(`${url}/skip-trial`, await params.patch(content))
        .then(response => response.json());
}

async function subscribeToFreemiumSubscriptionPlan() {
    return fetch(`${url}/subscribe/freemium`, await params.post())
        .then(response => response.json());
}

const SubscriptionRemote = {
    get,
    skipSubscriptionTrial,
    subscribeToFreemiumSubscriptionPlan
};

export default SubscriptionRemote;