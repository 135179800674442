import {Row, Col, Input, Button, message} from "antd";
import {useState} from "react";
import _ from "lodash";
import {Auth} from "aws-amplify";
import {LoadingOutlined} from "@ant-design/icons";
import UserRemote from "../../../services/dao/remote/user";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {faEnvelope, faUnlockKeyhole} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingsContainer from "../components/SettingsContainer";

const SettingsSecurity = ({user}) => {
    const [currentEmail, setCurrentEmail] = useState(_.get(user, "email", ""));
    const [newEmail, setNewEmail] = useState("");
    const [isUpdatingEmail, setUpdatingEmail] = useState(false);
    const [isConfirmingEmail, setConfirmingEmail] = useState(false);
    const [showVerificationCodeEmail, setShowVerificationCodeEmail] = useState(false);
    const [emailVerificationCode, setEmailVerificationCode] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    // const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isUpdatingPassword, setUpdatingPassword] = useState(false);

    const queryClient = useQueryClient();

    const updateEmailMutation = useMutation({
        mutationFn: (data) => UserRemote.updateEmail(data),
        onSuccess: (user) => {
            message.success("E-mail atualizado!");
            queryClient.setQueryData(["user"], (old) => user);
            setCurrentEmail(user.email);
            setNewEmail("");
        },
        onError: () => {
            message.error("Erro ao atualizar e-mail!");
        }
    });

    const isNumericString = (input) => {
        return input === "" || /^\d+$/.test(input);
    }

    const isValidEmail = (text) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);

    const containsNumber = (text) => /\d/.test(text);
    const containsLowercase = (text) => /[a-z]/.test(text);
    const containsUppercase = (text) => /[A-Z]/.test(text);
    const containsSpecialCharacter = (text) => /[!@#$%^&*(),.?":{}|<>]/.test(text);

    const isValidPassword = (text) => {
        return containsNumber(text)
            && containsLowercase(text)
            && containsUppercase(text)
            && containsSpecialCharacter(text)
            && text.length >= 8;
    }

    const translateErrorMessage = (message) => {
        switch (message) {
            case "Invalid code provided, please request a code again.":
                return "Código inválido, por favor solicite um novo código";
            case "Attempt limit exceeded, please try after some time.":
                return "Limite de tentativas excedido, por favor tente novamente mais tarde";
            case "Incorrect username or password.":
                return "Senha atual incorreta";
            default:
                return message;
        }
    }

    const handleUpdateEmail = async () => {
        setUpdatingEmail(true);

        try {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(authenticatedUser, {
                email: newEmail,
            });
            setShowVerificationCodeEmail(true);
        } catch (e) {
            message.error("Erro ao atualizar e-mail!");
        } finally {
            setUpdatingEmail(false);
        }
    }

    const handleUpdatePassword = async () => {
        setUpdatingPassword(true);

        try {
            const authenticatedUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(authenticatedUser, currentPassword, newPassword);
            message.success("Senha atualizada!");
        } catch (e) {
            message.error(translateErrorMessage(e.message));
        } finally {
            setCurrentPassword("");
            setNewPassword("");
            setUpdatingPassword(false);
        }
    }

    const handleEmailVerificationCode = (verificationCode) => {
        if (isNumericString(verificationCode)) {
            setEmailVerificationCode(verificationCode);
        }
    }

    const handleConfirmEmail = async () => {
        setConfirmingEmail(true);
        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', emailVerificationCode);
            await updateEmailMutation.mutateAsync({email: newEmail})
            setShowVerificationCodeEmail(false);
            setEmailVerificationCode("");
        } catch (e) {
            message.error(translateErrorMessage(e.message));
        } finally {
            setConfirmingEmail(false);
        }
    }

    const renderCurrentEmailField = () => {
        return (
            <>
                {!user.identityProvider && <div className="SettingsFieldTitle">
                    E-mail atual
                </div>}
                <Input
                    size={"large"}
                    value={currentEmail}
                    disabled
                />
            </>
        );
    }

    const renderNewEmailField = () => {
        if (user.identityProvider) {
            return null;
        }
        return (
            <>
                <div className="SettingsFieldTitle">
                    Novo e-mail
                </div>
                <Input
                    size={"large"}
                    value={newEmail}
                    disabled={isUpdatingEmail || showVerificationCodeEmail || isConfirmingEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                />
            </>
        );
    }

    const renderEmailSection = () => {
        return (
            <div>
                <div className="SettingsSectionTitle">
                    <FontAwesomeIcon icon={faEnvelope} style={{marginRight: "12px"}}/>
                    E-mail
                </div>
                <Row gutter={32}>
                    <Col
                        xs={{span: 24}}
                        sm={{span: 12}}
                    >
                        <div className="SettingsField">
                            {renderCurrentEmailField()}
                        </div>
                    </Col>

                    <Col
                        xs={{span: 24}}
                        sm={{span: 12}}
                    >
                        <div className="SettingsField">
                            {renderNewEmailField()}
                            {renderEmailTextValidation()}
                        </div>
                    </Col>
                </Row>
                {renderUpdateEmailSection()}
            </div>
        );
    }

    const renderEmailTextValidation = () => {
        if (!newEmail || isValidEmail(newEmail)) {
            return null;
        }

        return (
            <div className="SettingsValidationText">
                E-mail inválido
            </div>
        );
    }

    const renderUpdateEmailSection = () => {
        if (user.identityProvider) {
            return null;
        }

        if (showVerificationCodeEmail) {
            return renderVerificationCodeEmailSection();
        }

        return (
            <div className="SettingsSaveButton">
                <Button
                    size="large"
                    type="primary"
                    onClick={handleUpdateEmail}
                    disabled={isUpdatingEmail || !isValidEmail(newEmail)}
                >
                    {isUpdatingEmail && <><LoadingOutlined spin style={{marginRight: "12px"}}/> Atualizando</>}
                    {!isUpdatingEmail && "Atualizar e-mail"}
                </Button>
            </div>
        );
    }

    const renderVerificationCodeEmailSection = () => {
        const isVerificationCodeValid = emailVerificationCode.length === 6;
        return (
            <div>
                <div className="SettingsVerificationCodeText">Um código de verificação foi enviado ao seu e-mail.</div>
                <div className="SettingsVerificationCodeText">Por favor, insira o código recebido no campo abaixo para confirmar a alteração.</div>
                <div className="SettingsVerificationCodeContainer">

                    <Input
                        className={"SettingsVerificationCodeInput"}
                        placeholder={"Código de verificação"}
                        maxLength={6}
                        value={emailVerificationCode}
                        onChange={(e) => handleEmailVerificationCode(e.target.value)}
                    />
                    <Button
                        size="large"
                        type="primary"
                        onClick={handleConfirmEmail}
                        disabled={isConfirmingEmail || !isVerificationCodeValid}
                    >
                        {isConfirmingEmail ? (<><LoadingOutlined spin style={{marginRight: "12px"}}/> Confirmando</>) : "Confirmar"}
                    </Button>
                </div>
            </div>

        );
    }

    const renderPasswordSection = () => {
        if (user.identityProvider) {
            return null;
        }

        return (
            <div>
                <div className="SettingsSectionTitle">
                    <FontAwesomeIcon icon={faUnlockKeyhole} style={{marginRight: "12px"}}/>
                    Senha
                </div>

                <Row gutter={32}>
                    <Col
                        xs={{span: 24}}
                        sm={{span: 12}}
                    >
                        <div className="SettingsField">
                            <div className="SettingsFieldTitle">
                                Senha atual
                            </div>
                            <Input.Password
                                size={"large"}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                        </div>
                    </Col>

                    <Col
                        xs={{span: 24}}
                        sm={{span: 12}}
                    >
                        <div className="SettingsField">
                            <div className="SettingsFieldTitle">
                                Nova senha
                            </div>
                            <Input.Password
                                size={"large"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            {renderNewPasswordTextValidation()}
                        </div>
                    </Col>

                    {/*<Col span={12}>*/}
                    {/*    <div className="SettingsField">*/}
                    {/*        <div className="SettingsFieldTitle">*/}
                    {/*            Confirmar nova senha*/}
                    {/*        </div>*/}
                    {/*        <Input.Password*/}
                    {/*            size={"large"}*/}
                    {/*            value={confirmNewPassword}*/}
                    {/*            onChange={(e) => setConfirmNewPassword(e.target.value)}*/}
                    {/*        />*/}
                    {/*        {renderConfirmNewPasswordTextValidation()}*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
                <div className="SettingsSaveButton">
                    <Button
                        size="large"
                        type="primary"
                        onClick={handleUpdatePassword}
                        disabled={!isValidPassword(newPassword) || isUpdatingPassword}
                    >
                        {isUpdatingPassword && <><LoadingOutlined spin style={{marginRight: 12}}/>Atualizando</>}
                        {!isUpdatingPassword && "Atualizar senha"}
                    </Button>
                </div>
            </div>
        );
    }

    const renderNewPasswordTextValidation = () => {
        if (!newPassword || isValidPassword(newPassword)) {
            return null;
        }

        return (
            <div className="SettingsValidationText">
                <b>A senha deve conter ao menos</b>
                {newPassword.length < 8 && <div>- 8 caracteres</div>}
                {!containsNumber(newPassword) && <div>- 1 número</div>}
                {!containsLowercase(newPassword) && <div>- 1 letra minúscula</div>}
                {!containsUppercase(newPassword) && <div>- 1 letra maiúscula</div>}
                {!containsSpecialCharacter(newPassword) && <div>- 1 caractere especial</div>}
            </div>
        );
    }

    // const renderConfirmNewPasswordTextValidation = () => {
    //     if (!confirmNewPassword || _.isEqual(newPassword, confirmNewPassword)) {
    //         return null;
    //     }
    //
    //     return (
    //         <div className="SettingsValidationText">
    //             <b>As senhas devem ser iguais</b>
    //         </div>
    //     );
    // }

    const renderDivider = () => {
        return (
            <div className="SettingsDivider"/>
        );
    }

    return (
        <SettingsContainer containerized>
            {renderEmailSection()}
            {renderDivider()}
            {renderPasswordSection()}
        </SettingsContainer>
    );
}

export default SettingsSecurity;