import React, {useState} from 'react';
import {Avatar, Modal, Select} from "antd";
import {useBrandVoices} from "../../utils/hooks/useBrandVoices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import PremiumButton from "../buttons/PremiumButton";

const BrandVoiceSelectorModal = ({brandVoice, open, onBrandVoiceApply, onClose}) => {
    const {brandVoices, isLoading} = useBrandVoices();
    const [selectedBrandVoice, setSelectedBrandVoice] = useState(brandVoice?.idBrandVoice);

    const getBrandVoiceOptions = () => {
        if (isLoading || !brandVoices) {
            return [];
        }

        return brandVoices.map(brandVoice => {
            return {
                value: brandVoice?.idBrandVoice,
                label: brandVoice?.name
            }
        });
    }

    const getBrandVoiceById = (id) => {
        if (brandVoices && !isLoading) {
            return brandVoices.find(brandVoice => brandVoice.idBrandVoice === id);
        }
    }

    const handleBrandVoiceChange = (value) => {
        setSelectedBrandVoice(value);
    }

    const handleBrandVoiceApply = () => {
        onBrandVoiceApply(getBrandVoiceById(selectedBrandVoice));
        onClose();
    }

    const renderFooter = () => {
        return (
            <div className="flex flex-row gap-2 justify-end">
                <PremiumButton
                    type="primary"
                    size="large"
                    onClick={handleBrandVoiceApply}
                >
                    Aplicar
                </PremiumButton>
            </div>
        );
    }

    return (
        <Modal
            open={open}
            centered
            width={600}
            onCancel={onClose}
            footer={renderFooter()}
        >
            <div className="p-4">
                <div className="flex flex-row items-center gap-4 mb-4">
                    <Avatar className="bg-premium" size="large" icon={<FontAwesomeIcon icon={faBullhorn} size="sm"/>}/>
                    <div className="text-lg">
                        Brand voice
                    </div>
                </div>

                {selectedBrandVoice && (
                    <div>
                        <div className="flex flex-row gap-4 my-6">
                            <FontAwesomeIcon icon={faQuoteLeft} size="2x" className="text-gray-800"/>
                            <div>
                                <div className="text-gray-800 font-bold text-lg">
                                    {getBrandVoiceById(selectedBrandVoice)?.name}
                                </div>
                                <div className="text-gray-400 text-sm">
                                    {getBrandVoiceById(selectedBrandVoice)?.description}
                                </div>

                                <div className="text-gray-600 mt-4">
                                    {getBrandVoiceById(selectedBrandVoice)?.voice}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <Select
                    style={{width: "100%"}}
                    size="large"
                    showSearch
                    dropdownMatchSelectWidth={false}
                    allowClear
                    placeholder="Selecione uma brand voice"
                    value={selectedBrandVoice}
                    options={getBrandVoiceOptions()}
                    onChange={handleBrandVoiceChange}
                />
            </div>
        </Modal>
    );
};

export default BrandVoiceSelectorModal;