import "./ImageEditor.css";
import {useContext, useEffect, useState} from "react";
import {Button, Modal, Image, Layout, Tooltip, message} from "antd";
import DragNDropUpload from "../../../../components/drag-n-drop-upload/DragNDropUpload";
import {Content} from "antd/es/layout/layout";
import ImageEditorSidebar from "./ImageEditorSidebar";
import PromptInput from "../prompt/PromptInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectGroup, faUpload} from "@fortawesome/free-solid-svg-icons";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import SparklesIcon from "../../../../components/custom-icons/SparklesIcon";
import {ImageEditorMode} from "../../constants/ImageEditorMode";
import {UserContext} from "../../../../utils/providers/userProvider";
import _ from "lodash";
import {useQueryClient} from "@tanstack/react-query";
import {useImages} from "../../../../utils/hooks/useImages";
import ImageEditorCarousel from "./ImageEditorCarousel";
import {Theme} from "../../../../api/Theme";
import {useLocation} from "react-router-dom";
import ImageMenuOptionsWrapper from "../menu-options-wrapper/ImageMenuOptionsWrapper";
import ImageEditorGalleryModal from "./ImageEditorGalleryModal";
import {useScreenSize} from "../../../../utils/hooks/useScreenSize";
import {useImageService} from "../../../../utils/hooks/useImageService";
import InsufficientImageCreditsModal from "../modal/InsufficientImageCreditsModal";
import {useCurrentWorkspace} from "../../../../utils/hooks/useCurrentWorkspace";
import {useWallet} from "../../../../utils/hooks/useWallet";

const ImageEditor = ({onScreenChange}) => {
    const {user} = useContext(UserContext);

    const screenSize = useScreenSize();
    const galleryImages = useImages();
    const location = useLocation();
    const imageService = useImageService();
    const queryClient = useQueryClient();
    const {currentWorkspace} = useCurrentWorkspace();
    const {imageCredits} = useWallet();

    const [image, setImage] = useState(null);
    const [prompt, setPrompt] = useState("");
    const [isGenerating, setGenerating] = useState(false);
    const [currentMode, setCurrentMode] = useState(ImageEditorMode.REIMAGINE);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [openInsufficientCreditsModal, setOpenInsufficientCreditsModal] = useState(false);

    useEffect(() => {
        if (location?.state?.idImage) {
            setImage(galleryImages.find((image) => image.idImage === location.state.idImage));
        }
    }, [location?.state, galleryImages]);

    const getImageUrl = () => {
        return `${process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL}/${currentWorkspace?.idWorkspace}/${image.filename}.${image.extension}`;
    }

    const getCanvasSize = () => {
        switch (screenSize) {
            case 'xs':
            case 'sm':
            case 'md':
            case 'lg':
                return 384;
            case 'xl':
                return 512;
            case 'xxl':
                return 768;
            default:
                return 512;
        }
    }

    const handleGenerate = () => {
        const onMutationSuccess = (image) => {
            setImage(image);
            setGenerating(false);
        }

        const onMutationError = (error) => {
            setGenerating(false);
        }

        if (imageCredits === 0) {
            setOpenInsufficientCreditsModal(true);
        } else {
            setGenerating(true);
            switch (currentMode) {
                case ImageEditorMode.REIMAGINE:
                    imageService.reimagineImage().mutate({idImage: image.idImage}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
                case ImageEditorMode.REMOVE_BG:
                    imageService.removeBackground().mutate({idImage: image.idImage}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
                case ImageEditorMode.REMOVE_TEXT:
                    imageService.removeText().mutate({idImage: image.idImage}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
                case ImageEditorMode.REPLACE_BG:
                    imageService.replaceBackground().mutate({idImage: image.idImage, prompt}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
                case ImageEditorMode.SKETCH_TO_IMAGE:
                    imageService.sketchToImage().mutate({idImage: image.idImage, prompt}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
                case ImageEditorMode.UPSCALING:
                    imageService.upscale().mutate({idImage: image.idImage}, {
                        onSuccess: (image) => onMutationSuccess(image),
                        onError: (error) => onMutationError(error)
                    });
                    return;
            }
        }
    }

    const handleSelectImageFromGallery = (image) => {
        setImage(image);
        setShowGalleryModal(false);
    }

    const renderUploadButton = () => {
        return (
            <Button
                type="text"
                size="large"
                onClick={() => setShowUploadModal(true)}
                icon={<FontAwesomeIcon icon={faUpload} style={{marginRight: 8}}/>}
                block
            >
                Fazer upload de imagem
            </Button>
        )
    }

    const renderChooseFromGalleryButton = () => {
        return (
            <Button
                type="text"
                size="large"
                icon={<FontAwesomeIcon icon={faObjectGroup} style={{marginRight: 8}}/>}
                block
                onClick={() => setShowGalleryModal(true)}
            >
                Escolher uma imagem da galeria
            </Button>
        )
    }

    const renderImageUploadModal = () => {
        return (
            <Modal
                open={showUploadModal}
                footer={null}
                title={"Fazer upload de imagem"}
                centered
                onCancel={() => setShowUploadModal(false)}
                width={"50%"}
            >
                {renderImageUpload()}
            </Modal>
        );
    }

    const renderImageUpload = () => {
        return (
            <DragNDropUpload
                height={500}
                onFinish={() => setShowUploadModal(false)}
                onUpload={(image) => {
                    setImage(image);
                    setShowUploadModal(false);
                    queryClient.setQueryData(["images", currentWorkspace.idWorkspace], (old) => [image, ...old]);
                }}
            />
        );
    }

    const renderImageCanvas = () => {
        return (
            <div className="ImageEditorCanvasImage">
                <Image
                    className="ImageEditorUploadedImage"
                    src={getImageUrl()}
                    alt="Uploaded Image"
                    height={getCanvasSize()}
                    preview={false}
                />

                <div className="ImageEditorCanvasImageMenu">
                    <ImageMenuOptionsWrapper
                        idUser={user.idUser}
                        image={image}
                        options={["download"]}
                        onScreenChange={onScreenChange}
                    />
                </div>
            </div>

        );
    }

    const renderCanvas = () => {
        return (
            <div className="ImageEditorCanvas">
                {_.isEmpty(image) && renderEmptyCanvas()}
                {!_.isEmpty(image) && renderImageCanvas()}
            </div>
        )
    }

    const renderEmptyCanvas = () => {
        const size = getCanvasSize();
        return (
            <div className="ImageEditorEmptyCanvas" style={{width: size, height: size}}>
                {renderUploadButton()}
                {renderChooseFromGalleryButton()}
            </div>
        );
    }

    const renderGenerateButton = () => {
        if (!_.isEmpty(image)) {
            return (
                <div className="ImageEditorGenerateButton">
                    <PremiumButton
                        size="large"
                        block
                        style={{width: 260}}
                        icon={<SparklesIcon style={{marginRight: 8}} color={"white"}/>}
                        onClick={handleGenerate}
                        loading={isGenerating}
                        disabled={isGenerating || _.isEmpty(image)}
                    >
                        {currentMode.title}
                    </PremiumButton>
                    <label className="ImageEditorLabelWarning">(1 crédito de imagem)</label>
                </div>
            );
        }
    }

    const renderPromptInput = () => {
        if (currentMode.hasPrompt && !_.isEmpty(image)) {
            return (
                <div>
                    {renderPromptInputTitle()}
                    <PromptInput
                        prompt={prompt}
                        placeholder={currentMode.promptPlaceholder}
                        isLoading={isGenerating}
                        onChange={(prompt) => setPrompt(prompt)}
                        onGenerate={() => {
                            handleGenerate();
                        }}
                    />
                    <label className="ImageEditorLabelWarning">(1 crédito de imagem)</label>
                </div>
            );
        }
    }

    const renderPromptInputTitle = () => {
        return (
            <div style={{width: "100%"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className="ImageEditorPromptInputTitle">
                        <label>{currentMode.promptTitle}</label>
                    </div>
                </div>
            </div>

        );
    }

    const renderCarousel = () => {
        return (
            <ImageEditorCarousel
                idUser={user?.idUser}
                images={galleryImages}
                onSelect={(image) => setImage(image)}
            />
        )
    }

    const renderGeneratorComponent = () => {
        if (currentMode.hasPrompt) {
            return (
                <div className="ImageEditorGenerator">
                    {renderPromptInput()}
                </div>
            );
        }
        return (
            <div className="ImageEditorGenerator">
                {renderGenerateButton()}
            </div>
        );
    }

    const renderFloatButtons = () => {
        return (
            <div className="ImageEditorFloatingButtons">
                <Tooltip title={"Faça o upload de uma imagem"} placement="left" color={Theme.PRIMARY}>
                    <Button
                        style={{backgroundColor: "transparent"}}
                        shape="circle"
                        type="default"
                        size="large"
                        icon={<FontAwesomeIcon icon={faUpload}/>}
                        onClick={() => setShowUploadModal(true)}
                    />
                </Tooltip>

                <Tooltip title={"Escolha uma imagem da galeria"} placement="left" color={Theme.PRIMARY}>
                    <Button
                        style={{backgroundColor: "transparent"}}
                        shape="circle"
                        type="default"
                        size="large"
                        icon={<FontAwesomeIcon icon={faObjectGroup}/>}
                        onClick={() => setShowGalleryModal(true)}
                    />
                </Tooltip>
            </div>
        )
    }

    return (
        <div style={{height: "100%"}}>
            <Layout style={{height: "100%"}}>
                <Content className="ImageEditor">
                    {renderImageUploadModal()}
                    {renderCarousel()}
                    <div className="ImageEditorCanvasContainer">
                        {renderCanvas()}
                        {renderGeneratorComponent()}
                        {renderFloatButtons()}
                    </div>
                </Content>
                <ImageEditorSidebar
                    currentMode={currentMode}
                    onModeChange={(mode) => setCurrentMode(mode)}
                />
                <ImageEditorGalleryModal
                    isOpen={showGalleryModal}
                    onClose={() => setShowGalleryModal(false)}
                    onSelect={handleSelectImageFromGallery}
                />
                <InsufficientImageCreditsModal
                    isOpen={openInsufficientCreditsModal}
                    onClose={() => setOpenInsufficientCreditsModal(false)}
                />
            </Layout>
        </div>
    );
}

export default ImageEditor;