import { urlBase, params } from "./resources";
const url = urlBase + "/api/workspace/invitation";

const getAll = async ({idWorkspace}) => {
    return fetch(`${url}/${idWorkspace}/list`, await params.get())
        .then(response => response.json());
}

const generate = async ({idWorkspace}) => {
    return fetch(`${url}/${idWorkspace}/generate`, await params.get())
        .then(response => response.json());
}

const send = async ({idWorkspace, recipients}) => {
    const content = {body: {recipients}};
    return fetch(`${url}/${idWorkspace}/send`, await params.post(content))
        .then(response => response.json());
}

const deleteInvitation = async ({idWorkspace, idWorkspaceInvitation}) => {
    return fetch(`${url}/${idWorkspace}/${idWorkspaceInvitation}`, await params.delete())
        .then(response => response.json());
}

const WorkspaceInvitationDAORemote = {
    getAll,
    generate,
    send,
    delete: deleteInvitation,
};

export default WorkspaceInvitationDAORemote;