import React from 'react';
import {Button} from "antd";
import {useStripeService} from "../../../../utils/hooks/useStripeService";

const ManageSubscriptionButton = ({onOpenPortal}) => {
    const stripeService = useStripeService();

    return (
        <div>
            <Button
                type="primary"
                size="large"
                onClick={() => stripeService.openCustomerPortalWindow()}
            >
                Gerenciar assinatura
            </Button>
        </div>
    );
};

export default ManageSubscriptionButton;