import _ from "lodash";
import {Button, Col, Progress, Row} from "antd";
import React from "react";
import {Image} from "@aws-amplify/ui-react";
import LogoWhite from "../../images/logo-purple.svg";
import {useLocation, useNavigate} from "react-router-dom";
import UserRemote from "../../services/dao/remote/user";

const OrganizationWelcomeScreen = ({user, message, onFinish, ...props}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const redirectToPartnershipPage = () => {
        UserRemote.updateSetting("ORGANIZATION_WELCOME_SCREEN_COMPLETED");
        onFinish();
        if (location.pathname !== "/") {
            navigate("/");
        }
    }

    const renderWelcomeMessage = () => {
        return (
            <div>
                <div style={{fontSize: "36px", fontFamily: "Outfit", fontWeight: "bold", textAlign: "left"}}>{`Olá, ${_.get(user, "name", "").split(" ")[0]}`}</div>
                <div style={{fontSize: "24px", fontFamily: "Outfit", fontWeight: "bold", textAlign: "left"}}>Estamos muito felizes de ter você aqui!</div>
                <div style={{color: "gray", textAlign: "left", marginTop: "24px", marginBottom: 12, fontFamily: "Outfit", fontSize: "16px"}}>
                    Seja muito bem-vindo à nova área de membros do CMOs.ai!
                </div>
                <div style={{color: "gray", textAlign: "left", marginBottom: "24px", fontFamily: "Outfit", fontSize: "16px"}}>
                    {message}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button
                        style={{margin: "24px"}}
                        type="primary"
                        size="large"
                        onClick={() => redirectToPartnershipPage()}
                    >
                        Vamos lá!
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div style={{height: "calc(100vh - 52px)"}}>
            <div style={{height: "52px"}}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", height: "100%"}}>
                    <Image
                        style={{margin: "8px 24px 0 16px"}}
                        src={LogoWhite}
                        height="24px"
                    />
                </div>

                <Progress
                    className="PostRegistrationProgressBar"
                    percent={100}
                    showInfo={false}
                    strokeLinecap="square"
                    strokeColor="#724CF9"
                />
            </div>
            <Row style={{height: "100%"}}>
                <Col
                    xs={{span: 22, offset: 1}}
                    sm={{span: 16, offset: 4}}
                    lg={{span: 12, offset: 6}}
                    xl={{span: 10, offset: 7}}
                    xxl={{span: 8, offset: 8}}
                    style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    {renderWelcomeMessage()}
                </Col>
            </Row>
        </div>
    );
}

export default OrganizationWelcomeScreen;