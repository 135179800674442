import React, {useEffect, useState} from 'react';
import {Layout} from "antd";
import {TeamOutlined, ToolOutlined} from "@ant-design/icons";
import Submenu from "../../components/submenu/Submenu";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import MainHeaderTitle from "../../components/main-header/MainHeaderTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrochip} from "@fortawesome/free-solid-svg-icons";
import {Content} from "antd/es/layout/layout";
import {ActiveSubscriptionValidator} from "../../components/validator/ActiveSubscriptionValidator";
import CreatorTool from "../../components/creator-tool/creator/CreatorTool";
import ContentCreatorSpecialtiesPage from "./ContentCreatorSpecialtiesPage";
import ContentCreatorToolsPage from "./ContentCreatorToolsPage";

const ContentCreatorPage = ({setHeaderTitle}) => {
    const [currentScreen, setCurrentScreen] = useState("tools");

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = `Creator · CMOs.ai`;
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faMicrochip}/>}
                title="Creator"
                renderBack
            />
        );

        if (location.pathname.startsWith("/settings")) {
            const screen = location.pathname.split("/")[2];
            if (screen) {
                setCurrentScreen(screen);
            }
        }
    }, [location.pathname, setHeaderTitle]);

    const handleScreenChange = (screen) => {
        setCurrentScreen(screen);
        navigate(`/creator/${screen}`);
    }

    const renderMenu = () => {
        const items = [
            {
                key: "tools",
                label: (<div><ToolOutlined/><span>Agentes</span></div>)
            },
            {
                key: "specialties",
                label: (<div><TeamOutlined/><span>Especialidades</span></div>)
            },
        ];

        return (
            <Submenu
                items={items}
                selectedKeys={[currentScreen]}
                onClick={(item) => handleScreenChange(item.key)}
            />
        );
    }

    const renderContent = () => {
        return (
            <Content style={{height: "100%", overflow: "auto"}}>
                <Routes>
                    <Route path="" element={<Navigate to={`/creator/${currentScreen}`}/>}/>
                    <Route path="specialties" element={
                        <ActiveSubscriptionValidator
                            additionalRequiredPermissions={["Gods", "Administrators", "ContentCreators"]}
                        >
                            <ContentCreatorSpecialtiesPage/>
                        </ActiveSubscriptionValidator>
                    }/>

                    <Route path="tools" element={
                        <ActiveSubscriptionValidator
                            additionalRequiredPermissions={["Gods", "Administrators", "ContentCreators"]}
                        >
                            <ContentCreatorToolsPage/>
                        </ActiveSubscriptionValidator>
                    }/>

                    <Route path="tools/edit" element={
                        <ActiveSubscriptionValidator
                            additionalRequiredPermissions={["Gods", "Administrators", "ContentCreators"]}
                        >
                            <CreatorTool setHeaderTitle={setHeaderTitle}/>
                        </ActiveSubscriptionValidator>
                    }/>
                </Routes>
            </Content>
        );
    }

    return (
        <div className="h-full">
            <Layout className="h-full overflow-auto">
                <>
                    {renderMenu()}
                    {renderContent()}
                </>
            </Layout>
        </div>
    );
};

export default ContentCreatorPage;