import {useEffect} from "react";
import {Auth} from "aws-amplify";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {useNavigate} from "react-router-dom";
import useUser from "../../utils/hooks/useUser";

const SubscriptionActivation = () => {
    const navigate = useNavigate();
    const {refetch} = useUser();

    useEffect(() => {
        Auth.currentAuthenticatedUser({bypassCache: true}).then(r => {
            navigate("/");
            refetch();
        });
    }, []);

    return (
        <LoadingScreen/>
    );
}

export default SubscriptionActivation;