import _ from "lodash";
import "./ImageCreditsWidget.css";
import UpgradeButton from "../../../../components/subscription/UpgradeButton";
import {Popover, Statistic} from "antd";
import {useSubscriptionService} from "../../../../utils/hooks/useSubscriptionService";
import CountUp from "react-countup";
import {useImageService} from "../../../../utils/hooks/useImageService";
import {useWallet} from "../../../../utils/hooks/useWallet";

const ImageCreditsWidget = () => {
    const subscriptionService = useSubscriptionService();
    const imageService = useImageService();
    const {imageCredits} = useWallet();

    const getActiveSubscriptionTrialProvisionBalance = () => {
        const activeSubscription = _.head(subscriptionService.getUserActiveSubscriptions());
        const subscriptionPlan = activeSubscription.subscriptionPlan;

        const imageProvision = subscriptionPlan.trialProvisions.find((provision) => provision.type === "ImageGenerationCredits");
        return _.get(imageProvision, "balance", 0);
    }

    const renderCredits = () => {
        const formatter = (value) => <CountUp end={value} separator="." />;
        return (
            <Statistic title="Créditos restantes" value={imageCredits} formatter={formatter}/>
        );
    }

    const renderUpgradeButton = () => {
        if (imageService.getImageCreditsBalance() === 0) {
            return (
                <UpgradeButton/>
            );
        }
    }

    const renderPopoverContent = () => {
        return (
            <div className="ImageCreditsWidgetPopoverContent">
                <label className="ImageCreditsWidgetContainerTitle">Período de testes</label>
                <label className="ImageCreditsWidgetTrialPopoverText">O período de testes do plano atual dá direito
                    à <b>{getActiveSubscriptionTrialProvisionBalance()} imagens</b>.</label>
                <label className="ImageCreditsWidgetTrialPopoverText">O saldo de imagens será atualizado conforme o
                    plano adquirido assim que o período de testes finalizar.</label>
            </div>
        );
    }

    const renderPopover = (children) => {
        if (subscriptionService.isUserActiveSubscriptionOnTrial()) {
            return (
                <Popover placement="top" content={renderPopoverContent()}>
                    {children}
                </Popover>
            );
        }
        return children;
    }

    const render = () => {
        return (
            <div className="ImageCreditsWidget">
                <div className="ImageCreditsWidgetContainer">
                    {renderCredits()}
                    {renderUpgradeButton()}
                </div>
            </div>
        );
    }

    if (imageService.getImageCreditsBalance() === -1) {
        return null;
    }

    return renderPopover(render());
}

export default ImageCreditsWidget;