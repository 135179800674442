import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form, Input, Row, Button} from "antd";
import {useCurrentWorkspace} from "../../../utils/hooks/useCurrentWorkspace";
import {useUpdateWorkspace} from "../../../utils/hooks/useWorkspaces";

const WorkspaceSettingsForm = () => {
    const {currentWorkspace, currentUserRole} = useCurrentWorkspace();
    const {updateWorkspace, isUpdating} = useUpdateWorkspace();

    const [name, setName] = useState(currentWorkspace?.name);
    const [description, setDescription] = useState(currentWorkspace?.description);

    const areChangesDisabled = useCallback(() => {
        return currentWorkspace?.type === "DEFAULT" || currentUserRole !== "OWNER";
    }, [currentWorkspace]);

    useEffect(() => {
        setName(currentWorkspace?.name);
        setDescription(currentWorkspace?.description);
    }, [currentWorkspace]);

    return (
        <div className="my-8">
            <Row>
                <Col span={12}>
                    <Form layout="vertical" autoComplete="off">

                        <Form.Item label="Nome">
                            <Input
                                size="large"
                                placeholder="Nome do workspace"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                disabled={areChangesDisabled()}
                            />
                        </Form.Item>
                        <Form.Item label="Descrição">
                            <Input.TextArea
                                size="large"
                                placeholder="Descrição do workspace"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                disabled={areChangesDisabled()}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            {!areChangesDisabled() && (
                <Button
                    type="primary"
                    size="large"
                    onClick={() => updateWorkspace({...currentWorkspace, name, description})}
                    disabled={isUpdating}
                    loading={isUpdating}
                >
                    {isUpdating ? "Salvando" : "Salvar"}
                </Button>
            )}
        </div>
    );
};

export default WorkspaceSettingsForm;