import React, {useState} from 'react';
import {Layout} from "antd";
import Submenu from "../../components/submenu/Submenu";
import {Content} from "antd/es/layout/layout";
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import WorkspaceTeamPage from "./WorkspaceTeamPage";
import WorkspacePlanPage from "./WorkspacePlanPage";
import WorkspaceSettingsPage from "./WorkspaceSettingsPage";
import WorkspaceUsagePage from "./WorkspaceUsagePage";

const WorkspacePage = () => {
    const [currentScreen, setCurrentScreen] = useState("team");

    const navigate = useNavigate();

    const items = [
        {
            key: "plans",
            label: "Plano"
        },
        {
            key: "team",
            label: "Equipe"
        },
        {
            key: "settings",
            label: "Configurações"
        },
    ]

    const handleScreenChange = (screen) => {
        setCurrentScreen(screen);
        navigate(`/workspace/${screen}`);
    }

    return (
        <Layout>
            <Submenu
                items={items}
                selectedKeys={[currentScreen]}
                onClick={(e) => handleScreenChange(e.key)}
            />

            <Content>
                <Routes>
                    <Route path="" element={<Navigate to={`/workspace/${currentScreen}`} />}/>
                    <Route path="plans" element={<WorkspacePlanPage />} />
                    <Route path="team" element={<WorkspaceTeamPage />} />
                    <Route path="usage" element={<WorkspaceUsagePage />} />
                    <Route path="settings" element={<WorkspaceSettingsPage />} />
                </Routes>
            </Content>
        </Layout>
    );
};

export default WorkspacePage;