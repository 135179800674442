import Dexie from "dexie";

const DexieDatabase = new Dexie('brainwave')
DexieDatabase.version(2).stores(
    {
        chats: 'idChat, idUser, title, specialty, messages, createdAt, updatedAt',
        tools: 'idTool, name, description, category, icon, fields, badge, outputs, maxOutputs, artifact, tags, specialty, status, hasExample, createdAt, updatedAt, deletedAt'
    },
);

export default DexieDatabase;