import {useContext} from "react";
import {UserContext} from "../../../utils/providers/userProvider";
import SettingsContainer from "../components/SettingsContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartColumn, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const Usage = () => {
    const {user} = useContext(UserContext);

    const translateLabel = (label) => {
        return {
            "advancedModelCredits": "Modelo Avançado",
            "regularModelCredits": "Modelo Regular",
            "imageGenerationCredits": "Geração de imagens",
        }[label];
    }

    const getRemainingCredits = (fund) => {
        const currentFund = _.get(fund, "[1].preDatedBalance", []).filter((balance) => new Date(balance.validFrom * 1000) <= new Date() && new Date(balance.validUntil * 1000) > new Date());
        if (currentFund.length > 0) {
            return currentFund[0].currentBalance;
        }
    }

    const getNextFund = (fund) => {
        const currentFund = _.get(fund, "preDatedBalance", []).filter((balance) => new Date(balance.validFrom * 1000) <= new Date() && new Date(balance.validUntil * 1000) > new Date());
        return _.get(fund, "preDatedBalance", []).filter((balance) => balance.validFrom === currentFund[0].validUntil);
    }

    const renderUsageInformation = (fund) => {
        const remainingCredits = getRemainingCredits(fund);
        return (
            <div className="mb-4">
                <div className="font-bold" style={{fontFamily: "Outfit", fontSize: 14}}>
                    {translateLabel(_.get(fund, "[0]", null))}
                </div>
                <label>
                    {remainingCredits === -1 && "Créditos ilimitados"}
                    {remainingCredits !== -1 && `${remainingCredits} créditos restantes`}
                </label>
            </div>

        )
    }

    const formatDate = (date) => {
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    }

    return (
        <SettingsContainer containerized>
            <div>
                <div className="SettingsSectionTitle">
                    <FontAwesomeIcon icon={faChartColumn} style={{marginRight: "12px"}}/>
                    Créditos
                </div>
                <div>
                    {_.orderBy(Object.entries(_.get(user, "wallet.funds", [])).map((fund) => renderUsageInformation(fund)), ["[0]"], ["desc"])}
                </div>
                <div className="float-right">
                    {user && <div><label style={{fontFamily: "Outfit"}}>O seu saldo será renovado em {formatDate(new Date(_.get(getNextFund(Object.values(_.get(user, "wallet.funds", []))[0])[0], "validFrom")*1000))}</label></div>}
                </div>
            </div>
        </SettingsContainer>
    )
}

export default Usage;