export class Onboarding {
    constructor(script) {
        this.script = script;
        this.sectionIndex = 0;
        this.stepIndex = 0;
        this.messageIndex = 0;
    }

    getCurrentSection() {
        let currentSection = this.script[this.sectionIndex];

        if (currentSection.steps.length <= this.stepIndex) {
            this.sectionIndex++;
            this.stepIndex = 0;
        }

        if (this.script.length <= this.sectionIndex) {
            return null;
        }

        return this.script[this.sectionIndex];
    }

    getCurrentStep() {
        const currentStep = this.script[this.sectionIndex].steps[this.stepIndex];

        if (currentStep.messages.length <= this.messageIndex) {
            this.stepIndex++;
            this.messageIndex = 0;
        }

        const currentSection = this.getCurrentSection();

        if (currentSection == null) {
            return null;
        }

        return currentSection.steps[this.stepIndex];
    }

    getNextMessage() {
        const currentStep = this.getCurrentStep();

        if (currentStep == null || currentStep.messages.length <= this.messageIndex) {
            return null;
        }

        const message = currentStep.messages[this.messageIndex];
        this.messageIndex++;
        return message;
    }
}