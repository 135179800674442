import {Input, Progress} from "antd";
import React from "react";
import "./InputProgress.css";

const InputProgress = (props) => {
    const {currentlength, maxLength, defaultValue} = props;
    return (
        <>
            <Input
                {...props}
                value={defaultValue}
            />
            <Progress
                className="InputProgressBar"
                percent={currentlength ? (currentlength / maxLength) * 100 : 0}
                strokeColor={"#724CF9"}
                size={4}
                format={(percent) => `${Math.round(percent/100 * maxLength)} / ${maxLength}`}
            />
        </>
    );
}

export default InputProgress;