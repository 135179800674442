import React from 'react';
import {Button, Dropdown} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";

const BrandVoiceDropdownOptions = ({onEdit, onDelete}) => {
    const items = [
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onEdit}>
                    <EditOutlined/>
                    Editar
                </div>
            ),
            key: "edit",
        },
        {
            type: 'divider',
          },
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={onDelete}>
                    <DeleteOutlined/>
                    Excluir
                </div>
            ),
            key: "delete",
            danger: true
        }
    ];

    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
        >
            <Button type="text" shape="circle">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </Button>
        </Dropdown>
    );
};

export default BrandVoiceDropdownOptions;