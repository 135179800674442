import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faWarning} from "@fortawesome/free-solid-svg-icons";
import {DeleteOutlined} from "@ant-design/icons";
import {Button, Dropdown, Modal} from "antd";

const {confirm} = Modal;


const WorkspaceTeamInviteDropdownOptions = ({onDelete}) => {
    const showDeleteConfirm = () => {
        confirm({
            title: <label className="font-primary">Excluir convite</label>,
            content: <label className="font-primary">Tem certeza que deseja excluir este convite?</label>,
            icon: <FontAwesomeIcon className="text-red-500 mr-2"  size="lg" icon={faWarning}/>,
            okText: 'Remover',
            cancelText: 'Cancelar',
            okType: 'danger',
            onOk() {
                onDelete();
            },
    })};

    const items = [
        {
            key: 'delete',
            label: (
                <div className="flex flex-row items-center gap-2" onClick={showDeleteConfirm}>
                    <DeleteOutlined/>
                    Excluir
                </div>
            ),
            danger: true,
        }
    ]

    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
        >
            <Button type="text" shape="circle">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </Button>
        </Dropdown>
    );
};

export default WorkspaceTeamInviteDropdownOptions;