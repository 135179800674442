export const OnboardingScript = [
    {
        index: 1,
        title: "Introdução",
        steps: [
            {
                index: 1,
                title: "Step 1",
                messages: [
                    {
                        index: 0,
                        content: "Olá, líder de marketing. Boas vindas ao CMOs.ai! 😄",
                        suggestions: ["Sugestão 1", "Sugestão 2", "Sugestão 3"],
                        animated: true
                    }
                ]
            },
            {
                index: 2,
                title: "Step 2",
                messages: [
                    {
                        index: 0,
                        content: "Estou aqui para ajudar você a tirar o máximo proveito e potencializar os seus resultados!",
                        animated: true
                    }
                ]
            },
            {
                index: 3,
                title: "Step 3",
                messages: [
                    {
                        index: 0,
                        content: "**Aqui estão algumas dicas poderosas antes de começar:**\n" +
                            "\n" +
                            "1. Clique em **'Criar novo chat'** e selecione a especialidade que melhor atende as suas necessidades, ao lado da caixa de texto.\n" +
                            "2. Faça perguntas contextualizadas, especificando dados fundamentais, como mercado, estrutura do seu time, metas ou orçamento. Quanto mais informações você fornecer, melhor será a sua resposta!\n" +
                            "3. Os chats permitem que você organize as conversas por assuntos, e eles ficarão salvos na barra de histórico, ao lado esquerdo. Você pode renomeá-los ou excluí-los conforme desejar.\n" +
                            "4. Você pode trocar de especialista no chat a qualquer momento e quantas vezes for necessário para que você obtenha respostas mais assertivas. Caso prefira, você também pode explorar a plataforma sem optar por um especialista, fazendo perguntas gerais sobre temas amplos.\n" +
                            "\n" +
                            "Espero que essas dicas ajudem você a aproveitar ao máximo o CMOs.ai. Vamos começar? 😄",
                        animated: true
                    }
                ]
            },
            {
                index: 3,
                title: "Step 3",
                messages: [
                    {
                        index: 0,
                        content: "**Experimente fazer uma das solicitações a seguir:**\n" +
                            "\n" +
                            "1. Liste 7 maneiras criativas para começar uma campanha de branding.\n" +
                            "2. Liste 4 passos passos melhorar a performance de mídia paga.\n" +
                            "3. Construa um funil de growth marketing utilizando o modelo Hook. Apresente no formato de fluxo html cada etapa.\n" +
                            "4. Qual a melhor forma para comunicar os KPIs para o meu time de marketing?\n" +
                            "5. Como criar OKRs compartilhados?",
                        animated: true
                    }
                ]
            }
        ]
    },

    // {
    //     index: 2,
    //     title: "Especialidades",
    //     steps: [
    //         {
    //             index: 1,
    //             title: "Step 1",
    //             messages: [
    //                 {
    //                     index: 0,
    //                     content: "Até agora você conversou com um bot generalista, ou seja, ele fala sobre qualquer assunto e seu conhecimento é amplo e um pouco vago.",
    //                     animated: true
    //                 },
    //                 {
    //                     index: 1,
    //                     content: "Vá em frente e escolha uma especialidade para conversar com um bot especialista.",
    //                     animated: true
    //                 }
    //             ]
    //         }
    //     ]
    // }
]