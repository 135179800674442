import {useCallback, useEffect, useState} from 'react';
import {useAuthenticator} from "@aws-amplify/ui-react";

export const usePersistedState = (key, initialState) => {
    const {user} = useAuthenticator();

    const getKey = useCallback(() => {
        return `${user.username}_${key}`;
    }, [key, user.username]);

    const [state, setState] = useState(() => {
        const persistedState = localStorage.getItem(getKey());
        return persistedState && persistedState !== "undefined" ? JSON.parse(persistedState) : initialState;
    });

    useEffect(() => {
        localStorage.setItem(getKey(), JSON.stringify(state));
    }, [state, getKey]);

    return [state, setState];
}