import React, {useEffect, useState} from 'react';
import "./SubscriptionPlans.css";
import SubscriptionPlanColumn from "./SubscriptionPlanColumn";
import {Col, Row} from "antd";
import _ from "lodash";
import SubscriptionTeamModal from "../../../subscription/components/SubscriptionTeamModal";
import {useLocation} from "react-router-dom";
import FreeSubscriptionPlanColumn from "./FreeSubscriptionPlanColumn";


const SubscriptionPlans = ({billingPeriod, subscriptionPlans, activeSubscription, displayImage}) => {
    const [showTeamModal, setShowTeamModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.openTeamModal) {
            setShowTeamModal(true);
        }
    }, [location.state]);

    const handleOpenTeamModal = () => {
        setShowTeamModal(true);
    }


    const getActiveSubscriptionBillingPeriod = (activeSubscription) => {
        if (!activeSubscription) {
            return null;
        }

        return activeSubscription.plan.interval === "month" ? "monthly" : "yearly";
    }

    const renderRegularSubscriptionPlans = () => {
        return _.orderBy(subscriptionPlans, ["precedence"], ["asc"])
            .filter(subscriptionPlan => subscriptionPlan.status === "ACTIVE")
            .map(subscriptionPlan => {
                return (
                    <Col
                        className="mb-4"
                        key={subscriptionPlan.idSubscriptionPlan}
                    >
                        <SubscriptionPlanColumn
                            billingPeriod={billingPeriod}
                            activeBillingPeriod={getActiveSubscriptionBillingPeriod(activeSubscription)}
                            subscriptionPlan={subscriptionPlan}
                            activeSubscription={activeSubscription}
                            displayImage={displayImage}
                            onOpenTeamModal={handleOpenTeamModal}
                        />
                        {subscriptionPlan.teamConfig && <SubscriptionTeamModal
                            open={showTeamModal}
                            onClose={() => setShowTeamModal(false)}
                            idSubscriptionPlan={subscriptionPlan.idSubscriptionPlan}
                            billingCycle={billingPeriod}
                            prices={subscriptionPlan.product.prices}
                            teamConfig={subscriptionPlan.teamConfig}
                            activeSubscriptions={activeSubscription}
                        />}
                    </Col>
                );
            });
    }

    const renderFreeSubscriptionPlans = () => {
        return _.orderBy(subscriptionPlans, ["precedence"], ["asc"])
            .filter(subscriptionPlan => subscriptionPlan.status === "FREE")
            .map(subscriptionPlan => {
                return (
                    <Col
                        className="mb-4"
                        key={subscriptionPlan.idSubscriptionPlan}
                    >
                        <FreeSubscriptionPlanColumn
                            billingPeriod={billingPeriod}
                            activeBillingPeriod={getActiveSubscriptionBillingPeriod(activeSubscription)}
                            subscriptionPlan={subscriptionPlan}
                            activeSubscription={activeSubscription}
                            displayImage={displayImage}
                        />
                    </Col>
                );
            });
    }

    return (
        <div className="mt-4">
            <Row gutter={16} justify={"center"}>
                {renderFreeSubscriptionPlans()}
                {renderRegularSubscriptionPlans()}
            </Row>
        </div>
    );
};

export default SubscriptionPlans;