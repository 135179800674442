import Sider from "antd/es/layout/Sider";
import {Button, Popover} from "antd";
import "./ImageEditorSidebar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import {ImageEditorMode} from "../../constants/ImageEditorMode";

const ImageEditorSidebar = ({currentMode, onModeChange}) => {
    const renderTitle = () => {
        return (
            <div style={{textAlign: "center"}}>
                <label className="ImageEditorSidebarTitle">
                    O que você gostaria de fazer?
                </label>
            </div>
        );
    }
    const renderPopoverContent = (mode) => {
        switch (mode) {
            case ImageEditorMode.REIMAGINE:
                return renderReimaginePopoverContent();
            case ImageEditorMode.SKETCH_TO_IMAGE:
                return renderSketchPopoverContent();
            case ImageEditorMode.REMOVE_BG:
                return renderRemoveBgPopoverContent();
            case ImageEditorMode.REMOVE_TEXT:
                return renderRemoveTextPopoverContent();
            case ImageEditorMode.REPLACE_BG:
                return renderReplaceBgPopoverContent();
            case ImageEditorMode.UPSCALING:
                return renderUpscalingPopoverContent();
        }
    }

    const renderReimaginePopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Reimagine as suas imagens</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Nosso gerador de variações analisa qualquer imagem e cria versões novas e mais criativas para você.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Deixe sua criatividade fluir com nossas ferramentas intuitivas de edição de imagem.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Transforme suas fotos em obras de arte e encontre novas perspectivas.</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source src="https://static.clipdrop.co/web/apis/reimagine/reimagine-1280-720.mp4"
                                type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderSketchPopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Dê vida aos seus rascunhos</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Transforme seus rabiscos em obras de arte com apenas alguns cliques. É mágica!</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Basta fazer o upload de uma foto do seu rascunho e ver a mágica acontecer. Em segundos, seu esboço ganhará vida com cores vivas, texturas e detalhes</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source src="https://static.clipdrop.co/web/apis/sketch-to-image/sketch-to-image-1280-720.mp4"
                                type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderRemoveBgPopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Remova o background de suas imagens</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Cansado de procurar por imagens sem background no Google?</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Remova facilmente o background de qualquer imagem em poucos segundos.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Obtenha imagens limpas e profissionais para usar em projetos, posts e muito mais.</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source src="https://static.clipdrop.co/web/apis/remove-background/remove-background-demo.mp4"
                                type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderRemoveTextPopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Remova textos indesejados</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Remova marcas dágua, legendas e outros textos das suas imagens de forma rápida e fácil.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Perfeito para quando você quer dar um up em fotos antigas ou remover marcas indesejadas.</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source src="https://static.clipdrop.co/web/apis/remove-text/remove-text-demo.mp4"
                                type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderReplaceBgPopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Edite o background de suas imagens</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Imagina poder transformar o background de qualquer imagem apenas descrevendo o que você gostaria que aparecesse.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Sim, isso é possível!</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Substitua fundos chatos por paisagens deslumbrantes, mude de um ambiente interno para uma praia tropical ou crie qualquer cenário que sua imaginação permitir.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Basta inserir uma descrição do novo cenário que você imaginou e o <label className="ImageEditorSidebarEditionModelDescriptionTitle" style={{fontSize: 16}}>CMOs.ai</label> fará o trabalho pesado para você</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source
                            src="https://storage.googleapis.com/clipdrop-static-assets/web/apis/homepage/replace-background.mp4"
                            type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderUpscalingPopoverContent = () => {
        return (
            <div className="ImageEditorSidebarPopover">
                <div className="ImageEditorSidebarEditionModelDescription">
                    <div><label className="ImageEditorSidebarEditionModelDescriptionTitle">Aumente a resolução de suas imagens</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Deixe suas imagens nítidas como nunca!</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Com o <label className="ImageEditorSidebarEditionModelDescriptionTitle" style={{fontSize: 16}}>CMOs.ai</label>, você pode aumentar a resolução de suas imagens sem perder qualidade.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Basta fazer o upload de uma imagem de baixa resolução e nosso sistema inteligente irá analisá-la pixel por pixel para recriá-la em uma resolução maior.</label></div>
                    <div><label className="ImageEditorSidebarEditionModelDescriptionContent">Dessa forma, suas imagens ficarão muito mais nítidas e definidas, permitindo imprimi-las em tamanhos maiores sem que fiquem granuladas.</label></div>
                </div>
                <div>
                    <video loop autoPlay className="ImageEditorSidebarPopoverGif">
                        <source src="https://static.clipdrop.co/web/apis/super-resolution/super-resolution-demo.mp4"
                                type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
    }

    const renderOptionButton = (mode) => {
        return (
            <Popover content={renderPopoverContent(mode)} trigger="hover" placement="left">
                {mode.key === currentMode.key && (
                    <PremiumButton
                        innerClasses="ImageEditorSidebarOptionButtonSelected"
                        size="large"
                        block
                        icon={<FontAwesomeIcon className="ImageEditorSidebarOptionButtonIcon" icon={mode.icon}
                                               style={{marginRight: 8}}/>}
                    >
                        {mode.title}
                    </PremiumButton>
                )}
                {mode !== currentMode && (
                    <Button
                        className="ImageEditorSidebarOptionButton"
                        type="text"
                        size="large"
                        block
                        icon={<FontAwesomeIcon className="ImageEditorSidebarOptionButtonIcon" icon={mode.icon}
                                               style={{marginRight: 8}}/>}
                        onClick={() => onModeChange(mode)}
                    >
                        {mode.title}
                    </Button>
                )}
            </Popover>
        );
    }

    const renderEditionModes = () => {
        return (
            <div className="ImageEditorSidebarButtonsContainer">
                {renderOptionButton(ImageEditorMode.REIMAGINE)}
                {renderOptionButton(ImageEditorMode.REPLACE_BG)}
                {/*{renderOptionButton(ImageEditorMode.SKETCH_TO_IMAGE)}*/}
                {renderOptionButton(ImageEditorMode.REMOVE_BG)}
                {renderOptionButton(ImageEditorMode.REMOVE_TEXT)}
                {renderOptionButton(ImageEditorMode.UPSCALING)}
            </div>
        )
    }

    return (
        <Sider
            theme={"light"}
            width={360}
            className="ImageEditorSidebar"
        >
            {renderTitle()}
            {renderEditionModes()}
        </Sider>
    );
}

export default ImageEditorSidebar;