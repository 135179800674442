import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBolt} from "@fortawesome/free-solid-svg-icons";
import "./UpgradeButton.css";
import {useNavigate} from "react-router-dom";
import PremiumButton from "../buttons/PremiumButton";

const UpgradeButton = ({label = "Upgrade", size, block = true}) => {
    const navigate = useNavigate();

    return (
        <PremiumButton
            size={size}
            block={block}
            icon={<FontAwesomeIcon className="UpgradeButtonIcon" icon={faBolt} style={{marginRight: 8}}/>}
            onClick={() => navigate("/settings/subscription")}
        >
            {label}
        </PremiumButton>
    );
}

export default UpgradeButton;