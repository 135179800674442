import {params, urlBase} from "./resources";

const url = urlBase + "/api/playground";

async function generate(data) {
    const controller = new AbortController();
    const content = {body: data, controller: controller}
    const stream = await fetch(`${url}/completion`, await params.post(content))

    if (!stream.ok) {
        throw new Error(`HTTP error! status: ${stream.status}`);
    }

    return {
        controller,
        reader: stream.body.getReader()
    };
}

async function create(playgroundResult) {
    const content = {body: playgroundResult};
    return fetch(`${url}/results`, await params.post(content))
        .then(response => response.json());
}

async function getAll() {
    return fetch(`${url}/results`, await params.get())
        .then(response => response.json());
}

async function remove(idPlaygroundResult) {
    return fetch(`${url}/results/${idPlaygroundResult}`, await params.delete())
        .then(response => response.json());
}

const PlaygroundDAORemote = {
    generate,
    create,
    getAll,
    remove
};

export default PlaygroundDAORemote;