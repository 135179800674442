import OnboardingModal from "./OnboardingModal";
import OnboardingStatsRemote from "../../services/dao/remote/onboardingStats";
import {useMutation, useQuery} from "@tanstack/react-query";
import _ from "lodash";
import {useEffect, useRef} from "react";

const OnboardingController = ({open, onClose, refs}) => {

    const stats = useRef();

    useEffect(() => {
        if (open) {
            stats.current = {
                startedAt: new Date()
            }
        }
    }, [open]);

    const save = useMutation({
        mutationFn: (onboardingStats) => OnboardingStatsRemote.save(onboardingStats)
    });

    const { isLoading, isError, data, error, isSuccess } = useQuery({
        queryKey: ['onboardingStats'],
        queryFn: () => OnboardingStatsRemote.get()
    });

    const needsOnboarding = () => {
        return isSuccess && data !== null && _.get(data, "onboarded", false) === false;
    }

    const handleStopOnboarding = ({currentStep, completed}) => {
        stats.current.endedAt = new Date();
        stats.current.lastStep = currentStep;
        stats.current.completed = completed;

        save.mutate(stats.current);
        onClose();
    }

    if (needsOnboarding()) {
        return (
            <OnboardingModal open={open} onClose={handleStopOnboarding} {...refs}/>
        )
    }
}

export default OnboardingController;