import React, {useState} from 'react';
import {Avatar, Input} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchOutlined} from "@ant-design/icons";
import PremiumButton from "../../components/buttons/PremiumButton";
import {faPlus, faToolbox} from "@fortawesome/free-solid-svg-icons";
import ContentCreatorToolsList from "./components/ContentCreatorToolsList";
import {useNavigate} from "react-router-dom";
import useIsMobile from "../../utils/hooks/useIsMobile";

const ContentCreatorToolsPage = () => {
    const [filter, setFilter] = useState(null);
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const handleCreateTool = () => {
        navigate("/creator/tools/edit");
    }

    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                {!isMobile && <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faToolbox}/>}/>}
                <div className="font-sans">
                    <div className="text-3xl">
                        Agentes
                    </div>

                    <div className="text-sm text-gray-400">
                        Adicione, edite ou remova Agentes que você deseja trabalhar
                    </div>
                </div>
            </div>


            <div className="py-4">
                <div className="flex flex-row gap-2 items-center">
                    <Input
                        size="large"
                        placeholder="Buscar por nome"
                        prefix={<SearchOutlined/>}
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value)
                        }}
                    />

                    <PremiumButton
                        className="flex flex-row items-center gap-2"
                        type="primary"
                        size="large"
                        onClick={() => handleCreateTool()}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                        Adicionar
                    </PremiumButton>
                </div>

                <ContentCreatorToolsList filter={filter}/>
            </div>

            {/*<ContentCreatorSpecialtiesCreateModal*/}
            {/*    open={openCreateVoiceModal}*/}
            {/*    onClose={() => setOpenCreateVoiceModal(false)}*/}
            {/*/>*/}
        </div>
    );
};

export default ContentCreatorToolsPage;