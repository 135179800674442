import React from 'react';
import {Avatar, Tabs} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsersGear} from "@fortawesome/free-solid-svg-icons";
import WorkspaceTeamMembersTab from "./components/WorkspaceTeamMembersTab";
import WorkspaceTeamPendingInvitesTab from "./components/WorkspaceTeamPendingInvitesTab";

const WorkspaceTeamPage = () => {
    const getTabItems = () => {
        return [
            {
                key: "members",
                label: "Membros",
                children: <WorkspaceTeamMembersTab/>
            },
            {
                key: "pending",
                label: "Convites pendentes",
                children: <WorkspaceTeamPendingInvitesTab/>
            }
        ]
    }

    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faUsersGear}/>}/>
                <div className="font-sans">
                    <div className="text-3xl">
                        Equipe
                    </div>

                    <div className="text-sm text-gray-400">
                        Faça a gestão da sua equipe e defina as permissões de cada membro.
                    </div>
                </div>
            </div>

            <div className="py-4">
                <Tabs
                    rootClassName="font-primary"
                    defaultActiveKey="members"
                    items={getTabItems()}
                    tabBarStyle={{font: "Outfit"}}
                />
            </div>
        </div>
    );
};

export default WorkspaceTeamPage;