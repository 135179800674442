import React, {useEffect, useState} from 'react';
import {Avatar, Button, Col, Row, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";
import {useSubscriptionService} from "../../utils/hooks/useSubscriptionService";
import DetailCard from "./components/DetailCard";
import {useCurrentWorkspace} from "../../utils/hooks/useCurrentWorkspace";
import {useBrandVoices} from "../../utils/hooks/useBrandVoices";

const WorkspacePlanPage = () => {
    const {getUserActiveSubscription} = useSubscriptionService();
    const {brandVoices} = useBrandVoices();

    const [activeSubscription, setActiveSubscription] = useState();
    const {currentWorkspace, currentUserRole} = useCurrentWorkspace();

    useEffect(() => {
        setActiveSubscription(getUserActiveSubscription());
    }, [getUserActiveSubscription]);

    const renderPageHeader = () => {
        return (
            <div className="flex flex-row gap-3 items-center">
                <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faCrown}/>}/>
                <div className="font-primary">
                    <div className="text-3xl">
                        Assinatura
                    </div>

                    <div className="text-sm text-gray-400">
                        Informações sobre o plano de assinatura
                    </div>
                </div>
            </div>
        );
    }

    const renderActiveSubscriptionPlanDetails = () => {
        return (
            <div className="flex flex-col gap-2">
                <div className="text-lg">
                    Detalhes do plano
                </div>
                <div className="flex justify-between text-3xl font-bold">
                    <div className="flex flex-row items-center">
                        Team
                        {activeSubscription?.status === "trialing" && (
                            <Tag bordered={false} className="mx-2 bg-premium text-white">
                                Em trial
                            </Tag>
                        )}
                    </div>
                    <Button
                        type="text"
                        disabled={currentUserRole !== "OWNER"}
                    >
                        Gerenciar assinatura
                    </Button>
                </div>
            </div>
        );
    }

    const renderActiveSubscriptionPlanLimits = () => {
        return (
            <div className="flex flex-col gap-2">
                <div className="text-lg">
                    Limites de uso do plano
                </div>
                <Row className="w-full" gutter={16}>
                    <Col md={12}>
                        <DetailCard
                            title="Usuários"
                            content={`${currentWorkspace?.members?.length} de ${currentWorkspace?.maxUsers}`}
                            tooltipContent="Número de usuários disponíveis no plano atual"
                        />
                    </Col>
                    <Col md={12}>
                       <DetailCard
                            title="Brand voices"
                            content={`${brandVoices?.length || 0} de 3`}
                            tooltipContent="Número de brand voices disponíveis no plano atual"
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className="p-4 mx-8 font-primary flex flex-col gap-6">
            {renderPageHeader()}
            {renderActiveSubscriptionPlanDetails()}
            {renderActiveSubscriptionPlanLimits()}
        </div>
    );
};

export default WorkspacePlanPage;