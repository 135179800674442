import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {useCreateSpecialty} from "../../../utils/hooks/useSpecialties";
import PremiumButton from "../../../components/buttons/PremiumButton";

const ContentCreatorSpecialtiesCreateModal = ({open, onClose}) => {
    const {createSpecialty, isCreating} = useCreateSpecialty({
        onSuccess: () => {
            onClose();
            clearForm();
        },
        onError: () => {
            onClose();
            clearForm();
        }
    });

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [prompt, setPrompt] = useState("");

    const clearForm = () => {
        setName("");
        setDescription("");
        setPrompt("");
    }

    const handleCreate = () => {
        createSpecialty({
            name,
            description,
            prompt
        });
    }

    const renderFooter = () => {
        const disabled = !name || !prompt || isCreating;

        return (
            <div className="flex flex-row justify-end gap-4">
                <Button
                    type="text"
                    size="large"
                    onClick={onClose}
                    disabled={isCreating}
                >
                    Cancelar
                </Button>
                <PremiumButton
                    type="primary"
                    size="large"
                    disabled={disabled}
                    onClick={handleCreate}
                    loading={isCreating}
                >
                    {isCreating && "Adicionando"}
                    {!isCreating && "Adicionar"}
                </PremiumButton>
            </div>
        );
    }

    return (
        <Modal
            title="Adicionar nova especialidade"
            centered
            open={open}
            width={800}
            footer={renderFooter()}
            onCancel={onClose}
            className="font-primary"
        >
            <div className="p-4">
                <Form layout="vertical" autoComplete="off">
                    <Form.Item label="Nome">
                        <Input
                            placeholder="Nome da especialidade"
                            size="large"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={isCreating}
                        />
                    </Form.Item>

                    <Form.Item label="Descrição">
                        <Input
                            placeholder="Breve descrição da especialidade"
                            size="large"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={isCreating}
                        />
                    </Form.Item>

                    <Form.Item label="Prompt">
                        <Input.TextArea
                            placeholder="Prompt que será executado pela especialidade"
                            size="large"
                            autoSize={{minRows: 6, maxRows: 12}}
                            showCount
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            disabled={isCreating}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default ContentCreatorSpecialtiesCreateModal;