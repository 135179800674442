import React from 'react';

export default function withIsMobile(Component) {
    return class extends React.Component {
        state = {
            isMobile: window.innerWidth <= 1200
        };

        componentDidMount() {
            window.addEventListener('resize', this.handleResize);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.handleResize);
        }

        handleResize = () => {
            this.setState({isMobile: window.innerWidth <= 1200});
        }

        render() {
            const {isMobile} = this.state;
            return <Component {...this.props} isMobile={isMobile} />;
        }
    }
}