import {Flex, Link, useAuthenticator, useTheme} from "@aws-amplify/ui-react";
import "./SignInFooter.css";

export function SignInFooter() {
    const {toResetPassword} = useAuthenticator();
    const {tokens} = useTheme();

    const openTermsAndConditionsLink = () => {
        window.open('https://cmos.ai/termos-e-condicoes', '_blank');
    }

    return (
        <>
            <Flex className="SignInFooterResetPassword" justifyContent="right"
                  padding={`0 32px ${tokens.space.medium}`}>
                <Link onClick={toResetPassword}>Esqueceu a senha?</Link>
            </Flex>

            <Flex className="SignInFooterResetPassword" justifyContent="center" padding={`0 32px ${tokens.space.medium}`}>
                <span className="SignInFooterTermsAndConditions">
                    Ao prosseguir, você concorda com nossos <Link className="SignInFooterLink" onClick={openTermsAndConditionsLink}>Termos & Condições</Link>
                </span>
            </Flex>
        </>
    );
}
