import {Layout} from "antd";
import {Content} from "antd/es/layout/layout";
import "./Settings.css";
import React, {useEffect, useState} from "react";
import SettingsProfile from "./profile/SettingsProfile";
import SettingsSecurity from "./security/SettingsSecurity";
import SettingsSubscription from "./subscription/SettingsSubscription";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {CrownOutlined, LockOutlined, UserOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import MainHeaderTitle from "../../components/main-header/MainHeaderTitle";
import useIsMobile from "../../utils/hooks/useIsMobile";
import Submenu from "../../components/submenu/Submenu";
import Usage from "./usage/Usage";

const Settings = ({user, setHeaderTitle, defaultPicture, ...props}) => {
    const [currentScreen, setCurrentScreen] = useState("profile");

    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = `Configurações · CMOs.ai`;
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faCog}/>}
                title="Configurações"
                renderBack
            />
        );

        if (location.pathname.startsWith("/settings")) {
            const screen = location.pathname.split("/")[2];
            if (screen) {
                setCurrentScreen(screen);
            }
        }
    }, [location.pathname, setHeaderTitle]);

    const handleScreenChange = (screen) => {
        setCurrentScreen(screen);
        navigate(`/settings/${screen}`);
    }

    const renderMenu = () => {
        const items = [
            {
                key: "profile",
                label: (<div><UserOutlined/><span>Meu perfil</span></div>)
            },
            {
                key: "security",
                label: (<div><LockOutlined/><span>Segurança</span></div>)
            },
            {
                key: "subscription",
                label: (<div><CrownOutlined/><span>Assinatura</span></div>),
            },
            // {
            //     key: "usage",
            //     label: (<div><BarChartOutlined /><span>Créditos</span></div>),
            // },
        ];

        return (
            <Submenu
                items={items}
                selectedKeys={[currentScreen]}
                onClick={(item) => handleScreenChange(item.key)}
            />
        );
    }

    const renderContent = () => {
        return (
            <Content style={{height: "100%", overflow: "auto"}}>
                <Routes>
                    <Route path="" element={<Navigate to={`/settings/${currentScreen}`}/>}/>
                    <Route path="profile" element={<SettingsProfile user={user} defaultPicture={defaultPicture}/>}/>
                    <Route path="security" element={<SettingsSecurity user={user}/>}/>
                    <Route path="subscription" element={<SettingsSubscription user={user}/>}/>
                    <Route path="usage" element={<Usage user={user}/>}/>
                </Routes>
            </Content>
        );
    }

    const renderDesktop = () => {
        return (
            <>
                {renderMenu()}
                {renderContent()}
            </>
        );
    }

    const renderContentMobile = () => {
        return (
            <>
                {renderMenu()}
                {renderContent()}
            </>
        );
    }

    return (
        <div style={{height: "100%"}}>
            <Layout style={{height: "100%", overflow: "auto"}}>
                {isMobile ? renderContentMobile() : renderDesktop()}
            </Layout>
        </div>
    );
}

export default Settings;