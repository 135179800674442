import "./MainHeaderTitle.css";
import {Avatar, Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import useIsMobile from "../../utils/hooks/useIsMobile";

const MainHeaderTitle = ({renderBack, icon, src, title}) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const renderTitle = () => {
        return (
            <div className="MainHeaderTitleLabel">
                {!isMobile && renderIcon()}
                <label className="MainHeaderTitleLabelTitle">
                    {title}
                </label>
            </div>
        );
    }

    const renderBackButton = () => {
        if (renderBack) {
            return (
                <Button type="text" shape="circle" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon className="MainHeaderTitleBackIcon" icon={faChevronLeft}/>
                </Button>
            );
        }
    }

    const renderIcon = () => {
        if (icon) {
            return icon;
        }
        if (src) {
            return (
                <Avatar size={26} src={src} shape="circle"/>
            );
        }
    }

    return (
        <>
            <div className="MainHeaderTitle">
                {renderBackButton()}
                {renderTitle()}
            </div>
        </>
    );
}

export default MainHeaderTitle;