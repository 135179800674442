// src/services/dao/remote/ImageDAORemote.js

import { urlBase, params, resolve } from "./resources";

const url = `${urlBase}/api/image`;

async function generate(data) {
    // Inclua 'is_public' no conteúdo ao enviar para o backend
    const content = { 
        body: { ...data, is_public: data.is_public || false } 
    };
    return fetch(`${url}/generate`, await params.post(content))
        .then((response) => response.json())
        .then(resolve);
}

async function getAll() {
    return fetch(`${url}/list`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function getCommunityImages(page = 1, pageSize = 20) {
    const queryParams = new URLSearchParams({ page, page_size: pageSize });
    return fetch(`${url}/community?${queryParams.toString()}`, await params.get())
        .then((response) => {
            if (!response.ok) {
                return response.text().then(text => { throw new Error(text); });
            }
            return response.json();
        })
        .then(resolve);
}

async function remove(idImage) {
    return fetch(`${url}/${idImage}`, await params.delete())
        .then(response => response.json())
        .then(resolve);
}

async function reimagine(data) {
    const content = { body: data };
    return fetch(`${url}/reimagine`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function sketchToImage(data) {
    const content = { body: data };
    return fetch(`${url}/sketch`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function removeBg(data) {
    const content = { body: data };
    return fetch(`${url}/remove-bg`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function removeText(data) {
    const content = { body: data };
    return fetch(`${url}/remove-text`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function replaceBg(data) {
    const content = { body: data };
    return fetch(`${url}/replace-bg`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function upscale(data) {
    const content = { body: data };
    return fetch(`${url}/upscale`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function generateWithIdeogram(data) {
    const content = { 
        body: { ...data, is_public: data.is_public || false } 
    };
    return fetch(`${url}/generate/ideogram`, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

const ImageRemote = {
    generate,
    getAll,
    remove,
    reimagine,
    sketchToImage,
    removeBg,
    removeText,
    replaceBg,
    upscale,
    generateWithIdeogram,
    getCommunityImages,
};

export default ImageRemote;
