import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Input, InputNumber, Modal, Row, Select, Slider, Switch} from "antd";
import {Editor} from "primereact/editor";
import "./CreatorToolEditField.css";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const CreatorToolEditField = ({hasExample, fields, field, onSave}) => {

    // all fields
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [required, setRequired] = useState(true);
    const [exampleValue, setExampleValue] = useState(null);

    // text and textarea fields
    const [placeholder, setPlaceholder] = useState("");
    const [maxLength, setMaxLength] = useState(20);
    const [rows, setRows] = useState(1);

    // selector field
    const [options, setOptions] = useState([]);
    const [mode, setMode] = useState(null);

    // integer and numeric fields
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);
    const [step, setStep] = useState(null);

    const [showDescriptionModal, setShowDescriptionModal] = useState(false);

    useEffect(() => {
        setType(_.get(field, "type", ""));
        setName(_.get(field, "name", ""));
        setDescription(_.get(field, "description", ""));
        setRequired(_.get(field, "required", true));
        setPlaceholder(_.get(field, "placeholder", ""));
        setExampleValue(_.get(field, "exampleValue", null));

        setMaxLength(_.get(field, "maxLength", 20));
        setRows(_.get(field, "rows", 1));
        setOptions(_.get(field, "options", []));
        setMode(_.get(field, "mode", null));
        setMinValue(_.get(field, "minValue", null));
        setMaxValue(_.get(field, "maxValue", null));
        setStep(_.get(field, "step", null));
    }, [field]);

    useEffect(() => {
        if (_.get(field, "idField", null)) {
            onSave(buildFieldByType());
        }

    }, [type, name, description, required, placeholder, exampleValue, maxLength, rows, options, mode, minValue, maxValue, step]);

    const buildFieldByType = () => {
        switch (type) {
            case "TEXTFIELD":
                return buildTextField();
            case "TEXTAREAFIELD":
                return buildTextAreaField();
            case "SELECTFIELD":
                return buildSelectField();
            case "INTEGERFIELD":
                return buildIntegerField();
            case "NUMERICFIELD":
                return buildNumericField();
            case "BOOLEANFIELD":
            default:
                return buildBooleanField();
        }
    }

    const buildTextField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            required,
            placeholder,
            exampleValue,
            maxLength
        }
    }

    const buildTextAreaField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            required,
            placeholder,
            exampleValue,
            maxLength,
            rows
        }
    }

    const buildSelectField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            required,
            placeholder,
            exampleValue,
            options,
            mode
        }
    }

    const buildIntegerField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            required,
            placeholder,
            exampleValue,
            minValue,
            maxValue,
            step
        }
    }

    const buildNumericField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            required,
            placeholder,
            exampleValue,
            minValue,
            maxValue,
            step
        }
    }

    const buildBooleanField = () => {
        return {
            idField: field.idField,
            type,
            name,
            token: getToken(name),
            description,
            exampleValue,
            required
        }
    }

    const getToken = (text) => {
        return text.toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '_')
            .replace(/\([^)]*\)/g, '')
            .replace(/[^a-zA-Z0-9_]/g, '');
    }

    const renderFieldTypeSpecificInputs = () => {
        switch (type) {
            case "TEXTFIELD":
                return renderTextFieldTypeInputs();
            case "TEXTAREAFIELD":
                return renderTextAreaFieldTypeInputs();
            case "SELECTFIELD":
                return renderSelectFieldTypeInputs();
            case "INTEGERFIELD":
                return renderIntegerFieldTypeInputs();
            case "NUMERICFIELD":
                return renderNumericFieldTypeInputs();
            case "BOOLEANFIELD":
                return renderBooleanFieldTypeInputs();
            default:
                return;
        }
    }

    const renderFieldInputs = () => {
        return (
            <>
                {renderFieldNameInput()}
                {renderFieldTypeSelector()}
                {renderFieldDescriptionButton()}
                {renderFieldTypeSpecificInputs()}
                {renderFieldRequiredInput()}
            </>
        );
    }

    const renderTextFieldTypeInputs = () => {
        return (
            <>
                {renderFieldPlaceholderInput()}
                {renderFieldTextExampleValueInput()}
                {renderFieldMaxLengthInput()}
            </>
        )
    }

    const renderTextAreaFieldTypeInputs = () => {
        return (
            <>
                {renderFieldPlaceholderInput()}
                {renderFieldTextExampleValueInput()}
                <Row>
                    <Col span={12}>{renderFieldMaxLengthInput()}</Col>
                    <Col span={12}>{renderFieldTextAreaRowsInput()}</Col>
                </Row>
            </>
        );
    }

    const renderSelectFieldTypeInputs = () => {
        return (
            <>
                {renderFieldPlaceholderInput()}
                {renderFieldSelectModeInput()}
                {renderFieldSelectOptionsInput()}
                {renderFieldSelectExampleValueInput()}
            </>
        );
    }

    const renderIntegerFieldTypeInputs = () => {
        return (
            <>
                {renderFieldPlaceholderInput()}
                <Row gutter={8}>
                    <Col span={6}>{renderFieldNumberExampleValueInput()}</Col>
                    <Col span={6}>{renderFieldMinIntegerValueInput()}</Col>
                    <Col span={6}>{renderFieldMaxIntegerValueInput()}</Col>
                    <Col span={6}>{renderFieldIntegerStepInput()}</Col>
                </Row>
            </>
        );
    }

    const renderNumericFieldTypeInputs = () => {
        return (
            <>
                {renderFieldPlaceholderInput()}
                <Row gutter={8}>
                    <Col span={6}>{renderFieldNumberExampleValueInput(true)}</Col>
                    <Col span={6}>{renderFieldMinIntegerValueInput(true)}</Col>
                    <Col span={6}>{renderFieldMaxIntegerValueInput(true)}</Col>
                    <Col span={6}>{renderFieldIntegerStepInput(true)}</Col>
                </Row>
            </>
        );
    }

    const renderBooleanFieldTypeInputs = () => {
        return (
            <>
                {renderFieldBooleanExampleValueInput()}
            </>
        );
    }

    const renderFieldTypeSelector = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Tipo do campo
                </label>
                <Select
                    size={"large"}
                    dropdownMatchSelectWidth={false}
                    options={[
                        {value: 'TEXTFIELD', label: 'Texto curto'},
                        {value: 'TEXTAREAFIELD', label: 'Texto longo'},
                        {value: 'SELECTFIELD', label: 'Seleção'},
                        {value: 'INTEGERFIELD', label: 'Inteiro'},
                        {value: 'NUMERICFIELD', label: 'Numérico'},
                        {value: 'BOOLEANFIELD', label: 'Booleano'},
                    ]}
                    onChange={(value) => setType(value)}
                    placeholder={"Selecione o tipo do campo"}
                    value={type ? type : undefined}
                    showSearch
                />
            </div>
        );
    }

    const renderFieldNameInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Nome do campo
                </label>
                <Input
                    size={"large"}
                    placeholder={"Nome do campo"}
                    maxLength={50}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
            </div>
        );
    }

    const renderFieldDescriptionButton = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Descrição
                </label>
                <Button
                    type="default"
                    size="large"
                    onClick={() => setShowDescriptionModal(true)}
                >
                    Adicionar descrição
                </Button>
            </div>
        );
    }

    const renderFieldDescriptionModal = () => {
        return (
            <Modal
                title="Descrição do campo"
                open={showDescriptionModal}
                onOk={() => setShowDescriptionModal(false)}
                onCancel={() => setShowDescriptionModal(false)}
                width={900}
                style={{height: 500}}
                okText={"Salvar"}
                cancelText={"Cancelar"}
            >
                <Editor
                    value={description}
                    onTextChange={(e) => setDescription(e.htmlValue)}
                    style={{height: 500}}
                />

            </Modal>
        );
    }

    const renderFieldRequiredInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <Checkbox
                    style={{fontSize: 16}}
                    onChange={(e) => setRequired(e.target.checked)}
                    checked={required}
                >
                    Campo obrigatório
                </Checkbox>
            </div>
        );
    }

    const renderFieldPlaceholderInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Placeholder
                </label>
                <Input.TextArea
                    size={"large"}
                    placeholder={"Placeholder do campo"}
                    maxLength={300}
                    onChange={(e) => setPlaceholder(e.target.value)}
                    value={placeholder}
                />
            </div>
        );
    }

    const renderFieldTextExampleValueInput = () => {
        if (hasExample) {
            return (
                <div className="CreatorToolEditFieldWrapper">
                    <label className="CreatorToolEditFieldLabel">
                        Valor de exemplo
                    </label>
                    <Input.TextArea
                        size={"large"}
                        placeholder={"Valor que será utilizado como exemplo na introdução da ferramenta"}
                        onChange={(e) => setExampleValue(e.target.value)}
                        value={exampleValue}
                        maxLength={maxLength}
                        showCount
                    />
                </div>
            );
        }
    }

    const renderFieldSelectExampleValueInput = () => {
        if (hasExample) {
            return (
                <div className="CreatorToolEditFieldWrapper">
                    <label className="CreatorToolEditFieldLabel">
                        Valor de exemplo
                    </label>

                    <Select
                        value={exampleValue}
                        onChange={(value) => setExampleValue(value)}
                        mode={mode}
                        size="large"
                        placeholder={"Selecione uma ou mais opções"}
                        options={options.map((option) => {return {value: option, label: option}})}
                        disabled={_.isEmpty(options) || mode == null}
                    />
                </div>
            );
        }
    }

    const renderFieldNumberExampleValueInput = (numeric = false) => {
        if (hasExample) {
            return (
                <div className="CreatorToolEditFieldWrapper">
                    <label className="CreatorToolEditFieldLabel">
                        Valor de exemplo
                    </label>
                    <InputNumber
                        size={"large"}
                        placeholder={"Valor que será utilizado como exemplo na introdução da ferramenta"}
                        onChange={(value) => setExampleValue(value)}
                        value={exampleValue}
                        style={{width: "100%"}}
                        step={numeric ? 0.1 : 1}
                    />
                </div>
            );
        }
    }

    const renderFieldBooleanExampleValueInput = () => {
        if (hasExample) {
            return (
                <div className="CreatorToolEditFieldWrapper">
                    <label className="CreatorToolEditFieldLabel">
                        Valor de exemplo
                    </label>
                    <Switch
                        onChange={(value) => setExampleValue(value)}
                        value={exampleValue}
                        style={{maxWidth: 42}}
                    />
                </div>
            );
        }
    }

    const renderFieldMaxLengthInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Comprimento máximo
                </label>
                <InputNumber
                    size={"large"}
                    placeholder={"Placeholder do campo"}
                    min={1}
                    max={5000}
                    onChange={(value) => setMaxLength(value)}
                    value={maxLength}
                />
            </div>
        );
    }

    const renderFieldTextAreaRowsInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Quantidade de linhas
                </label>

                <div className="CreatorToolEditFieldSliderWrapper">
                    <Slider
                        value={rows}
                        onChange={(value) => setRows(value)}
                        min={1}
                        max={10}
                        style={{maxWidth: "250px", width: "100%"}}
                    />
                    <label className="CreatorToolEditFieldSliderCounter">
                        {rows}
                    </label>
                </div>
            </div>
        );
    }

    const renderFieldSelectModeInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Modo de seleção
                </label>
                <Select
                    size={"large"}
                    options={[
                        {value: 'single', label: 'Seleção única'},
                        {value: 'multiple', label: 'Seleção múltipla'},
                        {value: 'tags', label: 'Seleção de tags'},
                    ]}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => setMode(value)}
                    value={mode ? mode : undefined}
                    placeholder={"Escolha o modo de seleção"}
                />
            </div>
        );
    }

    const renderFieldSelectOptionsInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Opções da seleção
                </label>

                <Select
                    value={options}
                    onChange={(value) => setOptions(value)}
                    mode="tags"
                    size="large"
                    placeholder={"Insira as opções"}
                />
            </div>
        );
    }

    const renderFieldMinIntegerValueInput = (numeric = false) => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Valor mínimo
                </label>
                <InputNumber
                    size={"large"}
                    max={maxValue}
                    onChange={(value) => setMinValue(value)}
                    value={minValue}
                    step={numeric ? 0.1 : 1}
                    style={{width: "100%"}}
                />
            </div>
        );
    }

    const renderFieldMaxIntegerValueInput = (numeric = false) => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Valor máximo
                </label>
                <InputNumber
                    size={"large"}
                    min={minValue}
                    onChange={(value) => setMaxValue(value)}
                    value={maxValue}
                    step={numeric ? 0.1 : 1}
                    style={{width: "100%"}}
                />
            </div>
        );
    }

    const renderFieldIntegerStepInput = (numeric = false) => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Step (incremento)
                </label>
                <InputNumber
                    size={"large"}
                    placeholder={"Step (incremento)"}
                    onChange={(value) => setStep(value)}
                    value={step}
                    step={numeric ? 0.1 : 1}
                    style={{width: "100%"}}
                />
            </div>
        );
    }

    if (_.isEmpty(fields) || _.isEmpty(field)) {
        return;
    }

    return (
        <>
            {renderFieldInputs()}
            {renderFieldDescriptionModal()}
        </>
    );
}

export default CreatorToolEditField;