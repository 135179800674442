import React from 'react';

const CurrentSubscriptionPlanButton = () => {
    return (
        <div className="text-center rounded-full py-[9px] bg-primary-bg">
            <label className="font-sans text-reg text-primary-text font-bold">
                Plano atual
            </label>
        </div>
    );
};

export default CurrentSubscriptionPlanButton;