import { useState, useEffect } from 'react';

export function useScreenSize() {
    const [screenSize, setScreenSize] = useState('');

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;

            if (width < 600) {
                setScreenSize('xs')
            } else if (width < 800) {
                setScreenSize('sm')
            } else if (width < 1200) {
                setScreenSize('md')
            } else if (width < 1600) {
                setScreenSize('lg')
            } else if (width < 2000) {
                setScreenSize('xl')
            } else {
                setScreenSize('xxl')
            }
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return screenSize;
}