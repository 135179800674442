import {ProgressSpinner} from "primereact/progressspinner";

const LoadingScreen = () => {
    return (
        <div style={{height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <ProgressSpinner />
        </div>
    );
}

export default LoadingScreen;