import React from "react";

import {VisibilityContext} from "react-horizontal-scrolling-menu";
import {Button} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./Arrows.css";

function Arrow({
                   children,
                   disabled,
                   onClick,
                   position
               }) {
    return (
        <Button
            shape="circle"
            disabled={disabled}
            onClick={onClick}
            type="primary"
            icon={position === "left" ? <FontAwesomeIcon icon={faChevronLeft}/> : <FontAwesomeIcon icon={faChevronRight}/>}
            style={{opacity: disabled ? "0" : "1"}}
        >
            {children}
        </Button>
    );
}

export function LeftArrow() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleElements,
        initComplete
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollPrev()} position="left"/>
    );
}

export function RightArrow() {
    const {isLastItemVisible, scrollNext, visibleElements} = React.useContext(
        VisibilityContext
    );

    const [disabled, setDisabled] = React.useState(
        !visibleElements.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollNext()} position="right"/>
    );
}
