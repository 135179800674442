import React from 'react';
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import WorkspaceTeamList from "./WorkspaceTeamList";
import PremiumButton from "../../../components/buttons/PremiumButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {useCurrentWorkspace} from "../../../utils/hooks/useCurrentWorkspace";
import WorkspaceTeamInviteModal from "./WorkspaceTeamInviteModal";
import {useWorkspaceInvitations} from "../../../utils/hooks/useWorkspaceInvitations";

const WorkspaceTeamMembersTab = () => {
    const {currentWorkspace} = useCurrentWorkspace();
    const {shareableLinks} = useWorkspaceInvitations();

    const [filter, setFilter] = React.useState(null);
    const [openInviteModal, setOpenInviteModal] = React.useState(false);

    const isDisabled = () => {
        return currentWorkspace?.members?.length >= currentWorkspace?.maxUsers;
    }

    const renderInviteButton = () => {
        const button = (
            <PremiumButton
                className="flex flex-row items-center gap-2"
                type="primary"
                size="large"
                onClick={() => setOpenInviteModal(true)}
                disabled={isDisabled()}
            >
                <FontAwesomeIcon icon={faEnvelope}/>
                Convidar membros
            </PremiumButton>
        );

        return button;
    }

    return (
        <>
            <div className="flex flex-row gap-2 items-center">
                <Input
                    size="large"
                    placeholder="Buscar por nome ou descrição"
                    prefix={<SearchOutlined/>}
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value)
                    }}
                    disabled={isDisabled()}
                />

                {renderInviteButton()}
            </div>

            <WorkspaceTeamList
                members={currentWorkspace?.members || []}
                isLoading={false}
                filter={filter}
            />

            <WorkspaceTeamInviteModal
                open={openInviteModal}
                onClose={() => setOpenInviteModal(false)}
                shareableLinks={shareableLinks}
            />
        </>
    );
};

export default WorkspaceTeamMembersTab;