import "./ChatHistoryMobile.css";
import {Button, Input, Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ChatHistoryItemMobile from "./ChatHistoryItemMobile";
import React, {useState} from "react";
import _ from "lodash";

const { confirm } = Modal;


const ChatHistoryMobile = (props) => {

    const {chats, selectedChatId, onChatCreate, onChatChange, onChatDelete, onChatRename} = props;

    const [isItemModalOpen, setItemModalOpen] = useState(false);
    const [isRenameModalOpen, setRenameModalOpen] = useState(false);

    const [idPressedChat, setIdPressedChat] = useState(null);

    const [renamedTitle, setRenamedTitle] = useState(null);

    const getChat = (idChat) => {
        return chats.find(chat => chat.idChat === idChat);
    }

    const handleItemLongPress = (idChat) => {
        setItemModalOpen(true);
        setIdPressedChat(idChat);
    }

    const handleItemCloseModal = () => {
        setItemModalOpen(false);
    }

    const handleRenameModalOk = () => {
        setRenameModalOpen(false);
        onChatRename(idPressedChat, renamedTitle);
        setRenamedTitle(null);
    }

    const handleRenameModalCancel = () => {
        setRenameModalOpen(false);
    }

    const handleRename = () => {
        setItemModalOpen(false);
        setRenameModalOpen(true);
    }

    const handleDelete = () => {
        setItemModalOpen(false);
        showConfirmDeleteModal();
    }

    const renderChatItems = () => {
        return (
            <div className="ChatHistoryMobileContainer">
                <div className="ChatHistoryMobileItem">
                    {renderAddChatButton()}
                    <div className="ChatHistoryMobileItemLabel"><b>Novo chat</b></div>
                </div>

                {chats.map(chat => {
                    return (
                        <ChatHistoryItemMobile
                            key={chat.idChat}
                            idChat={chat.idChat}
                            title={chat.title}
                            onChatChange={onChatChange}
                            onLongPress={handleItemLongPress}
                            isSelected={chat.idChat === selectedChatId}
                        />
                    );
                })}
            </div>

        )
    }

    const renderAddChatButton = () => {
        return (
            <Button
                shape="circle"
                type="primary"
                size="large"
                className="ChatHistoryMobileAddChatButton"
                onClick={onChatCreate}
            >
                <FontAwesomeIcon icon={faPlus} style={{fontSize: "24px"}}/>
            </Button>
        );
    }

    const renderItemModal = () => {
        return (
            <Modal
                open={isItemModalOpen}
                footer={null}
                width={300}
                centered
                closable={false}
                onCancel={() => setItemModalOpen(false)}
            >
                <div className="ChatHistoryMobileModalButtons">
                    <Button
                        className="ChatHistoryMobileModalButton"
                        type="primary"
                        size="large"
                        block
                        onClick={handleRename}
                    >
                        Renomear
                    </Button>

                    <Button
                        className="ChatHistoryMobileModalButton"
                        type="primary"
                        size="large"
                        block
                        onClick={handleDelete}
                    >
                        Excluir
                    </Button>
                </div>

            </Modal>
        )
    }

    const renderRenameModal = () => {
        const pressedChat = getChat(idPressedChat);

        if (_.isEmpty(pressedChat)) {
            return null;
        }

        return (
            <Modal
                open={isRenameModalOpen}
                onOk={handleRenameModalOk}
                onCancel={handleRenameModalCancel}
                width={300}
                centered
                closable={false}
                okText={"Salvar"}
                cancelText={"Cancelar"}
            >
                <Input
                    bordered={false}
                    showCount={true}
                    placeholder={pressedChat.title}
                    value={renamedTitle}
                    size={"large"}
                    maxLength={20}
                    onChange={(e) => {
                        setRenamedTitle(e.target.value);
                    }}
                ></Input>
            </Modal>
        )
    }

    const showConfirmDeleteModal = () => {
        confirm({
            title: 'Você tem certeza que quer excluir esse chat?',
            icon: <ExclamationCircleFilled />,
            okText: 'Excluir',
            okType: 'danger',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                onChatDelete(idPressedChat);
                setIdPressedChat(null);
            },
            onCancel() {
                setIdPressedChat(null);
            },
        });
    }

    return (
        <>
            <div className="ChatHistoryMobile">
                {renderChatItems()}
            </div>
            {renderItemModal()}
            {renderRenameModal()}
        </>
    );
}

export default ChatHistoryMobile;