export const Theme = {
    PRIMARY: "#724CF9",
    PRIMARY_HOVER: "#9a78ff",
    PRIMARY_ACTIVE: "#5437d4",

    PRIMARY_BG: "#f6f0ff",
    PRIMARY_BG_HOVER: "#f5f0ff",
    PRIMARY_BG_ACTIVE: "#dac9ff",

    PRIMARY_TEXT: "#724cf9",
    PRIMARY_TEXT_HOVER: "#9a78ff",
    PRIMARY_TEXT_ACTIVE: "#5437d4",

    PRIMARY_BORDER: "#e6c9ff",
    PRIMARY_BORDER_HOVER: "#bba1ff",

    SECONDARY: "#2ED16F",
}