import React from "react";
import {ScrollMenu,} from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';

import {LeftArrow, RightArrow} from "./Arrows";
import usePreventBodyScroll from "./usePreventBodyScroll";

// NOTE: embrace power of CSS flexbox!
import "./hideScrollbar.css";

function HorizontalScroll({components}) {
    const {disableScroll, enableScroll} = usePreventBodyScroll();

    return (
        <>
            <div>
                <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    // onWheel={onWheel}
                >
                    {components}
                </ScrollMenu>
            </div>
        </>
    );
}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default HorizontalScroll;