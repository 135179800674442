// src/components/chat/ChatContainerNew.jsx

import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatRemote } from "../../services/dao/remote/chat";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import * as LocalStorage from "../../services/dao/local/localStorage";
import _ from "lodash";
import { Layout } from "antd";
import ChatOrchestrator from "./ChatOrchestrator"; // Importação padrão
import { Content } from "antd/es/layout/layout";
import ChatHistoryMobile from "./ChatHistoryMobile";
import ChatHistory from "./ChatHistory";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { v4 as uuidv4 } from 'uuid';
import { ChatDAO } from "../../services/dao/local/ChatLocalDAO";
import PropTypes from "prop-types"; // Importar PropTypes se necessário
import ArtifactViewer from "../Artifact/ArtifactViewer"; // Importar o ArtifactViewer

// Função para converter snake_case para camelCase
const snakeToCamel = (s) => {
    return s.replace(/(_\w)/g, m => m[1].toUpperCase());
};

// Função para mapear um único chat do backend para o frontend
const mapChatFromBackend = (chat) => {
    const mappedChat = {};
    for (let key in chat) {
        if (chat.hasOwnProperty(key)) {
            const camelKey = snakeToCamel(key);
            mappedChat[camelKey] = chat[key];
        }
    }
    return mappedChat;
};

// Função para mapear uma lista de chats do backend para o frontend
const mapChatsFromBackend = (chats) => {
    return chats.map(mapChatFromBackend);
};

// Função para mapear um chat do frontend para o backend
const mapChatToBackend = (chat) => {
    const mappedChat = {};
    for (let key in chat) {
        if (chat.hasOwnProperty(key)) {
            const snakeKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
            mappedChat[snakeKey] = chat[key];
        }
    }
    return mappedChat;
};

const ChatContainerNew = ({ setHeaderTitle, user }) => {
    const queryClient = useQueryClient();
    const [idSelectedChat, setIdSelectedChat] = useState(null);
    const isMobile = useIsMobile();
    const [disabled, setDisabled] = useState(false);
    const [isAIWriting, setIsAIWriting] = useState(false);
    const [chatToEditId, setChatToEditId] = useState(null);
    const [selectedArtifact, setSelectedArtifact] = useState(null); // Estado para ArtifactViewer
    const isCreatingChat = useRef(false);

    const removedChats = useRef([]);

    useEffect(() => {
        document.title = "Chat · CMOs.ai";
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faComments} />}
                title="Chat especialista"
            />
        );
        loadLocalChats();
    }, [setHeaderTitle]);

    const { data: chats, isLoading } = useQuery({
        queryKey: ['chats'],
        queryFn: () => ChatRemote.list(),
        refetchOnWindowFocus: false,
        onSuccess: (backendChats) => {
            const mappedChats = mapChatsFromBackend(backendChats);
            ChatDAO.clear()
                .then(() => {
                    ChatDAO.bulkPut(mappedChats)
                        .then(() => console.log("Chats carregados e armazenados localmente."))
                        .catch((error) => console.error("Erro ao armazenar chats localmente:", error));
                })
                .catch((error) => console.error("Erro ao limpar chats locais:", error));
        }
    });

    useEffect(() => {
        if (!idSelectedChat && !isCreatingChat.current && !disabled && chats != null) {
            selectChat();
        }
    }, [chats, idSelectedChat]);

    const { mutateAsync: createChatRemoteFn } = useMutation({
        mutationFn: (newChat) => {
            const backendChat = mapChatToBackend(newChat);
            return ChatRemote.create(backendChat);
        },
        onSuccess: (backendChat) => {
            const mappedChat = mapChatFromBackend(backendChat);
            queryClient.setQueryData(['chats'], (old) => [...(old || []), mappedChat]);
            selectChat(mappedChat.idChat);
            setChatToEditId(mappedChat.idChat);
            ChatDAO.put(mappedChat)
                .then(() => console.log(`Chat criado com sucesso no Dexie: ${mappedChat.idChat}`))
                .catch((error) => console.error(`Erro ao criar chat no Dexie:`, error));
        },
        onSettled: () => {
            setDisabled(false);
            isCreatingChat.current = false; // Libera a criação de novos chats
        }
    });

    const { mutateAsync: updateChatRemoteFn } = useMutation({
        mutationFn: (chat) => {
            const backendChat = mapChatToBackend(chat);
            return ChatRemote.update(backendChat);
        },
        onSuccess: (backendChat) => {
            const mappedChat = mapChatFromBackend(backendChat);
            queryClient.setQueryData(['chats'], (old) => {
                return old.map((c) => c.idChat === mappedChat.idChat ? mappedChat : c);
            });
            ChatDAO.put(mappedChat)
                .then(() => console.log(`Chat idChat=${mappedChat.idChat} atualizado com sucesso no Dexie.`))
                .catch((error) => console.error(`Erro ao atualizar chat idChat=${mappedChat.idChat} no Dexie:`, error));
        },
        onSettled: () => {
            setDisabled(false);
        }
    });

    const { mutateAsync: deleteChatRemoteFn } = useMutation({
        mutationFn: (idChat) => ChatRemote.remove(idChat),
        onSuccess: (response) => {
            // Atualiza os chats no queryClient
            queryClient.setQueryData(['chats'], (oldChats) => {
                return (oldChats || []).filter((c) => c.idChat !== response.idChat);
            });
            // Remove o chat do armazenamento local
            ChatDAO.remove(response.idChat)
                .then(() => console.log(`Chat ${response.idChat} removido do Dexie.`))
                .catch((error) => console.error(`Erro ao remover chat ${response.idChat} do Dexie:`, error));

            // Define idSelectedChat como null para acionar o useEffect
            setIdSelectedChat(null);
        },
        onSettled: () => {
            setDisabled(false);
        }
    });

    const createChatFn = async (newChat) => {
        setDisabled(true);
        createChatRemoteFn(newChat);
    };

    const updateChatFn = (chat) => {
        setDisabled(true);
        return updateChatRemoteFn(chat); // Retornar a Promise
    };

    const deleteChatFn = (idChat) => {
        setDisabled(true);
        deleteChatRemoteFn(idChat);
    };

    const loadLocalChats = () => {
        ChatDAO.list()
            .then((localChats) => {
                queryClient.setQueryData(['chats'], (old) => {
                    if (_.isEmpty(old) && !_.isEmpty(localChats)) {
                        return localChats;
                    }
                    return old;
                });
            })
            .catch((error) => console.error("Erro ao carregar chats locais:", error));
    };

    const selectChat = (idChat) => {
        if (chats == null) {
            return;
        }
    
        if (idChat == null) {
            const orderedChats = getOrderedChats();
            if (orderedChats.length === 0 && !disabled && !isCreatingChat.current) {
                // Nenhum chat existente, criar um novo
                isCreatingChat.current = true; // Indica que um chat está sendo criado
                handleChatCreate();
            } else {
                for (let chat of orderedChats) {
                    if (!removedChats.current.includes(chat.idChat)) {
                        setIdSelectedChat(chat.idChat);
                        LocalStorage.setIdChatSelected(chat.idChat);
                        break;
                    }
                }
            }
        } else {
            setIdSelectedChat(idChat);
            LocalStorage.setIdChatSelected(idChat);
        }
    };

    const getCurrentChat = () => {
        if (!chats) {
            return null;
        }

        const currentChat = chats.find(chat => chat.idChat === idSelectedChat);
        if (currentChat == null) {
            return _.head(getOrderedChats());
        }
        return currentChat;
    };

    const getOrderedChats = () => {
        if (!chats) {
            return [];
        }
        return _.orderBy(chats, (chat) => new Date(chat.updatedAt), ["desc"]);
    };

    const getEmptyChat = () => {
        const generateChatTitle = () => {
            const defaultTitle = "Chat";
            const usedTitles = chats.map(chat => chat.title);

            let index = 1;
            while (usedTitles.includes(`${defaultTitle} ${index}`)) {
                index++;
            }
            return `${defaultTitle} ${index}`;
        };

        return {
            idChat: uuidv4(),
            title: generateChatTitle(),
            messages: [],
            specialty: null,
            brandVoice: null,
            createdAt: new Date(),
            updatedAt: new Date()
        };
    };

    const handleChatCreate = () => {
        if (disabled) {
            console.log("Chat creation is disabled.");
            return;
        }

        const newChat = getEmptyChat();
        console.log("Creating new chat:", newChat);
        createChatFn(newChat);
        // Lógica para definir o chat em modo de edição foi movida para o onSuccess da mutação
    };

    const handleChatChange = (idChat) => {
        if (disabled) {
            console.log("Chat change is disabled.");
            return;
        }

        console.log(`Changing to chat: ${idChat}`);
        setIdSelectedChat(idChat);
        LocalStorage.setIdChatSelected(idChat);
    };

    const handleChatDelete = (idChat) => {
        if (disabled) {
            console.log("Chat delete is disabled.");
            return;
        }

        console.log(`Deleting chat: ${idChat}`);
        deleteChatFn(idChat);
    };

    const handleChatRename = (idChat, title) => {
        console.log(`Renaming chat ${idChat} to ${title}`);
        const chat = chats.find((c) => c.idChat === idChat);

        if (!chat) {
            console.error(`Chat with idChat=${idChat} not found.`);
            return Promise.reject(new Error('Chat not found'));
        }

        const updatedChat = { ...chat, title, updatedAt: new Date() };
        console.log("Renaming chat with updated data:", updatedChat);
        return updateChatFn(updatedChat); // Retornar a Promise
    };

    const handleSpecialtyChange = (idChat, specialty) => {
        console.log(`Changing specialty of chat ${idChat} to`, specialty);
        const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
        chat.specialty = specialty;
        queryClient.setQueryData(['chats'], (old) => {
            return old.map((c) => c.idChat === chat.idChat ? chat : c);
        });
        updateChatFn(chat);
    };

    const handleBrandVoiceChange = (idChat, brandVoice) => {
        console.log(`Changing brand voice of chat ${idChat} to`, brandVoice);
        const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
        chat.brandVoice = brandVoice;
        queryClient.setQueryData(['chats'], (old) => {
            return old.map((c) => c.idChat === chat.idChat ? chat : c);
        });
        updateChatFn(chat);
    };

    const handleMessageSent = (idChat) => {
        console.log(`Message sent in chat ${idChat}`);
        const chat = _.cloneDeep(chats.find((c) => c.idChat === idChat));
        chat.updatedAt = new Date();
        queryClient.setQueryData(['chats'], (old) => {
            return old.map((c) => c.idChat === chat.idChat ? chat : c);
        });
    };

    const renderChatHistory = () => {
        return (
            <>
                <ChatHistory
                    chats={getOrderedChats()}
                    selectedChatId={idSelectedChat}
                    onChatCreate={handleChatCreate}
                    onChatChange={handleChatChange}
                    onChatDelete={handleChatDelete}
                    onChatRename={handleChatRename}
                    isLoading={isLoading}
                    disabled={disabled || isAIWriting}
                    chatToEditId={chatToEditId}
                    onChatEditComplete={() => setChatToEditId(null)}
                    user={user}
                />
            </>
        );
    };

    const renderChatHistoryMobile = () => {
        return (
            <ChatHistoryMobile
                chats={getOrderedChats()}
                selectedChatId={idSelectedChat}
                onChatCreate={handleChatCreate}
                onChatChange={handleChatChange}
                onChatDelete={handleChatDelete}
                onChatRename={handleChatRename}
                disabled={disabled || isAIWriting}
                chatToEditId={chatToEditId}
                onChatEditComplete={() => setChatToEditId(null)}
                user={user}
            />
        );
    };

    // Defina a função de pré-visualização de artifacts
    const handleArtifactPreview = (artifact) => {
        console.log("Artifact preview requested in ChatContainerNew:", artifact);
        setSelectedArtifact(artifact);
    };

    return (
        <>
            <Layout>
                <Layout>
                    {isMobile && renderChatHistoryMobile()}
                    <Content>
                        <ChatOrchestrator
                            user={user}
                            chat={getCurrentChat()}
                            onSpecialtyChange={handleSpecialtyChange}
                            onBrandVoiceChange={handleBrandVoiceChange}
                            onAIWriting={(isWriting) => setIsAIWriting(isWriting)}
                            onMessageSent={handleMessageSent}
                            onArtifactPreview={handleArtifactPreview} // Passar a função aqui
                        />
                    </Content>
                    {!isMobile && renderChatHistory()}
                </Layout>
                {selectedArtifact && (
                    <ArtifactViewer
                        visible={!!selectedArtifact}
                        codeContent={selectedArtifact.content || ""}
                        codeLanguage={selectedArtifact.language || "javascript"}
                        onClose={() => {
                            console.log("Closing ArtifactViewer");
                            setSelectedArtifact(null);
                        }}
                    />
                )}
            </Layout>
        </>
    );
};

// Definir PropTypes para ChatContainerNew
ChatContainerNew.propTypes = {
    setHeaderTitle: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default ChatContainerNew;
