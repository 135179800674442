import {Button, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import "./CreatorToolFieldsTab.css";
import _ from "lodash";
import CreatorToolEditField from "./field/CreatorToolEditField";
import CreatorToolFieldsList from "./field/CreatorToolFieldsList";
import {v4 as uuidv4} from "uuid";

const CreatorToolFieldsTab = ({tool, onUpdate}) => {
    const [fields, setFields] = useState(_.get(tool, "fields", []));
    const [selectedField, setSelectedField] = useState(_.get(tool, "fields[0]", null));

    useEffect(() => {
        onUpdate(fields);
    }, [fields]);

    const handleNewField = () => {
        const newField = getEmptyField();
        setFields(prevState => [...prevState, newField]);
        setSelectedField(newField);
    }

    const handleRemoveField = (idField) => {
        setFields(prevState => prevState.filter(field => field.idField !== idField));
        if (selectedField.idField === idField)
            setSelectedField(null);
    }

    const handleSelectField = (idField) => {
        setSelectedField(getField(idField));
    }

    const handleSaveField = (field) => {
        const fieldsCopy = _.cloneDeep(fields);
        const fieldIndex = fieldsCopy.findIndex(f => f.idField === field.idField);
        fieldsCopy[fieldIndex] = field;
        setFields(fieldsCopy);
    }

    const handleReorderFields = (reorderedFields) => {
        const fields = _.cloneDeep(reorderedFields);
        fields.forEach((field, index) => field.position = index);
        setFields(fields);
    }

    const getField = (idField) => {
        return fields.find(field => field.idField === idField);
    }

    const getEmptyField = () => {
        return {
            idField: uuidv4(),
            name: `Campo sem nome (${fields.length})`,
            position: fields.length
        }
    }

    const renderNewFieldButton = () => {
        return (
            <div id="CreatorToolFieldsTabAddButtonContainer">
                <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    size={"large"}
                    id="CreatorToolFieldsTabAddButton"
                    onClick={handleNewField}
                    block
                >
                    Adicionar
                </Button>
            </div>

        );
    }

    const renderFieldsListComponent = () => {
        return (
            <div className="CreatorToolFieldsTabContainer">
                {renderNewFieldButton()}
                <CreatorToolFieldsList
                    fields={fields}
                    selectedField={selectedField}
                    onReorder={handleReorderFields}
                    onRemove={handleRemoveField}
                    onSelect={handleSelectField}
                />
            </div>
        )
    }

    const renderEditFieldComponent = () => {
        return (
            <div className="CreatorToolFieldsTabFieldContainer">
                <CreatorToolEditField hasExample={_.get(tool, "introduction.hasExample", false)} fields={fields} field={selectedField} onSave={handleSaveField}/>
            </div>
        );
    }

    return (
        <Row style={{padding: 12}}>
            <Col span={8}>{renderFieldsListComponent()}</Col>
            <Col span={16}>{renderEditFieldComponent()}</Col>
        </Row>
    );
}

export default CreatorToolFieldsTab;