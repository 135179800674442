import React from "react";
import "./MainHeader.css";
import {Button} from "antd";
import {Header} from "antd/es/layout/layout";
import {faBell, faMoon} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logo from "../../images/logo.svg";
import {useNavigate} from "react-router-dom";
import UserDropdown from "./UserDropdown";
import {useSubscriptionService} from "../../utils/hooks/useSubscriptionService";
import _ from "lodash";

function MainHeader(props) {
    const navigate = useNavigate();
    const subscriptionService = useSubscriptionService();

    function renderUserButtonMobile() {
        const {user} = props;
        return (
            <div className="HeaderButton">
                <Button
                    shape="circle"
                    size="large"
                    type="link"
                    style={{background: `url(${user.picture}) no-repeat center center`, backgroundSize: "110%"}}
                />
            </div>

        )
    }

    function renderNotificationsButton() {
        return (
            <div className="HeaderButtonIcon">
                <FontAwesomeIcon icon={faBell}/>
            </div>
        )
    }

    function renderDarkModeButton() {
        return (
            <div className="HeaderButtonIcon">
                <FontAwesomeIcon icon={faMoon}/>
            </div>
        )
    }

    function renderLogo() {
        return (
            <img className="MainHeaderLogo" src={logo} alt="Logo do CMOs.ai"/>
        )
    }

    const renderBackButton = () => {
        const {renderBack} = props;
        if (renderBack) {
            return (
                <Button type="text" shape="circle" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Button>
            );
        }
    }

    const renderUserDropdown = () => {
        const {user, onSignOut} = props;
        return (
            <UserDropdown
                user={user}
                defaultPicture={props.defaultPicture}
                onSignOut={onSignOut}
            />
        );
    }

    const renderSubscriptionLabel = () => {
        const activeSubscription = subscriptionService.getUserActiveSubscription();
        if (activeSubscription) {
            const trialing = subscriptionService.isUserActiveSubscriptionOnTrial();

            return (
                <div className="MainHeaderSubscriptionLabelContainer">
                    <label className="MainHeaderSubscriptionLabel">
                        {_.get(activeSubscription, "subscriptionPlan.name", null)}
                    </label>
                    {trialing && <label className="MainHeaderSubscriptionLabelTrialBadge">
                        trial
                    </label>}
                </div>

            );
        }
    }


    return (
        <Header className="MainHeader">
            <div className="MainHeaderContainer">
                <div className="MainHeaderLeftContainer">
                    {props.headerTitle}
                </div>
                <div
                    className="MainHeaderRightContainer"
                >
                    {/*{renderDarkModeButton()}*/}
                    {/*{renderNotificationsButton()}*/}
                    {renderSubscriptionLabel()}
                    {renderUserDropdown()}
                </div>
            </div>
        </Header>
    )
}


export default MainHeader;