import "./PremiumButton.css";
import {Button} from "antd";
import classNames from "classnames";

const PremiumButton = ({ children, className, innerClasses, type = "primary", ...props }) => {
    return (
        <Button
            className={classNames(
                "bg-premium border-0 transition-opacity duration-150 flex items-center justify-center hover:opacity-80 disabled:opacity-50 disabled:text-white",
                className,
                innerClasses
            )}
            type={type}
            {...props}
        >
            {children}
        </Button>
    );
}

export default PremiumButton;