import {urlBase, params} from "./resources";

const url = urlBase + "/api/preferences";


async function pinTool(idTool) {
    const content = {body: {idTool}};
    return fetch(`${url}/pin-tool`, await params.put(content));
}

const UserPreferencesRemote = {
    pinTool,
};

export default UserPreferencesRemote;