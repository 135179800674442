import React from 'react';
import {Statistic, Tooltip} from "antd";
import {Coins} from "lucide-react";
import CountUp from "react-countup";
import useUser from "../../utils/hooks/useUser";
import moment from "moment/moment";
import {useWallet} from "../../utils/hooks/useWallet";
import Tippy from '@tippyjs/react'; // Importar Tippy
import 'tippy.js/dist/tippy.css'; // Importar estilos do Tippy

const CreditsCounter = () => {
    const {user, isLoading} = useUser();
    const {textCredits, nextRecharge} = useWallet();

    const renderCredits = () => {
        const formatter = (value) => <CountUp end={value} />;

        return (
            <Tippy
                content={`Seus créditos serão recarregados em ${moment(nextRecharge).format('DD/MM/YYYY')}`}
                placement="left"
            >
                <div> {/* Envolver o Statistic em um elemento de bloco */}
                    <Statistic
                        title="Créditos restantes"
                        value={textCredits}
                        prefix={<Coins size={18} />}
                        loading={!user || isLoading}
                        formatter={formatter}
                    />
                </div>
            </Tippy>
        );
    }

    return renderCredits();
};

export default CreditsCounter;