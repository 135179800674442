import React, {useState} from 'react';
import {List, Modal, Skeleton, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedhat} from "@fortawesome/free-brands-svg-icons";
import ContentCreatorSpecialtiesDropdownOptions from "./ContentCreatorSpecialtiesDropdownOptions";
import {CheckOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import {useDeleteSpecialty, usePublishSpecialty, useUnpublishSpecialty} from "../../../utils/hooks/useSpecialties";
import ContentCreatorSpecialtiesUpdateModal from "./ContentCreatorSpecialtiesUpdateModal";

const ContentCreatorSpecialtiesListItem = ({item}) => {
    const {deleteSpecialty} = useDeleteSpecialty();
    const {publishSpecialty} = usePublishSpecialty();
    const {unpublishSpecialty} = useUnpublishSpecialty();

    const [modal, contextHolder] = Modal.useModal();
    const [openSpecialtyUpdateModal, setOpenSpecialtyUpdateModal] = useState(false);

    const getStatusLabel = (published) => {
        return published ? 'Publicado' : 'Oculto';
    }

    const getStatusTagColor = (published) => {
        return published ? 'green' : 'default';
    }

    const getTagIcon = (published) => {
        return published ? <CheckOutlined /> : <EyeInvisibleOutlined />;
    }

    const renderDeleteConfirmModal = (specialty) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Excluir "{specialty.name}"
                    </div>
                    <div>
                        Tem certeza que deseja excluir esta especialidade?
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                deleteSpecialty(specialty.idSpecialty)
            },
        });
    }

    const renderPublishConfirmModal = (specialty) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Publicar "{specialty.name}"
                    </div>
                    <div className="mb-2 flex flex-col gap-2">
                        <div>Tem certeza que deseja publicar esta especialidade?</div>
                        <div>Após a publicação, esta especialidade ficará disponível para os usuários.</div>
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                publishSpecialty(specialty)
            },
        });
    }

    const renderUnpublishConfirmModal = (specialty) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Ocultar "{specialty.name}"
                    </div>
                    <div className="mb-2 flex flex-col gap-2">
                        <div>Tem certeza que deseja ocultar esta especialidade?</div>
                        <div>Após confirmar, esta especialidade deixará de estar disponível para todos os usuários.</div>
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                unpublishSpecialty(specialty)
            },
        });
    }

    return (
        <List.Item className="font-primary">
            <Skeleton avatar title={false} loading={item.loading} active>
                <div className="flex flex-row gap-4 flex-grow">
                    <div className="flex flex-row items-center gap-4">
                        <FontAwesomeIcon icon={faRedhat} size="lg" className="text-gray-400"/>
                        <div>
                            <div className="font-bold">{item.name}</div>
                            <div className="text-gray-400 text-sm">{item.description}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-2 items-center">
                    <Tag bordered color={getStatusTagColor(item.published)} icon={getTagIcon(item.published)}>
                        {getStatusLabel(item.published)}
                    </Tag>
                </div>


                <div className="ml-4">
                    <ContentCreatorSpecialtiesDropdownOptions
                        specialty={item}
                        onEdit={() => setOpenSpecialtyUpdateModal(true)}
                        onDelete={() => renderDeleteConfirmModal(item)}
                        onPublish={() => renderPublishConfirmModal(item)}
                        onUnpublish={() => renderUnpublishConfirmModal(item)}
                    />
                </div>
            </Skeleton>

            <ContentCreatorSpecialtiesUpdateModal
                specialty={item}
                open={openSpecialtyUpdateModal}
                onClose={() => setOpenSpecialtyUpdateModal(false)}
            />
            {contextHolder}
        </List.Item>
    );
};

export default ContentCreatorSpecialtiesListItem;