import React, {useState} from 'react';
import {Avatar, List, Modal, Popover, Skeleton} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons";
import BrandVoiceDropdownOptions from "./BrandVoiceDropdownOptions";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useDeleteBrandVoice} from "../../../utils/hooks/useBrandVoices";
import BrandVoiceUpdateModal from "./BrandVoiceUpdateModal";

const BrandVoiceListItem = ({item}) => {
    const {deleteBrandVoice} = useDeleteBrandVoice();
    const [modal, contextHolder] = Modal.useModal();

    const [openBrandVoiceUpdateModal, setOpenBrandVoiceUpdateModal] = useState(false);

    const renderDeleteConfirmModal = (brandVoice) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Excluir "{brandVoice.name}"
                    </div>
                    <div>
                        Tem certeza que deseja excluir esta voz?
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                deleteBrandVoice(brandVoice.idBrandVoice)
            },
        });
    }

    const renderVoicePopoverContent = () => {
        return (
            <div style={{maxWidth: "300px"}} className="font-primary text-gray-600">
                {item.voice}
            </div>
        );
    }

    return (
        <List.Item className="font-primary">
            <Skeleton avatar title={false} loading={item.loading} active>
                <div className="flex flex-row gap-4 flex-grow">
                    <div className="flex flex-row items-center gap-4">
                        <FontAwesomeIcon icon={faBullhorn} size="lg" className="text-gray-400"/>
                        <div>
                            <div className="font-bold">{item.name}</div>
                            <div className="text-gray-400 text-sm">{item.description}</div>
                        </div>
                    </div>
                    <Popover content={renderVoicePopoverContent()} placement="right">
                        <ExclamationCircleOutlined size={24} className="text-gray-400 text-lg hover:cursor-pointer"/>
                    </Popover>
                </div>

                {/*<div className="text-gray-400">*/}
                {/*    {new Date(item.createdAt).toLocaleString()}*/}
                {/*</div>*/}
                <div className="ml-4">
                    <BrandVoiceDropdownOptions
                        onEdit={() => setOpenBrandVoiceUpdateModal(true)}
                        onDelete={() => renderDeleteConfirmModal(item)}
                    />
                </div>
            </Skeleton>

            <BrandVoiceUpdateModal
                brandVoice={item}
                open={openBrandVoiceUpdateModal}
                onClose={() => setOpenBrandVoiceUpdateModal(false)}
            />
            {contextHolder}
        </List.Item>
    );
};

export default BrandVoiceListItem;