import {useContext, useEffect, useState} from "react";
import {PermissionGroupsContext} from "../providers/permissionGroupsProvider";
import {useSubscriptions} from "./useSubscriptions";

export const useCurrentSubscriptionPlans = () => {
    const [permissionGroups] = useContext(PermissionGroupsContext);
    const subscriptions = useSubscriptions();

    const [currentSubscriptionPlans, setCurrentSubscriptionPlans] = useState([]);

    useEffect(() => {
        if (subscriptions && permissionGroups) {
            const currentSubscriptionPlans = subscriptions.filter(subscription => permissionGroups.includes(subscription.permissionGroup));
            setCurrentSubscriptionPlans(currentSubscriptionPlans);
        }
    }, [permissionGroups, subscriptions]);

    return {currentSubscriptionPlans};
}