import {Modal} from "antd";
import ImageGalleryComponent from "../gallery/ImageGalleryComponent";
import {useContext} from "react";
import {UserContext} from "../../../../utils/providers/userProvider";

const ImageEditorGalleryModal = ({ isOpen, onClose, onSelect }) => {
    const {user} = useContext(UserContext);

    const renderModal = () => {
        return (
            <Modal
                open={isOpen}
                onCancel={onClose}
                centered
                width={"80%"}
                footer={null}
            >
                {renderModalContent()}
            </Modal>
        );
    }

    const renderModalContent = () => {
        return (
            <ImageGalleryComponent
                user={user}
                size={128}
                onSelect={onSelect}
                selectableItems={true}
            />
        )
    }

    return (
        <>
            {renderModal()}
        </>
    );
}

export default ImageEditorGalleryModal;