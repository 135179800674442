import DexieDatabase from "./db/DexieDatabase";

const db = DexieDatabase;

const put = async (tool) => {
    return await db.tools.add(tool);
}

const update = async (tool) => {
    return await db.tools.update(tool.idTool, tool);
}

const get = async (idTool) => {
    return await db.tools.get(idTool);
}

const remove = async (idTool) => {
    return await db.tools.delete(idTool);
}

const list = async () => {
    return await db.tools.toArray();
}

const clear = async () => {
    return await db.tools.clear();
}

const bulkPut = async (chats) => {
    return db.tools.bulkPut(chats);
}

export const ToolLocalDAO = {
    put,
    update,
    get,
    remove,
    list,
    clear,
    bulkPut
}