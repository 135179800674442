import React from 'react';
import {useToolsWithPrompt} from "../../../utils/hooks/useTools";
import {List} from "antd";
import ContentCreatorToolsListItem from "./ContentCreatorToolsListItem";
import _ from "lodash";

const ContentCreatorToolsList = ({filter}) => {
    const {tools, isLoading: isToolsLoading} = useToolsWithPrompt();

    const getFilteredTools = () => {
        if (!filter) {
            return tools;
        }
        return _.orderBy(tools, ["name", ["asc"]]).filter((t) => {
            return t?.name.toLowerCase().includes(filter.toLowerCase())
                || t?.description.toLowerCase().includes(filter.toLowerCase());
        });
    }

    const renderHeader = () => {
        return (
            <div className="px-4 font-sans">
                <div className="text-lg">
                    Agentes
                </div>
            </div>
        );
    }

    const renderItem = (item) => {
        return (
            <ContentCreatorToolsListItem item={item}/>
        );
    }

    return (
        <div className="my-4">
            <List
                loading={isToolsLoading}
                itemLayout="horizontal"
                header={renderHeader()}
                dataSource={getFilteredTools()}
                size="large"
                renderItem={renderItem}
                bordered
                pagination={{
                    defaultCurrent: 1,
                    total: getFilteredTools()?.length,
                    defaultPageSize: 7,
                }}
            />
        </div>
    );
};

export default ContentCreatorToolsList;