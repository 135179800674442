import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputNumber, Row, Select, Skeleton, Switch, Tooltip} from "antd";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import InputProgress from "../../utils/components/input-progress/InputProgress";
import InputAreaProgress from "../../utils/components/input-progress/InputAreaProgress";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {LinkOutlined} from "@ant-design/icons";
import "./ToolForm.css";
import {useWalletService} from "../../utils/hooks/useWalletService";
import BrandVoiceSelector from "../chat/BrandVoiceSelector";

const ToolForm = ({
                      tool,
                      isLoading,
                      isGenerating,
                      onGenerateResult,
                      onOpenDrawer,
                      onPlaceholderClick,
                      onValueChange,
                      outputs,
                      handleDecreaseOutput,
                      handleIncreaseOutput,
                      resultsLength,
                      isExampleRunning,
                      onBrandVoiceChange,
                      disableGeneration = false,
                      exampleValues = null,
                  }) => {

    const [values, setValues] = useState({});
    const [brandVoice, setBrandVoice] = useState(null);

    const walletService = useWalletService();

    useEffect(() => {
        if (!_.isEmpty(exampleValues)) {
            setValues(exampleValues);
        }
    }, [exampleValues]);

    const areAllRequiredFieldsFilled = () => {
        const requiredFields = tool.fields.filter(field => field.required === true);
        return requiredFields.every(field => values[field.token] !== undefined && values[field.token] !== null && values[field.token] !== "");
    }

    const handleFieldChange = (value, field) => {
        const clone = _.cloneDeep(values);
        clone[field.token] = value;
        setValues(clone);

        if (onValueChange) {
            onValueChange(value, field)
        }
    }

    const handleBrandVoiceChange = (brandVoice) => {
        setBrandVoice(brandVoice);
        onBrandVoiceChange(brandVoice);
    }

    const renderSkeleton = () => {
        return (
            <div>
                <Skeleton active/>
                <Skeleton active/>
                <Skeleton active/>
            </div>
        )
    }

    const renderTitleField = (field) => {
        const hasDescription = !_.isEmpty(field.description);
        return (
            <div className="ToolFormTitle">
                <label className="ToolTitleField">
                    {field.name}
                </label>
                {hasDescription && onOpenDrawer && <a
                    className="ToolTitleFieldExplanation"
                    onClick={() => onOpenDrawer(field)}
                ><FontAwesomeIcon icon={faCircleQuestion}/></a>}
                {onPlaceholderClick && renderPlaceholderSetButton(field.token)}
            </div>

        );
    }

    const renderInput = (field) => {
        switch (field.type) {
            case "TEXTFIELD":
                return renderTextInput(field);
            case "TEXTAREAFIELD":
                return renderTextAreaInput(field);
            case "INTEGERFIELD":
            case "NUMERICFIELD":
                return renderNumberInput(field);
            case "BOOLEANFIELD":
                return renderBooleanInput(field);
            case "SELECTFIELD":
                return renderSelectInput(field);
            default:
                return null;
        }
    }

    const renderTextInput = (field) => {
        return (
            <InputProgress
                size={"large"}
                disabled={isGenerating}
                placeholder={field.placeholder}
                onChange={(e) => handleFieldChange(e.target.value, field)}
                currentlength={_.get(values, `[${field.token}].length`, 0)}
                maxLength={field.maxLength}
                defaultValue={_.get(values, `[${field.token}]`, null)}
            />
        )
    }

    const renderTextAreaInput = (field) => {
        return (
            <InputAreaProgress
                size={"large"}
                disabled={isGenerating}
                autoSize={{minRows: 1, maxRows: 10}}
                placeholder={field.placeholder}
                onChange={(e) => handleFieldChange(e.target.value, field)}
                currentlength={_.get(values, `[${field.token}].length`, 0)}
                maxLength={field.maxLength}
                defaultValue={_.get(values, `[${field.token}]`, null)}
            />
        );
    }

    const renderNumberInput = (field) => {
        return (
            <InputNumber
                size={"large"}
                disabled={isGenerating}
                min={field.minValue}
                max={field.maxValue}
                step={field.step}
                onChange={(value) => handleFieldChange(value, field)}
                value={_.get(values, `[${field.token}]`, null)}
            />
        );
    }

    const renderBooleanInput = (field) => {
        return (
            <Switch
                disabled={isGenerating}
                onChange={(value) => handleFieldChange(value, field)}
                value={_.get(values, `[${field.token}]`, null)}
                style={{maxWidth: 42}}
            />
        )
    }

    const renderSelectInput = (field) => {
        const options = field.options.map((option) => {
            return {label: option, value: option}
        });

        const selectedValue = _.get(values, `[${field.token}]`, undefined);
        const currentRef = React.createRef();

        return (
            <Select
                size={"large"}
                disabled={isGenerating}
                ref={currentRef}
                allowClear
                showSearch
                mode={field.mode}
                options={options}
                placeholder={field.placeholder}
                value={selectedValue}
                onChange={(value) => {
                    currentRef.current.blur();
                    handleFieldChange(value, field);
                }}
            />
        );
    }

    const renderCustomFields = () => {
        if (_.isEmpty(tool.fields))
            return;

        return tool.fields.map((field) => {
            return (
                <div
                    key={field.token}
                    className="ToolFormComponents"
                >
                    {renderTitleField(field)}
                    {renderInput(field)}
                </div>
            );
        });
    }

    const renderGenerateSection = () => {
        const maxOutputs = _.get(tool, "maxOutputs", 5);

        return (
            <Row className="ToolGenerateSection">
                {maxOutputs > 1 && <Col>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                        <Button
                            size="small"
                            icon={<FontAwesomeIcon icon={faMinus}/>}
                            onClick={handleDecreaseOutput}
                            className="ToolGenerateButton"
                            type="primary"
                            shape="circle"
                            disabled={outputs === 1 || isGenerating || isExampleRunning || !areAllRequiredFieldsFilled()}
                        />
                    </div>

                </Col>}
                <Col flex="auto">
                    <Form.Item>
                        {renderGenerateButton()}
                    </Form.Item>

                </Col>
                {maxOutputs > 1 && <Col>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                        <Button
                            size="small"
                            icon={<FontAwesomeIcon icon={faPlus}/>}
                            onClick={handleIncreaseOutput}
                            className="ToolGenerateButton"
                            type="primary"
                            shape="circle"
                            disabled={outputs === maxOutputs || isGenerating || isExampleRunning || !areAllRequiredFieldsFilled()}
                        />
                    </div>
                </Col>}
            </Row>
        );
    }

    const renderGenerateButton = () => {
        const getTooltipMessage = () => {
            if (!walletService.hasBalance()) {
                return "Você não possui saldo suficiente. Por favor, faça um upgrade em seu plano";
            } else if (!areAllRequiredFieldsFilled()) {
                return "Há campos obrigatórios não preenchidos";
            } else {
                return null;
            }
        }

        return (
            <Tooltip title={getTooltipMessage()}>
                <Button
                    type="primary"
                    size="large"
                    block
                    htmlType="submit"
                    className="ToolGenerateButton"
                    loading={isGenerating}
                    disabled={isGenerating || !areAllRequiredFieldsFilled() || isExampleRunning || !walletService.hasBalance()}
                >
                    {!isGenerating && renderGenerateButtonLabel()}
                </Button>
            </Tooltip>
        );
    }

    const renderGenerateButtonLabel = () => {
        const [singular, plural, genre] = tool.artifact ? tool.artifact : ["resultado", "resultados", "M"];

        const prefix = resultsLength ? "Gerar" : "Gerar mais";
        const suffix = outputs === 1 ? singular : plural;
        const amount = genre === "F" ? "uma" : "um";

        if (tool.maxOutputs === 1) {
            if (resultsLength) {
                return (
                    <div>{prefix} <b>{suffix}</b></div>
                );
            } else {
                return (
                    <div>{prefix} <b>{amount} {suffix}</b></div>
                );
            }
        }

        return (
            <div>{prefix} <b>{outputs} {suffix}</b></div>
        );
    }

    const renderPlaceholderSetButton = (placeholder) => {
        return (
            <Button
                className="ToolFormExportFieldToken"
                type="text"
                size="small"
                shape={"circle"}
                onClick={() => onPlaceholderClick(placeholder)}
            >
                <LinkOutlined/>
            </Button>
        );
    }

    const render = () => {
        if (isLoading || _.isEmpty(tool)) {
            return (
                <div className="ToolFormContainer">
                    <div className="ToolForm">
                        {renderSkeleton()}
                    </div>
                </div>
            );
        }

        return (
            <Form
                layout="vertical"
                className="ToolFormContainer"
                onFinish={() => onGenerateResult(values, false)}
            >
                <div className="ToolForm">
                    {renderCustomFields()}
                </div>

                {!disableGeneration && <div className="ToolGenerateSection">

                    <div className="ml-4 flex">
                        <BrandVoiceSelector
                            brandVoice={brandVoice}
                            onChange={handleBrandVoiceChange}
                        />
                    </div>
                    {renderGenerateSection()}
                </div>}
            </Form>
        );
    }

    return render();
}

export default ToolForm;