import {Menu} from "antd";
import useIsMobile from "../../utils/hooks/useIsMobile";
import "./Submenu.css";
import Sider from "antd/es/layout/Sider";
import {useScreenSize} from "../../utils/hooks/useScreenSize";

const Submenu = ({items, selectedKeys, onClick, footer}) => {
    const screenSize = useScreenSize();

    const renderDesktopMenu = () => {
        return (
            <Sider theme="light" width={180} style={{height: "100%"}}>
                <div className={"SettingsSideMenu"}>
                    <Menu
                        mode={"inline"}
                        items={items}
                        selectedKeys={selectedKeys}
                        onClick={onClick}
                    />
                </div>
                <div className="SettingsSideMenuFooter">
                    {footer}
                </div>
            </Sider>
        );
    }

    const renderMobileMenu = () => {
        return (
            <Menu
                mode="horizontal"
                items={items}
                selectedKeys={selectedKeys}
                onClick={onClick}
            />
        );
    }

    if (screenSize === "xs" || screenSize === "sm" || screenSize === "md" || screenSize === "lg") {
        return renderMobileMenu();
    } else {
        return renderDesktopMenu();
    }
}

export default Submenu;