// src/utils/providers/userProvider.js
import { createContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import UserRemote from "../../services/dao/remote/user";
import { Auth } from 'aws-amplify';

export const UserContext = createContext([]);

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [token, setToken] = useState(null); // Estado para armazenar o token

    const query = useQuery({
        queryKey: ['user'],
        queryFn: () => UserRemote.get(),
    });

    useEffect(() => {
        const fetchUserAndToken = async () => {
            if (query.data) {
                setUser(query.data);
                try {
                    const session = await Auth.currentSession();
                    const accessToken = session.getAccessToken().getJwtToken();
                    setToken(accessToken);
                } catch (error) {
                    console.error("Erro ao obter token:", error);
                }
            }
        };

        fetchUserAndToken();
    }, [query.data]);

    const refetch = async () => {
        setLoading(true);
        try {
            const response = await query.refetch();
            if (response.data) {
                setUser(response.data);
                const session = await Auth.currentSession();
                const accessToken = session.getAccessToken().getJwtToken();
                setToken(accessToken);
            }
        } catch (error) {
            console.error("Erro ao refetch user:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <UserContext.Provider value={{ user, token, setUser, isLoading, refetch: () => refetch() }}>
            {children}
        </UserContext.Provider>
    );
};