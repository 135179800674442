import "./CreatorToolPromptTab.css";
import {Button, Input} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import _ from "lodash";

const CreatorToolPromptTab = ({tool, onUpdate}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [prompt, setPrompt] = useState(_.get(tool, 'prompt', ''));

    useEffect(() => {
        if (location?.state?.prompt) {
            setPrompt(location.state.prompt);
        }
    })

    useEffect(() => {
        onUpdate(prompt);
    }, [prompt]);

    const handleOpenPlayground = () => {
        const placeholders = tool.fields.map(field => field.token);

        if (location.pathname !== `/playground`) {
            navigate("/playground", {state: {placeholders, tool}})
        }
    }

    const renderPromptInput = () => {
        return (
            <Input.TextArea
                size={"large"}
                placeholder={"Conte-me uma história."}
                onChange={(e) => setPrompt(e.target.value)}
                value={prompt}
                rows={10}
                autoSize={{minRows: 10, maxRows: 10}}
                showCount
            />
        );
    }

    const renderPlaygroundButton = () => {
        return (
            <div className="CreatorToolPromptTabPlaygroundButton">
                <Button
                    type="default"
                    onClick={handleOpenPlayground}
                >
                    Abrir Playground
                </Button>
            </div>
        );
    }

    const renderPromptLabel = () => {
        return (
            <div className="CreatorToolPromptTabPromptLabel">
                Prompt
            </div>
        );
    }

    return (
        <div className="CreatorToolPromptTab">
            {renderPromptLabel()}
            {renderPromptInput()}
            {renderPlaygroundButton()}
        </div>
    );
}

export default CreatorToolPromptTab;