import React from 'react';
import TextArea from "antd/lib/input/TextArea";
import "./PromptInput.css";
import _ from "lodash";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import { Switch } from 'antd';

const PromptInput = ({
  prompt,
  placeholder,
  isLoading,
  generateButton = true,
  onGenerate,
  onChange,
  isPublic,
  onTogglePublic,
}) => {
  const renderGenerateButton = () => {
    return (
      <PremiumButton
        className="PromptGenerateButton"
        size="large"
        disabled={_.isEmpty(prompt) || isLoading}
        loading={isLoading}
        onClick={() => onGenerate()}
      >
        Gerar
      </PremiumButton>
    );
  }

  const renderInput = () => {
    return (
      <TextArea
        className="PromptInput"
        size="large"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        variant="unstyled" // Substituição de 'bordered' por 'variant'
        autoSize={{ minRows: 2, maxRows: 6 }}
        showCount={true}
        maxLength={500}
        value={prompt}
        disabled={isLoading}
      />
    );
  }

  const renderIsPublicSwitch = () => {
    return (
      <div className="PublicSwitchContainer">
        <Switch checked={isPublic} onChange={onTogglePublic} />
        <span className="PublicSwitchLabel">Adicionar à galeria da comunidade</span>
      </div>
    );
  }

  return (
    <div className="PromptInputWrapper">
      <div className="PromptInputContainer">
        {renderInput()}
        <div className="PromptInputFooter">
          {renderIsPublicSwitch()}
          {generateButton && renderGenerateButton()}
        </div>
      </div>
    </div>
  );
}

export default PromptInput;