// src/services/dao/remote/completion.js

import { params, urlBase } from "./resources";

const url = urlBase + "/api/completion";

// Função 'get' original
async function get(data) {
    const content = { body: data };
    return fetch(url, await params.post(content))
        .then(response => {
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            push();
                        });
                    }

                    push();
                }
            });
        });
}

// Função 'getReader' original
async function getReader(data) {
    const content = { body: data };
    const stream = await fetch(url, await params.post(content));

    if (!stream.ok) {
        throw new Error(`HTTP error! status: ${stream.status}`);
    }

    return stream.body.getReader();
}

// Função 'enhanceStream' (antiga função 'enhance' que retorna um Reader)
async function enhanceStream(data) {
    const content = { body: data };
    const stream = await fetch(`${url}/enhance`, await params.post(content));

    if (!stream.ok) {
        throw new Error(`HTTP error! status: ${stream.status}`);
    }

    return stream.body.getReader();
}

// Função 'enhancePrompt' (nova função 'enhance' que retorna um prompt aprimorado)
async function enhancePrompt({ prompt, token }) {
    const content = { body: { prompt } };

    // Configurar os parâmetros da requisição, incluindo o token nos cabeçalhos
    const requestParams = await params.post(content);
    requestParams.headers['Authorization'] = `Bearer ${token}`;

    const response = await fetch(`${url}/enhance`, requestParams);

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Fazer o parsing da resposta JSON e retornar o prompt aprimorado
    const result = await response.json();
    return result.enhanced_prompt;
}

// Objeto 'CompletionRemote' exportado com todas as funções
const CompletionRemote = {
    get,
    getReader,
    enhanceStream,
    enhancePrompt
};

export default CompletionRemote;
