import {Avatar} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import "./ChatHistoryItemMobile.css";
import {useEffect, useState} from "react";

let isLongPress = false;

const ChatHistoryItemMobile = ({idChat, title, isSelected, onChatChange, onLongPress}) => {

    const [longPressTimer, setLongPressTimer] = useState(null);

    useEffect(() => {
        return () => {
            clearTimeout(longPressTimer);
        }
    }, [longPressTimer]);

    const handleClick = () => {
        if (isLongPress) {
            return;
        }

        if (isSelected) {
            onLongPress(idChat);
        } else {
            onChatChange(idChat);
        }
    }

    const handleChatLongPressStart = (event) => {
        setLongPressTimer(
            setTimeout(() => {
                handleChatLongPress();
            }, 500)
        );
    }

    const handleChatLongPressEnd = (event) => {
        clearTimeout(longPressTimer);
        setTimeout(() => {
            isLongPress = false;
        }, 500);
    }

    const handleChatLongPress = () => {
        isLongPress = true;
        onLongPress(idChat);
    }

    return (
        <div
            id={idChat}
            key={idChat}
            className="ChatHistoryMobileItem"
            onClick={handleClick}
            onTouchStart={handleChatLongPressStart}
            onTouchEnd={handleChatLongPressEnd}
        >
            <Avatar
                size={64}
                icon={<FontAwesomeIcon icon={faComment}/>}
                className={`${isSelected ? "bg-premium": "bg-gray-400"}`}
            />
            <label className="ChatHistoryMobileItemLabel">{title}</label>
        </div>
    );
}

export default ChatHistoryItemMobile;