import {Modal} from "antd";
import PremiumButton from "../buttons/PremiumButton";
import "./PastDueSubscriptionModal.css";
import {useStripeService} from "../../utils/hooks/useStripeService";

const PastDueSubscriptionModal = ({open, onClose}) => {
    const stripeService = useStripeService();

    const handleActionButtonClick = async () => {
        await stripeService.openCustomerPortalWindow();
    }

    const renderTitle = () => {
        return (
            <label className="PastDueSubscriptionModalTitle">Sua conta possui faturas pendentes</label>
        );
    }

    const renderMessage = () => {
        return (
            <div className="PastDueSubscriptionModalContent">
                <label className="PastDueSubscriptionModalSubtitle">
                    Para continuar utilizando o sistema, por favor, acesse a página de feturamento e revise a forma de pagamento.
                </label>
            </div>

        );
    }

    const renderActionButton = () => {
        return (
            <PremiumButton
                size={"large"}
                block
                onClick={handleActionButtonClick}
            >
                Atualizar forma de pagamento
            </PremiumButton>
        );
    }

    return (
        <Modal
            open={open}
            centered
            footer={null}
            onCancel={null}
            closable={false}
            title={renderTitle()}
        >
            {renderMessage()}
            {renderActionButton()}
        </Modal>
    );
}

export default PastDueSubscriptionModal;