import {urlBase, params, resolve} from './resources';
const url = urlBase + "/api/workspaces";

const getAll = async () => {
    return fetch(`${url}`, await params.get())
        .then(response => response.json());
}

const update = async (workspace) => {
    const content = { body: workspace };
    return fetch(`${url}/${workspace.idWorkspace}`, await params.put(content))
        .then(response => response.json());
}

const join = async (code) => {
    return fetch(`${url}/join/${code}`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

const removeMember = async ({idWorkspace, idUser}) => {
    return fetch(`${url}/${idWorkspace}/remove/${idUser}`, await params.put({}))
        .then(response => response.json())
        .then(resolve);
}

const WorkspaceDAORemote = {
    getAll,
    update,
    join,
    removeMember
};

export default WorkspaceDAORemote;