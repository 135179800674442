import {
    faEraser,
    faImage,
    faTextSlash,
    faUpRightAndDownLeftFromCenter,
    faWandMagicSparkles,
    faPenRuler
} from "@fortawesome/free-solid-svg-icons";


export const ImageEditorMode = {
    REIMAGINE: {
        key: 'reimagine',
        title: 'Reimaginar imagem',
        icon: faWandMagicSparkles,
        hasPrompt: false,
    },
    SKETCH_TO_IMAGE: {
        key: 'text-to-sketch',
        title: 'Ilustre seus rascunhos',
        icon: faPenRuler,
        hasPrompt: true,
        promptTitle: 'Enter text',
    },
    REMOVE_BG: {
        key: 'remove-bg',
        title: 'Remover background',
        icon: faEraser,
        hasPrompt: false,
    },
    REMOVE_TEXT: {
        key: 'remove-text',
        title: 'Remover elementos textuais',
        icon: faTextSlash,
        hasPrompt: false,
    },
    REPLACE_BG: {
        key: 'reimagine-bg',
        title: 'Reimaginar background',
        icon: faImage,
        hasPrompt: true,
        promptTitle: 'Descreva o background perfeito para sua imagem',
        promptPlaceholder: 'Um céu azul com nuvens brancas, montanhas ao fundo e um lago cristalino',
    },
    UPSCALING: {
        key: 'upscaling',
        title: 'Aumentar resolução',
        icon: faUpRightAndDownLeftFromCenter,
        hasPrompt: false,
    }
}