import WorkspaceDAORemote from "../../services/dao/remote/WorkspaceDAORemote";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useCurrentWorkspace} from "./useCurrentWorkspace";
import {message} from "antd";
import {useEffect} from "react";

export const useWorkspaces = () => {
    const {currentWorkspace, setCurrentWorkspace} = useCurrentWorkspace();

    const {data: workspaces, isLoading} = useQuery({
        queryKey: ["workspaces"],
        queryFn: WorkspaceDAORemote.getAll
    });

    useEffect(() => {
        if (workspaces && workspaces.length > 0) {
            setCurrentWorkspace(workspaces.filter(w => w.idWorkspace === currentWorkspace?.idWorkspace)[0]);
        }
    }, [workspaces, currentWorkspace, setCurrentWorkspace]);

    return {workspaces, isLoading};
}

export const useUpdateWorkspace = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();
    const {setCurrentWorkspace} = useCurrentWorkspace();

    const {mutate: updateWorkspace, isLoading: isUpdating} = useMutation({
        mutationFn: WorkspaceDAORemote.update,
        onSuccess: (workspace) => {
            queryClient.setQueryData(["workspaces"], (workspaces) => {
                return workspaces.map((w) => {
                    if (w.idWorkspace === workspace.idWorkspace) {
                        return workspace;
                    }
                    return w;
                });
            });
            setCurrentWorkspace(workspace);
            onSuccess?.();
        },
        onError: (error) => {
            onError?.(error);
        }
    });

    return {updateWorkspace, isUpdating};
}

export const useJoinWorkspace = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();
    const {setCurrentWorkspace} = useCurrentWorkspace();

    const {mutate: joinWorkspace, isLoading: isJoining} = useMutation({
        mutationFn: (code) => WorkspaceDAORemote.join(code),
        onSuccess: (workspace) => {
            queryClient.setQueryData(["workspaces"], (workspaces) => {
                return [...workspaces, workspace];
            });
            setCurrentWorkspace(workspace);
            onSuccess?.();
        },
        onError: (error) => {
            message.error(error);
            onError?.(error);
        }
    });

    return {joinWorkspace, isJoining};
}

export const useRemoveMemberFromWorkspace = ({onSuccess, onError} = {}) => {
    const queryClient = useQueryClient();
    const {setCurrentWorkspace} = useCurrentWorkspace();

    const {mutate: removeMemberFromWorkspace, isLoading: isRemoving} = useMutation({
        mutationFn: ({idWorkspace, idUser}) => WorkspaceDAORemote.removeMember({idWorkspace, idUser}),
        onSuccess: (workspace) => {
            queryClient.setQueryData(["workspaces"], (workspaces) => {
                return workspaces.map((w) => {
                    if (w.idWorkspace === workspace.idWorkspace) {
                        return workspace;
                    }
                    return w;
                });
            });
            setCurrentWorkspace(workspace);
            message.success(`Usuário removido do workspace`);
            onSuccess?.();
        },
        onError: (error) => {
            message.error(error);
            onError?.(error);
        }
    });

    return {removeMemberFromWorkspace, isRemoving};
}