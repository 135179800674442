import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";
import SkipTrialConfirmModal from "./SkipTrialConfirmationModal";

const SkipTrialButton = ({idSubscription}) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div>
            <Button
                className="rounded-full"
                type="primary"
                block
                size="large"
                icon={<FontAwesomeIcon icon={faRocket} className="mr-4"/>}
                onClick={() => setOpenModal(true)}
            >
                Pular período de testes
            </Button>

            <SkipTrialConfirmModal
                idSubscription={idSubscription}
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
            />
        </div>
    );
};

export default SkipTrialButton;