import {Modal, Tour} from "antd";
import React, {useRef, useState} from "react";
import _ from "lodash";

const OnboardingModal = (props) => {
    const {open, onClose, ...refs} = props;

    const [showModal, setShowModal] = useState(true);
    const [showTour, setShowTour] = useState(false);
    const currentStep = useRef(0);

    const getTarget = (label) => {
        return _.get(refs, `${label}.current`, null);
    }

    const handleClose = () => {
        setShowTour(false);
        onClose({
            currentStep: currentStep.current,
            completed: currentStep.current === steps.length,
        });
    }

    const steps = [
        {
            title: 'Chat',
            description: (
                <div>
                    <p>O chat é a área perfeita para você fazer perguntas, iniciar discussões, elaborar planejamentos e muito mais!</p>
                    <p>Nossa inteligência artificial está sempre pronta para fornecer insights valiosos, com base em todo o conhecimento adquirido pela nossa comunidade de especialistas de marketing, The CMOs Marketers!</p>
                </div>
            ),
            placement: 'left',
            nextButtonProps: {
                children: (["Próximo"])
            },
            target: () => getTarget('chatContainerRef'),
        },
        {
            title: 'Especialistas',
            description: (
                <div>
                    <p>Apresentamos a você uma enorme seleção de especialistas, cada qual com sua área de atuação dentro do marketing e que ajudarão você a resolver os problemas mais difíceis.</p>
                    <p>A escolha do especialista afeta o modo e o escopo das respostas geradas pela nossa inteligência artificial. Ela utiliza o escopo de conhecimento da especialidade para inferir o contexto e moldar a resposta de acordo com a sua preferência.</p>
                    <p>Experimente escolher o nosso CMO e explore todo conhecimento de um grande líder de marketing!</p>
                </div>
            ),
            prevButtonProps: {
                children: "Voltar"
            },
            nextButtonProps: {
                children: "Próximo"
            },
            placement: "top",
            target: () => getTarget('specialtyInputRef'),
        },
        {
            title: 'Faça uma pergunta!',
            description: (
                <div>
                    <p>Este é o lugar onde você vai fazer suas perguntas, procurar por soluções aos seus problemas ou então iniciar discussões. Explique o seu problema, peça por insights, desenvolva seus conteúdos, o céu é o limite!</p>
                    <p>Experimente iniciar com uma pergunta ao nosso especialista: Como montar campanhas para redes sociais assertivas e que tragam resultados?</p>
                </div>
            ),
            prevButtonProps: {
                children: "Voltar"
            },
            nextButtonProps: {
                children: "Próximo"
            },
            placement: "top",
            target: () => getTarget('chatInputRef'),
        },
        {
            title: 'Histórico de conversas',
            description: (
                <div>
                    <p>Encontrou diversos insights e não gostaria de perder todo o trabalho feito? Não se preocupe, pois todas as suas conversas estarão salvas e você poderá acessá-las quando quiser, por aqui!</p>
                    <p>Organize as suas conversas por temas, categorias ou projetos. Você pode renomeá-las como preferir, ou então excluí-las. Lembre-se de manter o ambiente limpo e organizado, para facilitar o seu trabalho.</p>
                </div>
            ),
            placement: 'right',
            prevButtonProps: {
                children: "Voltar"
            },
            nextButtonProps: {
                children: "Próximo"
            },
            target: () => getTarget('chatHistoryRef'),
        },
        {
            title: 'Nossa introdução chegou ao fim!',
            description: (
                <div>
                    <p>Esperamos que você tenha gostado desta pequena introdução e incentivamos que você continue explorando todo o potencial que o CMOs.ai tem a oferecer.</p>
                    <p>Caso você tenha dúvidas e/ou feedbacks, não hesite em nos contatar. Estaremos esperando por você!</p>
                    <p style={{textAlign: "center"}}>gustavo@thecmosmarketers.digital</p>
                    <p>Um abraço!</p>
                    <p>
                        Gustavo Stork<br/>
                        CEO, The CMOs Marketers
                    </p>
                </div>
            ),
            placement: 'center',
            prevButtonProps: {
                children: "Voltar"
            },
            nextButtonProps: {
                children: "Finalizar"
            },
        }
    ]

    const renderModal = () => {
        return (
            <Modal
                open={open && showModal}
                title={"Boas-vindas ao CMOs.ai!"}
                centered
                onCancel={() => {
                    setShowModal(false);
                    handleClose();
                }}
                onOk={() => {
                    setShowModal(false);
                    setShowTour(true);
                }}
                okText={"Vamos lá!"}
                cancelText={"Agora não"}
            >
                <p>Estamos muito felizes que você tenha chegado até aqui!</p>
                <p>Gostaríamos de fazer uma breve introdução do <b>CMOs.ai</b> e apresentar as nossas principais funcionalidades.</p>
                <p>Ao final desta introdução, você estará apto a explorar todos os recursos da nossa plataforma e, com isso, será capaz de potencializar o seu trabalho e carreira na área de marketing!</p>
                <p>Vamos lá?</p>
            </Modal>
        );
    }

    const renderTour = () => {
        return (
            <Tour
                steps={steps}
                open={showTour}
                onClose={handleClose}
                type={"primary"}
                mask={false}
                onChange={(step) => currentStep.current = step + 1}
            />
        );
    }

    return (
        <>
            {renderModal()}
            {renderTour()}
        </>
    )
}

export default OnboardingModal;