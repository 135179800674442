import {Button, Dropdown} from "antd";
import "./ImageMenuOptions.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDownload,
    faTrash,
    faPaintBrush,
    faEllipsis
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const ImageMenuOptions = ({options, onEdit, onDownload, onRemove}) => {
    const renderMenuItem = (label, icon, onClick) => {
        return (
            <div className="ImageMenuOptionsItem" onClick={onClick}>
                <FontAwesomeIcon className="ImageMenuOptionsItemIcon" icon={icon}/>
                <div className="ImageMenuOptionsItemLabel">{label}</div>
            </div>
        )
    }

    const items = [
        {
            label: renderMenuItem('Baixar', faDownload, onDownload),
            key: 'download',
            placement: 'bottomLeft',
            // children: [
            //     {
            //         label: renderMenuItem('1024 x 1024', null, onDownload),
            //         key: 'download-1024',
            //         placement: 'bottomLeft',
            //     },
            //     {
            //         label: renderMenuItem('2048 x 2048', null, onDownload),
            //         key: 'download-2048',
            //         placement: 'bottomLeft',
            //     },
            //     {
            //         label: renderMenuItem('4096 x 4096', null, onDownload),
            //         key: 'download-4096',
            //         placement: 'bottomLeft',
            //     }
            // ]
        },
        {
            type: 'divider',
        },
        {
            label: renderMenuItem('Excluir', faTrash, onRemove),
            key: 'delete',
        }
    ].filter(item => {
        if (!_.isEmpty(options)) {
            return options.includes(item.key);
        }
        return true;
    })

    return (
        <Dropdown
            className="ImageMenuOptions"
            menu={{items}}
            placement={'bottomRight'}
        >
            <Button
                size="middle"
                type="default"
            >
                <FontAwesomeIcon icon={faEllipsis}/>
            </Button>
        </Dropdown>
    )
}

export default ImageMenuOptions;