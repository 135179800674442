import React, {useState} from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {message, Upload} from 'antd';
import axios from "axios";
import {getAuthorization, urlBase} from "../../services/dao/remote/resources";
import ImgCrop from 'antd-img-crop';

const {Dragger} = Upload;

const DragNDropUpload = ({height, onUpload}) => {
    const [progress, setProgress] = useState(0);
    const [fileList, setFileList] = useState([]);

    const getRequestHeaders = async () => {
        return {
            'Authorization': await getAuthorization(),
            'Content-Type': 'multipart/form-data'
        }
    }

    const getRequestConfig = async (onUploadProgress) => {
        return {
            headers: await getRequestHeaders(),
            onUploadProgress
        };
    }

    const customRequest = async (options) => {
        const {onSuccess, onError, file, onProgress} = options;

        const data = new FormData();
        data.append("image", file);

        const config = await getRequestConfig(event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({percent: (event.loaded / event.total) * 100});
        });

        try {
            const response = await axios.post(
                urlBase + "/api/image/upload",
                data,
                config
            );
            onSuccess(response.data);
        } catch (err) {
            onError({err});
        }
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleChange = ({file, fileList, event}) => {
        setFileList(fileList);

        if (file.status === 'done') {
            onUpload(file.response);
        }
    };

    return (
        <ImgCrop>
            <Dragger
                customRequest={customRequest}
                onChange={handleChange}
                onPreview={onPreview}
                listType="picture-card"
                fileList={fileList}
            >
                <div style={{height: height, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Clique ou solte um arquivo nesta área para fazer o upload</p>
                </div>
            </Dragger>
        </ImgCrop>
    );
}

export default DragNDropUpload;