import {useContext} from "react";
import {UserContext} from "../providers/userProvider";
import _ from "lodash";
import {useSubscriptions} from "./useSubscriptions";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import SubscriptionRemote from "../../services/dao/remote/SubscriptionRemoteDAO";
import {message} from "antd";

export const useSubscriptionService = () => {
    const {user} = useContext(UserContext);
    const subscriptions = useSubscriptions();
    const queryClient = useQueryClient();

    // mutations
    const skipSubscriptionTrialMutation = useMutation(SubscriptionRemote.skipSubscriptionTrial, {
        mutationFn: (idSubscription) => SubscriptionRemote.skipSubscriptionTrial(idSubscription),
        onSuccess: (data) => {
            queryClient.invalidateQueries(["user"]);
        },
        onError: (error) => {
            message.error(error);
        }
    });

    const hasUserActiveSubscriptions = () => {
        return !_.isEmpty(getUserActiveSubscriptions());
    }

    const isUserActiveSubscriptionOnTrial = () => {
        const trialEnd = _.get(getUserActiveSubscription(), "trialEnd", null);
        return trialEnd != null && new Date(trialEnd) > new Date();
    }

    const getSubscriptions = () => {
        return subscriptions;
    }

    const getUserActiveSubscriptions = () => {
        return _.get(user, "activeSubscriptions", []);
    }

    const getUserActiveSubscription = () => {
        return _.head(getUserActiveSubscriptions());
    }

    const getUserActiveSubscriptionPlan = () => {
        return _.get(getUserActiveSubscription(), "subscriptionPlan", {});
    }

    const doesSubscriptionHaveImageCreditsProvision = (subscription) => {
        return subscription.provisions.some(provision => provision.type === "ImageGenerationCredits");
    }

    const getNextUserActiveSubscriptionPlan = () => {
        const userActiveSubscriptionPlan = getUserActiveSubscriptionPlan();
        const nextPrecedenceSubscription = subscriptions.sort((a, b) => a.precedence - b.precedence).filter(
            subscription => {
                return subscription.precedence >= userActiveSubscriptionPlan.precedence + 1
                    && subscription.idOrganization === userActiveSubscriptionPlan.idOrganization
                    && subscription.status === "ACTIVE"
                    && doesSubscriptionHaveImageCreditsProvision(subscription);
            });

        if (_.isEmpty(nextPrecedenceSubscription)) {
            return {};
        }

        return _.head(nextPrecedenceSubscription)
    }

    const getUserActiveSubscriptionPlans = () => {
        return getUserActiveSubscriptions().map(subscription => subscription.subscriptionPlan);
    }

    const getUserAvailableGenerationModelClasses = () => {
        return getUserActiveSubscriptionPlans().map(subscriptionPlan => subscriptionPlan.generationModelClasses).flat();
    }

    return {
        isUserActiveSubscriptionOnTrial,
        hasUserActiveSubscriptions,
        getSubscriptions,
        getUserActiveSubscription,
        getUserActiveSubscriptionPlan,
        getUserActiveSubscriptions,
        getUserActiveSubscriptionPlans,
        getUserAvailableGenerationModelClasses,
        getNextUserActiveSubscriptionPlan,

        skipSubscriptionTrial: () => skipSubscriptionTrialMutation,
    }
}