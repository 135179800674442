import "./CreatorToolIntroTab.css";
import React, {useEffect, useState} from "react";
import {Editor} from "primereact/editor";
import _ from "lodash";
import {Col, InputNumber, Row, Select, Switch} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStopwatch} from "@fortawesome/free-solid-svg-icons";

const CreatorToolIntroTab = ({tool, onUpdate}) => {
    const [text, setText] = useState(_.get(tool, "introduction.text", ""));
    const [hasExample, setHasExample] = useState(_.get(tool, "introduction.hasExample", false));
    const [difficultyLevel, setDifficultyLevel] = useState(_.get(tool, "introduction.difficultyLevel", null));
    const [estimatedTime, setEstimatedTime] = useState(_.get(tool, "introduction.estimatedTime", 0));

    useEffect(() => {
        onUpdate({text, hasExample, difficultyLevel, estimatedTime});
    }, [text, hasExample, difficultyLevel, estimatedTime]);

    const renderIntroLabel = () => {
        return (
            <div className="CreatorToolIntroTabPromptLabel">
                Introdução da ferramenta
            </div>
        );
    }

    const renderIntroEditor = () => {
        return (
            <Editor
                value={text}
                onTextChange={(e) => setText(e.htmlValue)}
                style={{height: 500}}
            />
        );
    }

    const renderHasExampleSwitch = () => {
        return (
            <div className="CreatorToolFieldContainer">
                <label className="CreatorToolFieldLabel">
                    Possui exemplo?
                </label>
                <Switch
                    onChange={(value) => setHasExample(value)}
                    checked={hasExample}
                    style={{width: 42}}
                />
            </div>
        )
    }

    const renderToolLevelSelector = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Nível de dificuldade
                </label>
                <Select
                    size={"large"}
                    dropdownMatchSelectWidth={false}
                    options={[
                        {value: 'beginner', label: 'Iniciante'},
                        {value: 'intermediate', label: 'Intermediário'},
                        {value: 'advanced', label: 'Avançado'},
                    ]}
                    onChange={(value) => setDifficultyLevel(value)}
                    placeholder={"Selecione o nível de dificuldade da ferramenta"}
                    value={difficultyLevel}
                    showSearch
                />
            </div>
        );
    }

    const renderEstimatedTimeInput = () => {
        return (
            <div className="CreatorToolEditFieldWrapper">
                <label className="CreatorToolEditFieldLabel">
                    Tempo estimado (minutos)
                </label>
                <InputNumber
                    size={"large"}
                    placeholder={"Tempo estimado"}
                    onChange={(value) => setEstimatedTime(value)}
                    value={estimatedTime}
                    style={{width: "100%"}}
                    step={1}
                    addonBefore={<FontAwesomeIcon icon={faStopwatch}/> }
                    addonAfter={"minutos"}
                    min={1}
                />
            </div>
        );
    }

    return (
        <div className="CreatorToolIntroTab">
            <Row gutter={20}>
                <Col span={18}>
                    {renderIntroLabel()}
                    {renderIntroEditor()}
                </Col>
                <Col span={6}>
                    {renderHasExampleSwitch()}
                    {renderToolLevelSelector()}
                    {renderEstimatedTimeInput()}
                </Col>
            </Row>
        </div>
    );
}

export default CreatorToolIntroTab;