// App.js
import './App.css';
import React from 'react';
import { Amplify } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import { Login } from "./components/login/Login";
import { Home } from "./components/home/Home";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient } from "@tanstack/react-query";
import { ConfigProvider, theme } from "antd";
import { PermissionGroupsProvider } from "./utils/providers/permissionGroupsProvider";
import { OrganizationContextProvider } from "./utils/providers/organizationProvider";
import { UserContextProvider } from "./utils/providers/userProvider";
import moment from "moment-timezone";
import { SubscriptionModalStatusContextProvider } from "./utils/providers/SubscriptionModalStatusContextProvider";
import { datadogRum } from '@datadog/browser-rum';
import { CurrentWorkspaceProvider } from "./utils/hooks/useCurrentWorkspace";


const { version } = require('../package.json');

if (process.env.REACT_APP_ENV !== "localhost") {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'brainwave-frontend',
        env: process.env.REACT_APP_ENV,
        version: version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackFrustrations: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: [
            (url) => url.startsWith("https://backend.cmos.ai"),
            (url) => url.startsWith("https://backend-stage.cmos.ai"),
            (url) => url.startsWith("https://backend.dev.cmos.ai")
        ],
        traceSampleRate: 100,
    });

    datadogRum.startSessionReplayRecording();
}

moment.tz.setDefault("America/Sao_Paulo");

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] é o endereço IPv6 de localhost.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 é considerado localhost para IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const isDevEnvironment = Boolean(
    window.location.hostname === "dev.cmos.ai"
);

// Supondo que você tenha múltiplos URIs de redirecionamento
const [
    localRedirectSignIn,
    productionRedirectSignIn,
    devRedirectSignIn,
    stageRedirectSignIn,
] = awsExports.oauth.redirectSignIn.split(",");

const [
    localRedirectSignOut,
    productionRedirectSignOut,
    devRedirectSignOut,
    stageRedirectSignOut,
] = awsExports.oauth.redirectSignOut.split(",");

const getRedirectSignIn = () => {
    if (isLocalhost) {
        return localRedirectSignIn;
    }

    switch (process.env.REACT_APP_ENV) {
        case "prod":
            return productionRedirectSignIn;
        case "stage":
            return stageRedirectSignIn;
        case "dev":
        default:
            return devRedirectSignIn;
    }
}

const getRedirectSignOut = () => {
    if (isLocalhost) {
        return localRedirectSignOut;
    }

    switch (process.env.REACT_APP_ENV) {
        case "prod":
            return productionRedirectSignOut;
        case "stage":
            return stageRedirectSignOut;
        case "dev":
        default:
            return devRedirectSignOut;
    }
}

const updatedAwsConfig = {
    ...awsExports,
    configurationOptions: {
        signInConfig: {
            isSignUpDisplayed: false
        }
    },
    aws_cognito_social_providers: isDevEnvironment ? [] : ["GOOGLE"],
    oauth: {
        ...awsExports.oauth,
        domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
        redirectSignIn: getRedirectSignIn(),
        redirectSignOut: getRedirectSignOut(),
    }
}

Amplify.configure(updatedAwsConfig);

const queryClient = new QueryClient();

const colors = {
    "darkGrey": "#6F6F6F",
    "lightGrey": "#C9C9C9",
    "pink1": "#FF0D55",
    "pink2": "#E52B67",
    "orange": "#FF7A00",
    "purple": "#724CF9",
    "purple2": "#661aa1",

    "error": "#882424",

    "primary": "#724CF9",
    "primary-hover": "#9a78ff",
    "primary-active": "#5437d4",

    "primary-bg": "#f6f0ff",
    "primary-bg-hover": "#f5f0ff",
    "primary-bg-active": "#dac9ff",

    "primary-text": "#724cf9",
    "primary-text-hover": "#9a78ff",
    "primary-text-active": "#5437d4",

    "primary-border": "#e6c9ff",
    "primary-border-hover": "#bba1ff", // Corrigido o erro de digitação aqui

    "dark": "#1d1c1d",
    "dark-grey": "#6f6f6f",
    "light-grey": "#dadada",
}

const { defaultAlgorithm } = theme;

const defaultTheme = {
    algorithm: defaultAlgorithm,
    token: {
        colorPrimary: colors["primary"],
    },
    components: {
        Steps: {
            colorPrimary: colors["primary"],
            controlItemBgActive: colors["primary-bg-active"],
        },
        Input: {
            colorPrimary: colors["primary"],
            colorPrimaryActive: colors["primary-active"],
            colorPrimaryHover: colors["primary-hover"],
            fontFamily: "Outfit",
            paddingLG: 48,
            controlHeightLG: 48,
            controlPaddingHorizontal: 16,
            fontSizeLG: 14,
            colorError: colors["error"],
            colorErrorBorderHover: colors["error"],
        },
        TextArea: {
            colorPrimary: colors["primary"],
            colorPrimaryActive: colors["primary-active"],
            colorPrimaryHover: colors["primary-hover"],
            fontFamily: "Outfit",
            paddingLG: 48,
            controlHeightLG: 48,
            controlPaddingHorizontal: 16,
            fontSizeLG: 14,
            colorError: colors["error"],
            colorErrorBorderHover: colors["error"],
        },
        InputNumber: {
            colorPrimary: colors["primary"],
            colorPrimaryActive: colors["primary-active"],
            colorPrimaryHover: colors["primary-hover"],
            fontFamily: "Outfit",
            paddingLG: 48,
            controlHeightLG: 48,
            controlPaddingHorizontal: 16,
            fontSizeLG: 14
        },
        Select: {
            colorPrimary: colors["primary"],
            colorPrimaryHover: colors["primary-hover"],
            controlItemBgActive: colors["primary-bg-active"],
            controlItemBgHover: colors["primary-bg-hover"],
            colorFillSecondary: colors["primary-bg-hover"],
            paddingLG: 48,
            controlHeightLG: 48,
            fontSizeLG: 14
        },
        Button: {
            // colorPrimary: colors["purple"],
            // colorPrimaryActive: colors["pink2"],
            // colorPrimaryHover: colors["pink2"],
            // colorBgContainerDisabled: "#e6c9ff",
            // colorTextDisabled: "white",
            colorText: colors["primary-text-active"],
            colorBorder: "#beacff",
        },
        // FloatButton: {
        //     colorPrimary: colors["purple"],
        //     colorPrimaryActive: colors["pink2"],
        //     colorPrimaryHover: colors["pink2"],
        //     colorBgContainerDisabled: "#e6c9ff",
        // },
        Tour: {
            colorPrimary: colors["primary"],
            fontFamily: "Outfit",
            fontSize: 15,
        },
        Modal: {
            fontFamily: "Outfit"
        },
        Switch: {
            colorPrimary: colors["primary"],
            colorPrimaryBorder: colors["primary-border"],
            colorPrimaryHover: colors["primary-hover"],
        },
        Checkbox: {
            colorPrimary: colors["primary"],
            colorPrimaryBorder: colors["primary-border"],
            colorPrimaryHover: colors["primary-hover"],
        },
        Radio: {
            colorPrimary: colors["primary"],
            colorPrimaryActive: colors["primary-active"],
            colorPrimaryBorder: colors["primary-border"],
            colorPrimaryHover: colors["primary-hover"],
        },
        Slider: {
            colorPrimary: colors["primary"],
            colorPrimaryBorder: colors["primary-border"],
            colorPrimaryBorderHover: colors["primary-border-hover"],
        },
        Message: {
            colorBgElevated: colors["purple"],
            colorInfo: "#fff",
            colorText: "#fff",
        },
        MenuItem: {
            colorPrimary: colors["primary"],
            colorPrimaryBorder: colors["primary-border"],
            colorBgTextHover: colors["primary-text-hover"],
        },
        Statistic: {
            colorTextHeading: colors["dark"],
            fontFamily: "Outfit",
        }
    },

}

const App = () => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    return (
        <QueryClientProvider client={queryClient}>
            <ConfigProvider theme={defaultTheme}>
                {authStatus === 'authenticated' ? (
                    <PermissionGroupsProvider>
                        <UserContextProvider>
                            <OrganizationContextProvider>
                                <SubscriptionModalStatusContextProvider>
                                    <CurrentWorkspaceProvider>
                                        <Home />
                                    </CurrentWorkspaceProvider>
                                </SubscriptionModalStatusContextProvider>
                            </OrganizationContextProvider>
                        </UserContextProvider>
                    </PermissionGroupsProvider>
                ) : (
                    <Login isDevEnvironment={isDevEnvironment || isLocalhost} />
                )}
                <ReactQueryDevtools initialIsOpen={false} />
            </ConfigProvider>
        </QueryClientProvider>
    );
}

export default App;
