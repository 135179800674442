import React from 'react';
import {DeleteOutlined} from "@ant-design/icons";
import {Button, Dropdown} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "antd";

const {confirm} = Modal;

const WorkspaceTeamMemberDropdownOptions = ({item, onRemove, disabled}) => {
    const showDeleteConfirm = () => {
        confirm({
            title: <label className="font-primary">Remover membro</label>,
            content: <label className="font-primary">Tem certeza que deseja remover <b>{item.name}</b> do workspace?</label>,
            icon: <FontAwesomeIcon className="text-red-500 mr-2"  size="lg" icon={faWarning}/>,
            okText: 'Remover',
            cancelText: 'Cancelar',
            okType: 'danger',
            onOk() {
                onRemove();
            },
    })};

    const items = [
        {
            label: (
                <div className="flex flex-row items-center gap-2" onClick={showDeleteConfirm}>
                    <DeleteOutlined/>
                    Remover
                </div>
            ),
            key: "remove",
            danger: true,
            disabled: item.role === "OWNER"
        }
    ];

    return (
        <Dropdown
            menu={{items}}
            trigger={['click']}
            disabled={disabled}
        >
            <Button type="text" shape="circle">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </Button>
        </Dropdown>
    );
};

export default WorkspaceTeamMemberDropdownOptions;