import {
    Authenticator,
    Flex,
    Grid,
    Image,
    View,
    translations
} from "@aws-amplify/ui-react";
import {Header} from "./Header";
import {SignInFooter} from "./SignInFooter";
import {SignUpFooter} from "./SignUpFooter";
import {I18n} from 'aws-amplify';
import LogoWhite from "../../images/logo-white.svg";
import "./Login.css";
import {Home} from "../home/Home";

I18n.putVocabularies(translations);
I18n.setLanguage('pt');

I18n.putVocabularies({
    pt: {
        'Your passwords must match': 'As senhas devem ser iguais',
        'Password must have at least 8 characters': 'A senha deve ter pelo menos 8 caracteres',
        'Password must have numbers': 'A senha deve conter números',
        'Password must have special characters': 'A senha deve conter caracteres especiais',
        'Password must have upper case letters': 'A senha deve conter letras maiúsculas',
        'Password must have lower case letters': 'A senha deve conter letras minúsculas',
        'Please fill out this field': 'Por favor, preencha este campo',
        'Sign Up with Google': 'Cadastre-se com o Google',
        'Reset Password': 'Alterar a senha',
        'An account with the given email already exists.': 'Já existe uma conta com este e-mail',
        'Confirm': 'Confirmar',
        'Sign In with Google': 'Entrar com o Google',
        'Create Account': 'Cadastre-se',
        'We Emailed You': 'Enviamos um e-mail para você',
        'We Texted You': 'Enviamos um e-mail para você',
        'Incorrect username or password.': 'Usuário ou senha incorretos',
        'User does not exist.': 'Usuário ou senha incorretos',
        'Please confirm your Password': 'Por favor, confirme a sua senha',
        'Enter your Name': 'Digite o seu nome',
        'Name': 'Nome',
    },
});

const components = {
    Header,
    SignIn: {
        Footer: SignInFooter
    },
    SignUp: {
        Footer: SignUpFooter
    }
};

const formFields = {
    signIn: {
        username: {
            labelHidden: true,
            placeholder: "E-mail",
            isRequired: true,
        },
        password: {
            labelHidden: true,
            placeholder: "Senha",
            isRequired: true,
        }
    },
    signUp: {
        name: {
            labelHidden: true,
            placeholder: "Nome completo",
            isRequired: true,
            order: 1,
        },
        email: {
            labelHidden: true,
            placeholder: "E-mail",
            isRequired: true,
        },
        password: {
            labelHidden: true,
            placeholder: "Senha",
            isRequired: true,
        },
        confirm_password: {
            labelHidden: true,
            placeholder: 'Confirme a senha',
            isRequired: true,
        },

    },
    forceNewPassword: {
        password: {
            placeholder: 'Digite a sua senha',
        },
    },
    resetPassword: {
        username: {
            placeholder: 'Insira o seu email',
            isRequired: true,
            labelHidden: true,
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            placeholder: 'Digite o seu código de confirmação',
            isRequired: false,
        },
        confirm_password: {
            placeholder: 'Digite a sua senha, por favor',
        },
    },
    setupTOTP: {
        confirmation_code: {
            placeholder: 'Digite o seu código de confirmação',
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            placeholder: 'Digite o seu código de confirmação',
            isRequired: false,
        },
    },
}

export function Login({isDevEnvironment}) {
    return (
        <div className="Login">
            <Grid templateColumns={{base: "0 1fr", medium: "1fr 1fr"}}>
                <View height="100vh" className="LoginLogo">
                    <Image
                        className={"LoginLogoImage"}
                        src={LogoWhite}
                        height="120px"
                        objectFit="cover"
                    />
                </View>
                <Flex
                    justifyContent="center"
                    margin={"32px"}
                >
                    <Authenticator components={components} formFields={formFields} hideSignUp={false}
                                   style={{borderWidth: "0 !important"}}>
                        {() => (
                            <Home/>
                        )}
                    </Authenticator>
                </Flex>
            </Grid>
        </div>
    );
}