import {urlBase, getAuthorization} from "../../../../services/dao/remote/resources";
import {Upload, message, Progress} from "antd";
import {useEffect, useState} from "react";
import {UploadOutlined} from "@ant-design/icons";
import axios from "axios";

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

const CreatorToolUploadIcon = ({icon, onFinishUpload, onRemoveIcon}) => {
    const [defaultFileList, setDefaultFileList] = useState(icon ? [{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: `${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${icon}`,
    }] : []);

    useEffect(() => {
        setDefaultFileList(icon ? [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: `${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${icon}`,
        }] : []);
    }, [icon]);

    const [progress, setProgress] = useState(0);

    const getRequestHeaders = async () => {
        return {
            'Authorization': await getAuthorization(),
            'Content-Type': 'multipart/form-data'
        }
    }

    const getRequestConfig = async (onUploadProgress) => {
        return {
            headers: await getRequestHeaders(),
            onUploadProgress
        };
    }

    const customRequest = async (options) => {
        const {onSuccess, onError, file, onProgress} = options;

        const data = new FormData();
        data.append("image", file);

        const config = await getRequestConfig(event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({percent: (event.loaded / event.total) * 100});
        });

        try {
            const response = await axios.post(
                urlBase + "/api/creator/tool/icon",
                data,
                config
            );

            onSuccess({"url": response["data"]["url"]});
        } catch (err) {
            onError({err});
        }
    }

    const handleChange = ({file, fileList, event}) => {
        setDefaultFileList(fileList);

        if (file.status === 'done') {
            onFinishUpload(file.response.url);
        }
    };

    const handleRemove = async () => {
        const config = await getRequestConfig();
        axios.delete(
            urlBase + `/api/creator/tool/icon/${icon}`,
            config
        ).then(() => onRemoveIcon());
    }

    const renderUploadButton = () => {
        return (
            <div>
                {<UploadOutlined/>}
                <div style={{marginTop: 8}}>
                    Upload
                </div>
            </div>
        );
    }

    return (
        <>
            <Upload
                accept=".png"
                beforeUpload={beforeUpload}
                customRequest={customRequest}
                onChange={handleChange}
                listType="picture-card"
                defaultFileList={defaultFileList}
                onRemove={handleRemove}
            >
                {defaultFileList.length >= 1 ? null : renderUploadButton()}
            </Upload>
            {progress > 0 && <Progress percent={progress}/>}
        </>
    );
}

export default CreatorToolUploadIcon;