// src/components/chat/ChatRenderer.jsx

import React, { useEffect, useRef, useState, useCallback } from "react";
import "./ChatRenderer.css";
import TextInput from "../text-input/TextInput";
import Message from "../message/Message";
import CompletionRemote from "../../services/dao/remote/completion";
import {
  Drawer,
  Layout,
  Modal,
  Tooltip,
  notification,
  message,
  Dropdown,
  Avatar,
  Button,
} from "antd";
import { Specialties } from "../../api/specialties";
import _ from "lodash";
import { Content } from "antd/es/layout/layout";
import { QuestionCircleOutlined } from "@ant-design/icons";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useGenerationModelClass } from "../../utils/hooks/useGenerationModelClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PremiumButton from "../buttons/PremiumButton";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import SpecialtySelector from "./SpecialtySelector";
import Logo from "../../images/message.png";
import SuggestionBox from "./SuggestionBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MessageRemote } from "../../services/dao/remote/message";
import LoadingScreen from "../loading-screen/LoadingScreen";
import BrandVoiceSelector from "./BrandVoiceSelector";
import GenerationModelClassSelector from "../../app/generation-model-class/GenerationModelClassSelector";
import CreditsCounter from "../../app/generation-model-class/CreditsCounter";
import { useWallet } from "../../utils/hooks/useWallet";
import NoMoreTextCreditsModal from "../../app/generation-model-class/NoMoreTextCreditsModal";
import PropTypes from "prop-types";
import ArtifactViewer from "../Artifact/ArtifactViewer"; // Import ArtifactViewer

const ChatRenderer = ({
  idChat,
  user,
  specialty = null,
  brandVoice = null,
  onSpecialtyChange,
  onBrandVoiceChange,
  onAIWriting,
  onMessageSent,
  onArtifactPreview,
}) => {
  const [isAIWritingState, setAIWriting] = useState(false);
  const [showSpecialtyFeatureDrawer, setShowSpecialtyFeatureDrawer] = useState(false);
  const [showSpecialtyModalMobile, setShowSpecialtyModalMobile] = useState(false);
  const [userPicture, setUserPicture] = useState(null);
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState(null);

  const isMobile = useIsMobile();

  let autoScroll = useRef(true);
  const chatBox = useRef();
  const textInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const textInputContainerRef = useRef(null);

  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const messageDelayWarningTimeout = useRef(null);

  const { generationModelClass } = useGenerationModelClass();

  const [messages, setMessages] = useState([]);

  const { refetch, textCredits, isLoading: isWalletLoading } = useWallet();
  const [hideNoMoreTextCreditsModal, setHideNoMoreTextCreditsModal] = useState(false);

  useEffect(() => {
    console.log("idChat recebido pelo ChatRenderer:", idChat);
    handleScrollDown(true);
    const picture = getUserPicture();
    console.log("URL da imagem de perfil do usuário:", picture);
    setUserPicture(picture);
  }, [idChat, user]);

  const { mutateAsync: createMessageRemoteFn } = useMutation({
    mutationFn: (newMessage) => {
      console.log('Creating message:', newMessage);
      return MessageRemote.create(newMessage);
    },
  });

  const { isLoading, isFetched } = useQuery({
    queryKey: ['messages', idChat],
    queryFn: () => MessageRemote.list(idChat),
    refetchOnWindowFocus: false,
    onSuccess: (messages) => {
      console.log('Mensagens carregadas:', messages);
      
      // Converter timestamp para instância de Date
      const messagesWithDates = messages.map((msg) => ({
        ...msg,
        timestamp: new Date(msg.timestamp),
      }));

      setMessages(messagesWithDates);
      handleScrollDown(true);
    },
    onError: (error) => {
      console.error("Erro ao carregar mensagens:", error);
    }
  });

  const getUserPicture = () => {
    const defaultPicture = localStorage.getItem(`${user?.email}/defaultPicture`);
    if (user?.picture) {
      if (user.picture.startsWith("http")) {
        return user.picture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/256/${user.picture}`;
    } else if (defaultPicture) {
      return defaultPicture;
    }
    return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
  };

  const openMessageDelayWarning = () => {
    messageApi.open({
      key: "message-delay-warning-chat",
      type: "loading",
      content: "A resposta está demorando um pouco mais do que o esperado...",
      duration: 0,
    });
  };

  const openRequestErrorNotification = () => {
    notificationApi["error"]({
      message: "Erro ao processar mensagem",
      description: (
        <>
          <div style={{ padding: "5px 0" }}>
            Estamos com um <b>alto nível de utilização</b> no momento, o que está ocasionando <b>lentidão no sistema</b>. Nosso time já está atuando para resolver o problema.
          </div>
          <div style={{ padding: "6px 0" }}>
            Pedimos desculpas pelo inconveniente e agradecemos a sua compreensão.
          </div>
        </>
      ),
      duration: 60,
    });
  };

  const startMessageDelayWarningTimeout = () => {
    messageDelayWarningTimeout.current = setTimeout(() => {
      openMessageDelayWarning();
    }, 7500);
  };

  const stopMessageDelayWarningTimeout = () => {
    messageApi.destroy("message-delay-warning-chat");
    clearTimeout(messageDelayWarningTimeout.current);
  };

  const handleScrollDetector = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const atBottom = scrollTop + clientHeight >= scrollHeight;
    autoScroll.current = atBottom;
  };

  const handleScrollDown = (force = false) => {
    if (force) {
      autoScroll.current = true;
    }

    if (autoScroll.current && chatBox && chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  };

  const handleCreateUserMessage = async (message, callback = null) => {
    try {
      setMessages((old) => [...old, message]);
      setTimeout(() => {
        handleScrollDown(true);
      }, 500);
      handleScrollDown(true);
      onMessageSent(idChat);
      await createMessageRemoteFn(message);
      await handleOpenAIMessage(message.content);
    } catch (error) {
      console.error("Erro em handleCreateUserMessage:", error);
      // Você pode adicionar uma notificação de erro aqui, se desejar
    }
  };

  const handleCreateSystemMessage = (message) => {
    setMessages((old) => [...old, message]);
  };

  const handleUpdateOngoingSystemMessage = (message) => {
    setMessages((old) => [...old.slice(0, -1), message]);
  };

  const handleUpdateReadySystemMessage = (message) => {
    console.log('Saving message:', message);
    setMessages((old) => [...old.slice(0, -1), message]);
    createMessageRemoteFn(message).catch(error => {
      console.error("Erro ao salvar mensagem:", error);
    });
  };

  const displaySpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(true);
  };

  const hideSpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(false);
  };

  const getSpecialtyItems = () => {
    return Specialties.map((specialty) => {
      return {
        label: specialty.name,
        key: specialty.id,
      };
    });
  };

  const handleSpecialtyChangeLocal = (specialty) => {
    onSpecialtyChange(idChat, specialty);
  };

  const handleBrandVoiceChangeLocal = (brandVoice) => {
    onBrandVoiceChange(idChat, brandVoice);
  };

  const handleUserSendingMessage = async (content) => {
    try {
      const message = {
        idChat: idChat,
        content: content,
        role: "USER",
        specialty,
        brandVoice,
        timestamp: new Date(),
        onboard: false,
      };

      await handleCreateUserMessage(message);
    } catch (error) {
      console.error("Erro em handleUserSendingMessage:", error);
      // Você pode adicionar uma notificação de erro aqui, se desejar
    }
  };

  const handleOpenAIMessage = async (content) => {
    const message = {
      idChat: idChat,
      content: "",
      role: "SYSTEM",
      specialty,
      brandVoice,
      timestamp: new Date(),
      onboard: false,
    };

    setAIWriting(true);
    onAIWriting(true);
    startMessageDelayWarningTimeout();

    try {
      console.log("Chamando CompletionRemote.getReader com os parâmetros:", {
        idChat: message.idChat,
        content: content,
        specialty: message.specialty,
        brandVoice: message.brandVoice,
        generationModelClass: generationModelClass,
      });

      const reader = await CompletionRemote.getReader({
        idChat: message.idChat,
        content: content,
        specialty: message.specialty,
        brandVoice: message.brandVoice,
        generationModelClass: generationModelClass,
      });

      if (!reader) {
        throw new Error("Reader não foi retornado por CompletionRemote.getReader");
      }

      console.log("Reader obtido com sucesso:", reader);

      stopMessageDelayWarningTimeout();
      handleCreateSystemMessage(message);

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const text = new TextDecoder("utf-8").decode(value);
        message.content += text;
        message.timestamp = new Date();
        handleUpdateOngoingSystemMessage(message);
        handleScrollDown();
      }
    } catch (error) {
      console.error("Erro em handleOpenAIMessage:", error);
      openRequestErrorNotification();
    } finally {
      stopMessageDelayWarningTimeout();
    }

    handleUpdateReadySystemMessage(message);

    if (!isMobile) {
      if (textInputRef && textInputRef.current)
        textInputRef.current.delayedFocus();
    }
    setAIWriting(false);
    onAIWriting(false);
  };

  const memoizedHandleArtifactPreview = useCallback(
    (codeSnippet) => {
      console.log("Artifact preview requested:", codeSnippet);
      setSelectedCodeSnippet(codeSnippet);
      if (onArtifactPreview) {
        onArtifactPreview(codeSnippet);
      }
    },
    [onArtifactPreview]
  );

  const memoizedHandleStartTypingOnboardingMessage = useCallback(() => {
    setAIWriting(true);
  }, []);

  const memoizedHandleFinishTypingOnboardingMessage = useCallback(() => {
    setAIWriting(false);
  }, []);

  const memoizedHandleScrollDown = useCallback(() => {
    handleScrollDown();
  }, [handleScrollDown]);

  // Removido: extractArtifactFromResponse, já que não usamos mais 'artifact'

  const handleArtifactPreview = (codeSnippet) => {
    console.log("Artifact preview requested:", codeSnippet);
    setSelectedCodeSnippet(codeSnippet);
  };

  const renderArtifactViewer = () => {
    return (
      <>
        {selectedCodeSnippet && (
          <ArtifactViewer
            visible={!!selectedCodeSnippet}
            codeContent={selectedCodeSnippet.content || ""}
            codeLanguage={selectedCodeSnippet.language || "javascript"}
            onClose={() => {
              console.log("Closing ArtifactViewer");
              setSelectedCodeSnippet(null);
            }}
          />
        )}
      </>
    );
  };

  const systemUser = {
    id: 'system',           // Provide a unique ID for the system user
    name: 'CMOs.ai',        // Name to display for system messages
    picture: Logo,          // Use the Logo for the system avatar
  };

  const renderMessages = () => {
    if (_.isEmpty(messages) && !isLoading && isFetched) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {renderEmptyChat()}
        </div>
      );
    }
  
    return messages.map((message, index) => {
      const uniqueKey = message.idMessage
        ? `${message.idMessage}-${index}`
        : `message-${index}`;
  
      // Choose the appropriate user object
      const messageUser = message.role === "SYSTEM" ? systemUser : user;
      const messagePicture = message.role === "SYSTEM" ? systemUser.picture : userPicture;
  
      return (
        <Message
          key={uniqueKey}
          idChat={idChat}
          user={messageUser}          // Pass the correct user object
          picture={messagePicture}
          role={message.role}
          specialty={message.specialty}
          brandVoice={message.brandVoice}
          content={message.content}
          onboard={message.onboard}
          timestamp={message.timestamp}
          onArtifactPreview={memoizedHandleArtifactPreview}
          onStartTyping={memoizedHandleStartTypingOnboardingMessage}
          onFinishTyping={memoizedHandleFinishTypingOnboardingMessage}
          scrollDown={memoizedHandleScrollDown}
          isMobile={isMobile}
        />
      );
    });
  };

  const renderEmptyChat = () => {
    return (
      <div className="flex flex-col h-full">
        {renderEmptyChatWelcomeMessage()}
        {renderEmptyChatSuggestions()}
      </div>
    );
  };

  const renderEmptyChatWelcomeMessage = () => {
    return (
      <div className="flex justify-end items-center flex-col h-[60%]">
        <Avatar size={96} src={Logo} />
        <label className="ImageGeneratorExample w-full">
          Como posso te ajudar hoje?
        </label>
      </div>
    );
  };

  const renderEmptyChatSuggestions = () => {
    if (isMobile) {
      return (
        <div className="flex flex-col gap-2 justify-center my-6">
          <SuggestionBox
            text={"Escreva um blogpost sobre inteligência artificial aplicado ao marketing"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Dê 10 sugestões de publicações sobre o tema 'Inbound Marketing'"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Crie uma estratégia para aumentar o engajamento no Instagram"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Faça uma lista com 10 estratégias de marketing digital"}
            onClick={handleUserSendingMessage}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4 justify-center h-[40%]">
        <div className="flex gap-4">
          <SuggestionBox
            text={"Escreva um blogpost sobre inteligência artificial aplicado ao marketing"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Dê 10 sugestões de publicações sobre o tema 'Inbound Marketing'"}
            onClick={handleUserSendingMessage}
          />
        </div>
        <div className="flex gap-4">
          <SuggestionBox
            text={"Crie uma estratégia para aumentar o engajamento no Instagram"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Faça uma lista com 10 estratégias de marketing digital"}
            onClick={handleUserSendingMessage}
          />
        </div>
      </div>
    );
  };

  const renderSpecialtyFeatureDrawer = () => {
    return (
      <Drawer
        title="O que são as especialidades?"
        placement="right"
        onClose={hideSpecialtyFeatureDrawer}
        open={showSpecialtyFeatureDrawer}
        style={{ fontFamily: "Outfit" }}
        width={isMobile ? "80%" : 650}
      >
        <p>
          O recurso de especialidades permite que o usuário defina o tom e o escopo das respostas em seu chat,
          personalizando a experiência do usuário de acordo com a especialidade escolhida.
        </p>

        <p>
          Cada especialidade tem sua própria abordagem e conhecimento específico, proporcionando respostas mais
          precisas e relevantes para o usuário.
        </p>
      </Drawer>
    );
  };

  const renderSpecialtySelect = () => {
    const items = getSpecialtyItems();

    return (
      <div className="flex flex-row gap-2 justify-center items-center">
        <Dropdown
          type="primary"
          size="small"
          menu={{ items }}
          trigger={['click']}
          className="rounded-full"
          placement="topRight"
          arrow
          dropdownRender={(menu) => (
            <div className="max-h-[200px] overflow-auto">
              {menu}
            </div>
          )}
        >
          <PremiumButton innerClasses="rounded-full">
            <FontAwesomeIcon icon={faUserTie} className="text-white mr-2" />
            Especialidade
          </PremiumButton>
        </Dropdown>
      </div>
    );
  };

  const handleStartTypingOnboardingMessage = () => {
    setAIWriting(true);
  };

  const handleFinishTypingOnboardingMessage = () => {
    setAIWriting(false);
  };

  const renderChatInput = () => {
    if (isMobile) {
      return (
        <div className="flex flex-col w-[95%] gap-2 mb-2 mt-2">
          <div className="w-full flex flex-row items-center">
            <div className="flex flex-row flex-grow items-center justify-start gap-2">
              <BrandVoiceSelector
                brandVoice={brandVoice}
                onChange={handleBrandVoiceChangeLocal}
              />
            </div>

            <div className="flex justify-end">
              <div className="flex items-center content-center gap-2">
                <SpecialtySelector
                  idSpecialty={specialty?.idSpecialty}
                  onChange={handleSpecialtyChangeLocal}
                  isMobile={isMobile}
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <TextInput
              ref={textInputContainerRef}
              onSubmit={handleUserSendingMessage}
              disabled={isAIWritingState || (textCredits === 0 && !isWalletLoading)}
              isMobile={isMobile}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ChatInput flex flex-col w-[75%] mb-4 mt-2">
        <div className="flex flex-row items-center">
          <div className="flex flex-row flex-grow items-center justify-start gap-2">
            <BrandVoiceSelector
              brandVoice={brandVoice}
              onChange={handleBrandVoiceChangeLocal}
            />
          </div>

          <div className="flex justify-end">
            <div className="flex items-center content-center gap-2">
              <Tooltip title="Saiba mais sobre as especialidades!">
                <a onClick={displaySpecialtyFeatureDrawer}>
                  <QuestionCircleOutlined className="ChatInputSpecialtyIcon" />
                </a>
              </Tooltip>

              <SpecialtySelector
                idSpecialty={specialty?.idSpecialty}
                onChange={handleSpecialtyChangeLocal}
              />
            </div>
          </div>
        </div>

        <div style={{ width: "100%" }} ref={textInputContainerRef}>
          <TextInput
            ref={textInputContainerRef}
            onSubmit={handleUserSendingMessage}
            disabled={isAIWritingState || (textCredits === 0 && !isWalletLoading)}
          />
        </div>
      </div>
    );
  };

  const renderSpecialtyModalMobile = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <Modal
        open={showSpecialtyModalMobile}
        title="Escolha a especialidade"
        onOk={() => {}}
        onCancel={() => setShowSpecialtyModalMobile(false)}
        width={300}
        centered
        closable={false}
        footer={null}
      >
        {renderSpecialtySelect()}
      </Modal>
    );
  };

  return (
    <>
      {messageContextHolder}
      {notificationContextHolder}
      <div className="w-full flex flex-row items-center justify-between mx-4 mr-10 my-2">
        <GenerationModelClassSelector />
        <div className="mr-8">
          <CreditsCounter />
        </div>
      </div>
      <Layout className="Chat w-full" ref={chatContainerRef}>
        <Content
          className="ChatContainer"
          ref={chatBox}
          onScroll={handleScrollDetector}
        >
          {renderMessages()}
        </Content>
        {renderChatInput()}
        {renderSpecialtyFeatureDrawer()}
        {renderSpecialtyModalMobile()}
      </Layout>
      <NoMoreTextCreditsModal
        hide={hideNoMoreTextCreditsModal}
        onClose={() => setHideNoMoreTextCreditsModal(true)}
      />
      {renderArtifactViewer()} {/* Renderiza o ArtifactViewer */}
    </>
  );
};

// Definir PropTypes para ChatRenderer
ChatRenderer.propTypes = {
  idChat: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  specialty: PropTypes.object,
  brandVoice: PropTypes.object,
  onSpecialtyChange: PropTypes.func.isRequired,
  onBrandVoiceChange: PropTypes.func.isRequired,
  onAIWriting: PropTypes.func.isRequired,
  onMessageSent: PropTypes.func.isRequired,
  onArtifactPreview: PropTypes.func.isRequired, // Atualizado para onArtifactPreview
};

export default ChatRenderer;
