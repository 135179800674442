import React from 'react';
import {Button, Modal, Select, Tooltip, message, Popconfirm} from "antd";
import PremiumButton from "../../../components/buttons/PremiumButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-regular-svg-icons";
import {faCopy, faLink, faTrash, faWarning} from "@fortawesome/free-solid-svg-icons";
import {
    useDeleteWorkspaceInvitation,
    useGenerateWorkspaceInvitation, useSendWorkspaceInvitations,
} from "../../../utils/hooks/useWorkspaceInvitations";
import {useCurrentWorkspace} from "../../../utils/hooks/useCurrentWorkspace";
import moment from "moment/moment";
import LoadingScreen from "../../../components/loading-screen/LoadingScreen";

const WorkspaceTeamInviteModal = ({open, onClose, shareableLinks}) => {
    const [emails, setEmails] = React.useState([]);
    const {generateInvitation, isGenerating} = useGenerateWorkspaceInvitation();
    const {sendInvitations, isSending} = useSendWorkspaceInvitations({
        onSuccess: () => setEmails([])
    });
    const {deleteInvitation, isDeleting} = useDeleteWorkspaceInvitation();
    const {currentWorkspace} = useCurrentWorkspace();
    const [messageApi, contextHolder] = message.useMessage();

    const getInvitationURL = (code) => {
        return `${process.env.REACT_APP_FRONTEND_HOST}/join/${code}`;
    }

    const handleCopyToClipboard = (code) => {
        navigator.clipboard.writeText(getInvitationURL(code));
        messageApi.open({
            type: "success",
            content: "Link copiado para a área de transferência"
        });
    }

    const renderEmailInvitesSection = () => {
        return (
            <div className="flex flex-col gap-2">
                <label className="text-lg font-bold text-gray-400">
                    Enviar convite por e-mail
                </label>
                <Select
                    mode="tags"
                    onChange={(value) => setEmails(value)}
                    value={emails}
                    tokenSeparators={[',', ' ']}
                    className="w-full"
                    dropdownStyle={{display: 'none'}}
                    size="large"
                    placeholder="Digite os e-mails dos usuários"
                />
                <div className="flex flex-row items-center justify-between">
                    <label>
                        Precisa de mais usuários? <a className="font-bold text-primary" href="/settings/subscription">Atualize
                        seu plano</a>
                    </label>

                    <PremiumButton
                        size="large"
                        className="font-primary"
                        icon={<FontAwesomeIcon icon={faPaperPlane}/>}
                        disabled={emails.length === 0 || isGenerating || isDeleting || isSending}
                        onClick={() => sendInvitations({idWorkspace: currentWorkspace.idWorkspace, recipients: emails})}
                        loading={isSending}
                    >
                        Enviar convite(s)
                    </PremiumButton>
                </div>
            </div>
        );
    }

    const renderGenerateLinkSection = () => {
        return (
            <div className="flex flex-col gap-2">
                <label className="text-lg font-bold text-gray-400">Gerar link compartilhável</label>

                {isGenerating && (
                    <LoadingScreen/>
                )}

                {shareableLinks?.length < 1 && !isGenerating && (
                    <label>Gere um link único para compartilhar com o seu time. Este link irá expirar em 2 semanas.</label>
                )}

                <div>
                    {shareableLinks?.map(invitation => (
                        <div key={invitation.idWorkspaceInvitation} className="flex flex-col w-full gap-2">
                            <div>
                                Este link irá expirar em <b>{moment(invitation.expiresAt).format('MMMM Do YYYY, h:mm:ss a')}</b>
                            </div>

                            <div className="flex flex-row items-center gap-2">
                                <div
                                    className="w-full bg-neutral-100 text-gray-500 font-bold px-4 py-3 rounded-lg hover:cursor-pointer"
                                    onClick={() => handleCopyToClipboard(invitation.code)}
                                >
                                    {getInvitationURL(invitation.code)}
                                </div>

                                <Tooltip title="Copiar">
                                    <Button
                                        className="p-2"
                                        size="large"
                                        type="text"
                                        icon={<FontAwesomeIcon icon={faCopy}/>}
                                        onClick={() => handleCopyToClipboard(invitation.code)}
                                    />
                                </Tooltip>
                                <Tooltip title="Excluir">
                                    <Popconfirm
                                        title={<label className="font-primary">Excluir link compartilhável</label>}
                                        description={<label className="font-primary">Tem certeza que deseja excluir este link?</label>}
                                        okText="Excluir"
                                        cancelText="Cancelar"
                                        onConfirm={() => deleteInvitation({idWorkspace: invitation.idWorkspace, idWorkspaceInvitation: invitation.idWorkspaceInvitation})}
                                        icon={<FontAwesomeIcon className="text-red-500 mr-2"  size="lg" icon={faWarning}/>}
                                    >
                                        <Button
                                            className="p-2"
                                            size="large"
                                            type="text"
                                            danger
                                            icon={<FontAwesomeIcon icon={faTrash}/>}
                                            disabled={isDeleting}
                                            loading={isDeleting}
                                        />
                                    </Popconfirm>

                                </Tooltip>
                            </div>
                        </div>
                    ))}
                </div>

                {shareableLinks?.length < 1 && !isGenerating && (
                    <div className="flex justify-end">
                        <Button
                            size="large"
                            type="default"
                            className="font-primary"
                            icon={<FontAwesomeIcon icon={faLink}/>}
                            disabled={isGenerating}
                            loading={isGenerating}
                            onClick={() => generateInvitation({idWorkspace: currentWorkspace.idWorkspace})}
                        >
                            Gerar link
                        </Button>
                    </div>
                )
                }
            </div>
        );
    }

    return (
        <Modal
            title={<label className="text-2xl bold font-primary">Convite o seu time</label>}
            open={open}
            centered
            width={800}
            footer={null}
            className="font-primary"
            onCancel={onClose}
        >
            {contextHolder}
            <div className="flex flex-col gap-2">
                {renderEmailInvitesSection()}
                <div className="bg-gray-200 h-0.5 w-full my-4"></div>
                {renderGenerateLinkSection()}
            </div>

        </Modal>
    );
};

export default WorkspaceTeamInviteModal;