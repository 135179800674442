import {Image} from "antd";
import _ from "lodash";
import HorizontalScroll from "../../../../utils/components/horizontal-scroll/HorizontalScroll";
import "./ImageEditorCarousel.css";
import {useCurrentWorkspace} from "../../../../utils/hooks/useCurrentWorkspace";

const ImageEditorCarousel = ({idUser, images, onSelect}) => {
    const {currentWorkspace} = useCurrentWorkspace();

    const getImageUrl = (image) => {
        return `${process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL}/${currentWorkspace?.idWorkspace}/${image.filename}.${image.extension}`;
    }

    const renderImage = (image) => {
        return (
            <div id={image.idImage} className="ImageEditorCarouselImageContainer">
                <Image
                    className="ImageEditorCarouselImage"
                    height={64}
                    width={64}
                    src={getImageUrl(image)}
                    preview={false}
                    onClick={() => onSelect(image)}
                />
            </div>
        );
    }

    const renderImages = () => {
        if (_.isEmpty(images)) {
            return;
        }

        return images.map(renderImage);
    }

    const renderCarousel = () => {
        return (
            <div className="ImageEditorCarouselContainer">
                <HorizontalScroll components={renderImages()}/>
            </div>
        );
    }

    return (
        <div>
            {renderCarousel()}
        </div>
    );
}

export default ImageEditorCarousel;