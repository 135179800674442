export const Specialties = [
    {order: 1, id: "CMO", name: "CMO"},
    {order: 2, id: "MARKETING_MANAGER", name: "Gerente de Marketing"},
    {order: 3, id: "COPYWRITER", name: "Copywriter"},

    {order: 4, id: "SOCIAL_MEDIA_SPECIALIST", suffix: "Especialista em ", name: "Redes Sociais"},
    {order: 5, id: "CONTENT_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Conteúdo"},
    {order: 6, id: "BRANDING_SPECIALIST", suffix: "Especialista em ", name: "Branding"},
    {order: 7, id: "MARKET_ANALYSIS_SPECIALIST", suffix: "Especialista em ", name: "Análise de Mercado"},
    {order: 8, id: "PAID_MEDIA_SPECIALIST", suffix: "Especialista em ", name: "Mídia Paga"},
    {order: 9, id: "SEO_SPECIALIST", suffix: "Especialista em ", name: "SEO"},
    {order: 10, id: "DIGITAL_MARKETING_SPECIALIST_FOR_B2B", suffix: "Especialista em ", name: "Marketing Digital para B2B"},
    {order: 11, id: "GROWTH_SPECIALIST", suffix: "Especialista em ", name: "Growth"},
    {order: 12, id: "COMPETITOR_ANALYSIS_SPECIALIST", suffix: "Especialista em ", name: "Análise de Concorrência"},
    {order: 13, id: "PRESENTATION_SPECIALIST", suffix: "Especialista em ", name: "Apresentação"},
    {order: 14, id: "ECOMMERCE_SPECIALIST", suffix: "Especialista em ", name: "E-commerce"},
    {order: 15, id: "STAKEHOLDER_MANAGEMENT_SPECIALIST", suffix: "Especialista em ", name: "Stakeholder Management"},
    {order: 16, id: "PRODUCT_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Produto"},
    {order: 17, id: "INBOUND_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Inbound Marketing"},
    {order: 18, id: "EMAIL_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Email Marketing"},
    {order: 19, id: "SALES_LETTER_SPECIALIST", suffix: "Especialista em ", name: "Carta de Vendas"},
    {order: 20, id: "CRM_SPECIALIST", suffix: "Especialista em ", name: "CRM"},
    {order: 21, id: "CUSTOMER_EXPERIENCE_SPECIALIST", suffix: "Especialista em ", name: "Customer Experience"},
    {order: 22, id: "KPI_AND_OKR_SPECIALIST", suffix: "Especialista em ", name: "KPIs e OKRs"},
    {order: 23, id: "INLFUENCER_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Influência"},
    {order: 24, id: "SERVICES_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Serviços"},
    {order: 25, id: "EVENT_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Eventos"},
    {order: 26, id: "DIGITAL_MARKETING_SPECIALIST_FOR_B2C", suffix: "Especialista em ", name: "Marketing Digital para B2C"},
    {order: 27, id: "DATA_SPECIALIST", suffix: "Especialista em ", name: "Dados"},
    {order: 28, id: "STARTUP_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing para Startups"},
    {order: 29, id: "TOURISM_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Turismo"},
    {order: 30, id: "LUXURY_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Luxo"},
    {order: 31, id: "RETAIL_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing de Varejo"},
    {order: 32, id: "POLITICAL_MARKETING_SPECIALIST", suffix: "Especialista em ", name: "Marketing Político"},
]