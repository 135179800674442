import React, {useState} from 'react';
import {Avatar, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ContentCreatorSpecialtiesList from "./components/ContentCreatorSpecialtiesList";
import ContentCreatorSpecialtiesCreateModal from "./components/ContentCreatorSpecialtiesCreateModal";
import {faRedhat} from "@fortawesome/free-brands-svg-icons";
import PremiumButton from "../../components/buttons/PremiumButton";

const ContentCreatorSpecialtiesPage = () => {
    const [filter, setFilter] = useState(null);
    const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);

    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faRedhat}/>}/>
                <div className="font-sans">
                    <div className="text-3xl">
                        Especialidades
                    </div>

                    <div className="text-sm text-gray-400">
                        Adicione, edite ou remova especialidades que você deseja trabalhar
                    </div>
                </div>
            </div>


            <div className="py-4">
                <div className="flex flex-row gap-2 items-center">
                    <Input
                        size="large"
                        placeholder="Buscar por nome ou descrição"
                        prefix={<SearchOutlined/>}
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value)
                        }}
                    />

                    <PremiumButton
                        className="flex flex-row items-center gap-2"
                        type="primary"
                        size="large"
                        onClick={() => setOpenCreateVoiceModal(true)}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                        Adicionar
                    </PremiumButton>
                </div>

                <ContentCreatorSpecialtiesList filter={filter}/>
            </div>

            <ContentCreatorSpecialtiesCreateModal
                open={openCreateVoiceModal}
                onClose={() => setOpenCreateVoiceModal(false)}
            />
        </div>
    );
};

export default ContentCreatorSpecialtiesPage;