// src/utils/hooks/useImageService.js

import { useContext } from "react";
import { UserContext } from "../providers/userProvider";
import _ from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ImageRemote from "../../services/dao/remote/ImageDAORemote";
import { message } from "antd";

export const useImageService = () => {
    const { user } = useContext(UserContext);
    const queryClient = useQueryClient();

    const generateImageMutation = useMutation({
        mutationFn: (data) => ImageRemote.generate(data),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const reimagineImageMutation = useMutation({
        mutationFn: ({ idImage }) => ImageRemote.reimagine({ idImage }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const sketchToImageMutation = useMutation({
        mutationFn: ({ idImage, prompt }) => ImageRemote.sketchToImage({ idImage, prompt }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const removeBackgroundMutation = useMutation({
        mutationFn: ({ idImage }) => ImageRemote.removeBg({ idImage }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const removeTextMutation = useMutation({
        mutationFn: ({ idImage }) => ImageRemote.removeText({ idImage }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const replaceBackgroundMutation = useMutation({
        mutationFn: ({ idImage, prompt }) => ImageRemote.replaceBg({ idImage, prompt }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const upscaleMutation = useMutation({
        mutationFn: ({ idImage }) => ImageRemote.upscale({ idImage }),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const generateImageWithIdeogramMutation = useMutation({
        mutationFn: (data) => ImageRemote.generateWithIdeogram(data),
        onSuccess: (image) => {
            pushImage(image);
            invalidateUserQuery();
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.error || error.message || 'Ocorreu um erro desconhecido.';
            message.error(errorMessage);
        }
    });

    const pushImage = (image) => {
        queryClient.setQueryData(["images"], (oldImages = []) => {
            if (!Array.isArray(oldImages)) {
                console.warn("oldImages não é um array. Inicializando com a nova imagem.");
                return [image];
            }
    
            // Verificar se a imagem já existe
            const exists = oldImages.some(img => img.idImage === image.idImage);
            if (exists) {
                console.warn(`Imagem com idImage ${image.idImage} já existe. Ignorando duplicata.`);
                return oldImages;
            }
    
            return [image, ...oldImages];
        });
    }

    const invalidateUserQuery = () => {
        setTimeout(() => queryClient.invalidateQueries(["user"]), 500);
    }

    const getImageCreditsBalance = () => {
        if (_.isEmpty(user)) {
            return 0;
        }

        const preDatedBalance = _.get(user, "wallet.funds.imageGenerationCredits.preDatedBalance", []);

        // FIXME: Fallback verification. Remove as soon as the new pre dated balance is validated.
        if (_.isEmpty(preDatedBalance)) {
            return _.get(user, "wallet.funds.imageGenerationCredits.balance", 0);
        }

        const currentPreDatedBalance = _.head(preDatedBalance.filter((preDatedBalance) => {
            return (preDatedBalance.validFrom * 1000) <= new Date() && (preDatedBalance.validUntil * 1000) >= new Date();
        }));

        if (currentPreDatedBalance) {
            return currentPreDatedBalance.currentBalance;
        }
        return 0;
    }

    return {
        getImageCreditsBalance,

        generateImage: () => generateImageMutation,
        reimagineImage: () => reimagineImageMutation,
        sketchToImage: () => sketchToImageMutation,
        removeBackground: () => removeBackgroundMutation,
        removeText: () => removeTextMutation,
        replaceBackground: () => replaceBackgroundMutation,
        upscale: () => upscaleMutation,
        generateImageWithIdeogram: () => generateImageWithIdeogramMutation,
    }
}