import {Modal} from "antd";
import SubscriptionPricingTable from "./SubscriptionPricingTable";
import React, {useContext} from "react";
import {Auth} from "aws-amplify";
import "./SubscriptionsModal.css";
import {UserContext} from "../../utils/providers/userProvider";
import _ from "lodash";
import {OrganizationContext} from "../../utils/providers/organizationProvider";
import {SubscriptionModalStatusContext} from "../../utils/providers/SubscriptionModalStatusContextProvider";
import {useSubscriptions} from "../../utils/hooks/useSubscriptions";

export const SubscriptionsModal = () => {
    const {user} = useContext(UserContext);
    const subscriptions = useSubscriptions();
    const subscriptionModalStatusContext = useContext(SubscriptionModalStatusContext);
    const [organization] = useContext(OrganizationContext);

    const handleCheckout = async ({idSubscription, billingCycle}) => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        const queryParams = new URLSearchParams({token, idSubscription, billingCycle});
        window.open(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/checkout?${queryParams}`, '_blank');
    }

    const handlePortal = async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        const queryParams = new URLSearchParams({token});
        window.open(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/portal?${queryParams}`, '_blank');
    }

    const renderModalTitle = () => {
        return (
            <div className="SubscriptionsModalHeader">
                <div className="SubscriptionsModalTitle">Torne-se um membro!</div>
                <div className="SubscriptionsModalSubtitle">Atualize seu plano a qualquer momento para obter recursos mais avançados</div>
            </div>
        );
    }

    const getSubscriptionPlans = () => {
        if (subscriptionModalStatusContext.isOrganizationSensitive) {
            return subscriptions.filter((subscription) => subscription.idOrganization === organization.idOrganization);
        }
        return subscriptions.filter((subscription) => subscription.idOrganization === 1);
    }

    return (
        <Modal
            className="SubscriptionsModal"
            open={subscriptionModalStatusContext.isOpen}
            footer={null}
            width={"75%"}
            centered
            title={renderModalTitle()}
            onCancel={() => subscriptionModalStatusContext.hide()}
        >
            <div style={{position: "relative", height: "100%", padding: "12px 36px 36px 36px"}}>
                <SubscriptionPricingTable
                    onCheckout={handleCheckout}
                    subscriptions={getSubscriptionPlans()}
                    activeSubscriptions={_.get(user, "activeSubscriptions", [])}
                />
            </div>
        </Modal>
    );
}