import React, {Component} from "react";
import autoBind from "auto-bind";
import "./ChatContainer.css";
import ChatHistory from "./ChatHistory";
import _ from "lodash";
import {ChatDAO} from "../../services/dao/local/ChatLocalDAO";
import {ChatRemote} from "../../services/dao/remote/chat";
import {MessageRemote} from "../../services/dao/remote/message";
import {replace} from "../../utils/functions/collection";
import * as LocalStorage from "../../services/dao/local/localStorage";
import {Content} from "antd/es/layout/layout";
import {Layout} from "antd";
import AppFooter from "../app-footer/AppFooter";
import {OnboardingScript} from "../../api/OnboardingScript";
import {Onboarding} from "../../modules/onboarding/Onboarding";
import ChatOrchestrator from "./ChatOrchestrator"; // Change to default import
import ChatHistoryMobile from "./ChatHistoryMobile";
import withIsMobile from "../../utils/hocs/withIsMobile";
import OnboardingController from "../onboarding/OnboardingController";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ChatContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            idChatSelected: null,
            isOnboardingRunning: false,

            showOnboardingModal: true,
        }

        this.onboarding = new Onboarding(OnboardingScript);

        this.onboardingRefs = {
            chatHistoryRef: React.createRef()
        };

        autoBind(this);
    }

    async componentDidMount() {
        const {setHeaderTitle} = this.props;

        this.syncRemoteChats();
        this.loadLocalChats()
            .then(this.loadIdChatSelected);

        document.title = `Chat · CMOs.ai`;

        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faComments}/>}
                title="Chat especialista"
            />
        );
    }

    syncRemoteChats() {
        ChatRemote.list()
            .then(chats => {
                if (_.isEmpty(chats)) {
                    this.handleOnboarding();
                } else {
                    this.updateChats(chats);
                    this.loadIdChatSelected();
                    ChatDAO.clear()
                        .then(() => ChatDAO.bulkPut(chats));
                }
            });
    }

    loadLocalChats() {
        return ChatDAO.list()
            .then(chats => this.updateChats(chats));
    }

    loadIdChatSelected() {
        const idChatSelected = LocalStorage.getIdChatSelected();
        const currentChat = this.getChat(idChatSelected);

        if (currentChat == null) {
            this.setState({idChatSelected: _.get(this.getCurrentChat(), "idChat", null)});
        }

        this.setState({idChatSelected});
    }

    getOnboardChat() {
        const onboardChat = this.getEmptyChat();
        onboardChat.title = "Chat de introdução";
        onboardChat.onboard = true;
        onboardChat.messages = [];
        onboardChat.onboardingMessages = this.getOnboardingMessages();
        return onboardChat;
    }

    getOnboardingMessages() {
        return [
            {
                role: "SYSTEM",
                content: "Olá, líder de marketing. Boas vindas ao CMOs.ai! 😄",
                timestamp: new Date(),
                specialty: null,
                onboard: true,
                waitingTime: 2000,
            },
            {
                role: "SYSTEM",
                content: "Estou aqui para ajudar você a tirar o máximo proveito e potencializar os seus resultados!",
                timestamp: new Date(),
                specialty: null,
                onboard: true,
                waitingTime: 4000,
            },
            {
                role: "SYSTEM",
                content: "**Aqui estão algumas dicas poderosas antes de começar:**\n" +
                    "\n" +
                    "1. Clique em **'Criar novo chat'** e selecione a especialidade que melhor atende as suas necessidades, ao lado da caixa de texto.\n" +
                    "2. Faça perguntas contextualizadas, especificando dados fundamentais, como mercado, estrutura do seu time, metas ou orçamento. Quanto mais informações você fornecer, melhor será a sua resposta!\n" +
                    "3. Os chats permitem que você organize as conversas por assuntos, e eles ficarão salvos na barra de histórico, ao lado esquerdo. Você pode renomeá-los ou excluí-los conforme desejar.\n" +
                    "4. Você pode trocar de especialista no chat a qualquer momento e quantas vezes for necessário para que você obtenha respostas mais assertivas. Caso prefira, você também pode explorar a plataforma sem optar por um especialista, fazendo perguntas gerais sobre temas amplos.\n" +
                    "\n" +
                    "Espero que essas dicas ajudem você a aproveitar ao máximo o CMOs.ai. Vamos começar? 😄",
                timestamp: new Date(),
                specialty: null,
                onboard: true,
                waitingTime: 7000,
            },
            {
                role: "SYSTEM",
                content: "**Experimente fazer uma das solicitações a seguir:**\n" +
                    "\n" +
                    "1. Liste 7 maneiras criativas para começar uma campanha de branding.\n" +
                    "2. Liste 4 passos passos melhorar a performance de mídia paga.\n" +
                    "3. Construa um funil de growth marketing utilizando o modelo Hook. Apresente no formato de fluxo html cada etapa.\n" +
                    "4. Qual a melhor forma para comunicar os KPIs para o meu time de marketing?\n" +
                    "5. Como criar OKRs compartilhados?",
                timestamp: new Date(),
                specialty: null,
                onboard: true,
                waitingTime: 23000,
            }
        ];
    }

    getEmptyChat() {
        const {chats} = this.state;

        const getChatTitleIndex = (usedTitles) => {
            let index = 0;
            usedTitles.forEach(title => {
                const match = /\((\d+)\)/.exec(title);
                const titleIndex = match ? parseInt(match[1], 10) : 0;

                if (title === "Novo assunto" && index === 0) {
                    index = 1;
                }

                if (titleIndex > index) {
                    index = titleIndex;
                }
            });
            return index;
        }

        const generateChatTitle = () => {
            const defaultTitle = "Novo assunto";
            const usedTitles = chats.map(chat => chat.title);

            let index = getChatTitleIndex(usedTitles);
            if (index > 0) {
                return `${defaultTitle} (${index + 1})`;
            }
            return defaultTitle;
        }

        return {
            title: generateChatTitle(),
            messages: [],
            specialty: null,
            createdAt: new Date(),
            updatedAt: new Date()
        }
    }

    async createChat(newChat) {
        // TODO: Criar interface para atualizar ambos os bancos de dados
        ChatRemote.create(newChat)
            .then(chat => {
                this.updateChats(this.getChats().concat([chat]))
                this.updateIdChatSelected(chat.idChat);
                ChatDAO.put(chat);
            });

        return newChat;
    }

    addMessageToCurrentChat(message) {
        const currentChat = this.getCurrentChat();
        currentChat.messages.push(message);
        this.updateChat(currentChat);
    }

    getCurrentChat() {
        const {idChatSelected} = this.state;
        const currentChat = this.getChat(idChatSelected);

        if (currentChat == null) {
            return _.head(this.getOrderedChats());
        }

        return currentChat;
    }

    getChat(idChat) {
        const {chats} = this.state;
        return _.cloneDeep(_.find(chats, _.matchesProperty("idChat", idChat)));
    }

    getChats() {
        const {chats} = this.state;
        return _.cloneDeep(chats);
    }

    getOrderedChats() {
        return this.orderChats(this.getChats());
    }

    orderChats(chats) {
        return _.orderBy(chats, (chat) => new Date(chat.updatedAt), ["desc"]);
    }

    setRefs(label, ref) {
        this.onboardingRefs[label] = ref;
    }

    handleOnboarding() {
        const {isMobile} = this.props;
        const onboardChat = this.getOnboardChat();

        ChatRemote.create(onboardChat)
            .then(chat => {
                this.updateIdChatSelected(chat.idChat);

                if (isMobile) {
                    this.setState({chats: [chat], isOnboardingRunning: true});
                    this.runOnboarding();
                    this.setState({showOnboardingModal: true});
                } else {
                    this.setState({chats: [chat]});
                }


                ChatDAO.put(chat);
            });
    }

    runOnboarding() {
        this.insertNextOnboardingMessage();
    }

    insertNextOnboardingMessage() {
        const nextOnboardingMessage = this.onboarding.getNextMessage();
        if (nextOnboardingMessage != null) {
            const message = this.buildOnboardingMessage(nextOnboardingMessage.content);
            this.addMessageToCurrentChat(message)
        } else {
            this.setState({isOnboardingRunning: false});
        }
    }

    buildOnboardingMessage(content) {
        return {
            role: "SYSTEM",
            content: content,
            timestamp: new Date(),
            specialty: null,
            onboard: true
        }
    }

    handleOnboardingMessageRender(idChat, message) {
        const chat = this.getChat(idChat);
        _.remove(chat.onboardingMessages, _.matchesProperty("content", message.content));
        this.updateChat(chat);
        this.updateChatRemote(chat);
    }

    handleUserMessage(idChat, message, callback) {
        const chat = this.getChat(idChat);

        chat.messages.push(message);
        chat.updatedAt = message.timestamp;

        // TODO: Criar interface para atualizar ambos os bancos de dados
        MessageRemote.create(message)
            .then((message) => {
                ChatDAO.update(chat);
            });

        this.updateChat(chat, callback);
        this.updateChatRemote(chat);
    }

    handleSystemMessage(idChat, message, update) {
        const chat = this.getChat(idChat);

        if (!update) {
            chat.messages.push(message);
            chat.updatedAt = message.timestamp;
        } else {
            const lastMessage = _.last(chat.messages);
            lastMessage.content = message.content
        }

        this.updateChat(chat);
    }

    handleSystemMessageEnd(idChat, message) {
        const chat = this.getChat(idChat);
        // TODO: Criar interface para atualizar ambos os bancos de dados
        MessageRemote.create(message)
            .then((message) => {
                ChatDAO.update(chat);
            });

        this.updateChat(chat);
        this.updateChatRemote(chat);
    }

    handleOnboardingMessage(idChat, message) {
        const chat = this.getChat(idChat);
        chat.messages.push(message);
        MessageRemote.create(message)
            .then((message) => ChatDAO.update(chat));

        this.updateChat(chat);
        this.updateChatRemote(chat);
    }

    handleChatCreate() {
        const emptyChat = this.getEmptyChat();
        this.createChat(emptyChat);
    }

    handleChatChange(idChat) {
        this.updateIdChatSelected(idChat);
    }

    handleChatDelete(idChat) {
        const chats = this.getChats();

        _.remove(chats, _.matchesProperty("idChat", idChat));
        this.updateChats(chats, this.updateIdChatSelected);

        // TODO: Adicionar este método em um facade
        ChatRemote.remove(idChat)
            .then(() => ChatDAO.remove(idChat));
    }

    handleChatRename(idChat, newTitle) {
        const chat = this.getChat(idChat);
        chat.title = newTitle

        this.updateChat(chat);
        this.updateChatRemote(chat);
    }

    handleSpecialtyChange(idChat, specialty) {
        const chat = this.getChat(idChat);
        chat.specialty = specialty;

        this.updateChat(chat);
        this.updateChatRemote(chat);
    }

    updateIdChatSelected(idChat) {
        if (idChat == null) {
            idChat = _.get(_.head(this.getOrderedChats()), "idChat", null);
        }
        this.setState({idChatSelected: idChat});
        LocalStorage.setIdChatSelected(idChat);
    }

    updateChats(chats, callback = null) {
        this.setState({chats}, callback);
    }

    updateChat(chat, callback = null) {
        const chats = replace(chat, this.getChats(), _.matchesProperty("idChat", chat.idChat));
        this.updateChats(chats, callback);
    }

    updateChatRemote(chat) {
        ChatRemote.update(chat)
            .then(() => ChatDAO.update(chat));
    }

    renderChatHistory() {
        const {chats, idChatSelected} = this.state;
        const {isMobile} = this.props;

        if (isMobile) {
            return (
                <ChatHistoryMobile
                    chats={this.getOrderedChats()}
                    selectedChatId={idChatSelected}
                    onChatCreate={this.handleChatCreate}
                    onChatChange={this.handleChatChange}
                    onChatDelete={this.handleChatDelete}
                    onChatRename={this.handleChatRename}
                />
            );
        } else {
            return (
                <>
                    <ChatHistory
                        chats={this.getOrderedChats()}
                        selectedChatId={idChatSelected}
                        onChatCreate={this.handleChatCreate}
                        onChatChange={this.handleChatChange}
                        onChatDelete={this.handleChatDelete}
                        onChatRename={this.handleChatRename}
                    />
                </>
            );
        }
    }

    renderFooter() {
        const {isMobile} = this.props;

        if (!isMobile) {
            return <AppFooter/>;
        }
    }

    renderOnboardingModal() {
        const {showOnboardingModal} = this.state;
        const {isMobile} = this.props;

        if (isMobile) {
            return;
        }

        return (
            <OnboardingController
                open={showOnboardingModal}
                onClose={() => this.setState({showOnboardingModal: false})}
                refs={this.onboardingRefs}
            />
        );
    }

    render() {
        const {isOnboardingRunning} = this.state;
        const {user} = this.props;

        return (
            <>
                <Layout>

                    <Layout>
                        <Content>
                            <ChatOrchestrator
                                user={user}
                                chat={this.getCurrentChat()}
                                onUserMessageCreate={this.handleUserMessage}
                                onSystemMessageCreate={this.handleSystemMessage}
                                onSystemMessageEnd={this.handleSystemMessageEnd}
                                onSpecialtyChange={this.handleSpecialtyChange}
                                onOnboardingMessageRender={this.handleOnboardingMessageRender}
                                onOnboardingMessageAnimationFinish={this.insertNextOnboardingMessage}
                                isOnboardingRunning={isOnboardingRunning}
                                setRefs={this.setRefs}
                            />
                        </Content>
                        {this.renderOnboardingModal()}
                        <div ref={this.onboardingRefs['chatHistoryRef']}>
                            {this.renderChatHistory()}
                        </div>
                    </Layout>
                    {/*{this.renderFooter()}*/}
                </Layout>
            </>
        );
    }
}

export default withIsMobile(ChatContainer);