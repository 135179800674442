import React from 'react';
import WorkspaceTeamInviteListItem from "./WorkspaceTeamInviteListItem";
import {List} from "antd";

const WorkspaceTeamInviteList = ({invites, isLoading, filter}) => {
    const getFilteredInvites = () => {
        if (!filter) {
            return invites;
        }

        return invites.filter((bv) => {
            return bv.name.toLowerCase().includes(filter.toLowerCase())
                || bv.description.toLowerCase().includes(filter.toLowerCase());
        });
    }

     const renderItem = (item) => {
        return (
            <WorkspaceTeamInviteListItem item={item}/>
        );
    }

    return (
        <div className="my-4">
            <List
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={getFilteredInvites()}
                size="large"
                renderItem={renderItem}
                bordered
                pagination={{
                    pageSize: 5
                }}
            />
        </div>
    );
};

export default WorkspaceTeamInviteList;