import {urlBase, params} from "./resources";

const url = urlBase + "/api/onboarding";


async function save(data) {
    const content = {body: {data}};
    return fetch(`${url}`, await params.put(content));
}

async function get() {
    return fetch(`${url}`, await params.get())
        .then(res => res.json());
}

const OnboardingStatsRemote = {
    save,
    get
};

export default OnboardingStatsRemote;