import {Button, message, Modal} from "antd";
import {useScreenSize} from "../../utils/hooks/useScreenSize";
import CompleteRegistrationForm from "../post-registration/CompleteRegistrationForm";
import React, {useState} from "react";
import "./TaxInformationModal.css";
import _ from "lodash";
import {useLegalDocumentValidation} from "../../utils/hooks/useLegalDocumentValidation";
import {useMutation} from "@tanstack/react-query";
import UserInformationRemote from "../../services/dao/remote/UserInformationRemoteDAO";


const TaxInformationModal = ({open, onClose}) => {
    const screenSize = useScreenSize();
    const legalDocumentValidation = useLegalDocumentValidation();

    const [entityType, setEntityType] = useState("individual"); // individual or company
    const [cpf, setCpf] = useState("");
    const [cnpj, setCnpj] = useState("");

    const [client, setClient] = useState("");
    const [phone, setPhone] = useState("");
    const [cep, setCep] = useState("");
    const [address, setAddress] = useState("");
    const [number, setNumber] = useState("");

    const [complement, setComplement] = useState("");
    const [neighborhood, setNeighborhood] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [cepValidateStatus, setCepValidateStatus] = useState("");

    const PHONE_MASK = "(00) 00000-0000";

    const update = useMutation({
        mutationFn: (data) => UserInformationRemote.createPersonalInformation(data),
        onSuccess: () => {
            onClose();
        },
        onError: (error) => {
            message.error({
                type: 'error',
                content: 'Erro ao salvar informações',
            });
        }
    });


    const getModalWidth = () => {
        switch (screenSize) {
            case "xs":
                return "90%";
            case "sm":
                return "90%";
            case "md":
                return "90%";
            case "lg":
                return "90%";
            case "xl":
                return "60%";
            case "xxl":
                return "60%";
        }
    }

    const isFormValid = () => {
        const isValidDocument = entityType === "individual" ? legalDocumentValidation.validateCPF(cpf) : legalDocumentValidation.validateCNPJ(cnpj);
        return (
            isValidDocument &&
            _.get(client, "length", 0) > 3 &&
            _.get(phone, "length", 0) === PHONE_MASK.length &&
            cepValidateStatus === "success" &&
            _.get(address, "length", 0) > 0 &&
            _.get(number, "length", 0) > 0 &&
            _.get(complement, "length", 0) > 0 &&
            _.get(neighborhood, "length", 0) > 0 &&
            _.get(city, "length", 0) > 0 &&
            _.get(state, "length", 0) > 0
        );
    }

    const renderTitle = () => {
        return (
            <div style={{padding: 12}}>
                <div className="TaxInformationModalTitle">
                    Finalize o seu cadastro
                </div>
                <label className="TaxInformationModalSubtitle">
                    Não se preocupe, suas informações estão seguras com a gente. 🔒
                </label>
            </div>
        );
    }

    return (
        <Modal
            open={open}
            centered
            width={getModalWidth()}
            footer={null}
            closable={false}
            title={renderTitle()}
        >
            <CompleteRegistrationForm
                entityType={[entityType, setEntityType]}
                cpf={[cpf, setCpf]}
                cnpj={[cnpj, setCnpj]}
                client={[client, setClient]}
                phone={[phone, setPhone]}
                cep={[cep, setCep]}
                address={[address, setAddress]}
                number={[number, setNumber]}
                complement={[complement, setComplement]}
                neighborhood={[neighborhood, setNeighborhood]}
                city={[city, setCity]}
                state={[state, setState]}
                cepValidateStatus={[cepValidateStatus, setCepValidateStatus]}
            />

            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                <Button
                    type="primary"
                    size="large"
                    disabled={!isFormValid() || update.isLoading}
                    onClick={() => {
                        update.mutate({
                            entityType,
                            name: client,
                            cpf,
                            cnpj,
                            phoneNumber: phone,
                            zipCode: cep,
                            street: address,
                            number,
                            complement,
                            neighborhood,
                            city,
                            state
                        });
                    }}
                    loading={update.isLoading}
                >
                    Salvar
                </Button>
            </div>
        </Modal>
    )
};

export default TaxInformationModal;