import {Badge} from "antd";
import ToolCard from "./ToolCard";
import "./ToolsCategory.css";
import {useCurrentSubscriptionPlans} from "../../utils/hooks/useCurrentSubscriptionPlan";

const ToolsCategory = ({category, tools, onClick, onPin, pinnedTools}) => {
    const {currentSubscriptionPlans} = useCurrentSubscriptionPlans();

    const getToolCards = () => {
        const currentSubscriptionPlansIds = currentSubscriptionPlans.map(subscriptionPlan => subscriptionPlan.idSubscriptionPlan);

        const toolsWithDisabled = tools.map(tool => {
            const isDisabled = tool.availableSubscriptions != null && tool.availableSubscriptions.every(subscriptionPlanId => !currentSubscriptionPlansIds.includes(subscriptionPlanId));
            return {
                ...tool,
                disabled: isDisabled
            };
        });

        // Sort tools so that disabled ones are at the end
        const sortedTools = toolsWithDisabled.sort((a, b) => a.disabled - b.disabled);

        return sortedTools.map((tool) => {
                return (
                    <ToolCard
                        key={tool.idTool}
                        idTool={tool.idTool}
                        title={tool.name}
                        description={tool.description}
                        badge={tool.badge}
                        icon={tool.icon}
                        loading={false}
                        onClick={onClick}
                        onPin={onPin}
                        isPinned={pinnedTools != null ? pinnedTools.includes(tool.idTool) : false}
                        disabled={tool.disabled}
                    />
                );
            }
        );
    }

    return (
        <>
            <div className="ToolsCategory" key={category}>
                <div className="ToolsCategoryTitleContainer">
                    <label className="ToolsCategoryLabel">{category}</label>
                    <Badge className="ToolsCategoryTitleBadge" count={tools.length} color={"#724CF9"}/>
                </div>
                {/*<HorizontalScroll components={getToolCards()}/>*/}
                <div className="ToolsCategoryCardRow">
                    {getToolCards()}
                </div>
            </div>

        </>
    );
}

export default ToolsCategory;