import React from "react";
import "./SuggestionBox.css";

const SuggestionBox = ({ text, onClick }) => {
    return (
        <div
            className="SuggestionBox w-[300px] min-h-[54px] p-4 rounded-[12px] bg-premium text-white flex justify-center items-center"
            style={{fontFamily: "var(--font-family)"}}
            onClick={() => onClick(text)}
        >
            {text}
        </div>
    );
}

export default SuggestionBox;