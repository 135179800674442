import React from 'react';
import {SelectButton} from "primereact/selectbutton";
import {ReactComponent as Arrow} from "../../../../images/arrow.svg";

const getBillingPeriodOptions = () => {
    return [
        {
            label: 'Mensal',
            value: 'monthly'
        },
        {
            label: 'Anual',
            value: 'yearly'
        }
    ];
}

const BillingPeriodSelector = ({billingPeriod, onChange}) => {
    return (
        <>
            <SelectButton
                className="SubscriptionPlansBillingPeriodSelector"
                options={getBillingPeriodOptions()}
                value={billingPeriod}
                onChange={(e) => onChange(e.value)}
            />
            <div className="flex justify-end relative">
                <label className="font-bold z-10 text-gray-700 mt-[10px] mr-[32px]">
                    2 meses grátis!
                </label>
                <Arrow className="SubscriptionPlansBillingPeriodSelectorArrow text-gray-700"/>
            </div>
        </>
    );
};

export default BillingPeriodSelector;