import {ToolsRemote} from "../dao/remote/tools";
import {ToolLocalDAO} from "../dao/local/ToolLocalDAO";

const create = async (tool) => {
    return ToolsRemote.create(tool)
        .then(tool => {
            ToolLocalDAO.put(tool);
            return tool;
        });
}

const update = async (tool) => {
    return ToolsRemote.update(tool)
        .then(tool => {
            ToolLocalDAO.update(tool);
            return tool;
        });
}

const publish = async (idTool) => {
    return ToolsRemote.publish(idTool)
        .then(tool => {
            ToolLocalDAO.update(tool);
            return tool;
        });
}

const unpublish = async (idTool) => {
    return ToolsRemote.unpublish(idTool)
        .then(tool => {
            ToolLocalDAO.update(tool);
            return tool;
        });
}

const remove = async (idTool) => {
    return ToolsRemote.remove(idTool)
        .then((idTool) => {
            ToolLocalDAO.remove(idTool);
            return idTool;
        });
}

const load = async () => {
    return ToolLocalDAO.list();
}

const fetch = async () => {
    return ToolsRemote.list()
        .then(tools => {
            ToolLocalDAO.clear()
                .then(() => ToolLocalDAO.bulkPut(tools));
            return tools;
        });
}

const fetchForOrganization = async () => {
    return ToolsRemote.listForOrganization()
        .then(tools => {
            ToolLocalDAO.clear()
                .then(() => ToolLocalDAO.bulkPut(tools));
            return tools;
        });
}

const fetchWithPrompt = async () => {
    return ToolsRemote.listWithPrompt()
        .then(tools => {
            ToolLocalDAO.clear()
                .then(() => ToolLocalDAO.bulkPut(tools));
            return tools;
        });
}

const ToolFacade = {
    create,
    update,
    publish,
    unpublish,
    remove,
    load,
    fetch,
    fetchForOrganization,
    fetchWithPrompt
}

export default ToolFacade;