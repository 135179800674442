import {Button, Col, Image, Row, Tooltip} from "antd";
import "./SubscriptionPricingTable.css";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faRocket} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useState} from "react";
import moment from "moment";
import {SelectButton} from "primereact/selectbutton";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {ReactComponent as Arrow} from "../../images/arrow.svg";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import {UserContext} from "../../utils/providers/userProvider";
import {useSubscriptionService} from "../../utils/hooks/useSubscriptionService";
import SkipTrialConfirmModal from "../../app/settings/subscription/components/SkipTrialConfirmationModal";

const SubscriptionPricingTable = ({subscriptions, activeSubscriptions, onCheckout, onPortal}) => {
    const {user} = useContext(UserContext);

    const [billingCycle, setBillingCycle] = useState("yearly"); // ["monthly", "yearly"

    const getBillingCycleOptions = () => {
        return [
            {
                label: 'Mensal',
                value: 'monthly'
            },
            {
                label: 'Anual',
                value: 'yearly'
            }
        ];
    }

    const renderBillingCycle = () => {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                position: "absolute",
                right: 24,
                top: 24
            }}>
                <SelectButton
                    className="SubscriptionPricingTableBillingCycle"
                    options={getBillingCycleOptions()}
                    value={billingCycle}
                    onChange={(e) => setBillingCycle(e.value)}
                />
                <div style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column"
                }}>
                    <div style={{display: "flex"}}>
                        <label className="SubscriptionPricingTableBillingCycleLabel">
                            2 meses grátis!
                        </label>
                        <Arrow className="SubscriptionPricingTableBillingCycleArrow"/>
                    </div>
                </div>


            </div>
        )
    }

    const renderTrialMessage = () => {
        const trialingSubscription = activeSubscriptions.find((activeSubscription) => activeSubscription.status === 'trialing');
        if (trialingSubscription) {
            return (
                <div className="SubscriptionPricingTableTrialWarning">
                    Seu período de testes finaliza
                    em {moment(trialingSubscription.trialEnd).diff(moment(), 'days')} dias
                </div>
            );
        }
    }

    const getActiveSubscription = (idSubscriptionPlan) => {
        if (_.isEmpty(activeSubscriptions)) {
            return null;
        }
        return activeSubscriptions.find((activeSubscription) => activeSubscription.idSubscriptionPlan === idSubscriptionPlan);
    }

    const hasSubscriptionTrial = (subscriptionPlan) => {
        const lastSubscribedAt = _.get(user, "settings.lastSubscribedAt", null);
        return subscriptionPlan.hasTrial && lastSubscribedAt === null;
    }

    if (_.isEmpty(subscriptions)) {
        return <LoadingScreen/>;
    }

    return (
        <div className="SubscriptionPricingTable">
            {renderBillingCycle()}
            <Row gutter={24} style={{width: "100%", display: "flex", justifyContent: "center"}}>
                {_.orderBy(subscriptions, ["precedence"], ["asc"]).map((subscription) => {
                    const activeSubscription = getActiveSubscription(subscription.idSubscriptionPlan);
                    return (
                        <Col key={subscription.idSubscription} style={{marginTop: 24}}>
                            <SubscriptionPricingColumn
                                idSubscription={subscription.idSubscriptionPlan}
                                status={_.get(activeSubscription, 'status', null)}
                                idOrganization={subscription.idOrganization}
                                precedence={subscription.precedence}
                                activeSubscriptionBillingPeriod={_.get(activeSubscription, "plan.interval") === 'month' ? 'monthly' : 'yearly'}
                                image={`${process.env.REACT_APP_TOOL_SUBSCRIPTIONS_ICON_S3_BUCKET_PREFIX_URL}/${subscription.icon}`}
                                title={subscription.name}
                                label={subscription.label}
                                product={subscription.product}
                                features={subscription.features}
                                hasTrial={hasSubscriptionTrial(subscription)}
                                billingCycle={billingCycle}
                                tag={subscription.tag}
                                highlight={subscription.highlighted}
                                onCheckout={onCheckout}
                                onPortal={onPortal}
                                activeSubscriptions={activeSubscriptions}
                            />
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}

const SubscriptionPricingColumn = ({
                                       idSubscription,
                                       status,
                                       precedence,
                                       idOrganization,
                                       activeSubscriptionBillingPeriod,
                                       image,
                                       title,
                                       billingCycle,
                                       label,
                                       product,
                                       features,
                                       hasTrial,
                                       tag,
                                       ltdPrice,
                                       highlight,
                                       onCheckout,
                                       onPortal,
                                       activeSubscriptions
                                   }) => {
    const subscriptionService = useSubscriptionService();
    const [openSkipTrialModal, setOpenSkipTrialModal] = useState(false);

    const handleCheckoutButton = () => {
        const activeSubscriptionPlan = getActiveSubscriptionPlan();
        const activeSubscription = subscriptionService.getUserActiveSubscription();

        if (activeSubscriptionPlan) {
            if (activeSubscription.status === "trialing" && getButtonLabel() === "Pular período de testes") {
                setOpenSkipTrialModal(true);
            } else {
                onPortal();
            }
        } else {
            onCheckout({
                idSubscription,
                billingCycle
            })
        }
    }

    const getMonthlyPrice = () => {
        const prices = _.get(product, "prices", []);
        const monthlyPrice = _.head(prices.filter((price) => price.billingCycle === 'monthly'));
        const yearlyPrice = _.head(prices.filter((price) => price.billingCycle === 'yearly'));

        if (billingCycle === 'yearly') {
            const price = _.get(yearlyPrice, "value", 0);
            return price ? formatPrice(price / 12) : formatPrice(999);
        } else {
            return formatPrice(_.get(monthlyPrice, "value", 999));
        }
    }

    const getDailyPrice = () => {
        const prices = _.get(product, "prices", []);
        const monthlyPrice = _.head(prices.filter((price) => price.billingCycle === 'monthly'));
        const yearlyPrice = _.head(prices.filter((price) => price.billingCycle === 'yearly'));

        if (billingCycle === 'yearly') {
            const price = _.get(yearlyPrice, "value", 0);
            return price ? formatPrice(price / 365, 2) : formatPrice(999);
        } else {
            const price = _.get(monthlyPrice, "value", 0);
            return price ? formatPrice(price / 30, 2) : formatPrice(999);
        }
    }

    const formatPrice = (price, decimals = 0) => {
        if (price === 0) {
            return 999;
        }

        return price.toFixed(decimals).replace('.', ',');
    }

    const getActiveSubscriptionPlan = () => {
        if (_.isEmpty(activeSubscriptions)) {
            return null;
        }

        return activeSubscriptions.map((activeSubscription) => activeSubscription.subscriptionPlan)
            .find((subscriptionPlan) => subscriptionPlan && subscriptionPlan.idOrganization === idOrganization);
    }

    const isMorePrecedent = () => {
        const activeSubscriptionPlan = getActiveSubscriptionPlan();
        if (activeSubscriptionPlan) {
            return activeSubscriptionPlan.precedence < precedence;
        }
        return false;
    }

    const isLessPrecedent = () => {
        const activeSubscriptionPlan = getActiveSubscriptionPlan();
        if (activeSubscriptionPlan) {
            return activeSubscriptionPlan.precedence > precedence;
        }
        return false;
    }

    const translateBillingCycle = (billingCycle) => {
        if (billingCycle === 'monthly') {
            return 'mensal';
        } else if (billingCycle === 'yearly') {
            return 'anual';
        }
    }

    const getButtonLabel = () => {
        if (status === 'active' || status === 'trialing') {
            if (activeSubscriptionBillingPeriod !== billingCycle) {
                return `Mudar para o plano ${translateBillingCycle(billingCycle)}`;
            }

            if (status === 'trialing') {
                return 'Pular período de testes';
            }

            return "Plano atual"
        } else if (hasTrial && status !== 'trialing') {
            return 'Iniciar teste grátis';
        } else if (getActiveSubscriptionPlan()) {
            if (isMorePrecedent()) {
                return 'Fazer upgrade';
            }
            if (isLessPrecedent()) {
                return 'Fazer downgrade';
            }
            return 'Assinar';
        } else {
            return 'Assinar';
        }
    }

    const renderTag = () => {
        if (status === 'trialing') {
            return (
                <div className="SubscriptionPricingColumnTag SubscriptionPricingColumnTagTrial">
                    Em trial
                </div>
            );
        }

        if (tag) {
            return (
                <div className="SubscriptionPricingColumnTag shimmer-effect">
                    {tag}
                </div>
            );
        }
    }

    const renderImage = () => {
        if (!image)
            return;

        return (
            <div className="SubscriptionPricingColumnImage">
                <Image
                    preview={false}
                    src={image}
                    alt={title}
                    width={120}
                    height={120}
                />
            </div>
        );
    }

    const renderTitle = () => {
        return (
            <div className="SubscriptionPricingColumnTitle">{title}</div>
        );
    }

    const renderPrice = () => {
        if (ltdPrice) {
            return (
                <div>
                    <div className="SubscriptionPricingColumnPrice">
                        <div className="SubscriptionPricingColumnMonthlyPriceContainer">
                            <div className="SubscriptionPricingColumnLTDPrice">{ltdPrice}</div>
                        </div>
                    </div>

                    <Tooltip title={"Pague uma vez e tenha acesso para sempre!"} color={"#724CF9"}>
                        <div className="SubscriptionPricingColumnDailyPrice">
                            <label><b>plano vitalício</b></label>
                        </div>
                    </Tooltip>
                </div>
            );
        }

        const renderDailyPriceLabel = () => {
            return `Apenas <b>R$ ${getDailyPrice()}</b> por dia!`
        }

        return (
            <div>
                <div className="SubscriptionPricingColumnPrice">
                    <div className="SubscriptionPricingColumnMonthlyPriceContainer">
                        <div className="SubscriptionPricingColumnMonthlyPriceAppendix">R$</div>
                        <div className="SubscriptionPricingColumnMonthlyPrice">{getMonthlyPrice()}</div>
                        <div className="SubscriptionPricingColumnMonthlyPriceAppendix">/mês</div>
                    </div>
                    {billingCycle === 'yearly' &&
                        <label className="SubscriptionPricingColumnMonthlyPriceBillingLabel">no plano anual</label>}
                    {billingCycle === 'monthly' &&
                        <label className="SubscriptionPricingColumnMonthlyPriceBillingLabel">no plano mensal</label>}
                </div>

                <div className="SubscriptionPricingColumnDailyPrice">
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                    >
                        {renderDailyPriceLabel()}
                    </ReactMarkdown>
                </div>

            </div>
        );
    }

    const renderCheckoutButton = () => {
        const disabled = status === 'active' && activeSubscriptionBillingPeriod === billingCycle;
        return (
            <Button
                className="SubscriptionPricingColumnButton"
                type={highlight ? "primary" : "default"}
                block
                size="large"
                style={{borderRadius: 24}}
                icon={!disabled && <FontAwesomeIcon icon={faRocket} style={{marginRight: 8}}/>}
                onClick={handleCheckoutButton}
                disabled={disabled}
            >
                {getButtonLabel()}
            </Button>
        );
    }

    const renderFeatures = () => {
        if (_.isEmpty(features))
            return;

        return (
            <div className="SubscriptionPricingColumnFeatures">
                {features.map(feature => {
                    return (
                        <div className="SubscriptionPricingColumnFeature">
                            <FontAwesomeIcon className="SubscriptionPricingColumnFeatureIcon" icon={faCheckCircle}/>
                            <label className="SubscriptionPricingColumnFeatureLabel">
                                {feature}
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    }

    const renderSkipTrialModal = () => {
        const activeSubscription = _.head(activeSubscriptions.filter((activeSubscription) => _.get(activeSubscription, "subscriptionPlan.idSubscriptionPlan", null) === idSubscription));
        if (!_.isEmpty(activeSubscription)) {
            return <SkipTrialConfirmModal
                idSubscription={activeSubscription.idSubscription}
                isOpen={openSkipTrialModal}
                onClose={() => setOpenSkipTrialModal(false)}
            />
        }

    }

    return (
        <div className={`SubscriptionPricingColumn ${highlight && "SubscriptionPricingColumnHighlight"}`}>
            {renderTag()}
            {renderImage()}
            {renderTitle()}
            {renderPrice()}
            {renderCheckoutButton()}
            {renderFeatures()}
            {renderSkipTrialModal()}
        </div>
    );
}

export default SubscriptionPricingTable;