import {useContext} from "react";
import {UserContext} from "../../../../utils/providers/userProvider";
import ImageGalleryComponent from "./ImageGalleryComponent";
import {useScreenSize} from "../../../../utils/hooks/useScreenSize";
import { useImages } from "../../../../utils/hooks/useImages"; // Importar aqui

const ImageGallery = ({onScreenChange}) => {
    const {user} = useContext(UserContext);
    const screenSize = useScreenSize();
    const images = useImages(); // Obter as imagens aqui

    const getImageSize = () => {
        switch (screenSize) {
            case 'xs':
                return 64;
            case 'sm':
            case 'md':
                return 96;
            case 'lg':
            case 'xl':
                return 256;
            case 'xxl':
                return 384;
            default:
                return 128;
        }
    }

    return (
        <ImageGalleryComponent
            user={user}
            size={getImageSize()}
            preview={true}
            optionsMenu={true}
            rows={25}
            onScreenChange={onScreenChange}
            images={images} // Passar as imagens como prop
        />
    );
};

export default ImageGallery;

// const GalleryGridItem = ({image, idUser, onScreenChange}) => {
//     return (
//         <div className="ImageGalleryGridItem">
//             <Image
//                 className="ImageGalleryImage"
//                 height={256}
//                 width={256}
//                 src={getImageUrl(idUser, image)}
//             />
//
//             <div className="ImageGalleryImageMenu">
//                 <ImageMenuOptionsWrapper
//                     idUser={idUser}
//                     image={image}
//                     options={["edit", "download", "delete"]}
//                     onScreenChange={onScreenChange}
//                 />
//             </div>
//
//         </div>
//     );
// }
//
// const GalleryListItem = ({image, idUser}) => {
//     const renderImage = () => {
//         return (
//             <Image
//                 className="ImageGalleryImage"
//                 height={128}
//                 width={128}
//                 src={getImageUrl(idUser, image)}
//                 preview={false}
//             />
//         );
//     }
//
//     const renderInfo = () => {
//         return (
//             <div className="ImageGalleryListItemInfo">
//                 <div className="ImageGalleryListItemTitle">{image.title}</div>
//                 <div className="ImageGalleryListItemPrompt">{image.prompt}</div>
//             </div>
//         )
//     }
//
//     return (
//         <Space className="ImageGalleryListItem" align="start">
//             {renderImage()}
//             {renderInfo()}
//             <div className="ImageGalleryListItemMenuOptions">
//                 <ImageMenuOptionsWrapper image={image} idUser={idUser} />
//             </div>
//         </Space>
//     );
// }