import {urlBase, params} from "./resources";

const url = urlBase + "/api/tools";

async function create(tool) {
    const content = {body: tool};
    return fetch(`${url}`, await params.post(content))
        .then(response => response.json());
}

async function update(tool) {
    const content = {body: tool};
    return fetch(`${url}`, await params.patch(content))
        .then(response => response.json());
}

async function publish(idTool) {
    const content = {body: {idTool}};
    return fetch(`${url}/publish`, await params.post(content))
        .then(response => response.json());
}

async function unpublish(idTool) {
    const content = {body: {idTool}};
    return fetch(`${url}/unpublish`, await params.post(content))
        .then(response => response.json());
}

async function get(idTool) {
    return fetch(`${url}/${idTool}`, await params.get())
        .then(response => response.json());
}

async function getForOrganization(idTool) {
    return fetch(`${url}/org/${idTool}`, await params.get())
        .then(response => response.json());
}

async function list() {
    return fetch(`${url}`, await params.get())
        .then(response => response.json());
}

async function listForOrganization() {
    return fetch(`${url}/org`, await params.get())
        .then(response => response.json());
}

async function listWithPrompt(prompt) {
    return fetch(`${url}/creator`, await params.get())
        .then(response => response.json());
}

async function remove(idTool) {
    return fetch(`${url}/${idTool}`, await params.delete())
        .then(response => response.json());
}

async function generate(data) {
    const controller = new AbortController();
    const content = {body: data, controller: controller}
    const stream = await fetch(`${url}/generate`, await params.post(content))

    if (!stream.ok) {
        throw new Error(`HTTP error! status: ${stream.status}`);
    }

    return {
        controller,
        reader: stream.body.getReader()
    };
}

async function generateForOrganization(data) {
    const controller = new AbortController();
    const content = {body: data, controller: controller}
    const stream = await fetch(`${url}/org/generate`, await params.post(content))

    if (!stream.ok) {
        throw new Error(`HTTP error! status: ${stream.status}`);
    }

    return {
        controller,
        reader: stream.body.getReader()
    };
}

export const ToolsRemote = {
    create,
    update,
    get,
    list,
    listForOrganization,
    listWithPrompt,
    generate,
    generateForOrganization,
    publish,
    unpublish,
    remove,
    getForOrganization
};