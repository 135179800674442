import {Auth} from "aws-amplify";

export const useStripeService = () => {
    const getToken = async () => {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    const openCheckoutWindow = async ({idSubscription, billingCycle, numberOfUsers = 1}) => {
        const token = await getToken();
        const queryParams = new URLSearchParams({token, idSubscription, billingCycle, numberOfUsers});
        window.open(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/checkout?${queryParams}`, '_blank');
    }

    const openCustomerPortalWindow = async () => {
        const token = await getToken();
        const queryParams = new URLSearchParams({token});
        window.open(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/portal?${queryParams}`, '_blank');
    }

    return {
        openCheckoutWindow,
        openCustomerPortalWindow
    };
}