import {Col, Row} from "antd";
import "../Settings.css";

const SettingsContainer = ({containerized, children}) => {
    if (containerized) {
        return (
            <Row>
                <Col
                    xs={{span: 22, offset: 1}}
                    lg={{span: 18, offset: 3}}
                    xl={{span: 18, offset: 3}}
                    xxl={{span: 14, offset: 5}}
                >
                    <div className="SettingsOutsideContainer">
                        <div className="SettingsInsideContainer">
                            {children}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    return (
        <div className="SettingsOutsideContainer">
            <div className="SettingsInsideContainer">
                {children}
            </div>
        </div>
    );
}

export default SettingsContainer;