import ImageMenuOptions from "../menu-options/ImageMenuOptions";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import ImageDAORemote from "../../../../services/dao/remote/ImageDAORemote";
import {message} from "antd";
import {saveAs} from "file-saver";
import {useCurrentWorkspace} from "../../../../utils/hooks/useCurrentWorkspace";

const ImageMenuOptionsWrapper = ({idUser, image, options, onScreenChange}) => {
    const queryClient = useQueryClient();
    const {currentWorkspace} = useCurrentWorkspace();

    const remove = useMutation({
        mutationFn: (idImage) => ImageDAORemote.remove(idImage),
        onSuccess: (idImage) => {
            queryClient.setQueryData(["images", currentWorkspace.idWorkspace], (old) => {
                return old.filter((i) => i.idImage !== idImage)
            });
            queryClient.invalidateQueries(["images", currentWorkspace.idWorkspace]);
            message.success('Imagem removida');
        }
    })

    const onEdit = () => {
        onScreenChange("editor", image.idImage);
    }

    const onDownload = () => {
        const url = `${process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL}/${currentWorkspace?.idWorkspace}/${image.filename}.${image.extension}`;
        saveAs(url, `CMOs.ai_${new Date().toISOString()}.png`);
    };

    const onRemove = () => {
        remove.mutate(image.idImage);
    }

    return (
        <ImageMenuOptions
            onEdit={onEdit}
            onDownload={onDownload}
            onRemove={onRemove}
            options={options}
        />
    );
}

export default ImageMenuOptionsWrapper;