export const useLegalDocumentValidation= () => {
    const validateCPF = (cpf) => {
        const validateVerificationDigit = (digits, verificationDigit) => {
            const sum = digits.reduce((accumulator, currentValue, index) => {
                return accumulator + currentValue * ((digits.length + 1) - index);
            }, 0);

            let rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) {
                rest = 0;
            }

            if (rest !== verificationDigit) {
                return false;
            }
            return rest === verificationDigit;
        }

        if (cpf.length !== 14) {
            return false;
        }

        const invalidCPFs = [
            "00000000000",
            "11111111111",
            "22222222222",
            "33333333333",
            "44444444444",
            "55555555555",
            "66666666666",
            "77777777777",
            "88888888888",
            "99999999999"
        ];

        if (invalidCPFs.includes(cpf.replace(/[.-]/g, ''))) {
            return false;
        }

        const cpfDigits = cpf.replace(/[.-]/g, '').split("").map((digit) => parseInt(digit));

        const cpfDigitsWithoutVerificationDigits = cpfDigits.slice(0, 9);
        const cpfDigitsWithoutSecondVerificationDigit = cpfDigits.slice(0, 10);

        const firstVerificationDigit = cpfDigits[9];
        const secondVerificationDigit = cpfDigits[10];

        const isFirstVerificationDigitValid = validateVerificationDigit(
            cpfDigitsWithoutVerificationDigits,
            firstVerificationDigit
        );

        const isSecondVerificationDigitValid = validateVerificationDigit(
            cpfDigitsWithoutSecondVerificationDigit,
            secondVerificationDigit
        )

        return isFirstVerificationDigitValid && isSecondVerificationDigitValid;
    }

    const validateCNPJ = (cnpj) => {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return false;

        let tamanhoTotal = cnpj.length - 2
        let cnpjSemDigitos = cnpj.substring(0, tamanhoTotal).split("").map((digit) => parseInt(digit));
        let digitosVerificadores = cnpj.substring(tamanhoTotal).split("").map((digit) => parseInt(digit));
        let soma = 0;
        let pos = tamanhoTotal - 7;

        for (let i = tamanhoTotal; i >= 1; i--) {
            soma += cnpjSemDigitos[tamanhoTotal - i] * pos--;
            if (pos < 2)
                pos = 9;
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== digitosVerificadores[0]) {
            return false;
        }

        tamanhoTotal = tamanhoTotal + 1;
        cnpjSemDigitos = cnpj.substring(0, tamanhoTotal).split("").map((digit) => parseInt(digit));
        soma = 0;
        pos = tamanhoTotal - 7;
        for (let i = tamanhoTotal; i >= 1; i--) {
            soma += cnpjSemDigitos[tamanhoTotal - i] * pos--;
            if (pos < 2)
                pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        return resultado === digitosVerificadores[1];
    }

    return {
        validateCPF,
        validateCNPJ
    }
};