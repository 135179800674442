import {useQuery} from "@tanstack/react-query";
import OrganizationRemote from "../../services/dao/remote/OrganizationDAORemote";
import {useEffect, useState} from "react";

export const useSubscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);

    const query = useQuery({
        queryKey: ['subscriptions'],
        queryFn: () => OrganizationRemote.getSubscriptionPlans(),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setSubscriptions(data);
        }
    });

    return subscriptions;
}