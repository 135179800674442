import {Col, InputNumber, Row, Select, Slider} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import "./PlaygroundAttributes.css";
import BrandVoiceSelector from "../chat/BrandVoiceSelector";
import {useGenerationModelClasses} from "../../utils/hooks/useGenerationModels";
import {usePublishedSpecialties} from "../../utils/hooks/useSpecialties";

const PlaygroundAttributes = forwardRef((props, ref) => {
    const [specialty, setSpecialty] = useState(null);
    const [generationModelClass, setGenerationModelClass] = useState(null);
    const [temperature, setTemperature] = useState(1);
    const [maximumLength, setMaximumLength] = useState(1000);
    const [brandVoice, setBrandVoice] = useState(null);

    const {publishedSpecialties, isLoading} = usePublishedSpecialties();
    const {generationModelClasses, isLoading: isGenerationModelClassesLoading} = useGenerationModelClasses();

    useImperativeHandle(ref, () => ({
        specialty,
        generationModelClass,
        temperature,
        maximumLength,
        brandVoice
    }));

    useEffect(() => {
        if (generationModelClasses?.length > 0) {
            setGenerationModelClass(generationModelClasses[0].value);
        }
    }, [generationModelClasses]);

    const getSpecialtyOptions = () => {
        if (!publishedSpecialties || isLoading) return [];

        return publishedSpecialties.map(specialty => {
            return {
                value: specialty.idSpecialty,
                label: specialty.name
            }
        });
    }

    const getModelOptions = () => {
        if (isGenerationModelClassesLoading || !generationModelClasses) {
            return [];
        }

        return generationModelClasses.map(model => {
            return {
                value: model.value,
                label: model.name
            }
        });
    }

    const renderTitle = () => {
        return (
            <div className="PlaygroundAttributesTitle">
                Atributos
            </div>
        );
    }

    const renderSpecialty = () => {
        return (
            <div className="PlaygroundAttributesAttribute">
                <div className="PlaygroundAttributesAttributeTitle">
                    Especialidade
                </div>
                <div>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        dropdownMatchSelectWidth={false}
                        allowClear
                        placeholder="Especialidade"
                        value={specialty?.idSpecialty}
                        options={getSpecialtyOptions()}
                        onChange={(value) => setSpecialty(publishedSpecialties.find(specialty => specialty.idSpecialty === value))}
                    />
                </div>
            </div>
        );
    }

    const renderModel = () => {
        return (
            <>
                <div className="PlaygroundAttributesAttributeTitle">
                    Modelo
                </div>
                <div>
                    <Select
                        style={{width: "100%"}}
                        showSearch
                        dropdownMatchSelectWidth={false}
                        allowClear
                        placeholder="Modelo"
                        value={generationModelClass}
                        options={getModelOptions()}
                        onChange={(value) => setGenerationModelClass(value)}
                        disabled={isGenerationModelClassesLoading || !generationModelClasses}
                        loading={isGenerationModelClassesLoading}
                    />
                </div>
            </>
        );
    }

    const renderTemperature = () => {
        const [min, max, step] = [0.01, 1, 0.01];
        return (
            <>
                <div className="PlaygroundAttributesAttributeTitle">Temperatura</div>
                <Row>
                    <Col flex="auto">
                        <Slider
                            value={temperature}
                            onChange={(value) => setTemperature(value)}
                            min={min}
                            max={max}
                            step={step}
                        />
                    </Col>
                    <Col flex="70px">
                        <InputNumber
                            min={min}
                            max={max}
                            step={step}
                            onChange={(value) => setTemperature(value)}
                            value={temperature}
                            style={{width: "70px"}}
                            bordered={false}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    const renderMaximumLength = () => {
        const [min, max, step] = [1, 200000, 100];
        return (
            <>
                <div className="PlaygroundAttributesAttributeTitle">Comprimento máximo</div>
                <Row>
                    <Col flex="auto">
                        <Slider
                            value={maximumLength}
                            onChange={(value) => setMaximumLength(value)}
                            min={min}
                            max={max}
                            step={step}
                        />
                    </Col>
                    <Col flex="70px">
                        <InputNumber
                            min={min}
                            max={max}
                            step={step}
                            onChange={(value) => setMaximumLength(value)}
                            value={maximumLength}
                            style={{width: "70px"}}
                            bordered={false}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <div className="PlaygroundAttributesContainer">
            {renderTitle()}
            <div className="PlaygroundAttributesAttribute">{renderSpecialty()}</div>
            <div className="PlaygroundAttributesAttribute">{renderModel()}</div>
            <div className="PlaygroundAttributesAttribute">{renderTemperature()}</div>
            <div className="PlaygroundAttributesAttribute">{renderMaximumLength()}</div>
            <div className="flex">
                <BrandVoiceSelector
                    brandVoice={brandVoice}
                    onChange={setBrandVoice}
                />
            </div>
        </div>
    );
});

export default PlaygroundAttributes;