import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import ImageRemote from "../../services/dao/remote/ImageDAORemote";
import { useCurrentWorkspace } from "./useCurrentWorkspace";

export const useImages = () => {
    const { currentWorkspace } = useCurrentWorkspace();

    // Ensure currentWorkspace.idWorkspace is defined before using it in queryKey
    const workspaceId = currentWorkspace?.idWorkspace;

    const {
        data: images = [], // Default to an empty array if data is undefined
        refetch,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['images', workspaceId],
        queryFn: () => ImageRemote.getAll(),
        refetchOnWindowFocus: false,
        enabled: !!workspaceId, // Only run query if workspaceId is defined
    });

    // No need for useEffect to set images in state; use the data directly

    const sortedImages = useMemo(() => {
        return images.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
    }, [images]);

    // Return an object containing images and refetch
    return {
        images: sortedImages,
        refetch,
        isLoading,
        isError,
        error,
    };
};
