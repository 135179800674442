import {useState, useEffect} from "react";

export default function useIsMobile() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth <= 1200);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
}