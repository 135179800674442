import logo from "../../images/logo-purple.svg";
import { Button, Menu, Tag } from "antd";
import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faComments,
    faWandSparkles,
    faFlask,
    faPenNib,
    faGaugeHigh,
    faChartSimple,
    faMoneyBill,
    faBriefcase,
    faLandmark,
    faImage,
    faBullhorn,
    faMicrochip
} from "@fortawesome/free-solid-svg-icons";
import { PermissionGroupsContext } from "../../utils/providers/permissionGroupsProvider";
import _ from "lodash";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import WalletBalanceWidget from "../usage/WalletBalanceWidget";
import { OrganizationContext } from "../../utils/providers/organizationProvider";
import UpgradeButton from "../subscription/UpgradeButton";
import useIsMobile from "../../utils/hooks/useIsMobile";
import WorkspaceSelector from "../../app/workspace/WorkspaceSelector";
import { Theme } from "../../api/Theme";

const MainMenu = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [permissionGroups] = useContext(PermissionGroupsContext);
    const [organization] = useContext(OrganizationContext);

    const { onClose, user } = props;

    const handleMenuClick = (key, subkey = null) => {
        let to = `/${key}`;
        if (subkey) {
            to = `/t/${subkey}`;
        }

        if (location.pathname !== to) {
            navigate(to, { state: { categoryKey: subkey } });
        }

        if (onClose)
            onClose();
    }

    const tokenize = (text) => {
        return text.toLowerCase()
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '-')
            .replace(/\([^)]*\)/g, '')
            .replace(/[^a-zA-Z0-9_-]/g, '');
    }

    const handleOrganizationMenuClick = (organization) => {
        const to = `/org/${tokenize(organization.name)}`;

        if (location.pathname !== to) {
            navigate(to, { state: { organization } });
        }

        if (onClose)
            onClose();
    }

    const handleLogoClick = () => {
        navigate("/");
    }

    const handleOpenAcademy = () => {
        window.open('https://thecmosmarketers.digital/', '_blank');

        if (onClose)
            onClose();
    }

    const getDefaultSelectedKeys = () => {
        if (!_.isEmpty(permissionGroups)) {
            const allowedGroups = ["PartnershipMetodoAureo"];
            if (allowedGroups.some(group => permissionGroups.includes(group))) {
                return ["partnership"];
            }
        }

        return ["chat"];
    }

    const menuItems = useMemo(() => {
        const items = [
            {
                key: 'chat',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faComments} />,
                label: 'Chat especialista',
                onClick: () => handleMenuClick("chat")
            },
            {
                key: 'tools',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faWandSparkles} />,
                label: 'Agentes',
                children: [
                    {
                        key: 'content',
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faPenNib} />,
                        label: 'Conteúdo',
                        onClick: () => handleMenuClick("tools", "content")
                    },
                    {
                        key: 'social',
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faFacebook} />,
                        label: 'Redes sociais',
                        onClick: () => handleMenuClick("tools", "social")
                    },
                    {
                        key: 'productivity',
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faGaugeHigh} />,
                        label: 'Produtividade',
                        onClick: () => handleMenuClick("tools", "productivity")
                    },
                    {
                        key: 'management',
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faChartSimple} />,
                        label: 'Gestão',
                        onClick: () => handleMenuClick("tools", "management")
                    },
                    {
                        key: 'sales',
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faMoneyBill} />,
                        label: 'Vendas',
                        onClick: () => handleMenuClick("tools", "sales")
                    },
                ]
            },
            {
                key: 'image',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faImage} />,
                label: 'Imagens',
                onClick: () => handleMenuClick("image")
            },
            {
                key: 'brand-voice',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faBullhorn} />,
                label: (
                    <div className="flex items-center justify-between">
                        Brand voice
                        <Tag color={Theme.PRIMARY}>Novo</Tag>
                    </div>
                ),
                onClick: () => handleMenuClick("brand-voice")
            },
        ];

        // Condicional: Creator
        const allowedGroupsForCreator = ["Gods", "Administrators", "ContentCreators"];
        if (!_.isEmpty(permissionGroups) && allowedGroupsForCreator.some(group => permissionGroups.includes(group))) {
            items.push({
                key: 'creator',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faMicrochip} />,
                label: 'Creator',
                onClick: () => handleMenuClick("creator")
            });
        }

        // Condicional: Playground
        if (!_.isEmpty(permissionGroups) && allowedGroupsForCreator.some(group => permissionGroups.includes(group))) {
            items.push({
                key: 'playground',
                icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faFlask} />,
                label: 'Playground',
                onClick: () => handleMenuClick("playground")
            });
        }

        // Condicional: Organizações
        if (user && user.organizations) {
            const organizations = user.organizations.filter(org => org.idOrganization > 1);
            if (!_.isEmpty(organizations)) {
                // Adiciona um divisor
                items.push({
                    type: 'divider'
                });

                // Adiciona cada organização como item
                organizations.forEach(org => {
                    items.push({
                        key: `organization-${org.idOrganization}`,
                        icon: <FontAwesomeIcon className="MainMenuItemIcon" icon={faBriefcase} />,
                        label: org.name,
                        onClick: () => handleOrganizationMenuClick(org)
                    });
                });
            }
        }

        return items;
    }, [permissionGroups, user, location.pathname, onClose]);

    return (
        <div className="MainMenu">
            {/* Logo */}
            <img
                className="MainMenuLogo"
                src={logo}
                alt="Logo do CMOs.ai"
                onClick={handleLogoClick}
            />
            {/* Divider */}
            <div className="MainMenuDivider" />

            {/* Menu */}
            <Menu
                defaultSelectedKeys={getDefaultSelectedKeys()}
                defaultOpenKeys={isMobile ? [] : ['tools']}
                mode="inline"
                theme="light"
                items={menuItems}
            />

            {/* End Section */}
            <div className="MainMenuEndSection flex flex-col gap-2">
                {/* <SubscriptionWidget/> */}
                <WalletBalanceWidget currentOrganization={organization}>
                    <UpgradeButton size="large" />
                </WalletBalanceWidget>
                <WorkspaceSelector />
                <Button
                    type="default"
                    size="large"
                    block
                    onClick={handleOpenAcademy}
                    style={{ marginTop: 4, borderRadius: 12 }}
                >
                    <FontAwesomeIcon style={{ marginRight: 8 }} icon={faLandmark} />
                    Academy
                </Button>
            </div>
        </div>
    )
}

export default MainMenu;
