// src/components/Artifact/ArtifactViewer.js

import React, { useMemo, useEffect, useState } from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";
// Removemos a importação estática do Babel
// import * as Babel from "@babel/standalone";
import "./ArtifactViewer.css"; // Importação do CSS

// Delimitador único para separar JS e CSS
const DELIMITER = "===CSS_START===";

// Mapeamento de módulos para URLs CDN, nomes globais e exportações padrão
const moduleCDNMapping = {
  react: [
    "https://unpkg.com/react@17.0.2/umd/react.development.js",
    "React",
    null,
  ],
  "react-dom": [
    "https://unpkg.com/react-dom@17.0.2/umd/react-dom.development.js",
    "ReactDOM",
    null,
  ],
  "prop-types": [
    "https://unpkg.com/prop-types@15.8.1/prop-types.min.js",
    "PropTypes",
    null,
  ],
  antd: [
    "https://cdnjs.cloudflare.com/ajax/libs/antd/4.22.8/antd.min.js",
    "antd",
    null,
  ],
  "@mui/material": [
    "https://unpkg.com/@mui/material@5.13.0/umd/material-ui.development.js",
    "MaterialUI",
    null,
  ],
  "@emotion/react": [
    "https://unpkg.com/@emotion/react@11.10.6/dist/emotion-react.umd.min.js",
    "emotionReact",
    null,
  ],
  "@emotion/styled": [
    "https://unpkg.com/@emotion/styled@11.10.6/dist/emotion-styled.umd.min.js",
    "emotionStyled",
    "styled",
  ],
  "styled-components": [
    "https://unpkg.com/styled-components/dist/styled-components.min.js",
    "styled",
    null,
  ],
  "chart.js": [
    "https://cdn.jsdelivr.net/npm/chart.js@3.9.1/dist/chart.min.js",
    "Chart",
    null,
  ],
  // Recharts não tem um build UMD; vamos mockar seus componentes
  // Não é necessário incluir Recharts em moduleCDNMapping
};

// Função para extrair módulos importados
const extractImports = (code) => {
  const importRegex = /import\s+(?:[^'"]+?\s+from\s+)?['"]([^'"]+)['"];?/g;
  const imports = new Set();
  let match;

  while ((match = importRegex.exec(code)) !== null) {
    imports.add(match[1]);
  }

  return Array.from(imports);
};

// Função para substituir importações por declarações de variáveis globais
const replaceImports = (code, cssImports) => {
  // Remover todas as importações de CSS
  code = code.replace(/import\s+['"]([^'"]+\.css)['"];?/g, (match, cssPath) => {
    cssImports.add(cssPath);
    return "";
  });

  // Substituir diferentes tipos de importações
  code = code.replace(
    /import\s+((?:[\w*{}\s,]*)?)\s*from\s+['"]([^'"]+)['"];?/g,
    (match, imports, moduleName) => {
      const mapping = moduleCDNMapping[moduleName];
      if (mapping && mapping[0]) {
        const [url, globalName, defaultExport] = mapping;
        let replacement = "";

        if (imports) {
          // Processar importações padrão e nomeadas
          const defaultImportMatch = imports.match(/^([\w]+)(?:,)?/);
          const namedImportsMatch = imports.match(/{([^}]+)}/);

          if (defaultImportMatch) {
            const defaultImport = defaultImportMatch[1];
            if (defaultExport) {
              replacement += `const ${defaultImport} = window.${globalName}.${defaultExport};\n`;
            } else {
              replacement += `const ${defaultImport} = window.${globalName};\n`;
            }
          }

          if (namedImportsMatch) {
            const namedImports = namedImportsMatch[1]
              .split(",")
              .map((imp) => imp.trim().replace(/\s+as\s+/g, ": "))
              .join(", ");
            replacement += `const { ${namedImports} } = window.${globalName};\n`;
          }
        } else {
          // Importação sem especificação (possivelmente para efeitos colaterais)
          replacement += `// Importação de efeitos colaterais para "${moduleName}" foi removida.\n`;
        }

        return replacement;
      } else {
        // Tratamento para módulos não encontrados no mapeamento
        if (moduleName.startsWith('@/')) {
          const importedComponents = imports
            .replace(/[{}]/g, "")
            .split(",")
            .map((imp) => imp.trim());
          let replacement = "";
          importedComponents.forEach((component) => {
            replacement += `const ${component} = window['${component}'];\n`;
          });
          return replacement;
        } else if (moduleName === 'recharts') {
          // Mockar componentes do Recharts
          const importedComponents = imports
            .replace(/[{}]/g, "")
            .split(",")
            .map((imp) => imp.trim());
          let replacement = "";
          importedComponents.forEach((component) => {
            replacement += `const ${component} = window['${component}'];\n`;
          });
          return replacement;
        } else {
          console.warn(
            `Módulo "${moduleName}" não possui URL de CDN mapeada. Importação removida.`
          );
          return `// Módulo "${moduleName}" não possui URL de CDN mapeada. Importação removida.\n`;
        }
      }
    }
  );

  // Substituir importações de namespace
  code = code.replace(
    /import\s+\*\s+as\s+([\w]+)\s+from\s+['"]([^'"]+)['"];?/g,
    (match, alias, moduleName) => {
      const mapping = moduleCDNMapping[moduleName];
      if (mapping && mapping[0]) {
        const [url, globalName] = mapping;
        return `const ${alias} = window.${globalName};\n`;
      } else {
        console.warn(
          `Módulo "${moduleName}" não possui URL de CDN mapeada. Importação removida.`
        );
        return `// Módulo "${moduleName}" não possui URL de CDN mapeada. Importação removida.\n`;
      }
    }
  );

  return code;
};

// Função para normalizar caminhos, removendo './' ou '/'
const normalizePath = (path) => path.replace(/^\.\/|^\//, "");

// Função para extrair múltiplos blocos de código do codeContent
const extractCodeBlocks = (codeContent) => {
  const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
  const codeBlocks = [];
  let match;

  while ((match = codeBlockRegex.exec(codeContent)) !== null) {
    const language = match[1] ? match[1].trim() : "";
    const code = match[2];
    codeBlocks.push({ language, code });
  }

  return codeBlocks;
};

// Função para identificar o nome do componente
const getComponentName = (code) => {
  const exportDefaultRegex = /export\s+default\s+(?:function\s+|class\s+)?([A-Z]\w*)/;
  const exportDefaultMatch = code.match(exportDefaultRegex);
  if (exportDefaultMatch) {
    return exportDefaultMatch[1];
  }

  // Fallback caso não encontre 'export default'
  const functionMatch = code.match(/function\s+([A-Z]\w*)/);
  const classMatch = code.match(/class\s+([A-Z]\w*)/);
  const constMatch = code.match(/const\s+([A-Z]\w*)\s*=/);
  const letMatch = code.match(/let\s+([A-Z]\w*)\s*=/);
  const arrowFunctionMatch = code.match(/const\s+([A-Z]\w*)\s*=\s*\(\s*\)\s*=>/);

  if (functionMatch) return functionMatch[1];
  if (classMatch) return classMatch[1];
  if (constMatch) return constMatch[1];
  if (letMatch) return letMatch[1];
  if (arrowFunctionMatch) return arrowFunctionMatch[1];
  return "App"; // Nome padrão se nenhum for encontrado
};

// Função para gerar o conteúdo do iframe
const generateIframeContent = (
  code,
  initialCssContentMap,
  BabelModule
) => {
  const cssContentMap = { ...initialCssContentMap };

  const codeBlocks = extractCodeBlocks(code);

  let processedCode = "";

  codeBlocks.forEach(({ language, code }) => {
    if (language.includes("css")) {
      const cssFileNameMatch = code.match(/\/\*\s*File:\s*(.*?)\s*\*\//);
      const cssFileName = cssFileNameMatch ? cssFileNameMatch[1].trim() : "style.css";
      const normalizedCssPath = normalizePath(cssFileName);
      cssContentMap[normalizedCssPath] = code;
    } else {
      processedCode += code + "\n";
    }
  });

  if (processedCode.trim() === "") {
    processedCode = code;
  }

  // Extrair módulos importados
  const imports = extractImports(processedCode);

  // Conjunto para armazenar caminhos de CSS importados
  const cssImports = new Set();

  // Substituir importações por declarações de variáveis globais e coletar CSS imports
  let cleanCode = replaceImports(processedCode, cssImports);

  // Identificar o nome do componente
  const componentName = getComponentName(cleanCode);

  // Substituir exportações por atribuição global
  const exportDefaultRegex = /export\s+default\s+(?:function\s+|class\s+)?([A-Z]\w*)/;
  cleanCode = cleanCode
    .replace(exportDefaultRegex, `window['${componentName}'] = ${componentName};`)
    .replace(/export\s+{[^}]+};/g, "");

  // Incluir React e ReactDOM por padrão
  imports.push("react", "react-dom");

  // Remover duplicatas
  const uniqueImports = [...new Set(imports)];

  // Detectar se há importações personalizadas e, se houver, adicionar 'antd' aos imports
  const hasCustomImports = uniqueImports.some((module) => module.startsWith("@/"));
  if (hasCustomImports && !uniqueImports.includes("antd")) {
    uniqueImports.push("antd"); // Adicionar 'antd' para garantir que o CSS seja carregado
  }

  // Normalizar as chaves do cssContentMap
  const normalizedCssContentMap = {};
  Object.keys(cssContentMap).forEach((key) => {
    const normalizedKey = normalizePath(key);
    normalizedCssContentMap[normalizedKey] = cssContentMap[key];
  });

  // Criar listas de scripts e estilos externos
  const externalScripts = [];
  const externalStyles = [];

  uniqueImports.forEach((module) => {
    const mapping = moduleCDNMapping[module];
    if (mapping && mapping[0]) {
      const [url, globalName] = mapping;
      if (url.endsWith(".css")) {
        externalStyles.push(`<link rel="stylesheet" href="${url}" />`);
      } else {
        externalScripts.push(
          `<script src="${url}" crossorigin="anonymous"></script>`
        );
      }
    } else {
      // Módulo não encontrado no mapeamento
      if (!module.startsWith("@/") && module !== 'recharts') {
        console.warn(`Módulo "${module}" não possui URL de CDN mapeada.`);
      }
    }
  });

  // Incluir CSS do Ant Design se estiver sendo utilizado
  if (uniqueImports.includes("antd")) {
    externalStyles.push(
      '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.22.8/antd.min.css" />'
    );
  }

  // Incluir Normalize.css
  externalStyles.unshift(
    '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" />'
  );

  // Adicionar Tailwind CSS via CDN
  externalStyles.push(
    '<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2/dist/tailwind.min.css" rel="stylesheet">'
  );

  // Ordenar os scripts para garantir a ordem correta de carregamento
  const scriptLoadOrder = [
    "react.development.js",
    "react-dom.development.js",
    "prop-types.min.js",
    "antd.min.js",
    "material-ui.development.js",
    "emotion-react.umd.min.js",
    "emotion-styled.umd.min.js",
    "styled-components.min.js",
    "chart.min.js",
    // Recharts não é incluído na ordem de carregamento
  ];

  externalScripts.sort((a, b) => {
    const aIndex = scriptLoadOrder.findIndex((script) => a.includes(script));
    const bIndex = scriptLoadOrder.findIndex((script) => b.includes(script));
    if (aIndex === -1 && bIndex === -1) return 0;
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });

  // Transpilar o código React para JavaScript usando Babel
  let transpiledCode;
  try {
    transpiledCode = BabelModule.transform(cleanCode, {
      presets: [
        ["env", { modules: false }],
        "react",
        // Adicione "typescript" se precisar suportar TypeScript
      ],
      plugins: [
        // Adicione plugins se necessário
      ],
    }).code;
  } catch (error) {
    // Retornar o erro no iframe
    return `
      <!DOCTYPE html>
      <html>
        <body>
          <pre style="color: red;">Erro na transpilaçao com Babel: ${error.message}</pre>
        </body>
      </html>
    `;
  }

  // Chamada para renderizar o componente
  const renderCall = `
    window['${componentName}'] = ${componentName};
    if (window['${componentName}']) {
      ReactDOM.render(
        React.createElement(window['${componentName}']),
        document.getElementById('root')
      );
    } else {
      console.error('Componente "${componentName}" não foi encontrado.');
      document.body.innerHTML = '<pre style="color: red;">Componente "${componentName}" não foi encontrado.</pre>';
    }
  `;

  // Script para tratamento de erros
  const errorHandlingScript = `
    <script>
      window.onerror = function (message, source, lineno, colno, error) {
        document.body.innerHTML = '<pre style="color: red;">' + message + '\\n' + (error ? error.stack : '') + '</pre>';
      };
    </script>
  `;

  // Inicializações específicas de bibliotecas
  let initializationScripts = "";

  if (uniqueImports.includes("chart.js")) {
    initializationScripts += `
      <script>
        (function(){
          if (window.Chart) {
            const { CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend } = window.Chart;
            window.Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);
            window.ChartJS = window.Chart;
          }
        })();
      </script>
    `;
  }

  // Definir componentes ausentes e mocks
  const missingComponentsScript = `
    <script>
      // Mocks para componentes internos
      const mockComponent = (name) => {
        if (!window[name]) {
          // Mocks para componentes específicos
          if (name === 'Button') {
            window[name] = function(props) {
              // Mapeia a prop 'variant' para as classes do Ant Design
              let typeClass = '';
              switch (props.variant) {
                case 'primary':
                  typeClass = 'ant-btn-primary';
                  break;
                case 'dashed':
                  typeClass = 'ant-btn-dashed';
                  break;
                case 'link':
                  typeClass = 'ant-btn-link';
                  break;
                case 'text':
                  typeClass = 'ant-btn-text';
                  break;
                default:
                  typeClass = '';
              }
              const className = \`ant-btn \${typeClass} \${props.className || ''}\`.trim();
              return React.createElement('button', { ...props, className }, props.children || 'Button');
            };
          } else if (name === 'Alert') {
            window[name] = function(props) {
              const className = props.className ? \`\${props.className} ant-alert\` : 'ant-alert';
              return React.createElement('div', { ...props, className }, props.children || 'Alert');
            };
          } else if ([
            'BarChart', 'Bar', 'LineChart', 'Line', 'AreaChart', 'Area',
            'PieChart', 'Pie', 'Cell', 'XAxis', 'YAxis', 'Tooltip',
            'Legend', 'CartesianGrid', 'ResponsiveContainer'
          ].includes(name)) {
            window[name] = function(props) {
              return React.createElement('div', { ...props, style: { border: '1px dashed gray', padding: '10px', margin: '5px' } }, \`\${name} (Mocked Chart Component)\`);
            };
          } else if (['Card', 'CardHeader', 'CardTitle', 'CardContent'].includes(name)) {
            window[name] = function(props) {
              return React.createElement('div', { ...props }, props.children || name);
            };
          } else if (['Briefcase', 'CheckCircle', 'XCircle', 'Book', 'Target', 'User', 'Star', 'Award'].includes(name)) {
            window[name] = function(props) {
              return React.createElement('span', { ...props }, \`\${name} Icon\`);
            };
          } else {
            window[name] = function(props) {
              return React.createElement('div', { ...props, className: props.className || '' }, props.children || name);
            };
          }
        }
      };

      // Lista de componentes a serem mockados
      const predefinedMocks = [
        'Button', 'Alert', 'Card', 'CardHeader', 'CardTitle', 'CardContent',
        'Briefcase', 'CheckCircle', 'XCircle', 'Book', 'Target', 'User',
        'Star', 'Award', 'UICard', 'UITabs', 'UIProgress', 'UIAlert',
        'Grid', 'Paper', 'Typography', 'CountUp', 'TableContainer',
        // Componentes do Recharts
        'BarChart', 'Bar', 'LineChart', 'Line', 'AreaChart', 'Area',
        'PieChart', 'Pie', 'Cell', 'XAxis', 'YAxis', 'Tooltip',
        'Legend', 'CartesianGrid', 'ResponsiveContainer'
      ];
      predefinedMocks.forEach(mockComponent);

      // Definir window.MaterialUI.Component para componentes de '@mui/material'
      const materialUIMocks = ['Grid', 'Paper', 'Typography', 'TableContainer'];
      if (!window.MaterialUI) {
        window.MaterialUI = {};
      }
      materialUIMocks.forEach((name) => {
        if (window[name]) {
          window.MaterialUI[name] = window[name];
        }
      });

      // Mock do useSpring do react-spring
      window.useSpring = function() {
        return {};
      };

      // Mock do animated do react-spring
      window.animated = new Proxy({}, {
        get: function(target, prop) {
          return function(props, ...children) {
            return React.createElement(prop, props, ...children);
          };
        }
      });

      // Override React.createElement para criar mocks dinamicamente
      const originalCreateElement = React.createElement;
      React.createElement = function(type, props, ...children) {
        if (typeof type === 'string') {
          return originalCreateElement(type, props, ...children);
        }
        if (!window[type.name]) {
          mockComponent(type.name);
        }
        return originalCreateElement(type, props, ...children);
      };
    </script>
  `;

  // Scripts de logs para depuração
  const debugLogsScript = `
    <script>
      ${uniqueImports
        .map((module) => {
          const mapping = moduleCDNMapping[module];
          if (mapping && mapping[1]) {
            const globalName = mapping[1];
            return `console.log('${globalName} loaded:', window.${globalName});`;
          }
          return "";
        })
        .join("\n")}
      console.log('styled loaded:', window.styled);
    </script>
  `;

  // Script para prevenir a navegação de links
  const preventLinkNavigationScript = `
    <script>
      document.addEventListener('DOMContentLoaded', function() {
        document.body.addEventListener('click', function(event) {
          var target = event.target;
          while (target && target !== document.body) {
            if (target.tagName === 'A') {
              event.preventDefault();
              break;
            }
            target = target.parentNode;
          }
        });
      });
    </script>
  `;

  // Coletar todo o conteúdo CSS a ser injetado
  let inlineStyles = "";
  cssImports.forEach((cssPath) => {
    const normalizedCssPath = normalizePath(cssPath);
    const cssContent = normalizedCssContentMap[normalizedCssPath];

    if (cssContent) {
      inlineStyles += `<style>${cssContent}</style>\n`;
    } else {
      console.warn(`CSS content for "${cssPath}" not found in cssContentMap.`);
      inlineStyles += `<style>
        /* Estilo padrão para ${cssPath} não encontrado */
        .${componentName.toLowerCase()}-container {
          border: 2px solid red;
        }
      </style>\n`;
    }
  });

  // Retornar o conteúdo completo do iframe
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8" />
        <title>Artifact Preview Beta - YourApp</title>
        ${externalStyles.join("\n")} <!-- Estilos Externos Primeiro -->
        ${inlineStyles} <!-- Estilos Inline Extraídos -->
        <style>
          body {
            margin: 0;
          }
        </style>
      </head>
      <body>
        <div id="root"></div>
        ${externalScripts.join("\n")} <!-- Scripts Externos -->
        ${errorHandlingScript}
        ${initializationScripts} <!-- Inicializações Específicas -->
        ${missingComponentsScript}
        <script>
          (function(){
            // Verificar se todas as bibliotecas estão carregadas
            if (!window.React || !window.ReactDOM) {
              document.body.innerHTML = '<pre style="color: red;">React ou ReactDOM não carregado.</pre>';
              return;
            }
            // Executar o código transpilado
            try {
              ${transpiledCode}
              ${renderCall}
            } catch (e) {
              document.body.innerHTML = '<pre style="color: red;">' + e.message + '</pre>';
            }
          })();
        </script>
        ${debugLogsScript}
        ${preventLinkNavigationScript}
      </body>
    </html>
  `;
};

// Componente principal ArtifactViewer
const ArtifactViewer = React.memo(
  ({
    visible,
    codeContent,
    codeLanguage,
    onClose,
    cssContentMap: initialCssContentMap = {},
  }) => {
    const isReactCode = useMemo(() => {
      if (!codeLanguage) return false;
      const lang = codeLanguage.toLowerCase();
      return lang.includes("jsx") || lang.includes("tsx") || lang.includes("react");
    }, [codeLanguage]);

    const [iframeContent, setIframeContent] = useState('');

    useEffect(() => {
      let isCancelled = false;

      if (isReactCode) {
        // Importação dinâmica do Babel
        import('@babel/standalone').then(BabelModule => {
          if (!isCancelled) {
            const content = generateIframeContent(codeContent, initialCssContentMap, BabelModule);
            setIframeContent(content);
          }
        }).catch(error => {
          if (!isCancelled) {
            setIframeContent(`
              <!DOCTYPE html>
              <html>
                <body>
                  <pre style="color: red;">Erro ao carregar o Babel: ${error.message}</pre>
                </body>
              </html>
            `);
          }
        });
      } else {
        // Novo código para lidar com documentos HTML completos
        const containsHtmlTag = /<html[\s\S]*>/i.test(codeContent);
        if (containsHtmlTag) {
          setIframeContent(codeContent);
        } else {
          setIframeContent(`
            <!DOCTYPE html>
            <html>
              <head>
                <meta charset="UTF-8" />
                ${codeContent.includes("<style>") ? "" : "<style></style>"}
                <style>
                  /* Adicione estilos aqui se necessário */
                </style>
              </head>
              <body>
                ${codeContent}
              </body>
            </html>
          `);
        }
      }

      return () => {
        isCancelled = true;
      };
    }, [isReactCode, codeContent, initialCssContentMap]);

    return (
      <Modal
        open={visible}
        title="Preview Beta - YourApp"
        onCancel={onClose}
        footer={null}
        width="80%"
        style={{ top: 20 }}
        destroyOnClose
      >
        {iframeContent ? (
          <iframe
            srcDoc={iframeContent}
            style={{ width: "100%", height: "80vh", border: "none" }}
            sandbox="allow-scripts allow-same-origin"
            title="Code Preview"
          />
        ) : (
          <div>Carregando...</div>
        )}
      </Modal>
    );
  }
);

// Definir PropTypes para melhor manutenção e documentação
ArtifactViewer.propTypes = {
  visible: PropTypes.bool.isRequired,
  codeContent: PropTypes.string.isRequired,
  codeLanguage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  cssContentMap: PropTypes.object,
};

export default ArtifactViewer;
