import {params, urlBase, resolve} from "./resources";

const url = urlBase + "/api/models";

async function getOpenAIModels() {
    return fetch(url + "/openai", await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function getAnthropicModels() {
    return fetch(url + "/anthropic", await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function getGenerationModelClasses() {
    return fetch(url + "/classes", await params.get())
        .then(response => response.json())
        .then(resolve);
}

const GenerationModelsRemote = {
    getOpenAIModels,
    getAnthropicModels,
    getGenerationModelClasses
};

export default GenerationModelsRemote;
