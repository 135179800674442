// src/components/chat/ChatHistoryItem.jsx

import React, { Component } from "react";
import "./ChatHistoryItem.css";
import autoBind from "auto-bind";
import { Dropdown, Input } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UserOutlined } from "@ant-design/icons";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

class ChatHistoryItem extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isEditMode: false,
            renamedTitle: "",
        };

        this.inputRef = React.createRef();
        this.componentRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isEditMode && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleClickOutside(event) {
        if (this.componentRef.current && !this.componentRef.current.contains(event.target)) {
            this.handleTitleEditCancel();
        }
    }

    handleChatChanging() {
        const { onChatChange, idChat } = this.props;
        onChatChange(idChat);
    }

    handleDropdownClick(event) {
        const { onChatDelete, idChat } = this.props;

        if (event.key === "delete") {
            onChatDelete(idChat);
        } else if (event.key === "rename") {
            this.setState({ isEditMode: true });
        }
    }

    handleTitleEdit(event) {
        this.setState({ renamedTitle: event.target.value });
    }

    handleTitleEditConfirm() {
        const { onChatRename, idChat } = this.props;
        const { isEditMode, renamedTitle } = this.state;

        if (isEditMode) {
            onChatRename(idChat, renamedTitle);
            this.setState({ isEditMode: false, renamedTitle: "" });
        }
    }

    handleTitleEditCancel() {
        this.setState({ isEditMode: false });
    }

    handleTitleEditKeyPress(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.handleTitleEditConfirm(event);
        }
    }

    getDropdownItems() {
        return [
            {
                label: "Renomear",
                key: "rename"
            },
            {
                label: "Apagar",
                key: "delete"
            }
        ];
    }

    getAvatarColor() {
        const { idChat } = this.props;
        const colors = ["#000000", "#AAAAAA", "#1D1C1D", "#6F6F6F", "#C9C9C9", "#2ED16F", "#724CF9", "#FF0D55", "#E52B67", "#FF7A00", "#661AA1"];
    
        // Simple hash function
        let hash = 0;
        for (let i = 0; i < idChat.length; i++) {
          hash = idChat.charCodeAt(i) + ((hash << 5) - hash);
        }
        const index = Math.abs(hash) % colors.length;
        return colors[index];
      }

    renderDropdownButton() {
        const items = this.getDropdownItems();
        const onClick = this.handleDropdownClick;

        return (
            <Dropdown
                className="ChatHistoryItemDropdownButton text-gray-400 hover:text-white mr-2"
                menu={{ items, onClick }}
                trigger={['click']}
                placement={"bottomLeft"}
            >
                <a
                    className="ChatHistoryItemDropdownButtonIcon"
                    onClick={(e) => e.preventDefault()}
                >
                    <FontAwesomeIcon icon={faChevronDown} />
                </a>
            </Dropdown>
        );
    }

    renderTitleSection() {
        const { isEditMode } = this.state;
        const { collapsed } = this.props;

        if (collapsed) {
            return null;
        }

        if (isEditMode) {
            return this.renderTitleInput();
        } else {
            return this.renderTitleLabel();
        }
    }

    renderTitleLabel() {
        const { title, isSelected } = this.props;
        return (
            <div className="ChatHistoryItemTitleContainer">
                <div className={`ChatHistoryItemTitle ${isSelected ? "font-bold" : ""}`}>
                    {title}
                </div>
            </div>
        );
    }

    renderTitleInput() {
        const { title } = this.props;
        return (
            <Input
                ref={this.inputRef}
                className="text-white placeholder-gray-400 w-full overflow-hidden overflow-ellipsis"
                bordered={false}
                placeholder={title}
                onChange={this.handleTitleEdit}
                onKeyPress={this.handleTitleEditKeyPress}
            />
        );
    }

    renderActionSection() {
        const { isEditMode } = this.state;
        const { collapsed } = this.props;

        if (collapsed) {
            return null;
        }

        if (isEditMode) {
            return this.renderActionButtons();
        } else {
            return this.renderDropdownButton();
        }
    }

    renderActionButtons() {
        return (
            <div className="flex items-center gap-2">
                <a
                    className="hover:text-white text-gray-400"
                    onClick={this.handleTitleEditConfirm}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </a>
                <a
                    className="hover:text-white text-gray-400"
                    onClick={this.handleTitleEditCancel}
                >
                    <FontAwesomeIcon icon={faXmark} />
                </a>
            </div>
        );
    }

    renderAvatar() {
        const { collapsed, title, profilePicture } = this.props;

        if (collapsed) {
            return (
                <Tippy content={title} placement="right">
                    {profilePicture ? (
                        <img
                            src={profilePicture}
                            alt={`${title} Profile`}
                            className="ChatHistoryItemAvatarImg"
                        />
                    ) : (
                        <div
                            className="ChatHistoryItemAvatar"
                            style={{ backgroundColor: this.getAvatarColor() }}
                        >
                            <UserOutlined />
                        </div>
                    )}
                </Tippy>
            );
        }

        return profilePicture ? (
            <img
                src={profilePicture}
                alt={`${title} Profile`}
                className="ChatHistoryItemAvatarImg"
            />
        ) : (
            <div
                className="ChatHistoryItemAvatar"
                style={{ backgroundColor: this.getAvatarColor() }}
            >
                <UserOutlined />
            </div>
        );
    }

    renderCursor() {
        const { isSelected } = this.props;
        return (
            <div className={`h-[28px] my-1 flex w-1 bg-${isSelected ? "premium" : "transparent"} rounded`} />
        );
    }

    render() {
        const { idChat, isSelected, disabled, collapsed } = this.props;

        const itemClassName = `ChatHistoryItem h-full w-full text-white font-sans rounded-lg px-1 py-2 mx-1 ${
            !isSelected && !disabled ? "hover:bg-indigo-900 hover:cursor-pointer" : ""
        } ${disabled ? "ChatHistoryItemDisabled" : ""} ${collapsed ? "collapsed" : ""}`;

        return (
            <div className="w-full flex items-center">
                {this.renderCursor()}
                <div
                    id={`ChatHistoryItem-${idChat}`}
                    key={`ChatHistoryItem-${idChat}`}
                    ref={this.componentRef}
                    className={itemClassName}
                    onClick={disabled ? null : this.handleChatChanging}
                >
                    {this.renderAvatar()}
                    {this.renderTitleSection()}
                    {this.renderActionSection()}
                </div>
            </div>
        );
    }
}

export default ChatHistoryItem;
