import {Avatar, Upload, Button} from "antd";
import {useState} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import {getAuthorization, urlBase} from "../../../../services/dao/remote/resources";
import "../../Settings.css";

const SettingsProfilePicture = ({idUser, profilePicture, onUpdate}) => {
    const [isLoading, setLoading] = useState(false);

    const getProfilePictureUrl = () => {
        if (profilePicture) {
            if (profilePicture.startsWith("http")) {
                return profilePicture;
            }
            return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${idUser}/256/${profilePicture}`;
        }
        return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
    }

    const getRequestHeaders = async () => {
        return {
            'Authorization': await getAuthorization(),
            'Content-Type': 'multipart/form-data'
        }
    }

    const getRequestConfig = async () => {
        return {
            headers: await getRequestHeaders(),
        };
    }

    const customRequest = async (options) => {
        const {onSuccess, onError, file, onProgress} = options;
        setLoading(true);
        const data = new FormData();
        data.append("image", file);

        const config = await getRequestConfig();

        try {
            const response = await axios.post(
                urlBase + "/api/user/picture",
                data,
                config
            );

            onUpdate(response["data"]);
        } catch (err) {
            onError({err});
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{position: "relative", display: "flex"}}>
            <Avatar
                className="SettingsProfilePictureBackground"
                size={120}
                src={getProfilePictureUrl()}
            />
            <Upload
                name="userProfilePicture"
                accept=".png,.jpg,.jpeg"
                customRequest={customRequest}
                showUploadList={false}
            >
                <Button
                    shape="circle"
                    type="primary"
                    size="large"
                    className="SettingsProfilePictureUploadButton"
                >
                    {isLoading ? <LoadingOutlined spin/> : <FontAwesomeIcon icon={faPencil}/> }
                </Button>
            </Upload>
        </div>
    );
}

export default SettingsProfilePicture;