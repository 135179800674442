import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useJoinWorkspace} from "../../../utils/hooks/useWorkspaces";
import LoadingScreen from "../../../components/loading-screen/LoadingScreen";
import {useCurrentWorkspace} from "../../../utils/hooks/useCurrentWorkspace";
import {message} from "antd";

const WorkspaceJoinValidator = () => {
    const {code} = useParams();
    const navigate = useNavigate();
    const {setCurrentWorkspace} = useCurrentWorkspace();

    const {joinWorkspace, isJoining} = useJoinWorkspace({
        onSuccess: (workspace) => {
            setCurrentWorkspace(workspace);
            navigate('/subscription/activation');
        },
        onError: (error) => {
            message.error(error);
        }
    });

    useEffect(() => {
        if (code) {
            joinWorkspace(code);
        }
    }, [code, joinWorkspace]);

    if (isJoining) {
        return <LoadingScreen/>;
    }
};

export default WorkspaceJoinValidator;