import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocket} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";

const StartTrialButton = ({highlight, onClick}) => {
    return (
        <div>
            <Button
                className="rounded-full"
                type={highlight ? "primary" : "default"}
                block
                size="large"
                icon={<FontAwesomeIcon icon={faRocket} className="mr-4"/>}
                onClick={onClick}
            >
                Iniciar 7 dias grátis
            </Button>
        </div>
    );
};

export default StartTrialButton;