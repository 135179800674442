import "./CreatorToolFieldsList.css";
import {useEffect, useState} from "react";
import {Reorder, useDragControls, useMotionValue} from "framer-motion";
import {useRaisedShadow} from "../../../../../utils/hooks/useRaisedShadow";
import {DeleteOutlined, HolderOutlined} from "@ant-design/icons";
import {Button, Col, Row} from "antd";

const CreatorToolFieldsList = ({fields, selectedField, onReorder, onRemove, onSelect}) => {
    const [items, setItems] = useState(fields);

    useEffect(() => {
        setItems(fields)
    }, [fields]);

    return (
        <Reorder.Group className="CreatorToolFieldsList" axis="y" onReorder={onReorder} values={items}>
            {items.map(field => (
                <FieldItem
                    key={field.idField}
                    field={field}
                    isSelected={selectedField && field.idField === selectedField.idField}
                    onRemove={onRemove}
                    onSelect={onSelect}
                />
            ))}
        </Reorder.Group>
    )
}

const FieldItem = ({field, isSelected, onRemove, onSelect}) => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const controls = useDragControls();

    const translateType = (type) => {
        if (!type)
            return "Nenhum tipo definido";

        switch (type) {
            case "TEXTFIELD":
                return "Texto curto";
            case "TEXTAREAFIELD":
                return "Texto longo";
            case "SELECTFIELD":
                return "Seleção";
            case "INTEGERFIELD":
                return "Inteiro";
            case "NUMERICFIELD":
                return "Decimal";
            case "BOOLEANFIELD":
                return "Verdadeiro/Falso";
        }
    }

    return (
        <Reorder.Item
            value={field}
            id={field.idField}
            dragListener={false}
            dragControls={controls}
            style={{y, borderRadius: 10}}
            onClick={() => onSelect(field.idField)}
        >
            <Row>
                <Col flex="1 1 150px">
                    <div className={`CreatorToolFieldsListItem ${isSelected? "CreatorToolFieldsListItemSelected" : ""}`}>
                        <div className="CreatorTooFieldListItemDragIcon"
                             onPointerDown={e => controls.start(e)}
                        >
                            <HolderOutlined/>
                        </div>
                        <div>
                            <div className="CreatorToolFieldsListItemLabel">
                                {field.name}
                            </div>
                            <div className="CreatorToolFieldsListItemTypeLabel">
                                {translateType(field.type)}
                            </div>
                        </div>


                    </div>
                </Col>
                <Col flex="0 1 35px" style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <Button
                        className="CreatorToolFieldsListItemRemoveButton"
                        type="text"
                        shape="square"
                        onClick={() => onRemove(field.idField)}
                    >
                        <DeleteOutlined/>
                    </Button>
                </Col>
            </Row>
        </Reorder.Item>
    );
}

export default CreatorToolFieldsList;