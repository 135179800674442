import DexieDatabase from "./db/DexieDatabase";

const db = DexieDatabase;
const put = async (chat) => {
    return await db.chats.add(chat);
}

const update = async (chat) => {
    return await db.chats.update(chat.idChat, chat);
}

const get = async (idChat) => {
    return await db.chats.get(idChat);
}

const remove = async (idChat) => {
    return await db.chats.delete(idChat);
}

const list = async () => {
    return await db.chats.toArray();
}

const clear = async () => {
    return await db.chats.clear();
}

const bulkPut = async (chats) => {
    return db.chats.bulkPut(chats);
}

export const ChatDAO = {
    put,
    update,
    get,
    remove,
    list,
    clear,
    bulkPut
};