import _ from "lodash";
import ToolForm from "../tools/ToolForm";
import {Avatar, Drawer, Space} from "antd";
import React, {useRef} from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "./CreatorToolPreview.css";


const CreatorToolPreview = ({tool}) => {
    const [showDrawer, setShowDrawer] = React.useState(false);

    const drawerTitle = useRef(null);
    const drawerContent = useRef(null);

    const handleOpenDrawer = (field) => {
        drawerTitle.current = field.name;
        drawerContent.current = field.description;
        setShowDrawer(true);
    }

    const renderDrawer = () => {
        return (
            <div className="ToolDrawer">
                <Drawer
                    title={drawerTitle.current}
                    placement="right"
                    open={showDrawer}
                    onClose={() => setShowDrawer(false)}
                    style={{fontFamily: "Outfit"}}
                    width={650}
                    closable={false}
                >
                    {renderDrawerContent()}
                </Drawer>
            </div>
        );
    }

    const renderDrawerContent = () => {
        return (
            <div className="ToolDrawerContent">
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                >
                    {drawerContent.current}
                </ReactMarkdown>
            </div>
        );
    }

    return (
        <div className="CreatorToolPreview">
            <div className="CreatorToolPreviewLabel">
                Pré-visualização
            </div>
            <div className="CreatorToolPreviewHeaderContainer">
                <Space direction="horizontal" className="CreatorToolPreviewToolNameContainer">
                    {tool.icon &&  <Avatar shape={"square"} size={"default"}
                            src={`${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${tool.icon}`}></Avatar>}
                    <div className="CreatorToolPreviewToolName">
                        {tool.name}
                    </div>
                </Space>
                <div className="CreatorToolPreviewToolDescription">
                    {tool.description}
                </div>
            </div>

            <ToolForm
                tool={tool}
                isLoading={false}
                isGenerating={false}
                onGenerateResult={() => {
                }}
                onOpenDrawer={handleOpenDrawer}
                outputs={_.get(tool, "outputs", 1)}
                handleDecreaseOutput={() => {
                }}
                handleIncreaseOutput={() => {
                }}
                resultsLength={0}
                isExampleRunning={false}
                disableGeneration={true}
            />
            {renderDrawer()}
        </div>
    );
}

export default CreatorToolPreview;