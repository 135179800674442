import React from 'react';
import {Avatar} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import WorkspaceSettingsForm from "./components/WorkspaceSettingsForm";

const WorkspaceSettingsPage = () => {
    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faGear}/>}/>
                <div className="font-sans">
                    <div className="text-3xl">
                        Configurações
                    </div>

                    <div className="text-sm text-gray-400">
                        Configurações do workspace
                    </div>
                </div>
            </div>

            <WorkspaceSettingsForm/>
        </div>
    );
};

export default WorkspaceSettingsPage;