import React, {useState} from 'react';
import {List} from "antd";
import BrandVoiceUpdateModal from "./BrandVoiceUpdateModal";
import BrandVoiceListItem from "./BrandVoiceListItem";

const BrandVoiceList = ({brandVoices, isLoading, filter}) => {
    const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);

    const getFilteredBrandVoices = () => {
        if (!filter) {
            return brandVoices;
        }

        return brandVoices.filter((bv) => {
            return bv.name.toLowerCase().includes(filter.toLowerCase())
                || bv.description.toLowerCase().includes(filter.toLowerCase());
        });
    }

    const renderHeader = () => {
        return (
            <div className="px-4 font-sans">
                <div className="text-lg">
                    Suas vozes
                </div>
            </div>
        );
    }

    const renderItem = (item) => {
        return (
            <BrandVoiceListItem item={item}/>
        );
    }

    return (
        <div className="my-4">
            <List
                loading={isLoading}
                itemLayout="horizontal"
                header={renderHeader()}
                dataSource={getFilteredBrandVoices()}
                size="large"
                renderItem={renderItem}
                bordered
                pagination={{
                    pageSize: 5
                }}
            />

            <BrandVoiceUpdateModal
                open={openCreateVoiceModal}
                onClose={() => setOpenCreateVoiceModal(false)}
            />
        </div>
    );
};

export default BrandVoiceList;