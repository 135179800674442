import React from 'react';
import {Card, Popover} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

const DetailCard = ({title, content, tooltipContent}) => {
    return (
        <Card className="font-primary">
            <div className="text-sm text-gray-400">
                <div className="flex flex-row items-center gap-1">
                    {title}
                    <Popover
                        content={<div className="font-primary">{tooltipContent}</div>}
                    >
                        <QuestionCircleOutlined className="hover:cursor-pointer"/>
                    </Popover>
                </div>
            </div>

            <div className="text-lg font-bold">
                {content}
            </div>
        </Card>
    );
};

export default DetailCard;