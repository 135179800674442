import React, {useState} from 'react';
import {useUpdateBrandVoice} from "../../../utils/hooks/useBrandVoices";
import {Button, Form, Input, Modal} from "antd";

const BrandVoiceUpdateModal = ({brandVoice, open, onClose}) => {
    const {updateBrandVoice, isUpdating} = useUpdateBrandVoice({
        onSuccess: () => {
            onClose();
        },
        onError: () => {
            onClose();
        }
    });

    const [name, setName] = useState(brandVoice?.name);
    const [description, setDescription] = useState(brandVoice?.description);
    const [voice, setVoice] = useState(brandVoice?.voice);

    const handleUpdate = () => {
        updateBrandVoice({
            idBrandVoice: brandVoice.idBrandVoice,
            name,
            description,
            voice
        });
    }

    const renderFooter = () => {
        const disabled = isUpdating || !voice || !name;

        return (
            <div className="flex flex-row justify-end">
                <Button
                    type="text"
                    size="large"
                    onClick={onClose}
                    disabled={isUpdating}
                >
                    Cancelar
                </Button>
                <Button
                    type="primary"
                    size="large"
                    disabled={disabled}
                    onClick={handleUpdate}
                    loading={isUpdating}
                >
                    {isUpdating && "Atualizando"}
                    {!isUpdating && "Atualizar"}
                </Button>
            </div>
        );
    }

    return (
        <Modal
            title="Editar brand voice"
            centered
            open={open}
            width={800}
            footer={renderFooter()}
            onCancel={onClose}
        >
            <div className="p-4">
                <Form layout="vertical" autoComplete="off">
                    <Form.Item label="Nome">
                        <Input
                            placeholder="Voz da minha marca"
                            size="large"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={isUpdating}
                        />
                    </Form.Item>

                    <Form.Item name="description" label="Descrição">
                        <Input
                            placeholder="Voz da marca para uso em publicações em redes sociais"
                            size="large"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={isUpdating}
                        />
                    </Form.Item>

                    <Form.Item label="Voz da marca">
                        <Input.TextArea
                            rows={4}
                            autoSize={{minRows: 6, maxRows: 12}}
                            showCount
                            maxLength={7000}
                            placeholder="Escreva ou cole o texto que deseja utilizar como voz da sua marca"
                            onChange={(e) => setVoice(e.target.value)}
                            value={voice}
                            disabled={isUpdating}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default BrandVoiceUpdateModal;