// src/pages/image-generator/components/generator/ImageGenerator.jsx

import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../utils/providers/userProvider";
import { Button, Image, message, Segmented, Progress } from "antd";
import CompletionRemote from "../../../../services/dao/remote/completion";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import SparklesIcon from "../../../../components/custom-icons/SparklesIcon";
import { Theme } from "../../../../api/Theme";
import HorizontalScroll from "../../../../utils/components/horizontal-scroll/HorizontalScroll";
import "./ImageGenerator.css";
import ImageMenuOptionsWrapper from "../menu-options-wrapper/ImageMenuOptionsWrapper";
import { useGeneratedImages } from "../../../../utils/hooks/useGeneratedImages";
import { useScreenSize } from "../../../../utils/hooks/useScreenSize";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import InsufficientImageCreditsModal from "../modal/InsufficientImageCreditsModal";
import { useImageService } from "../../../../utils/hooks/useImageService";
import { useCurrentWorkspace } from "../../../../utils/hooks/useCurrentWorkspace";
import PromptInput from "../prompt/PromptInput";
import { useWallet } from "../../../../utils/hooks/useWallet";

const ImageGenerator = ({ onScreenChange }) => {
    const { user, token } = useContext(UserContext);
    const imageService = useImageService();
    const { imageCredits } = useWallet();
    const { currentWorkspace } = useCurrentWorkspace();

    const generateImageWithIdeogram = imageService.generateImageWithIdeogram();

    const [prompt, setPrompt] = useState('');
    const [example, setExample] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [isEnhancing, setIsEnhancing] = useState(false);

    const [displayMode, setDisplayMode] = useState("suggestion");

    const [openInsufficientCreditsModal, setOpenInsufficientCreditsModal] = useState(false);

    const { images, refetch } = useGeneratedImages();

    const isMobile = useIsMobile();
    const screenSize = useScreenSize();

    const [isPublic, setIsPublic] = useState(true);

    // Estados para progresso e mensagens
    const [progress, setProgress] = useState(0);
    const [progressMessage, setProgressMessage] = useState('');

    const examples = [
        "“Uma renderização 3D vibrante de um personagem de desenho animado vestido com roupa vermelha, posando contra um fundo amarelo sólido. Ele usa um moletom estampado, calça cargo e tênis preto e vermelho, com um boné preto escrito 'Aura', exalando energia e diversão.”",
        "“Pôster em preto e branco com design tipográfico retrô, exibindo 'Calma é um super poder' em uma fonte grande e nostálgica. O fundo texturizado adiciona profundidade e contraste ao texto.”",
        "“Ilustração de um relógio azul com o texto 'PARABÉNS PELOS 10 K SEGUIDORES' e 'VOCÊ É INCRÍVEL' em um pódio, celebrando a conquista de seguidores.”",
        "“Pôster tipográfico colorido com o texto 'obrigado a todos pelos 3.500 seguidores', em negrito e cursivo, com tons de rosa, roxo, azul e amarelo. Pequenas ilustrações de corações e estrelas estão espalhadas pelo fundo bege claro.”",
        "“Pôster em preto e branco com tipografia retrô exibindo 'Legends Never Die' em fonte grande e elaborada, com letras que parecem ícones únicos. O fundo sujo adiciona profundidade e contraste ao design dramático.”",
    ];

    useEffect(() => {
        setExample(examples[Math.floor(Math.random() * examples.length)]);
    }, []);

    // Uso do hook useMessage
    const [messageApi, contextHolder] = message.useMessage();

    // Função para lidar com erros não autenticados
    const handleUnauthenticatedError = () => {
        messageApi.error("Usuário não autenticado. Por favor, faça login novamente.");
        window.location.href = '/login';
    };

    // Função para aprimorar o prompt
    const onEnhancePrompt = async () => {
        setIsEnhancing(true);

        try {
            if (!token) {
                throw new Error('Usuário não autenticado.');
            }

            const enhancedPrompt = await CompletionRemote.enhancePrompt({ prompt, token });
            setPrompt(enhancedPrompt);
        } catch (error) {
            if (error.message === 'Usuário não autenticado.') {
                handleUnauthenticatedError();
            } else {
                messageApi.error(error.message || 'Falha ao melhorar a descrição');
            }
        } finally {
            setIsEnhancing(false);
        }
    };

    // Função para alternar o estado de isPublic
    const handleToggleIsPublic = (checked) => {
        setIsPublic(checked);
    };

    // Função para simular o progresso
    const simulateProgress = () => {
        let progressValue = 0;

        const progressInterval = setInterval(() => {
            // Incrementa o progresso de forma não linear
            progressValue += Math.random() * 3;
            if (progressValue > 95) progressValue = 95;

            setProgress(progressValue);

            // Atualiza as mensagens com base no progresso
            if (progressValue < 20) {
                setProgressMessage("Que prompt interessante...");
            } else if (progressValue < 40) {
                setProgressMessage("Estou misturando cores e formas...");
            } else if (progressValue < 60) {
                setProgressMessage("A obra-prima está ganhando vida...");
            } else if (progressValue < 80) {
                setProgressMessage("Finalizando os toques finais...");
            } else {
                setProgressMessage("Finalizando...");
            }
        }, 500);

        return progressInterval;
    };

    const onGenerate = (text = null) => {
        if (imageCredits === 0) {
            setOpenInsufficientCreditsModal(true);
        } else {
            let value = text || prompt;
            setIsGenerating(true);
            setProgress(0);
            setProgressMessage("Iniciando...");

            const progressInterval = simulateProgress();

            generateImageWithIdeogram.mutate({ prompt: value, is_public: isPublic }, {
                onSuccess: (data) => {
                    clearInterval(progressInterval);
                    setProgress(100);
                    setProgressMessage("Imagem gerada com sucesso!");

                    setTimeout(() => {
                        setIsGenerating(false);
                        setProgress(0);
                        setProgressMessage('');
                        setDisplayMode("gallery");
                        setPrompt('');
                        setIsPublic(false);
                        messageApi.success("Imagem salva na galeria!");
                        refetch();
                    }, 1000);
                },
                onError: (error) => {
                    clearInterval(progressInterval);
                    setIsGenerating(false);
                    setProgress(0);
                    setProgressMessage('');
                    const errorMessage = error?.response?.data?.error || error.message || "Erro ao gerar imagem.";
                    messageApi.error(errorMessage);
                }
            });
        }
    };

    const typingEffect = (text, onFinish) => {
        let index = 0;
        const interval = setInterval(() => {
            setPrompt(prompt => prompt + text[index]);
            index++;

            if (index === text.length) {
                clearInterval(interval);
                onFinish();
            }
        }, 15);
    };

    const getImageUrl = (image) => {
        return `${process.env.REACT_APP_TOOL_GENERATED_IMAGES_S3_BUCKET_PREFIX_URL}/${currentWorkspace?.idWorkspace}/${image.filename}.${image.extension}`;
    };

    const getImageSize = () => {
        switch (screenSize) {
            case 'xs':
            case 'sm':
            case 'md':
                return 128;
            case 'lg':
                return 256;
            case 'xl':
                return 384;
            case 'xxl':
                return 512;
            default:
                return 512;
        }
    };

    const renderPromptInput = () => {
        return (
            <PromptInput
                prompt={prompt}
                placeholder="Descreva a imagem que deseja gerar..."
                isLoading={isGenerating || isEnhancing}
                onChange={setPrompt}
                onGenerate={onGenerate}
                isPublic={isPublic}
                onTogglePublic={handleToggleIsPublic}
                style={{ width: '100%' }}
            />
        );
    };

    const renderEnhanceButton = () => {
        return (
            <Button
                type="default"
                size="large"
                disabled={isEnhancing || _.isEmpty(prompt) || isGenerating}
                onClick={onEnhancePrompt}
                className="ImageGeneratorEnhanceButton"
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <SparklesIcon className="ImageGeneratorPromptSparklesIcon" color={Theme.PRIMARY} />
                    <span>Melhorar descrição</span>
                </div>
            </Button>
        );
    };

    const renderImages = () => {
        if (_.isEmpty(images)) {
            return null;
        }

        return images.slice(0, 15).map(renderImage);
    };

    const renderImage = (image) => {
        return (
            <div key={image.idImage} className="ImageGeneratorCarouselImageContainer">
                <Image
                    className="ImageGeneratorCarouselImage"
                    height={getImageSize()}
                    width={getImageSize()}
                    src={getImageUrl(image)}
                />
                <div className="ImageGeneratorCarouselImageMenu">
                    <ImageMenuOptionsWrapper
                        idUser={user.idUser}
                        image={image}
                        options={["edit", "download"]}
                        onScreenChange={onScreenChange}
                    />
                </div>
                {!isMobile && (
                    <div className="ImageGeneratorImageTitleContainer">
                        <label className="ImageGeneratorImageTitle">
                            {image.title}
                        </label>
                    </div>
                )}
            </div>
        );
    };

    const renderCarousel = () => {
        return (
            <div className="ImageGeneratorCarouselContainer">
                <HorizontalScroll components={renderImages()} />
            </div>
        );
    };

    const renderExample = () => {
        if (isGenerating || isEnhancing) {
            return null;
        }

        return (
            <div className="ImageGeneratorExampleContainer">
                <div className="ImageGeneratorExample">{example}</div>
                <PremiumButton
                    style={{ marginTop: 24 }}
                    onClick={handleTryExample}
                    disabled={isGenerating || isEnhancing}
                >
                    Experimentar
                </PremiumButton>
            </div>
        );
    };

    const onFinishTypingEffect = () => {
        messageApi.success("Descrição preenchida! Agora você pode gerar a imagem.");
    };

    const handleTryExample = () => {
        setPrompt('');
        const text = example.replace("“", "").replace("”", "");
        typingEffect(text, onFinishTypingEffect);
    };

    const renderSegmented = () => {
        return (
            <Segmented
                options={[
                    {
                        label: "Sugestão",
                        value: "suggestion",
                        icon: <FontAwesomeIcon icon={faLightbulb} />
                    },
                    {
                        label: "Galeria",
                        value: "gallery",
                        icon: <FontAwesomeIcon icon={faImages} />
                    }
                ]}
                onChange={(option) => setDisplayMode(option)}
                value={displayMode}
                className="ImageGeneratorSegmented"
            />
        );
    };

    return (
        <div className="ImageGenerator">
            {contextHolder}
            {renderSegmented()}

            {displayMode === 'gallery' && renderCarousel()}
            {displayMode === 'suggestion' && renderExample()}

            <div className="ImageGeneratorPrompt">
                <div className="ImageGeneratorPromptToolbar">
                    {renderEnhanceButton()}
                </div>
                <div className="ImageGeneratorPromptContainer">
                    {renderPromptInput()}
                </div>
            </div>

            {/* Renderização da barra de progresso */}
            {isGenerating && (
                <div className="ImageGeneratorProgressContainer">
                    <div className="progress-animation">
                        <Progress
                            percent={Math.floor(progress)}
                            status="active"
                            strokeColor={{
                                from: '#6a11cb',
                                to: '#2575fc',
                            }}
                            className="ImageGeneratorProgressBar"
                        />
                        <p className="ImageGeneratorProgressMessage">{progressMessage}</p>
                    </div>
                </div>
            )}

            <InsufficientImageCreditsModal
                isOpen={openInsufficientCreditsModal}
                onClose={() => setOpenInsufficientCreditsModal(false)}
            />
        </div>
    );
};

export default ImageGenerator;