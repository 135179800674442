import {Layout} from "antd";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import ToolWrapper from "../tools/Tool";
import Settings from "../../app/settings/Settings";
import Playground from "../playground/Playground";
import OrganizationScreen from "../organization/OrganizationScreen";
import useOrganizationService from "../../utils/hooks/useOrganizationService";
import SubscriptionActivation from "../subscription/SubscriptionActivation";
import {ActiveSubscriptionValidator} from "../validator/ActiveSubscriptionValidator";
import ImageGeneratorPage from "../../pages/image-generator/page/ImageGeneratorPage";
import ToolsContainerWrapper from "../tools/ToolsContainer";
import ChatContainerNew from "../chat/ChatContainerNew";
import BrandVoicePage from "../../app/brand-voice/BrandVoicePage";
import ContentCreatorPage from "../../app/content-creator/ContentCreatorPage";
import WorkspacePage from "../../app/workspace/WorkspacePage";
import WorkspaceJoinValidator from "../../app/workspace/components/WorkspaceJoinValidator";


const AppContainer = ({user, setHeaderTitle, defaultPicture, showWelcomeModal}) => {

    const organizationService = useOrganizationService();

    const getPathForInitialPage = () => {
        if (organizationService.hasCustomOrganizations()) {
            const organizations = organizationService.getOrganizations();
            return organizations.map(organization => organization.settings?.pathInitialPage).find(path => path != null);
        }
        return "/chat";
    }

    return (
        <>
            <Layout>
                <Routes>
                    <Route exact path="/" element={<Navigate to={getPathForInitialPage()}/>}/>

                    <Route exact path="/chat" element={
                        <ActiveSubscriptionValidator>
                            <ChatContainerNew
                                user={user}
                                setHeaderTitle={setHeaderTitle}
                            />
                        </ActiveSubscriptionValidator>
                    }/>

                    <Route exact
                           path="/tools"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/t/content"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/t/social"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/t/productivity"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/t/management"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/t/sales"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolsContainerWrapper
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/tools/:idTool"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ToolWrapper
                                       user={user}
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/settings/*"
                           element={
                               <Settings
                                   user={user}
                                   setHeaderTitle={setHeaderTitle}
                                   defaultPicture={defaultPicture}
                               />
                           }
                    />

                    <Route exact path="/creator/*" element={
                        <ActiveSubscriptionValidator
                            additionalRequiredPermissions={["Gods", "Administrators", "ContentCreators"]}
                        >
                            <ContentCreatorPage
                                setHeaderTitle={setHeaderTitle}
                            />
                        </ActiveSubscriptionValidator>
                    }/>

                    <Route exact path="/playground" element={
                        <ActiveSubscriptionValidator
                            additionalRequiredPermissions={["Gods", "Administrators", "ContentCreators"]}
                        >
                            <Playground
                                setHeaderTitle={setHeaderTitle}
                            />
                        </ActiveSubscriptionValidator>
                    }/>

                    <Route exact
                           path="/org/:slug"
                           element={
                               <OrganizationScreen
                                   setHeaderTitle={setHeaderTitle}
                               />
                           }
                    />

                    <Route exact
                           path="/org/:idOrganization/:slug"
                           element={
                               <OrganizationScreen
                                   setHeaderTitle={setHeaderTitle}
                               />
                           }
                    />

                    <Route exact
                           path="/org/:idOrganization/:slug/:idTool"
                           element={
                               <ToolWrapper
                                   user={user}
                                   setHeaderTitle={setHeaderTitle}
                               />
                           }
                    />

                    <Route exact
                           path="/image/*"
                           element={
                               <ActiveSubscriptionValidator>
                                   <ImageGeneratorPage
                                       setHeaderTitle={setHeaderTitle}
                                   />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/brand-voice"
                           element={
                               <ActiveSubscriptionValidator>
                                    <BrandVoicePage
                                        setHeaderTitle={setHeaderTitle}
                                    />
                               </ActiveSubscriptionValidator>
                           }
                    />

                    <Route exact
                           path="/workspace/*"
                           element={
                                <WorkspacePage
                                    setHeaderTitle={setHeaderTitle}
                                />
                           }
                    />

                    <Route exact
                           path="/subscription/activation"
                           element={
                               <SubscriptionActivation/>
                           }
                    />

                    <Route exact
                           path="/join/:code"
                           element={
                                <WorkspaceJoinValidator/>
                           }
                    />
                </Routes>
            </Layout>
        </>
    );
}

export default AppContainer;