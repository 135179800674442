import {urlBase, params, resolve} from "./resources";

const url = urlBase + "/api/chat";


async function create(chat) {
    const content = {body: chat};
    return fetch(url, await params.put(content))
        .then(response => response.json())
        .then(resolve);
}

async function update(data) {
    const content = {body: data};
    return fetch(url, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function list() {
    return fetch(`${url}/list`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function remove(idChat) {
    return fetch(`${url}/${idChat}`, await params.delete())
        .then(response => response.json())
        .then(resolve);
}

export const ChatRemote = {
    create,
    update,
    list,
    remove
};