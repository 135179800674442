import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause} from "@fortawesome/free-solid-svg-icons";
import {Button} from "antd";
import "./StopGenerationButton.css";
import useIsMobile from "../../utils/hooks/useIsMobile";

const StopGenerationButton = ({isGenerating, onStop}) => {
    const [zIndex, setZIndex] = useState(0);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (isGenerating) {
            setZIndex(99);
        } else {
            setTimeout(() => {
                setZIndex(-99);
            }, 1000);
        }
    }, [isGenerating]);

    const handleClick = () => {
        onStop();
    }

    return (
        <Button
            className={`StopGenerationButton ${isMobile ? "isMobile" : "isDesktop"} ${isGenerating ? "visible" : ""}`}
            type="primary"
            onClick={handleClick}
            size={"large"}
            style={{zIndex: zIndex}}
        >
            <FontAwesomeIcon icon={faPause} style={{marginRight: "12px"}}/>
            Interromper geração
        </Button>
    );
}

export default StopGenerationButton;