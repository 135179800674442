import {params, resolve} from "./resources";

const url = "https://brasilapi.com.br/api";

async function fetchCep(cep) {
    return fetch(`${url}/cep/v1/${cep}`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function fetchCities(state) {
    return fetch(`${url}/ibge/municipios/v1/${state}?providers=dados-abertos-br,gov,wikipedia`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

const BrasilDAORemote = {
    fetchCep,
    fetchCities
}

export default BrasilDAORemote;