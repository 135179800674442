import {useAuthenticator} from "@aws-amplify/ui-react";
import {Layout} from "antd";
import React, {useEffect, useState} from "react";
import MainHeader from "../main-header/MainHeader";
import AppContainer from "../container/AppContainer";
import MainBurgerMenu from "../menu/MainBurgerMenu";
import useIsMobile from "../../utils/hooks/useIsMobile";
import PostRegistrationScreen from "../post-registration/PostRegistrationScreen";
import useUser from "../../utils/hooks/useUser";
import _ from "lodash";
import LoadingScreen from "../loading-screen/LoadingScreen";
import MainMenuDesktop from "../menu/MainMenuDesktop";
import OrganizationWelcomeScreen from "../organization/OrganizationWelcomeScreen";
import useOrganizationService from "../../utils/hooks/useOrganizationService";
import {SubscriptionsModal} from "../subscription/SubscriptionsModal";
import WelcomeModal from "../subscription/WelcomeModal";
import {datadogRum} from "@datadog/browser-rum";
import TaxInformationModal from "../modals/TaxInformationModal";
import PastDueSubscriptionModal from "../modals/PastDueSubscriptionModal";

export function Home() {
    const {signOut, authStatus, user: authUser} = useAuthenticator();

    const authenticatedUser = authUser ? {
        name: authUser.attributes.name,
        email: authUser.attributes.email,
        picture: authUser.attributes.picture,
    } : {};

    const {user, isLoading} = useUser();

    const [headerTitle, setHeaderTitle] = useState(null);

    const [renderPostRegistrationScreen, setRenderPostRegistrationScreen] = useState(true);
    const [renderOrganizationWelcomeScreen, setRenderOrganizationWelcomeScreen] = useState(true);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showTaxInformationModal, setShowTaxInformationModal] = useState(false);
    const [showPastDueModal, setShowPastDueModal] = useState(false);

    const isMobile = useIsMobile();
    const organizationService = useOrganizationService();

    useEffect(() => {
        if (user != null) {
            if (_.isEmpty(_.get(user, "activeSubscriptions", []))) {
                if (!hasCustomOrganizations(user)) {
                    setShowWelcomeModal(true);
                }
            } else if (_.head(_.get(user, "activeSubscriptions", [])).status === "past_due") {
                setShowPastDueModal(true);
            } else {
                const registrationCompletedAt = _.get(user, "settings.postRegistrationCompletedAt", null);
                const taxInformationCompletedAt = _.get(user, "settings.taxInformationCompletedAt", null);
                setShowTaxInformationModal(registrationCompletedAt != null && taxInformationCompletedAt == null && !isMobile);
            }
            saveDefaultPicture();
        }
    }, [user]);

    const saveDefaultPicture = () => {
        localStorage.setItem(`${authenticatedUser.email}/defaultPicture`, authenticatedUser.picture)
    }

    const hasCustomOrganizations = (user) => {
        if (user == null) return false;

        const customOrganizations = Object.values(user.organizations)
            .filter((organization) => {
                return organization.idOrganization > 1
            });

        return !_.isEmpty(customOrganizations);
    }

    const handleFinishPostRegistrationScreen = () => {
        setRenderPostRegistrationScreen(false);
    }

    const handleFinishOrganizationWelcomeScreen = () => {
        setRenderOrganizationWelcomeScreen(false);
    }

    const showPostRegistrationScreen = () => {
        const registrationCompletedAt = _.get(user, "settings.postRegistrationCompletedAt", null);
        const organizationWelcomeScreenCompletedAt = _.get(user, "settings.organizationWelcomeScreenCompletedAt", null);
        const activeSubscription = _.head(_.get(user, "activeSubscriptions", [])
            .filter(subscription => subscription.status === "active")
        );

        return registrationCompletedAt == null
            && activeSubscription != null
            && !isMobileDevice();
    }

    const showOrganizationWelcomeScreen = () => {
        const organizationWelcomeScreenCompletedAt = _.get(user, "settings.organizationWelcomeScreenCompletedAt", null);
        const welcomeOrganization = organizationService.getWelcomeOrganization();
        return organizationService.hasCustomOrganizations()
            && welcomeOrganization != null
            && organizationWelcomeScreenCompletedAt == null
            && renderOrganizationWelcomeScreen;
    }

    const isMobileDevice = () => {
        if (typeof window !== 'undefined') {
            const userAgent = window.navigator.userAgent;
            return /Mobi|Android/i.test(userAgent);
        }
        return false;
    }

    const renderMainMenu = () => {
        if (isMobile) {
            return <MainBurgerMenu user={user}/>;
        }
        return <MainMenuDesktop user={user}/>;
    }

    const renderHome = () => {
        if (isLoading) {
            return (
                <div className="AppLayout">
                    <LoadingScreen/>
                </div>
            );
        }

        if (showOrganizationWelcomeScreen()) {
            return <OrganizationWelcomeScreen
                user={user}
                message={organizationService.getWelcomeOrganization().settings?.messageWelcomeScreen}
                onFinish={handleFinishOrganizationWelcomeScreen}
            />;
        } else if (showPostRegistrationScreen() && renderPostRegistrationScreen) {
            return (
                <PostRegistrationScreen
                    user={user}
                    isLoading={isLoading}
                    onFinish={handleFinishPostRegistrationScreen}
                />
            );
        } else {
            return (
                <Layout className="AppLayout">
                    {renderMainMenu()}
                    <Layout>
                        <MainHeader
                            user={user}
                            onSignOut={signOut}
                            headerTitle={headerTitle}
                            defaultPicture={authenticatedUser.picture}
                        />

                        <AppContainer
                            user={user}
                            onSignOut={signOut}
                            setHeaderTitle={setHeaderTitle}
                            defaultPicture={authenticatedUser.picture}
                            showWelcomeModal={showWelcomeModal}
                        />
                        <SubscriptionsModal/>
                        {/*<WelcomeModal*/}
                        {/*    open={showWelcomeModal}*/}
                        {/*    onClose={() => setShowWelcomeModal(false)}*/}
                        {/*/>*/}
                        <TaxInformationModal
                            open={showTaxInformationModal}
                            onClose={() => setShowTaxInformationModal(false)}
                        />
                        <PastDueSubscriptionModal
                            open={showPastDueModal}
                            onClose={() => setShowPastDueModal(false)}
                        />
                    </Layout>
                </Layout>
            );
        }
    }

    if (user && authStatus === 'authenticated') {
        datadogRum.setUser({
            id: user.idUser,
            name: user.name,
            email: user.email,
            plan: _.get(_.head(user.activeSubscriptions), "subscriptionPlan.name", null),
        });

        datadogRum.startSessionReplayRecording();

        return (
            renderHome()
        );
    } else {
        return (
            <div className="AppLayout">
                <LoadingScreen/>
            </div>
        );
    }
}