import React, {useEffect, useState} from 'react';
import MainHeaderTitle from "../../components/main-header/MainHeaderTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn, faPlus} from "@fortawesome/free-solid-svg-icons";
import BrandVoiceList from "./components/BrandVoiceList";
import {Avatar, Input, Popover} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import BrandVoiceCreateModal from "./components/BrandVoiceCreateModal";
import PremiumButton from "../../components/buttons/PremiumButton";
import {useSubscriptionService} from "../../utils/hooks/useSubscriptionService";
import {useBrandVoices} from "../../utils/hooks/useBrandVoices";
import _ from "lodash";
import UpgradeButton from "../../components/subscription/UpgradeButton";
import useIsMobile from "../../utils/hooks/useIsMobile";

const BrandVoicePage = ({setHeaderTitle}) => {
    const [filter, setFilter] = useState(null);
    const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);

    const subscriptionService = useSubscriptionService();
    const {brandVoices, isLoading} = useBrandVoices();
    const isMobile = useIsMobile();

    useEffect(() => {
        document.title = `Brand voice · CMOs.ai`;
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faBullhorn}/>}
                title="Brand voice"
            />
        );
    }, [setHeaderTitle]);

    const canUserCreateMoreBrandVoices = () => {
        if (!brandVoices || isLoading) return false;

        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
        const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);

        return brandVoices.length < numberOfBrandVoices
    }

    const handleCreateBrandVoice = () => {
        if (canUserCreateMoreBrandVoices()) {
            setOpenCreateVoiceModal(true);
        }
    }

    const renderUpgradeWarningPopoverContent = () => {
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
        const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);

        return (
            <div className="font-primary w-[200px] flex flex-col gap-2">
                <div>O plano atual permite a criação de apenas {numberOfBrandVoices} vozes.</div>
                <div>Faça um upgrade de plano para habilitar mais vozes.</div>
                <UpgradeButton size="large"/>
            </div>
        )
    }

    const renderCreateButton = () => {
        const button = (
            <PremiumButton
                className="flex flex-row items-center gap-2"
                type="primary"
                size="large"
                onClick={handleCreateBrandVoice}
                disabled={!canUserCreateMoreBrandVoices()}
            >
                <FontAwesomeIcon icon={faPlus}/>
                Adicionar voz
            </PremiumButton>
        );

        if (!canUserCreateMoreBrandVoices()) {
            return (
                <Popover
                    content={renderUpgradeWarningPopoverContent()}
                    trigger="hover"
                    placement="right"
                >
                    {button}
                </Popover>
            );
        } else {
            return button;
        }
    }

    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                {!isMobile && <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faBullhorn}/>}/>}
                <div className="font-sans">
                    <div className="text-3xl">
                        Brand voice
                    </div>

                    <div className="text-sm text-gray-400">
                        Branding voices permite que o CMOs.ai utilize vozes e estilos de escrita em seus conteúdos.
                    </div>
                </div>
            </div>

            <div className="py-4">
                <div className="flex flex-row gap-2 items-center">
                    <Input
                        size="large"
                        placeholder="Buscar por nome ou descrição"
                        prefix={<SearchOutlined/>}
                        value={filter}
                        onChange={(e) => {setFilter(e.target.value)}}
                    />

                    {renderCreateButton()}
                </div>

                {!canUserCreateMoreBrandVoices() && (
                    <div className="my-3">
                        <UpgradeButton clas size="large" label="Fazer upgrade"/>
                    </div>
                )}

                <BrandVoiceList
                    brandVoices={brandVoices}
                    isLoading={isLoading}
                    filter={filter}
                />
            </div>

            <BrandVoiceCreateModal
                open={openCreateVoiceModal}
                onClose={() => setOpenCreateVoiceModal(false)}
            />
        </div>
    );
};

export default BrandVoicePage;