import React, {useContext, useEffect, useState} from "react";
import ToolFacade from "../../services/facade/ToolFacade";
import {useQuery} from "@tanstack/react-query";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBriefcase} from "@fortawesome/free-solid-svg-icons";
import ToolCard from "../tools/ToolCard";
import "./OrganizationScreen.css";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import LoadingScreen from "../loading-screen/LoadingScreen";
import {OrganizationContext} from "../../utils/providers/organizationProvider";
import useOrganizationService from "../../utils/hooks/useOrganizationService";

const OrganizationScreen = ({setHeaderTitle, setCurrentOrganization, ...props}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [organization, setOrganization] = useContext(OrganizationContext);
    const [tools, setTools] = useState([]);

    const queryTools = useQuery({
        queryKey: ['tools'],
        queryFn: () => ToolFacade.fetchForOrganization(),
        refetchOnWindowFocus: false,
    });

    const organizationService = useOrganizationService();
    const params = useParams();

    useEffect(() => {
        if (!queryTools.isLoading && !_.isEmpty(organization)) {
            setTools(queryTools.data.filter((tool) => tool.organizationIds.includes(organization.idOrganization)));
            setOrganization(organization);
        }
    }, [queryTools.data, organization]);

    useEffect(() => {
        return () => setOrganization(null);
    }, []);

    useEffect(() => {
        if (location.state?.organization) {
            setOrganization(location.state.organization)
        } else {
            const {idOrganization} = params;
            if (idOrganization) {
                const organization = organizationService.getOrganization(idOrganization);
                if (!_.isEmpty(organization))
                    setOrganization(organization);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (!_.isEmpty(organization)) {
            document.title = `${organization.name} · CMOs.ai`;
            setHeaderTitle(
                <MainHeaderTitle
                    icon={<FontAwesomeIcon icon={faBriefcase}/>}
                    title={organization.name}
                />
            );
        }
    }, [organization]);

    const tokenize = (text) => {
        return text.toLowerCase()
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '-')
            .replace(/\([^)]*\)/g, '')
            .replace(/[^a-zA-Z0-9_-]/g, '');
    }

    const handleToolClick = (idTool) => {
        navigate(
            `/org/${organization.idOrganization}/${tokenize(organization.name)}/${idTool}`,
            {state: {currentOrganization: _.cloneDeep(organization)}}
        )
    }

    const renderTools = () => {
        if (queryTools.isLoading) {
            return <LoadingScreen/>;
        }

        return (
            <div style={{
                marginTop: 24,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, max-content))",
                gridGap: 24,
            }}>
                {tools.map((tool) => (
                    <ToolCard
                        idTool={tool.idTool}
                        title={tool.name}
                        description={tool.description}
                        badge={tool.badge}
                        icon={tool.icon}
                        onClick={() => handleToolClick(tool.idTool)}
                    />
                ))}
            </div>
        );
    }

    return (
        <div className="OrganizationScreen">
            {renderTools()}
        </div>
    );
}

export default OrganizationScreen;