import {params, urlBase, resolve} from "./resources";
import axios from "axios";

const url = urlBase + "/api/brand-voice";

async function create(brandVoice) {
    const content = {body: brandVoice};
    return fetch(url, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function update(data) {
    const content = {body: data};
    return fetch(url, await params.put(content))
        .then(response => response.json())
        .then(resolve);
}

async function list() {
    return fetch(`${url}`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function remove(idBrandVoice) {
    return fetch(`${url}/${idBrandVoice}`, await params.delete())
        .then(response => response.json())
        .then(resolve);
}

async function scanUrl(data) {
    const content = {body: data};
    return fetch(url + "/scan/url", await params.post(content))
        .then(res => res.json());
}

async function scanFile(data, config) {
    return axios.post(url + "/scan/file", data, config);
}

async function scanText(data) {
    const content = {body: data};
    return fetch(url + "/scan/text", await params.post(content))
        .then(res => res.json());
}

const BrandVoiceRemote = {
    create,
    update,
    list,
    remove,
    scanUrl,
    scanFile,
    scanText,
};

export default BrandVoiceRemote;