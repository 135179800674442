import React, {useState} from 'react';
import {Button, Modal} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import {useStripeService} from "../../../utils/hooks/useStripeService";

const SubscriptionTeamModal = ({idSubscriptionPlan, billingCycle, prices, teamConfig, open, onClose, activeSubscriptions}) => {
    const [numberOfUsers, setNumberOfUsers] = useState(teamConfig?.usersIncluded);
    const [minNumberOfUsers, setMinNumberOfUsers] = useState(teamConfig?.usersIncluded);

    const stripeService = useStripeService();

    const formatPrice = (price, decimals = 0) => {
        if (price === 0) {
            return 999;
        }

        return parseFloat(price.toFixed(decimals));
    }

    const getBasePrice = () => {
        const monthlyPrice = _.head(prices.filter((price) => price.billingCycle === 'monthly'));
        const yearlyPrice = _.head(prices.filter((price) => price.billingCycle === 'yearly'));

        if (billingCycle === 'yearly') {
            const price = _.get(yearlyPrice, "value", 0);
            return price ? formatPrice(price / 12) : 999;
        } else {
            return _.get(monthlyPrice, "value", 999);
        }
    }

    const getPricePerUser = () => {
        if (billingCycle === 'yearly') {
            return formatPrice(teamConfig?.pricePerUserYearly / 12);
        }
        return teamConfig?.pricePerUserMonthly;
    }

    const getConsolidatedPrice = () => {
        const basePrice = getBasePrice();
        const pricePerUser = getPricePerUser();

        return basePrice + ((numberOfUsers - minNumberOfUsers) * pricePerUser);
    }

    const renderTitle = () => {
        return (
            <div className="text-3xl font-bold text-center text-dark font-sans">
                Team
            </div>
        );
    }

    return (
        <Modal
            open={open}
            centered
            footer={null}
            onClose={onClose}
            title={renderTitle()}
            onCancel={onClose}
        >
            <div className="flex flex-col text-center justify-center gap-1">
                <div className="text-lg">Workspace para</div>
                <div className="flex flex-row items-center justify-center gap-4">
                    <Button
                        type="text"
                        size="large"
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faMinus}/>}
                        disabled={numberOfUsers <= minNumberOfUsers}
                        onClick={() => numberOfUsers > minNumberOfUsers ? setNumberOfUsers(numberOfUsers - 1) : null}
                    />
                    <div className="text-6xl font-bold">{numberOfUsers}</div>
                    <Button
                        type="text"
                        size="large"
                        shape="circle"
                        icon={<FontAwesomeIcon icon={faPlus}/>}
                        onClick={() => setNumberOfUsers(numberOfUsers + 1)}
                    />
                </div>
                <div className="text-lg">usuários</div>
                <div className="flex justify-center mt-4 mb-1">
                    <div className="w-[50%] h-[1px] bg-gray-200"/>
                </div>
                <div className="mb-4">
                    <div className="flex flex-row items-center justify-center gap-2">
                        <div className="text-lg">R$</div>
                        <div className="text-[42px]">{getConsolidatedPrice()}</div>
                        <div className="text-lg">/mês</div>
                    </div>
                    <label>cobrança <label className="font-bold">
                        {billingCycle === "yearly" ? "anual" : "mensal"}
                    </label></label>
                </div>

                <Button
                    type="primary"
                    size="large"
                    className="rounded-full"
                    block
                    onClick={() =>
                        activeSubscriptions ? stripeService.openCustomerPortalWindow() :
                            stripeService.openCheckoutWindow({idSubscription: idSubscriptionPlan, billingCycle, numberOfUsers})}
                >
                    Continuar
                </Button>
            </div>
        </Modal>
    );
};

export default SubscriptionTeamModal;