import "./PlaygroundResults.css";
import {Button, Col, Dropdown, message, Modal, Row, Tooltip} from "antd";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import React, {useRef, useState} from "react";
import {
    CopyOutlined,
    DownOutlined,
    MenuUnfoldOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import LoadingScreen from "../loading-screen/LoadingScreen";

const PlaygroundResults = ({results, onRemove, isLoading}) => {
    const currentPrompt = useRef(null);
    const currentValues = useRef(null);

    const [showPrompt, setShowPrompt] = useState(false);

    const handleRemoveResult = (result) => {
        onRemove(result);
    }

    const handleCopyPrompt = () => {
        navigator.clipboard.writeText(currentPrompt.current)
            .then(() => {
                message.success("Copiado!", 1);
            });
    }

    const renderAttributes = (result) => {
        return (
            <div>
                <div className="PlaygroundResultAttributesTitle">Modelo</div>
                <div className="PlaygroundResultAttributesValue">{result.generationModelClass}</div>

                {result.specialty && (
                    <>
                        <div className="PlaygroundResultAttributesTitle">Especialidade</div>
                        <div className="PlaygroundResultAttributesValue">{result.specialty?.name}</div>
                    </>
                )}

                {result.brandVoice && (
                    <>
                        <div className="PlaygroundResultAttributesTitle">Brand voice</div>
                        <div className="PlaygroundResultAttributesValue">{result.brandVoice?.name}</div>
                    </>
                )}

                <div className="PlaygroundResultAttributesTitle">Temperatura</div>
                <div className="PlaygroundResultAttributesValue">{result.temperature}</div>
                <div className="PlaygroundResultAttributesTitle">Tamanho máximo</div>
                <div className="PlaygroundResultAttributesValue">{result.maximumLength}</div>
                <div className="PlaygroundResultAttributesTitle">Prompt</div>
                <Button
                    className="PlaygroundResultAttributesButton"
                    size="small"
                    type="text"
                    icon={<MenuUnfoldOutlined/>}
                    onClick={() => {
                        currentPrompt.current = result.prompt;
                        currentValues.current = result.values;
                        setShowPrompt(true)
                    }}
                />
                <div className="PlaygroundResultAttributesTitle">Data</div>
                <div className="PlaygroundResultAttributesValue">{new Date(result.createdAt).toLocaleString()}</div>
            </div>
        )
    }

    const getNumberOfCharacters = (text) => {
        return text.length;
    }

    const getNumberOfWords = (text) => {
        return text.split(" ").length;
    }

    const renderText = (text) => {
        if (!text) {
            return null;
        }

        return (
            <div className="PlaygroundResultsResultText">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {text}
                </ReactMarkdown>
            </div>
        );
    }

    const renderTextInfo = (text) => {
        if (!text) {
            return null;
        }

        return (
            <div className="PlaygroundResultsResultTextInfo">
                <div>{getNumberOfWords(text)} palavras, {getNumberOfCharacters(text)} caracteres</div>
            </div>
        )
    }

    const renderMenu = (result) => {
        const items = [
            {
                label: <div onClick={() => handleRemoveResult(result)}>Excluir</div>,
                key: 'delete',
            },
        ];

        return (
            <div className="PlaygroundResultsResultMenu">
                <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
                    <a onClick={(e) => e.preventDefault()}>
                        <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        );
    }

    const renderResult = (result) => {
        if (!result) {
            return null;
        }

        return (
            <div key={result.idPlaygroundResult} className="PlaygroundResult">
                {renderMenu(result)}
                <Row>
                    <Col span={4}>
                        {renderAttributes(result)}
                    </Col>
                    <Col span={20}>
                        {renderText(result.text)}
                        {renderTextInfo(result.text)}
                    </Col>
                </Row>
            </div>
        );
    }

    const renderPrompt = () => {
        let string = _.cloneDeep(currentPrompt.current);
        const values = _.cloneDeep(currentValues.current);

        if (_.isEmpty(string)) {
            return null;
        }

        const chunks = string.split(/(\{\{.*?\}\})/);

        return (
            <>
                {chunks.map((chunk, index) => {
                    if (chunk.startsWith("{{") && chunk.endsWith("}}")) {
                        const placeholder = chunk;
                        const replacement = values[chunk.replace("{{", "").replace("}}", "")];
                        return (
                            <Tooltip title={placeholder} color={"#E52B67"}>
                                <label key={index} className="PlaygroundResultsResultPromptHighlight">{replacement}</label>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <span>{chunk}</span>
                        );
                    }
                })};
            </>
        )
    }

    const renderPromptModal = () => {
        return (
            <Modal
                title="Prompt"
                open={showPrompt}
                onOk={() => setShowPrompt(false)}
                onCancel={() => setShowPrompt(false)}
                centered
                footer={[
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                            type="text"
                            onClick={handleCopyPrompt}
                            icon={<CopyOutlined />}
                        >
                            Copiar
                        </Button>
                    </div>
                ]}
            >

                {renderPrompt()}
            </Modal>
        );
    }

    const renderResults = () => {
        if (_.isEmpty(results) && isLoading) {
            return (
                <LoadingScreen/>
            )
        }

        return results.map(result => renderResult(result));
    }

    return (
        <div className="PlaygroundResults">
            {renderResults()}
            {renderPromptModal()}
        </div>
    );
}

export default PlaygroundResults;