import { Flex, Link, useTheme } from "@aws-amplify/ui-react";
import "./SignUpFooter.css";

export function SignUpFooter() {
    const { tokens } = useTheme();

    const openTermsAndConditionsLink = () => {
        window.open('https://cmos.ai/termos-e-condicoes/', '_blank');
    }

    return (
        <Flex className="SignUpFooterTermsAndConditions" justifyContent="center" padding={`0 32px ${tokens.space.medium}`}>
            <span  className="SignUpFooterTermsAndConditions">
                Ao prosseguir, você concorda com nossos <Link className="SignUpFooterLink" onClick={openTermsAndConditionsLink}>Termos & Condições</Link>
            </span>
        </Flex>
    );
}
