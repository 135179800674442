import {Button, Modal} from "antd";
import "./SkipTrialConfirmationModal.css";
import PremiumButton from "../../../../components/buttons/PremiumButton";
import {useSubscriptionService} from "../../../../utils/hooks/useSubscriptionService";
import moment from "moment/moment";
import _ from "lodash";

const SkipTrialConfirmationModal = ({idSubscription, isOpen, onClose}) => {
    const subscriptionService = useSubscriptionService();
    const skipSubscriptionMutation = subscriptionService.skipSubscriptionTrial();

    const onConfirm = () => {
        skipSubscriptionMutation.mutate(idSubscription, {
            onSuccess: (data) => onClose(),
            onError: (error) => onClose()
        });
    }

    const renderTitle = () => {
        return (
            <label className="PremiumTitle">
                Você deseja pular o período de testes?
            </label>
        );
    }

    const getPlanImageProvisionCredits = () => {
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
        if (!_.isEmpty(activeSubscriptionPlan)) {
            const imageProvision = _.get(activeSubscriptionPlan, "provisions", []).find((provision) => provision.type === "ImageGenerationCredits");
            return _.get(imageProvision, "balance", 0);
        }
        return 0;
    }

    const renderContent = () => {
        const activeSubscription = subscriptionService.getUserActiveSubscription();
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();

        return (
            <>
                <label className="SkipTrialConfirmModalParagraph">Você possui
                    mais <strong>{moment(activeSubscription.trialEnd).diff(moment(), 'days')} dias</strong> de testes
                    grátis do plano <strong>{_.get(activeSubscriptionPlan, "name", null)}</strong>.</label>
                <label className="SkipTrialConfirmModalParagraph">Ao pular o período de testes, você
                    receberá <strong>{getPlanImageProvisionCredits()} créditos de imagens</strong> para utilizar e uma
                    nova fatura será gerada em seu cartão.</label>
            </>
        );
    }

    const renderFooter = () => {
        return [
            <Button
                key="back"
                onClick={onClose}
                disabled={skipSubscriptionMutation.isLoading}
            >
                Cancelar
            </Button>,
            <span className="SkipTrialConfirmModalConfirmButton">
                <PremiumButton
                    onClick={onConfirm}
                    disabled={skipSubscriptionMutation.isLoading}
                    loading={skipSubscriptionMutation.isLoading}
                >
                    Confirmar
                </PremiumButton>
            </span>
        ];
    }

    return (
        <Modal
            open={isOpen}
            centered
            footer={renderFooter()}
            onClose={onClose}
            title={renderTitle()}
            onCancel={onClose}
        >
            {renderContent()}
        </Modal>
    );
}

export default SkipTrialConfirmationModal;