import {Button} from "antd";
import "./PlaygroundPlaceholders.css";
import React, {useState} from "react";
import _ from "lodash";
import ToolForm from "../tools/ToolForm";

const PlaygroundPlaceholders = ({placeholders, onClick, onValueChange, tool}) => {
    const [usedPlaceholders, setUsedPlaceholders] = useState([]);

    const getPlaceholders = () => {
        if (!_.isEmpty(placeholders)) {
            return placeholders;
        }

        return [
            "name", "description", "language", "value", "currency"
        ];
    }

    const handlePlaceholderClick = (placeholder) => {
        setUsedPlaceholders(
            [...usedPlaceholders.filter(p => p !== placeholder), placeholder]
        );
        onClick(placeholder);
    }

    const renderTitle = () => {
        return (
            <div className="PlaygroundPlaceholdersTitle">
                Variáveis
            </div>
        );
    }

    const renderPlaceholder = (placeholder) => {
        const isPlaceholderUsed = usedPlaceholders.includes(placeholder);
        const style = isPlaceholderUsed ? {textDecoration: 'line-through'} : {};
        return (
            <div key={`${placeholder}-placeholder-key`} className="PlaygroundPlaceholdersPlaceholder">
                <Button
                    type="text"
                    shape="round"
                    block
                    onClick={() => handlePlaceholderClick(placeholder)}
                >
                    <div className="PlaygroundPlaceholdersPlaceholderText" style={style}>
                        {placeholder}
                    </div>
                </Button>
            </div>
        );
    }

    const renderPlaceholders = () => {
        return (
            <div>
                <ToolForm
                    tool={tool}
                    disableGeneration
                    onPlaceholderClick={handlePlaceholderClick}
                    onValueChange={onValueChange}
                />
            </div>
        );
    }

    return (
        <div className="PlaygroundPlaceholdersContainer">
            {renderTitle()}
            {renderPlaceholders(getPlaceholders())}
        </div>
    );
}

export default PlaygroundPlaceholders;