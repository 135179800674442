import React from 'react';
import {useDeleteTool, usePublishTool, useUnpublishTool} from "../../../utils/hooks/useTools";
import {Avatar, List, Modal, Skeleton, Tag} from "antd";
import {CheckOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import ContentCreatorToolsDropdownOptions from "./ContentCreatorToolsDropdownOptions";
import {useNavigate} from "react-router-dom";

const ContentCreatorToolsListItem = ({item}) => {
    const {deleteTool} = useDeleteTool();
    const {publishTool} = usePublishTool();
    const {unpublishTool} = useUnpublishTool();

    const navigate = useNavigate();

    const [modal, contextHolder] = Modal.useModal();

    const getStatusLabel = (status) => {
        return status === 'PUBLISHED' ? 'Publicado' : 'Oculto';
    }

    const getStatusTagColor = (status) => {
        return status === 'PUBLISHED' ? 'green' : 'default';
    }

    const getTagIcon = (status) => {
        return status === 'PUBLISHED' ? <CheckOutlined /> : <EyeInvisibleOutlined />;
    }

    const handleEdit = () => {
        navigate("/creator/tools/edit", {state: {tool: item}});
    }

    const renderDeleteConfirmModal = (tool) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Excluir "{tool.name}"
                    </div>
                    <div>
                        Tem certeza que deseja excluir esta ferramenta?
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                deleteTool(tool.idTool)
            },
        });
    }

    const renderPublishConfirmModal = (tool) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Publicar "{tool.name}"
                    </div>
                    <div className="mb-2 flex flex-col gap-2">
                        <div>Tem certeza que deseja publicar esta ferramenta?</div>
                        <div>Após a publicação, esta ferramenta ficará disponível para os usuários.</div>
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                publishTool(tool.idTool)
            },
        });
    }

    const renderUnpublishConfirmModal = (tool) => {
        modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: (
                <div>
                    <div className="font-bold mb-2">
                        Ocultar "{tool.name}"
                    </div>
                    <div className="mb-2 flex flex-col gap-2">
                        <div>Tem certeza que deseja ocultar esta ferramenta?</div>
                        <div>Após confirmar, esta ferramenta deixará de estar disponível para todos os usuários.</div>
                    </div>
                </div>
            ),
            okText: 'Sim, tenho certeza',
            cancelText: 'Não, cancelar',
            onOk: () => {
                unpublishTool(tool.idTool)
            },
        });
    }

    return (
        <List.Item className="font-primary">
            <Skeleton avatar title={false} loading={item.loading} active>
                <div className="flex flex-row gap-4 flex-grow">
                    <div className="flex flex-row items-center gap-4">
                        <Avatar
                            src={`${process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL}${item.icon}`}
                        />
                        <div>
                            <div className="font-bold">{item.name}</div>
                            <div className="text-gray-400 text-sm">{item.description}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row gap-2 items-center">
                    <Tag bordered color={getStatusTagColor(item?.status)} icon={getTagIcon(item?.status)}>
                        {getStatusLabel(item?.status)}
                    </Tag>
                </div>


                <div className="ml-4">
                    <ContentCreatorToolsDropdownOptions
                        tool={item}
                        onEdit={() => handleEdit()}
                        onDelete={() => renderDeleteConfirmModal(item)}
                        onPublish={() => renderPublishConfirmModal(item)}
                        onUnpublish={() => renderUnpublishConfirmModal(item)}
                    />
                </div>
            </Skeleton>
            {contextHolder}
        </List.Item>
    );
};

export default ContentCreatorToolsListItem;