import {urlBase, params, resolve} from "./resources";

const url = urlBase + "/api/message";


async function create(message) {
    const content = {body: message};
    return fetch(url, await params.put(content))
        .then(response => response.json())
        .then(resolve);
}

async function update(message) {
    const content = {body: message};
    return fetch(url, await params.post(content))
        .then(response => response.json())
        .then(resolve);
}

async function list(idChat) {
    return fetch(`${url}/${idChat}`, await params.get())
        .then(response => response.json())
        .then(resolve);
}

async function remove(idChat, idMessage) {
    return fetch(`${url}/${idChat}/${idMessage}`, await params.delete())
        .then(response => response.json())
        .then(resolve);
}

export const MessageRemote = {
    create,
    update,
    list,
    remove
};