import _ from "lodash";


export const replace = (item, collection, predicate) => {
    const copyCollection = _.cloneDeep(collection);
    const index = _.findIndex(copyCollection, predicate)
    copyCollection.splice(index, 1, item);
    return copyCollection;
}

export const remove = (collection, predicate) => {
    const copyCollection = _.cloneDeep(collection);
    const index = _.findIndex(copyCollection, predicate)
    copyCollection.splice(index, 1);
    return copyCollection;
}

export const CollectionUtils = {
    replace,
    remove
}