import "./WalletBalanceWidget.css";
import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import {UserContext} from "../../utils/providers/userProvider";
import {LoadingOutlined} from "@ant-design/icons";
import {useWalletService} from "../../utils/hooks/useWalletService";
import {Badge, Button, Tooltip} from "antd";
import {Theme} from "../../api/Theme";
import UpgradeButton from "../subscription/UpgradeButton";
import {SubscriptionModalStatusContext} from "../../utils/providers/SubscriptionModalStatusContextProvider";

const WalletBalanceWidget = ({children, currentOrganization}) => {
    const {user, isLoading} = useContext(UserContext); //change to session
    const [wallet, setWallet] = useState(null);
    const subscriptionModalStatusContext = useContext(SubscriptionModalStatusContext);
    const walletService = useWalletService();

    const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

    useEffect(() => {
        if (user) {
            setWallet(walletService.getWallet());
        }
    }, [user, isLoading, currentOrganization]);

    const getBalance = () => {
        const balance = _.get(wallet, "balance.advanced", 0);
        return balance > 0 ? balance : 0;

        if (_.isEmpty(user)) {
            return 0;
        }

        const preDatedBalance = _.get(user, "wallet.funds.advancedModelCredits.preDatedBalance", []);

        // FIXME: Fallback verification. Remove as soon as the new pre dated balance is validated.
        if (_.isEmpty(preDatedBalance)) {
            return _.get(user, "wallet.funds.advancedGenerationCredits.balance", 0);
        }

        const currentPreDatedBalance = _.head(preDatedBalance.filter((preDatedBalance) => {
            return (preDatedBalance.validFrom * 1000) <= new Date() && (preDatedBalance.validUntil * 1000) >= new Date();
        }));

        if (currentPreDatedBalance) {
            return currentPreDatedBalance.currentBalance;
        }
        return 0;
    }

    const renderBalance = () => {
        if (isLoading) {
            return <div>
                <LoadingOutlined/>
            </div>;
        }

        return (
            <Tooltip title={renderNumberOfRequests()} color={Theme.PRIMARY} placement={"right"}>
                <div>
                    <label className="WalletBalanceWidgetSubtitle">{getBalance()} palavras</label>
                </div>
            </Tooltip>
        );
    }

    const renderNumberOfRequests = () => {
        const balance = getBalance();

        if (balance > 0) {
            return (
                <label>
                    ± {Math.round(getBalance() / 144)} requisições restantes
                </label>
            );
        }
    }

    const getOrganizationActiveSubscription = () => {
        const activeSubscriptions = _.get(user, "activeSubscriptions", []);
        if (_.isEmpty(activeSubscriptions)) {
            return null;
        }

        return activeSubscriptions.filter(activeSubscription => _.get(activeSubscription, "subscriptionPlan", false))
            .map(activeSubscription => activeSubscription.subscriptionPlan)
            .find(subscription => subscription.idOrganization === currentOrganization.idOrganization);
    }

    const doesUserHaveOrganizationActiveSubscriptions = () => {
        return !_.isEmpty(getOrganizationActiveSubscription());
    }

    const renderUpgradeButton = () => {
        if (doesUserHaveOrganizationActiveSubscriptions()) {
            return (
                <div className="SubscriptionWidgetMoreDetailsContainer">
                    <Button
                        className="SubscriptionWidgetMoreDetailsButton"
                        type="text"
                        size="middle"
                        onClick={() => setOpenUpgradeModal(true)}
                    >
                        Mais detalhes
                    </Button>
                </div>
            );
        }

        return (
            <div className="WalletBalanceWidgetUpgradeContainer">
                <UpgradeButton size="large"/>
            </div>
        );
    }

    const renderActiveUpgradeBadge = () => {
        const organizationActiveSubscription = getOrganizationActiveSubscription();
        if (_.isEmpty(organizationActiveSubscription)) {
            return null;
        }

        return (
            <Tooltip>
                <Badge count={"upgrade ativo"} style={{marginLeft: 8}} color={Theme.SECONDARY}/>
            </Tooltip>
        )
    }

    if (wallet == null || _.isEmpty(currentOrganization) || _.get(currentOrganization, "idOrganization", 1) === 1) {
        return null;
    }

    return (
        <div className="WalletBalanceWidget">
            <label className="WalletBalanceWidgetTitle">
                Saldo atual
                {renderActiveUpgradeBadge()}
            </label>

            {renderBalance()}
            {renderUpgradeButton()}
        </div>
    );
}

export default WalletBalanceWidget;