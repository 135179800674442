import { useImages } from "./useImages";
import { useMemo } from "react";

export const useGeneratedImages = () => {
    const { images, refetch, isLoading, isError, error } = useImages();

    const generatedImages = useMemo(() => {
        return images.filter(image => image.type === 'generated');
    }, [images]);

    return { images: generatedImages, refetch, isLoading, isError, error };
};
