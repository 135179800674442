import {Button, Input, message} from "antd";
import React, {forwardRef, useEffect, useImperativeHandle} from "react";
import "./PlaygroundInput.css";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";

const PlaygroundInput = forwardRef(({isGenerating, onGenerate, onPromptChange}, ref) => {
    const [input, setInput] = React.useState("");

    useEffect(() => {
        onPromptChange(input);
    }, [input]);

    useImperativeHandle(ref, () => ({
        setPlaceholderVariable,
        input
    }));

    const setPlaceholderVariable = (placeholder) => {
        const newInput = input + "{{" + placeholder + "}}";
        setInput(newInput);
    }

    const handleGenerate = () => {
        onGenerate(input);
    }

    function handleCopy(event) {
        navigator.clipboard.writeText(input)
            .then(() => {
                message.success("Copiado!", 1);
            });
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            handleGenerate();
        }
    }

    const renderTextField = () => {
        return (
            <Input.TextArea
                size={"large"}
                placeholder={"Conte-me uma história."}
                onChange={(e) => setInput(e.target.value)}
                value={input}
                rows={10}
                autoSize={{minRows: 10, maxRows: 10}}
                showCount
                onKeyDown={handleKeyDown}
            />
        );
    }

    const renderButtons = () => {
        return (
            <div className="PlaygroundInputButtons">
                {renderCopyButton()}
                {renderSubmitButton()}
            </div>
        );
    }

    const renderCopyButton = () => {
        return (
            <div className="PlaygroundInputSubmitButton">
                <Button
                    type="text"
                    style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                    icon={<CopyOutlined />}
                    onClick={handleCopy}
                >
                    Copiar prompt
                </Button>
            </div>
        );
    }

    const renderSubmitButton = () => {
        return (
            <div className="PlaygroundInputSubmitButton">
                <Button
                    type="primary"
                    onClick={handleGenerate}
                    disabled={isGenerating}
                >
                    {isGenerating && <><LoadingOutlined spin/></>}
                    {!isGenerating && "Gerar"}
                </Button>
            </div>
        );
    }

    return (
        <div>
            {renderTextField()}
            {renderButtons()}
        </div>
    )
});

export default PlaygroundInput;