// src/pages/image-generator/ImageGeneratorPage.jsx

import { useEffect, useState } from "react";
import MainHeaderTitle from "../../../components/main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCropSimple,
    faImage,
    faObjectGroup,
    faWandMagicSparkles,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "./ImageGeneratorPage.css";
import Submenu from "../../../components/submenu/Submenu";
import ImageGallery from "../components/gallery/ImageGallery";
import CommunityGallery from "../community-gallery/CommunityGallery";
import ImageGenerator from "../components/generator/ImageGenerator";
import ImageEditor from "../components/editor/ImageEditor";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import ImageCreditsWidget from "../components/widget/ImageCreditsWidget";
import useIsMobile from "../../../utils/hooks/useIsMobile";

const ImageGeneratorPage = ({ setHeaderTitle }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [screen, setScreen] = useState("generator");
    const isMobile = useIsMobile();

    useEffect(() => {
        document.title = "Imagens · CMOs.ai"; // Adicionado aspas
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faImage} />}
                title="Imagens"
            />
        );

        // Navegação condicional para evitar redirecionamento se já estiver em uma sub-rota
        if (location.pathname === '/image' || location.pathname === '/image/') {
            navigate(`/image/${screen}`); // Corrigido template literal
        }
    }, [location.pathname, navigate, screen, setHeaderTitle]);

    const handleScreenChange = (screen, idImage) => {
        setScreen(screen);

        if (idImage) {
            navigate(`/image/${screen}`, { state: { idImage } }); 
        } else {
            navigate(`/image/${screen}`); 
        }
    }

    const renderImageCreditsWidget = () => {
        return (
            <div>
                <ImageCreditsWidget />
            </div>
        );
    }

    const renderMenu = () => {
        const items = [
            {
                key: "generator",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faWandMagicSparkles} className="submenu-icon" />
                        <span>Gerador</span>
                    </div>
                )
            },
            {
                key: "gallery",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faObjectGroup} className="submenu-icon" />
                        <span>Galeria</span>
                    </div>
                )
            },
            {
                key: "community",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faUsers} className="submenu-icon" />
                        <span>Comunidade</span>
                    </div>
                )
            },
        ];

        const itemsMobile = [
            {
                key: "generator",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faWandMagicSparkles} className="submenu-icon" />
                        <span>Gerador</span>
                    </div>
                )
            },
            {
                key: "gallery",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faObjectGroup} className="submenu-icon" />
                        <span>Galeria</span>
                    </div>
                )
            },
            {
                key: "community",
                label: (
                    <div className="submenu-item">
                        <FontAwesomeIcon icon={faUsers} className="submenu-icon" />
                        <span>Comunidade</span>
                    </div>
                )
            },
        ];

        return (
            <div className="submenu-container">
                <Submenu
                    items={isMobile ? itemsMobile : items}
                    selectedKeys={[screen]}
                    onClick={(item) => handleScreenChange(item.key)}
                    footer={renderImageCreditsWidget()}
                />
            </div>
        );
    }

    const renderContent = () => {
        return (
            <div className="ImageGeneratorPage">
                <Routes>
                    <Route path="generator" element={<ImageGenerator onScreenChange={handleScreenChange} />} />
                    <Route path="gallery" element={<ImageGallery onScreenChange={handleScreenChange} />} />
                    <Route path="editor" element={<ImageEditor onScreenChange={handleScreenChange} />} />
                    <Route path="community" element={<CommunityGallery onScreenChange={handleScreenChange} />} />
                </Routes>
            </div>
        );
    }

    return (
        <>
            {renderMenu()}
            {renderContent()}
        </>
    );
}

export default ImageGeneratorPage;
