import {createContext, useState} from "react";

export const SubscriptionModalStatusContext = createContext([]);

export const SubscriptionModalStatusContextProvider = ({children}) => {
    const [isOpen, setOpen] = useState(false);
    const [isOrganizationSensitive, setOrganizationSensitive] = useState(false);

    const show = (organizationBased = false) => {
        setOrganizationSensitive(organizationBased);
        setOpen(true);
    }

    const hide = () => {
        setOrganizationSensitive(false);
        setOpen(false);
    }

    return (
        <SubscriptionModalStatusContext.Provider value={{
            isOpen,
            isOrganizationSensitive,
            show,
            hide
        }}>
            {children}
        </SubscriptionModalStatusContext.Provider>
    );
}