import React from 'react';
import {Avatar, List, Skeleton, Tag} from "antd";
import WorkspaceTeamMemberDropdownOptions from "./WorkspaceTeamMemberDropdownOptions";
import {useCurrentWorkspace} from "../../../utils/hooks/useCurrentWorkspace";
import {useRemoveMemberFromWorkspace} from "../../../utils/hooks/useWorkspaces";

const WorkspaceTeamListItem = ({item}) => {
    const {currentWorkspace} = useCurrentWorkspace();
    const {removeMemberFromWorkspace} = useRemoveMemberFromWorkspace();

    const isDisabled = () => {
        return currentWorkspace?.members?.length >= currentWorkspace?.maxUsers;
    }

    const getFirstUpperLetter = (name) => {
        const [first, _] = name.split(" ")
        return first.charAt(0).toUpperCase();
    }

    const getSecondUpperLetter = (name) => {
        const [_, second] = name.split(" ")
        return second?.charAt(0).toUpperCase();
    }

    const translateRole = (role) => {
        switch (role) {
            case "OWNER":
                return "Administrador";
            case "MEMBER":
                return "Membro";
            default:
                return "Membro";
        }
    }

    const getRoleColor = (role) => {
        switch (role) {
            case "OWNER":
                return "green";
            case "MEMBER":
                return "lightgray";
            default:
                return "gray";
        }
    }

    return (
        <List.Item className="font-primary">
            <Skeleton avatar title={false} loading={item.loading} active>
                <div className="flex flex-row gap-4 flex-grow">
                    <div className="flex flex-row items-center gap-4">
                        <Avatar src={item.avatar}>
                            {getFirstUpperLetter(item.name)}{getSecondUpperLetter(item.name)}
                        </Avatar>
                        <div>
                            <div className="font-bold">{item.name}</div>
                            <div className="text-gray-400 text-sm">{item.email}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row items-center gap-4">
                    <div>
                        <Tag color={getRoleColor(item.role)}>{translateRole(item.role)}</Tag>
                    </div>
                </div>


                <div className="ml-4">
                    <WorkspaceTeamMemberDropdownOptions
                        item={item}
                        onRemove={() => removeMemberFromWorkspace({idWorkspace: currentWorkspace.idWorkspace, idUser: item.idUser})}
                        disabled={isDisabled()}
                    />
                </div>
            </Skeleton>
        </List.Item>
    );
};

export default WorkspaceTeamListItem;