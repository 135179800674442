import {Card, Collapse, Input, Popconfirm, Rate, Select, Tooltip} from "antd";
import "./ResultCard.css";
import React from "react";
import {faBookmark, faClone, faEdit, faTrashCan} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {message, Modal} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {faCheck, faXmark, faBookmark as faBookmarkSolid} from "@fortawesome/free-solid-svg-icons";
import RateWrapper from "../../utils/components/rate-wrapper/RateWrapper";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

const {confirm} = Modal;


const ResultCard = ({result, isGenerating, onEdit, onRemove, onRate, onTitleChange, onSave}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [isEditing, setEditing] = React.useState(false);

    const [title, setTitle] = React.useState(result.title);
    const [content, setContent] = React.useState(result.content);

    // const [isSaveModalOpen, setSaveModalOpen] = React.useState(false);
    const [isSaved, setSaved] = React.useState(false);

    function showDeleteConfirmModal() {
        confirm({
            title: "Você deseja apagar esse resultado?",
            icon: <ExclamationCircleFilled/>,
            okText: "Apagar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
                onRemove(result);
            },
            onCancel() {
            }
        });
    }

    function handleCopy(event) {
        if (isGenerating || isEditing)
            return;

        navigator.clipboard.writeText(result.content)
            .then(() => {
                messageApi.success("Copiado!", 1);
            })
    }

    function handleRemove(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        showDeleteConfirmModal();
    }

    function handleEdit(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        setTitle(result.title);
        setContent(result.content);
        setEditing(true);
    }

    function handleContentChange(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        setContent(event.target.value);
    }

    function handleResultEditConfirm(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        setEditing(false);
        onEdit(result, content);
    }

    function handleResultEditCancel(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        setEditing(false);
    }

    function handleResultRatingChange(value, event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        onRate(result, value);
    }

    const handleResultTitleChange = (event) => {
        event.stopPropagation();

        if (isGenerating)
            return;

        onTitleChange(result, event.target.value);
    }

    function handleSave(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        onSave(result);
    }

    function handleUnsave(event) {
        event.stopPropagation();

        if (isGenerating)
            return;

        onSave(result);
    }

    // const handleSaveResultModalOk = () => {
    //     setSaveModalOpen(false);
    //     setSaved(true);
    //     handleSave();
    // }
    //
    // const handleSaveResultModalCancel = () => {
    //     setSaveModalOpen(false);
    // }
    //
    const handleSaveButtonClick = (event) => {
        event.stopPropagation();

        setSaved(!isSaved);
        isSaved ? handleUnsave() : handleSave();
    }

    function renderIconTray() {
        if (isGenerating)
            return (<div style={{height: "22px"}}></div>)

        if (isEditing) {
            return renderEditIconTray();
        }

        return renderRegularIconTray();
    }

    function renderEditIconTray() {
        return (
            <div className="ResultCardIconTray">
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleResultEditConfirm}>
                    <FontAwesomeIcon icon={faCheck}/>
                </div>
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleResultEditCancel}>
                    <FontAwesomeIcon icon={faXmark}/>
                </div>
            </div>
        );
    }

    function renderRegularIconTray() {
        return (
            <div className="ResultCardIconTray">
                {/*{renderRate()}*/}
                {renderCopyButton()}
                {renderDeleteButton()}
                {renderEditButton()}
                {/*{renderSaveButton()}*/}
            </div>
        );
    }

    function renderRate() {
        return (
            <RateWrapper onChange={handleResultRatingChange}/>
        )
    }

    function renderCopyButton() {
        return (
            <Tooltip title="Copiar" color={"#724cf9"} key={"copy-icon-tray-icon"}>
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleCopy}>
                    <FontAwesomeIcon icon={faClone}/>
                </div>
            </Tooltip>
        );
    }

    function renderDeleteButton() {
        return (
            <Tooltip title="Apagar" color={"#724cf9"} key={"delete-icon-tray-icon"}>
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleRemove}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </div>
            </Tooltip>
        );
    }

    function renderEditButton() {
        return (
            <Tooltip title="Editar" color={"#724cf9"} key={"edit-icon-tray-icon"}>
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleEdit}>
                    <FontAwesomeIcon icon={faEdit}/>
                </div>
            </Tooltip>
        );
    }

    function renderSaveButton() {
        return (
            <Tooltip title="Salvar" color={"#724cf9"} key={"save-icon-tray-icon"}>
                <div className={!isGenerating && "ResultCardIconTrayIcon"} onClick={handleSaveButtonClick}>
                    {isSaved ? <FontAwesomeIcon className="ResultCardSavedIcon" icon={faBookmarkSolid}/> :
                        <FontAwesomeIcon icon={faBookmark}/>}
                </div>
            </Tooltip>
        );
    }

    const renderTitle = () => {
        if (isEditing) {
            return (
                <Input
                    className={"ResultCardTitleField"}
                    bordered={false}
                    maxLength={70}
                    value={title}
                    onChange={handleResultTitleChange}
                />
            )
        }

        return (
            <div className={"ResultCardTitle"}>
                {result.title}
            </div>
        )
    }

    function renderContent() {
        if (isEditing) {
            return (
                <TextArea
                    className="ResultCardContentField"
                    autoSize
                    value={content}
                    bordered={false}
                    onChange={handleContentChange}
                />
            )
        }

        return (
            <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
            >
                {result.content && result.content.replace(/```/g, "")}
            </ReactMarkdown>
        );
    }

    // const handleDelete = i => {
    //     setTags(tags.filter((tag, index) => index !== i));
    // };
    //
    // const handleAddition = tag => {
    //     setTags([...tags, tag]);
    // };
    //
    // const handleDrag = (tag, currPos, newPos) => {
    //     const newTags = tags.slice();
    //
    //     newTags.splice(currPos, 1);
    //     newTags.splice(newPos, 0, tag);
    //
    //     // re-render
    //     setTags(newTags);
    // };
    //
    // const handleTagClick = index => {
    //     console.log('The tag at index ' + index + ' was clicked');
    // };
    //
    // const renderSaveModal = () => {
    //     const KeyCodes = {
    //         comma: 188,
    //         enter: 13
    //     };
    //
    //     const delimiters = [KeyCodes.comma, KeyCodes.enter];
    //
    //     return (
    //         <Modal
    //             title="Salvar resultado"
    //             open={isSaveModalOpen}
    //             onOk={handleSaveResultModalOk}
    //             onCancel={handleSaveResultModalCancel}
    //             okText={"Salvar"}
    //             cancelText={"Cancelar"}
    //         >
    //             <Input
    //                 placeholder={"Título"}
    //                 maxLength={70}
    //                 showCount={true}
    //                 onChange={handleTitleChange}
    //             />
    //
    //             {/*<ReactTags*/}
    //             {/*    delimiters={delimiters}*/}
    //             {/*    tags={tags}*/}
    //             {/*    handleDelete={handleDelete}*/}
    //             {/*    handleAddition={handleAddition}*/}
    //             {/*    handleDrag={handleDrag}*/}
    //             {/*    handleTagClick={handleTagClick}*/}
    //             {/*    inputFieldPosition="bottom"*/}
    //             {/*    autocomplete*/}
    //             {/*/>*/}
    //
    //         </Modal>
    //     )
    // }

    return (
        <>
            <Card
                className="ResultCard"
                onClick={handleCopy}
            >
                {contextHolder}
                {renderTitle()}
                <div className="ResultCardContent">
                    {renderContent()}
                </div>

                {renderIconTray()}
                {/*{renderSaveModal()}*/}
            </Card>

        </>
    );
}

export default ResultCard;