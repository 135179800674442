import React from 'react';
import {List, Skeleton, Tag} from "antd";
import WorkspaceTeamInviteDropdownOptions from "./WorkspaceTeamInviteDropdownOptions";
import {useDeleteWorkspaceInvitation} from "../../../utils/hooks/useWorkspaceInvitations";

const WorkspaceTeamInviteListItem = ({item}) => {
    const {deleteInvitation} = useDeleteWorkspaceInvitation();

    const getStatusLabel = (status) => {
        switch (status) {
            case "ACTIVE":
                return "Ativo";
            case "PENDING":
                return "Pendente";
            case "ACCEPTED":
                return "Aceito";
            case "EXPIRED":
                return "Expirado";
            default:
                return "Error";
        }
    }

    const getStatusColor = (status) => {
        switch (status) {
            case "ACTIVE":
                return "blue";
            case "PENDING":
                return "lightgray";
            case "ACCEPTED":
                return "green";
            case "EXPIRED":
                return "red";
            default:
                return "gray";
        }
    }

    return (
        <List.Item className="font-primary">
            <Skeleton avatar title={false} loading={item.loading} active>
                <div className="flex flex-row gap-4 flex-grow">
                    <div className="flex flex-row items-center gap-4">
                        <div>
                            <div className="font-md">{item.recipient}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row items-center gap-4">
                    <div>
                        <Tag color={getStatusColor(item.status)}>{getStatusLabel(item.status)}</Tag>
                    </div>
                </div>


                <div className="ml-4">
                    <WorkspaceTeamInviteDropdownOptions
                        onDelete={() => deleteInvitation({idWorkspace: item.idWorkspace, idWorkspaceInvitation: item.idWorkspaceInvitation})}
                    />
                </div>
            </Skeleton>
        </List.Item>
    );
};

export default WorkspaceTeamInviteListItem;