import {Button, Col, Input, Row, message} from "antd";
import React, {useState} from "react";
import SettingsProfilePicture from "./components/SettingsProfilePicture";
import _ from "lodash";
import {InputMask} from "primereact/inputmask";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {LoadingOutlined} from "@ant-design/icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserRemote from "../../../services/dao/remote/user";
import SettingsContainer from "../components/SettingsContainer";

const SettingsProfile = ({user, defaultPicture}) => {
    const [name, setName] = useState(_.get(user, "name", ""));
    const [phoneNumber, setPhoneNumber] = useState(_.get(user, "phoneNumber", ""));
    const [picture, setPicture] = useState(_.get(user, "picture", null) ? _.get(user, "picture", null) : defaultPicture);

    const queryClient = useQueryClient();

    const update = useMutation({
        mutationFn: (data) => UserRemote.update(data),
        onSuccess: (user) => {
            queryClient.setQueryData(["user"], (old) => user);
            message.open({
                type: 'success',
                content: 'Perfil atualizado!',
            });
        },
        onError: () => {
            message.open({
                type: 'error',
                content: 'Erro ao atualizar perfil!',
            });
        }
    });

    const handleUpdateProfile = () => {
        update.mutate({name, phoneNumber});
    }

    const handleUpdatePicture = (user) => {
        queryClient.setQueryData(["user"], (old) => user);
        setPicture(user.picture);
        message.open({
            type: 'success',
            content: 'Imagem de perfil atualizada!',
        });
    }

    const renderNameField = () => {
        return (
            <div className="SettingsField">
                <div className="SettingsFieldTitle">
                    Nome completo
                </div>
                <Input
                    size={"large"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
        );
    }

    const renderPhoneNumberField = () => {
        return (
            <div className="SettingsField">
                <div className="SettingsFieldTitle">
                    Telefone
                </div>
                <InputMask
                    mask="+55 (99) 99999 9999"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    style={{width: "100%"}}
                >
                    {() => <Input/>}
                </InputMask>
            </div>
        );
    }

    const renderProfilePicture = () => {
        return (
            <div className="SettingsProfilePicture">
                <SettingsProfilePicture
                    idUser={user.idUser}
                    profilePicture={picture}
                    onUpdate={handleUpdatePicture}
                />
            </div>

        );
    }

    const renderUpdateButton = () => {
        return (
            <div className="SettingsSaveButton">
                <Button
                    size="large"
                    type="primary"
                    onClick={handleUpdateProfile}
                    disabled={update.isLoading}
                >
                    {update.isLoading ? (
                        <>
                            <LoadingOutlined
                                style={{marginRight: 6}}
                                spin
                            /> Atualizando
                        </>
                    ) : ('Atualizar')}
                </Button>
            </div>
        );
    }

    return (
        <SettingsContainer containerized>
            <div className="SettingsSectionTitle" style={{marginBottom: 24}}>
                <FontAwesomeIcon icon={faUser} style={{marginRight: 12}}/>
                Meu perfil
            </div>
            <Row gutter={36}>
                <Col
                    xs={{span: 8}}
                    lg={{span: 6}}
                >
                    {renderProfilePicture()}
                </Col>
                <Col
                    xs={{span: 16}}
                    lg={{span: 18}}
                >
                    {renderNameField()}
                    {renderPhoneNumberField()}
                </Col>
            </Row>
            {renderUpdateButton()}
        </SettingsContainer>
    );
}

export default SettingsProfile;