import React, {useState} from 'react';
import {InboxOutlined} from "@ant-design/icons";
import {Upload} from "antd";
import {getAuthorization} from "../../../services/dao/remote/resources";
import BrandVoiceRemote from "../../../services/dao/remote/brandVoice";
import LoadingScreen from "../../../components/loading-screen/LoadingScreen";

const {Dragger} = Upload;

const DocsUpload = ({onUpload, onLoading}) => {
    const [progress, setProgress] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [isUploading, setUploading] = useState(false);

    const getRequestHeaders = async () => {
        return {
            'Authorization': await getAuthorization(),
            'Content-Type': 'multipart/form-data'
        }
    }

    const getRequestConfig = async (onUploadProgress) => {
        return {
            headers: await getRequestHeaders(),
            onUploadProgress
        };
    }

    const customRequest = async (options) => {
        const {onSuccess, onError, file, onProgress} = options;

        const data = new FormData();
        data.append("file", file);
        setUploading(true);
        onLoading?.(true);

        const config = await getRequestConfig(event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({percent: (event.loaded / event.total) * 100});
        });

        try {
            const response = await BrandVoiceRemote.scanFile(data, config);
            onSuccess(response.data);
        } catch (err) {
            onError({err});
        } finally {
            setUploading(false);
            onLoading?.(false);
        }
    }

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleChange = ({file, fileList, event}) => {
        setFileList(fileList);

        if (file.status === 'done') {
            onUpload?.(file.response);
        }
    };

    const renderUploadContent = () => {
        if (isUploading) {
            return <LoadingScreen/>
        } else {
            return (
                <>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Clique ou solte um arquivo nesta área para fazer o upload</p>
                </>
            );
        }
    }

    return (
        <div>
            <Dragger
                accept=".doc,.docx,.pdf,.txt"
                customRequest={customRequest}
                onChange={handleChange}
                onPreview={onPreview}
                listType="picture-card"
                fileList={fileList}
                showUploadList={false}
            >
                <div style={{
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {renderUploadContent()}
                </div>
            </Dragger>
        </div>
    );
};

export default DocsUpload;